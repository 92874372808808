@media (max-width: 768px) {

    .p-left-160 {
        padding-left: rem(0);
        box-sizing: border-box;
    }

    .p-right-160 {
        padding-right: rem(0);
        box-sizing: border-box;
    }

    .p-left-190 {
        padding-left: rem(98px);
        box-sizing: border-box;
    }

    .p-right-190 {
        padding-right: rem(98px);
        box-sizing: border-box;
    }

    .br-hide {
        display: none;
    }

    .nav {
        width: 100%;
        height: rem(112px);
        border-right: none;

        display: flex;
        flex-direction: row-reverse;
        border-bottom: 2px solid #fff;
        box-sizing: border-box;

        .top {
            width: 50%;
            height: 100%;
            border-bottom: none;

            .text {
                transform: none;
                margin-left: 45%;
                font-size: rem(40px);
            }
        }

        .bot {
            width: 50%;
            height: 100%;

            .icon {
                width: rem(46px);
                height: rem(36px);
                margin-right: 55%;

                .line {
                    width: 100%;
                    height: rem(4px);

                    &:first-child {}

                    &:nth-child(2) {}

                    &:last-child {}
                }
            }
        }

        &.nav-cisco-landing {
            .bot {
                width: 50%;
                height: 100%;

                margin-right: auto;
    
                .icon {
                    width: rem(46px);
                    height: rem(36px);
                    margin-right: 55%;
    
                    .line {
                        width: 100%;
                        height: rem(4px);
                    }
                }
            }

            &.active {
                transform: translateY(90vh);
            }
        }

        &:hover {
            width: 100%;

            .bot {
                .icon {
                    .line {
                        &:first-child {
                            transform: translateY(rem(-5px));
                        }

                        &:last-child {
                            transform: translateY(rem(5px));
                        }
                    }
                }
            }
        }

        &.active {
            transform: translateY(rem(920px));
        }
    }

    .nav-menu {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;

        width: 100%;
        height: rem(920px);
        background-color: #00aeef;
        border-right: none;
        border-bottom: 1px solid #fff;

        padding-top: rem(50px);
        padding-left: rem(50px);
        padding-right: rem(50px);
        box-sizing: border-box;

        transform: translateY(rem(-920px));

        transition: 0.5s;

        &.active {
            transform: translateY(0);
        }

        .top {
            .info {
                .phone {
                    font-size: rem(36px);
                }

                .address {
                    font-size: rem(20px);
                }
            }
        }

        .list {
            height: rem(500px);

            a {
                font-size: rem(34px);
                &:hover {}
            }
            .item {
                &.subitem {
                    font-size: rem(26px);
                    line-height: rem(42px);
                }
            }

            .submenu {
                &.submenu1 {
                    &:before {
                        height: calc(100% - 2.5rem);
                    }
                }
            }
        }

        .search {
            margin-top: rem(70px);

            .search-wrap {
                input {
                    font-size: 16px;
                    outline: none;

                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    box-shadow: none;
                    border-radius: 0;

                    &.active {
                        width: 100%;
                    }
                }
            }
        }

        &.nav-menu-cisco-landing {
            height: 90vh;

            transform: translateY(-90vh);

            &.active {
                transform: translateY(0);
            }

            .list {
                height: auto;
            }
        }
    }

    .hero {
        background: url(../img/bg_hero_mob.jpg) 50% 50% no-repeat;
        background-size: cover;

        overflow: hidden;

        .container {
            .texts {
                width: 84%;
                height: 100vh;
                left: 0;
                top: initial;
                bottom: rem(70px);
                position: relative;
                justify-content: flex-end;

                .circle_mob {
                    display: block;

                    width: 115vw;
                    height: 79vh;

                    position: absolute;
                    top: 11%;
                    right: -31%;

                    .ball {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }

                    .pic {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: auto;

                        opacity: 0;
                        transform: scale(0.2);
                        transition: 0.8s;

                        &.active {
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                }

                .logo {
                    display: none;
                }

                .title {
                    font-size: rem(44px);
                    margin-bottom: rem(50px);
                }

                .btns {
                    a {
                        font-size: rem(32px);

                        &:last-child {}
                    }

                    .border {
                        border-radius: rem(50px);
                    }
                }
            }

            .video {
                display: none;
            }
        }
    }

    .slider {
        height: rem(840px);

        .slick-dots {
            position: absolute;
            bottom: 3.7%;
            list-style: none;
            display: block;
            text-align: center;
            padding: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: auto;
        }

        .slide {
            position: relative;
            outline: none;
            height: rem(840px);

            img {
                height: auto;
                bottom: initial;
            }

            .text_wrap {
                background-color: #00aeef;

                width: 100%;
                height: rem(270px);

                position: absolute;
                top: initial;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;

                padding: rem(40px 60px);
                box-sizing: border-box;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                z-index: 10;

                .title {
                    font-family: 'PT Sans Caption', sans-serif;
                    font-size: rem(40px);
                }

                .btn {
                    color: #fff;
                    text-decoration: none;
                    font-size: rem(31px);

                    border: 2px solid #fff;
                    box-sizing: border-box;

                    border-radius: rem(40px);

                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    width: rem(267px);
                    height: rem(79px);

                    outline: none;
                }
            }
        }
    }

    .announcement {
        margin-top: rem(0);

        .container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .video_wrap {
                margin-top: rem(30px);
                width: 100%;

                video {}

                .poster {

                    img {}

                    &.hide {}
                }


                .sound {

                    &.active {}
                }

                .mute {

                    &.active {}
                }

                .texts {
                    justify-content: center;
                    align-items: center;
                    padding-left: 0;

                    &.hide {}
                }
            }
        }
    }

    .feed {
        margin-top: rem(50px);

        .container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .feed-wrap {
                .feed-news,
                .feed-promo,
                .feed-events {
                    width: rem(740px);

                    .slick-slide {
                        outline: none;
                    }

                    .item {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;

                        margin-top: rem(0);

                        cursor: pointer;

                        width: rem(276px);

                        .pic {
                            width: rem(276px);
                            height: rem(235px);

                            filter: none;
                            transition: 0.25s;

                            img {
                                width: rem(276px);
                                height: rem(235px);

                                object-fit: cover;
                            }
                        }

                        .texts {
                            margin-left: rem(0);
                            width: rem(276px);

                            .category {
                                margin-top: rem(20px);
                                font-size: rem(24px);
                                color: #b7b7b7;
                            }

                            .title {
                                font-size: rem(40px);
                                color: #00aeef;
                                font-weight: 700;

                                margin-top: rem(20px);
                            }

                            .desc {
                                font-size: rem(28px);
                                color: #000;

                                margin-top: rem(15px);
                            }

                            .date {
                                font-size: rem(19px);
                                color: #b7b7b7;

                                margin-top: rem(24px);
                            }
                        }

                        &:hover .pic {
                            filter: contrast(0.3) brightness(1.5);
                        }

                    }
                }

                .loader {
                    width: 100%;
                    height: 100%;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;

                    background-color: #fff;

                    visibility: visible;
                    opacity: 1;
                    transition: opacity 0.3s, visibility 0.3s 0s;

                    &.hide {
                        opacity: 0;
                        visibility: hidden;
                    }

                    .load {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        margin-top: auto;
                        margin-bottom: auto;

                        width: rem(100px);
                        height: rem(100px);

                        hr {
                            border: 0;
                            margin: 0;
                            width: 40%;
                            height: 40%;
                            position: absolute;
                            border-radius: 50%;
                            animation: spin 2s ease infinite;
                        }

                    }
                }
            }

            .revol {
                background: url(../img/bg_promo_mob.svg) 50% 0% no-repeat;
                background-size: cover;

                width: 100%;
                height: rem(350px);

                position: relative;
                top: rem(100px);

                &:before {
                    display: none;
                }

                .slider-nav {
                    color: #000;
                    position: absolute;

                    bottom: 5%;
                    left: 0;
                    right: 0;
                    margin: auto;

                    z-index: 10;

                    font-size: rem(40px);
                    color: #fff;
                    font-weight: 700;

                    width: 95%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    visibility: visible;

                    .slick-prev,
                    .slick-next {
                        cursor: pointer;
                    }
                }

                .carousel_wrap {
                    width: 100%;
                    height: auto;

                    position: absolute;
                    top: rem(-60px);
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;

                    .slick-track {
                        top: rem(-40px);
                    }

                    .item {
                        height: auto;

                        margin: rem(0);

                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;

                        cursor: pointer;

                        transform: scale(1) translateY(0);
                        transition: 0.4s;

                        outline: none;

                        margin: rem(0 100px);

                        .title {
                            font-size: rem(43px);
                            font-weight: 700;
                            text-align: center;
                            margin-top: rem(30px);

                            order: 2;

                            visibility: visible;
                            opacity: 1;
                        }

                        .desc {
                            font-family: 'PT Sans Caption', sans-serif;
                            font-size: rem(17px);
                            margin-top: rem(20px);
                            text-align: center;
                            order: 3;

                            opacity: 0;

                            transition: 0;

                            visibility: visible;
                            opacity: 1;
                        }

                        .btn {
                            font-family: 'Open Sans', sans-serif;
                            color: #fff;
                            background-color: #00aeef;
                            text-decoration: none;
                            font-size: rem(25px);

                            border: 2px solid #fff;
                            box-sizing: border-box;
                            border-radius: rem(32px);

                            display: inline-flex;
                            justify-content: center;
                            align-items: center;

                            order: 1;

                            width: rem(248px);
                            height: rem(77px);

                            outline: none;

                            margin-top: rem(25px);

                            visibility: visible;
                            opacity: 1;

                            transition: opacity 0s, background-color 0.3s, color 0.3s;
                            
                            &:hover {
                                background-color: #fff;
                                color: #00aeef;
                            }
                        }

                        &.slick-active {
                            transform: scale(1) translateY(0);

                            .desc {
                                opacity: 1;
                                visibility: visible;
                            }

                            .btn {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }
    }

    .vendors {
        margin-top: rem(140px);

        .container {
            .logos_wrap {
                width: 100%;

                .item {
                    width: rem(180px);
                    height: rem(90px);

                    position: relative;

                    outline: none;

                    filter: grayscale(0);
                    transition: 0.3s;
                    opacity: 1;

                    cursor: pointer;

                    &:hover {
                        filter: grayscale(0);
                    }

                    img {
                        width: rem(180px);
                        height: rem(90px);
                        object-fit: contain;

                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                    }
                }
            }
        }
    }

    .bottom {
        /* background: url(../img/bg_hero.jpg) 50% 0% no-repeat;
        background-size: cover; */

        margin-top: rem(60px);

        &.bottom-direction {
            padding-top: rem(0);
            margin-top: rem(0);

            .footer {
                .container {
                    .right {
                        .feed {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        &.bottom-events {
            padding-top: rem(0);
            margin-top: rem(0);

            .footer {
                .container {
                    .right {
                        .feed {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        >.container {
            height: 100%;
            height: auto;

            display: flex;
            flex-direction: column;
            align-items: center;

            .video {
                width: 100%;
                height: auto;

                &:after {
                    content: '';
                    position: absolute;
                    top: initial;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: auto;
    
                    height: 25%;
                    width: 100%;
                    
                    background: url(../img/video-clip2.svg) 50% 0% no-repeat;
                    background-size: cover;
                }
            }

            .texts {
                width: 100%;

                .logo-slogan {
                    width: 100%;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    p {
                        margin: 0;
                        font-size: rem(48px);
                        text-align: center;
                    }

                    img {
                        display: none;
                    }
                }

                .counters {
                    width: 88%;

                    margin: 0 auto;
                    margin-top: rem(50px);

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    p {
                        margin: 0;
                        transition: 0.2s;

                        &:first-child {
                            font-size: rem(70px);
                            font-weight: 700;
                        }

                        &:last-child {
                            font-size: rem(34px);
                        }
                    }
                }
            }
        }

        .footer {
            padding-bottom: rem(60px);

            .container {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                justify-content: center;

                .left {
                    margin-top: rem(40px);

                    .logo {
                        margin-left: rem(-10px);
                        width: rem(350px);
                    }

                    .copyright {
                        margin-top: rem(40px);
                        font-size: rem(21px);
                    }
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    .feed {
                        margin-top: rem(75px);

                        p {
                            font-size: rem(31px);
                            text-align: center;
                        }

                        form {
                            display: block;
                            flex-direction: column;
                            align-items: center;

                            input {
                                font-size: rem(31px);
                                background-color: transparent;
                                box-shadow: none;
                                border: 1px solid #fff;
                                color: #fff;
                                padding: rem(18px);
                                box-sizing: border-box;

                                outline: none;

                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                box-shadow: none;
                                border-radius: 0;
                            }

                            input::placeholder {
                                color: #fff;
                            }

                            input::-webkit-input-placeholder {
                                color: #fff;
                            }

                            input::-moz-placeholder {
                                color: #fff;
                            }

                            input:-moz-placeholder {
                                color: #fff;
                            }

                            input:-ms-input-placeholder {
                                color: #fff;
                            }

                            input[type="email"] {
                                margin-left: rem(87px);
                                border-right: none;
                                width: rem(325px);
                            }

                            input[type="submit"] {
                                cursor: pointer;
                                margin-left: rem(-3px);
                            }
                        }
                    }

                    .links {
                        width: rem(550px);
                        margin-top: rem(50px);

                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        a {
                            color: #fff;
                            font-size: rem(28px);
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    .hero-direction {
        padding-top: rem(130px);

        background: url(../img/bg_hero-direction_mob.png) 50% 50% no-repeat;
        background-size: cover;

        .container {
            height: 100vh;

            &.p-right-190 {
                padding: 0 3.125rem;
            }

            .video {
                display: none;
            }

            .mob-version {
                display: block;

                height: 65vh;

                overflow: hidden;

                .circle {
                    background: url(../img/bg_hero-direction-circle_mob.svg) 50% 50% no-repeat;
                    background-size: contain;

                    width: rem(1600px);
                    height: rem(1600px);

                    position: absolute;
                    top: rem(-35px);
                    left: rem(-1080px);
                    bottom: 0;
                    margin: auto;

                    transform: rotate(0deg);

                    opacity: 0.8;

                    animation-name: rotate-circle;
                    animation-timing-function: linear;
                    animation-duration: 120s;
                    animation-iteration-count: infinite;
                }

                @keyframes rotate-circle {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }

                .icon {
                    position: relative;

                    width: rem(175px);
                    height: rem(175px);

                    display: block;

                    margin-bottom: rem(30px);

                    text-decoration: none;

                    display: flex;
                    align-items: center;

                    width: 100%;

                    p {
                        text-transform: uppercase;
                        color: #fff;
                        font-family: 'PT Sans Caption', sans-serif;
                        font-size: rem(36px);

                        margin-left: rem(215px);

                    }
                }

                .icon1 {
                    background: url(../img/directions_hero/icon1.svg) 0% 50% no-repeat;
                    background-size: contain;
                }
                .icon2 {
                    background: url(../img/directions_hero/icon2.svg) 0% 50% no-repeat;
                    background-size: contain;

                    margin-left: rem(70px);
                }
                .icon3 {
                    background: url(../img/directions_hero/icon3.svg) 0% 50% no-repeat;
                    background-size: contain;

                    margin-left: rem(70px);
                }
                .icon4 {
                    background: url(../img/directions_hero/icon4.svg) 0% 50% no-repeat;
                    background-size: contain;
                }
            }

            .texts {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                width: 100%;
                height: 20vh;

                .logo {
                    width: rem(200px);
                }

                .title {
                    font-family: 'PT Sans Caption', sans-serif;
                    font-size: rem(56px);
                }
            }
        }
    }

    .directions {
        display: none;
    }

    .directions-mob {
        overflow: hidden;

        background-color: #00aeef;
        display: block;

        padding-top: rem(85px);

        .line {
            background-color: #fff;

            width: 2px;

            position: absolute;
            top: 0;
            left: rem(71px);

            transition: 0.35s;
        }

        .items {
            width: 100%;
            margin: 0 auto;

            position: relative;

            >.item {
                box-shadow: none;
                padding: rem(0 50px 0 100px);
                box-sizing: border-box;
                padding-bottom: rem(40px);

                position: relative;

                cursor: pointer;

                &.active {
                    box-shadow: rem(0 12px 22px -5px rgba(68, 68, 68, 0.4));

                    .title {
                        font-family: 'PT Sans Caption', sans-serif;
                        font-size: rem(58px);
                        font-weight: 700;
                    }
                }

                .point {
                    background-color: #fff;
                    width: rem(15px);
                    height: rem(15px);

                    border-radius: 50%;

                    position: absolute;
                    top: rem(17px);
                    left: rem(65px);
                }

                .title {
                    font-family: 'PT Sans Caption', sans-serif;
                    font-size: rem(40px);

                    transition: 0.3s;
                }

                .desc {
                    font-family: 'PT Sans Caption', sans-serif;
                    font-size: rem(28px);

                    margin-top: rem(30px);

                    display: none;
                }

                .btn {
                    font-family: 'Open Sans', sans-serif;
                    color: #00aeef;
                    background-color: #ffe000;
                    text-decoration: none;
                    font-size: rem(28px);

                    border-radius: rem(45px);

                    justify-content: center;
                    align-items: center;

                    width: rem(335px);
                    height: rem(92px);

                    outline: none;

                    margin-top: rem(30px);

                    display: none;

                    opacity: 0;

                    transition: 0.3s;

                    &:hover {
                        color: #00aeef;
                        background-color: #fff;
                    }

                    &.active {
                        display: inline-flex !important;
                        opacity: 1;
                    }
                }
            }
        }

        .list {
            padding-top: rem(20px);
            margin-bottom: rem(50px);
            position: relative;

            display: none;

            .item {
                margin-bottom: rem(20px);
                padding: rem(0 50px 0 100px);
                box-sizing: border-box;
                position: relative;

                .point {
                    background-color: #fff;
                    width: rem(12px);
                    height: rem(12px);

                    border-radius: 50%;

                    position: absolute;
                    top: rem(15px);
                    left: rem(65px);
                }

                .title {
                    font-weight: 700;
                    font-size: rem(33px);
                    text-transform: uppercase;
                }

                .desc {
                    font-size: rem(28px);
                }
            }

            .vendor-logos {
                background-color: #fff;

                padding: rem(30px 45px 30px 30px);
                box-sizing: border-box;

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-around;

                margin-right: rem(-30px);

                box-shadow: rem(inset 0 -9px 10px -6px rgba(68, 68, 68, .4));

                img {
                    max-width: rem(250px);
                    max-height: rem(60px);
                    margin-right: rem(30px);
                    margin-bottom: rem(30px);
                }
            }

            .close-list {
                width: rem(45px);
                height: rem(30px);

                position: absolute;
                left: 0;
                bottom: rem(10px);
                right: 0;
                margin: auto;

                cursor: pointer;
            }
        }
    }

    .events-main {
    
        .left {
            width: 100%;
            height: rem(700px);

            position: relative;

            .logo {
                display: none;
            }
    
            .texts {
                width: 86%;
                height: rem(470px);

                top: rem(110px);
                left: rem(55px);

                .title {
                    font-size: rem(62px);
                }
    
                .search {
                    margin-top: rem(45px);
                    margin-bottom: rem(45px);
    
                    input {
                        width: 100%;
                        height: rem(77px);

                        font-size: rem(31px);

                        border-radius: rem(25px);
                    }
                }
    
                .list {
                    
    
                    .item {
                        
    
                        .dot {
                            width: rem(26px);
                            height: rem(26px);
    
                            &:before {
                                width: rem(10px);
                                height: rem(10px);
                            }
                        }
    
                        &.active {
                            .dot {
                                &:before {
                                    
                                }
                            }
                        }
    
                        .text {
                            font-size: rem(40px);
                        }
                    }
    
                    &:before {
                        height: rem(180px);

                        top: rem(15px);
                        left: rem(13px);
                    }
                }
            }
        }
    
        .right {
            width: 100%;
            margin-top: 0;
    
            .event-list {
                width: 93%;
                margin-left: 0;
                margin: auto;
                padding-left: rem(55px);

                &.active {
                    
                }
    
                .line {
                    top: rem(180px);
                    left: rem(34px);
                }
                
                .event-item {
                    margin-top: rem(30px);

                    .pic {
                        width: rem(246px);
                        height: rem(208px);
    
                        img {
                            width: rem(246px);
                            height: rem(208px);
                        }
                    }
    
                    .texts {
                        
                        .category {
                            font-size: rem(16.8px);
                        }
    
                        .title {
                            font-size: rem(28.8px);
                        }
    
                        .desc {
                            font-size: rem(19px);
                        }
    
                        .date {
                            font-size: rem(16.8px);
                        }
                    }
    
                    .dot {
                        left: rem(-55px);
                    }
    
                    &:after {
                        width: rem(40px);
                        left: rem(-40px);
                    }
                }
            }
    
            .calendar-wrap {
                height: rem(800px);

                .calendar {
                    width: rem(670px);
                    height: rem(690px);
                    .days {
                        .day {
                            height: rem(100px);

                            .list {
                                position: absolute;
                                right: rem(-270px);
                                top: 0;
    
                                width: rem(250px);
    
                                color: #00aeef;
    
                                border-radius: rem(15px);
                                padding: rem(15px 0);
                                box-sizing: border-box;
    
                                background-color: #fff;
                                box-shadow: rem(7px 7px 4px rgba(0, 0, 0, 0.15));
    
                                z-index: 1;
    
                                opacity: 0;
                                visibility: hidden;
    
                                transition: opacity 0.3s, visibility 0s;
    
                                .item {
                                    font-size: rem(30px);
                                    font-weight: 700;
                                    padding: rem(15px 40px);
    
                                    span {
                                        color: #b7b7b7;
                                        display: block;
                                        position: relative;
                                        top: 0;
                                        right: 0;
                                    }
    
                                    &:hover {
                                        background-color: #00aeef;
                                        color: #fff;
                                        
                                        span {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
    
                            &:nth-child(7) {
                                .list {
                                    right: rem(130px);
                                }
                            }
    
                            &:nth-child(6) {
                                .list {
                                    right: rem(130px);
                                }
                            }
    
                            &:nth-child(5) {
                                .list {
                                    right: rem(130px);
                                }
                            }
    
                            &.hasEvent {
                                background-color: #00aeef;
                                cursor: pointer;
    
                                span {
                                    color: #fff;
                                }
    
                                .list {
                                    &.active {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                    }
                }
            }
    
            .event-single {
                padding: rem(90px 50px);
                margin-top: rem(70px);
    
                .registration_btn {
                    position: absolute;

                    top: rem(75px);
                    left: initial;
                    right: rem(55px);
                    transform: rotate(0deg);

                    border-radius: rem(30px);
                }
    
                .logo {
                    width: 40%;
                }
    
                .meta {
                    .date_wrap {
                        .icon {
                            width: rem(60px);
                            height: rem(84px);

                            position: relative;

                            img {
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                margin: auto;
                            }
                        }
                    }
    
                    .text_wrap {
                        .category {
                            font-size: rem(17px);
                        }
    
                        .title {
                            font-size: rem(52.5px);
                        }
                    }
                }
    
                .event-single-text {
                    font-size: rem(26.3px);
                    line-height: rem(27px);
                }
    
                .footer {
                    .social {
                        span {
                            font-size: rem(25px);

                            margin-right: rem(15px);
                        }
    
                        .icon {
                            width: rem(60px);
                            height: rem(60px);

                            margin-right: rem(10px);
                        }
                    }
                }
            }
    
            .registration-wrap {
                height: auto;
    
                .registration-container {
                    width: 88%;
                    height: auto;

                    position: relative;
                    top: initial;
                    margin-top: rem(150px);

                    padding-bottom: rem(170px);
    
                    .meta {
                        
        
                        .date_wrap {
                            
        
                            .icon {
                                width: rem(60px);
                                height: rem(84px);
    
                                position: relative;
    
                                img {
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    right: 0;
                                    margin: auto;
                                }
                            }
        
                            .date {
                                
                            }
                        }
        
                        .text_wrap {
                            
        
                            .category {
                                font-size: rem(17px);
                            }
        
                            .title {
                                font-size: rem(52.5px);
                            }
                        }
                    }
    
                    .registration-form {
                        form {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;

                            position: relative;

                            margin-top: rem(30px);
    
                            .col {
                                width: 95%;
    
                                input {
                                    font-size: rem(33px);
                                    color: #b6b7b7;

                                    margin-bottom: rem(30px);

                                    padding: rem(20px 30px);

                                    border-radius: rem(20px);
                                }
    
                                a {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    bottom: rem(-100px);
                                    margin: auto;
                                    display: block;

                                    width: 45%;

                                    font-size: rem(52.2px);
    
                                    &:hover {
                                        
                                    }
    
                                    &.hide {
                                        
                                    }
                                }
                            }
                        }
    
                        .success {
                            font-size: rem(43px);
                            text-align: center;
    
                            &.active {
                                
                            }
                        }
                    }
                }
            }
        }
    }

    .promo-main {
        .first-screen {
            flex-direction: column;
            .left {
                position: relative;
                width: 100%;
                height: auto;
                margin-top: rem(110px);
    
                .wrap {
                    position: relative;
                    width: 86%;
                    padding-top: rem(40px);
                    padding-bottom: rem(40px);
                    padding-left: 0;
                    padding-right: 0;
    
                    .pagename {
                        font-size: rem(62px);
                    }
        
                    .search {
                        input {
                            width: 100%;
                            height: rem(77px);

                            font-size: rem(31px);

                            border-radius: rem(25px);
                        }
                    }
        
                    .promo-select {
                        .pic {
                            width: 100%;
        
                            img {
                                width: 100%;
                                max-height: initial;
                            }
                        }
        
                        .new-promo {
                            font-size: rem(60px);
                        }
        
                        .category {
                            font-size: rem(25px);
                            line-height: rem(75px);
                        }
        
                        .title {
                            font-size: rem(44px);
                            line-height: rem(50px);
                        }
        
                        .desc {
                            font-size: rem(27px);
                            line-height: rem(27px);
                        }
                        
                        .date {
                            font-size: rem(30px);
                            line-height: rem(62px);
                        }
                    }
                }
            }
        
            .right {
                position: relative;
                width: 100%;
    
                .promo-list {
                    width: 93%;
                    margin-left: 0;
                    margin: auto;
                    padding-left: rem(55px);
        
                    .line {
                        top: rem(180px);
                        left: rem(7px);
                    }
                    
                    .promo-item {
                        margin-top: rem(30px);
        
                        .pic {
                            width: rem(246px);
                            height: rem(208px);
        
                            img {
                                width: rem(246px);
                                height: rem(208px);
                            }
                        }
        
                        .texts {
                            .category {
                                font-size: rem(16.8px);
                            }
        
                            .title {
                                font-size: rem(28.8px);
                            }
        
                            .desc {
                                font-size: rem(19px);
                            }
        
                            .date {
                                font-size: rem(16.8px);
                            }
                        }
        
                        .dot {
                            left: rem(-55px);
                        }
        
                        &:after {
                            width: rem(40px);
                            left: rem(-40px);
                        }
        
                        &:hover .pic {
                            
                        }
                    }
                }
            }
        }
    
        .search-result {
            .header {
                position: relative;
                width: 86%;
                margin: auto;

                padding-top: rem(150px);
                padding-bottom: 0;
                padding-left: 0;

                .pagename {
                    font-size: rem(62px);
                }
    
                .search {
                    input {
                        width: 100%;
                        height: rem(77px);

                        font-size: rem(31px);

                        border-radius: rem(25px);
                    }
                }
    
                .logo {
                    display: none;
                }
            }
    
            .main {
                flex-direction: column;

                .left {
                    width: 100%;
                    position: relative;

                    .promo-list {
                        width: 93%;
                        margin-left: 0;
                        margin: auto;
                        padding-left: rem(55px);
                        padding-bottom: 0;
            
                        .line {
                            top: rem(180px);
                            left: rem(7px);
                        }
                        
                        .promo-item {
                            margin-top: rem(30px);
            
                            .pic {
                                width: rem(246px);
                                height: rem(208px);
            
                                img {
                                    width: rem(246px);
                                    height: rem(208px);
                                }
                            }
            
                            .texts {
                                .category {
                                    font-size: rem(16.8px);
                                }
            
                                .title {
                                    font-size: rem(28.8px);
                                }
            
                                .desc {
                                    font-size: rem(19px);
                                }
            
                                .date {
                                    font-size: rem(16.8px);
                                }
                            }
            
                            .dot {
                                left: rem(-55px);
                            }
            
                            &:after {
                                width: rem(40px);
                                left: rem(-40px);
                            }
            
                            &:hover .pic {
                                
                            }
                        }
                    }
                }
            
                .right {
                    width: 100%;
        
                    .promo-list {
                        width: 93%;
                        margin-left: 0;
                        margin: auto;
                        padding-left: rem(55px);
                        padding-top: 0;
            
                        .line {
                            top: rem(180px);
                            left: rem(7px);
                        }
                        
                        .promo-item {
                            margin-top: rem(30px);
            
                            .pic {
                                width: rem(246px);
                                height: rem(208px);
            
                                img {
                                    width: rem(246px);
                                    height: rem(208px);
                                }
                            }
            
                            .texts {
                                .category {
                                    font-size: rem(16.8px);
                                }
            
                                .title {
                                    font-size: rem(28.8px);
                                }
            
                                .desc {
                                    font-size: rem(19px);
                                }
            
                                .date {
                                    font-size: rem(16.8px);
                                }
                            }
            
                            .dot {
                                left: rem(-55px);
                            }
            
                            &:after {
                                width: rem(40px);
                                left: rem(-40px);
                            }
            
                            &:hover .pic {
                                
                            }
                        }
                    }
                }
            }
        }
    
        .promo-single {
    
            .left {
                width: 86%;
                margin: auto;
                padding: rem(150px 0 50px);

                .logo {
                    width: 40%;
                }
    
                .meta {
                    .date_wrap {
                        width: rem(170px);

                        .icon {
                            width: rem(60px);
                            height: rem(84px);
    
                            position: relative;
    
                            img {
                                width: 100%;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                margin: auto;
                            }
                        }

                        .date {
                            font-size: rem(22px);
                        }
                    }
                    
    
                    .text_wrap {
                        .category {
                            font-size: rem(17px);
                        }
    
                        .title {
                            font-size: rem(52.5px);
                        }
                    }
                }
    
                .promo-single-text {
                    font-size: rem(26.3px);
                    line-height: rem(27px);
                }
    
                .footer {
                    .social {
                        span {
                            font-size: rem(25px);

                            margin-right: rem(15px);
                        }
    
                        .icon {
                            width: rem(60px);
                            height: rem(60px);

                            margin-right: rem(10px);
                        }
                    }
                }
            }
        
            .right {
                display: none;
            }
        }
    }

    .news-main {
        .first-screen {
            flex-direction: column;
            .left {
                position: relative;
                width: 100%;
                height: auto;
                margin-top: rem(110px);
    
                .wrap {
                    position: relative;
                    width: 86%;
                    padding-top: rem(40px);
                    padding-bottom: rem(40px);
                    padding-left: 0;
                    padding-right: 0;
    
                    .pagename {
                        font-size: rem(62px);
                    }
        
                    .search {
                        input, 
                        select {
                            width: 44%;
                            height: rem(77px);

                            font-size: rem(31px);

                            border-radius: rem(25px);

                            &.year {
                                width: rem(0);
                                
                                option {
                                    width: rem(135px);
                                }
                            }
    
                            &.month {
                                width: rem(0);
                                margin-left: rem(10px);
                            }
    
                            &.year {
                                &.active {
                                    width: 20%;
                                }
                            }
    
                            &.month {
                                &.active {
                                    width: 30%;
                                }
                            }
                        }

                        a {
                            font-size: rem(31px);
                        }
                    }
        
                    .news-select {
                        .pic {
                            width: 100%;
        
                            img {
                                width: 100%;
                                max-height: initial;
                            }
                        }
        
                        .new-news {
                            font-size: rem(60px);
                        }
        
                        .category {
                            font-size: rem(25px);
                            line-height: rem(75px);
                        }
        
                        .title {
                            font-size: rem(44px);
                            line-height: rem(50px);
                        }
        
                        .desc {
                            font-size: rem(27px);
                            line-height: rem(27px);
                        }
                        
                        .date {
                            font-size: rem(30px);
                            line-height: rem(62px);
                        }
                    }
                }
            }
        
            .right {
                position: relative;
                width: 100%;
    
                .news-list {
                    width: 93%;
                    margin-left: 0;
                    margin: auto;
                    padding-left: rem(55px);
        
                    .line {
                        top: rem(180px);
                        left: rem(7px);
                    }
                    
                    .news-item {
                        margin-top: rem(30px);
        
                        .pic {
                            width: rem(246px);
                            height: rem(208px);
        
                            img {
                                width: rem(246px);
                                height: rem(208px);
                            }
                        }
        
                        .texts {
                            .category {
                                font-size: rem(16.8px);
                            }
        
                            .title {
                                font-size: rem(28.8px);
                            }
        
                            .desc {
                                font-size: rem(19px);
                            }
        
                            .date {
                                font-size: rem(16.8px);
                            }
                        }
        
                        .dot {
                            left: rem(-55px);
                        }
        
                        &:after {
                            width: rem(40px);
                            left: rem(-40px);
                        }
                    }
                }
            }
        }
    
        .search-result {
            .header {
                position: relative;
                width: 86%;
                margin: auto;

                padding-top: rem(150px);
                padding-bottom: 0;
                padding-left: 0;

                .pagename {
                    font-size: rem(62px);
                }
    
                .search {
                    input, 
                        select {
                            width: 44%;
                            height: rem(77px);

                            font-size: rem(31px);

                            border-radius: rem(25px);

                            &.year {
                                width: rem(0);
                                
                                option {
                                    width: rem(135px);
                                }
                            }
    
                            &.month {
                                width: rem(0);
                                margin-left: rem(10px);
                            }
    
                            &.year {
                                &.active {
                                    width: 20%;
                                }
                            }
    
                            &.month {
                                &.active {
                                    width: 30%;
                                }
                            }
                        }

                        a {
                            font-size: rem(31px);
                        }
                }
    
                .logo {
                    display: none;
                }
            }
    
            .main {
                flex-direction: column;

                .left {
                    width: 100%;
                    position: relative;

                    .news-list {
                        width: 93%;
                        margin-left: 0;
                        margin: auto;
                        padding-left: rem(55px);
                        padding-bottom: 0;
            
                        .line {
                            top: rem(180px);
                            left: rem(7px);
                        }
                        
                        .news-item {
                            margin-top: rem(30px);
            
                            .pic {
                                width: rem(246px);
                                height: rem(208px);
            
                                img {
                                    width: rem(246px);
                                    height: rem(208px);
                                }
                            }
            
                            .texts {
                                .category {
                                    font-size: rem(16.8px);
                                }
            
                                .title {
                                    font-size: rem(28.8px);
                                }
            
                                .desc {
                                    font-size: rem(19px);
                                }
            
                                .date {
                                    font-size: rem(16.8px);
                                }
                            }
            
                            .dot {
                                left: rem(-55px);
                            }
            
                            &:after {
                                width: rem(40px);
                                left: rem(-40px);
                            }
                        }
                    }
                }
            
                .right {
                    width: 100%;
        
                    .news-list {
                        width: 93%;
                        margin-left: 0;
                        margin: auto;
                        padding-left: rem(55px);
                        padding-top: 0;
            
                        .line {
                            top: rem(180px);
                            left: rem(7px);
                        }
                        
                        .news-item {
                            margin-top: rem(30px);
            
                            .pic {
                                width: rem(246px);
                                height: rem(208px);
            
                                img {
                                    width: rem(246px);
                                    height: rem(208px);
                                }
                            }
            
                            .texts {
                                .category {
                                    font-size: rem(16.8px);
                                }
            
                                .title {
                                    font-size: rem(28.8px);
                                }
            
                                .desc {
                                    font-size: rem(19px);
                                }
            
                                .date {
                                    font-size: rem(16.8px);
                                }
                            }
            
                            .dot {
                                left: rem(-55px);
                            }
            
                            &:after {
                                width: rem(40px);
                                left: rem(-40px);
                            }
                        }
                    }
                }
            }
        }
    
        .news-single {
    
            .left {
                width: 86%;
                margin: auto;
                padding: rem(150px 0 50px);

                .logo {
                    width: 40%;
                }
    
                .meta {
                    .date_wrap {
                        width: rem(170px);

                        .icon {
                            width: rem(60px);
                            height: rem(84px);
    
                            position: relative;
    
                            img {
                                width: 100%;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                margin: auto;
                            }
                        }

                        .date {
                            font-size: rem(22px);
                        }
                    }
                    
    
                    .text_wrap {
                        .category {
                            font-size: rem(17px);
                        }
    
                        .title {
                            font-size: rem(52.5px);
                        }
                    }
                }
    
                .news-single-text {
                    font-size: rem(26.3px);
                    line-height: rem(27px);
                }
    
                .footer {
                    .social {
                        span {
                            font-size: rem(25px);

                            margin-right: rem(15px);
                        }
    
                        .icon {
                            width: rem(60px);
                            height: rem(60px);

                            margin-right: rem(10px);
                        }
                    }
                }
            }
        
            .right {
                display: none;
            }
        }
    }

    .about-main {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .f1,
        .f2,
        .f3,
        .f4,
        .f5,
        .f6 {
            width: 100%;
        }

        canvas{}
    
        #particles-js1{}
    
        .block1 {
            padding-left: rem(40px);
            margin-top: rem(150px);

            order: 1;

            .container {
                .texts {
                    width: 95%;
                    .logo {
                        margin: 0 auto;
                    }
        
                    .text {
                        margin-top: rem(60px);

                        p {
                            font-size: rem(28px);
                        }
                    }
    
                    .stat {
                        width: 97%;
                        span {
                            
                        }
                    }
                }
        
                .video {
                    display: none;
                    
                    video {
                        
                    }
                }
            }
        }
    
        .block2 {
            padding-left: rem(40px);
            margin-top: rem(50px);

            height: rem(641px);
            
            order: 2;
    
            .container {
                height: 100%;

                img {
                    width: 100%;
                    height: rem(641px);
                }
            }
        }
    
        .block3 {
            padding-left: rem(40px);
            margin-top: rem(50px);

            order: 4;
    
            .container {
                
                p {
                    font-size: rem(28.8px);
    
                    span {
                        width: 100%;
                    }
                }
            }
        }
    
        .block4 {
            padding-left: 0;
            margin-top: rem(150px);

            order: 3;
    
            .container {

                .video_wrap {
                    margin-left: rem(-140px);
    
                    .video {
                        width: 76vw;
                        height: 76vw;
            
                        video {
                            width: 76vw;
                            height: 76vw;
                        }
    
                        .before {
                            font-size: rem(32px);

                            top: rem(-100px);
                            left: rem(180px);
                        }
                    }
    
                    .items {
                        width: 56%;
    
                        .item {
                            width: rem(120px);
                            height: rem(120px);
    
                            .icon {
                                width: rem(120px);
                                height: rem(120px);

                                img {
                                    width: rem(120px);
                                    height: rem(120px);
                                }
                            }
    
                            .text {
                                font-size: rem(28.8px);
                            }
    
                            &.item2,
                            &.item3 {
                                margin-left: rem(110px);
                            }
                        }
                    }
                }
            }
        }
    
        .block5 {
            order: 5;

            .container {
                
                p {
                    
                }
            }
        }
    
        .block6 {
            padding-left: rem(40px);
            padding-right: rem(40px);
            margin-top: rem(50px);

            order: 6;
    
            .container {
                flex-direction: column;
    
                .video {
                    width: 71vw;
                    height: 71vw;

                    margin-left: rem(-450px);
        
                    video {
                        width: 71vw;
                        height: 71vw;
                    }
                }
    
                .texts {
                    margin-left: 0;
    
                    .title {
                        position: absolute;
                        top: rem(-108px);
                        right: 0;

                        font-size: rem(45px);

                        width: 45%;
                    }
    
                    p {
                        font-size: rem(28px);
                    }
    
                    .ul {
                        font-size: rem(28px);
                    }
                }
            }
        }
    
        .block7 {
            padding-left: rem(40px);
            padding-right: rem(40px);
            padding-bottom: rem(40px);
            margin-top: rem(50px);

            order: 7;
    
            .container {
                flex-direction: column-reverse;
    
                .video {
                    width: 71vw;
                    height: 71vw;

                    margin-right: rem(-300px);
        
                    video {
                        width: 71vw;
                        height: 71vw;
                    }
                }
    
                .texts {
                    margin-top: rem(15px);
                    margin-left: 0;
    
                    &>.title {
                        position: absolute;
                        top: rem(-50px);
                        left: 0;

                        font-size: rem(45px);

                        width: 45%;
                    }
    
                    &>p {
                        font-size: rem(28px);
                    }
    
    
                }
            }
        }
    
        .block8 {
            padding-left: rem(40px);
            padding-right: rem(40px);
            padding-top: rem(50px);

            order: 8;
    
            .container {
                flex-direction: column;
    
                .video {
                    width: 71vw;
                    height: 71vw;

                    margin-left: rem(-400px);
        
                    video {
                        width: 71vw;
                        height: 71vw;
                    }
                }
    
                .texts {
                    margin-top: rem(20px);
                    margin-left: 0;

                    width: 100%;
    
                    &>.title {
                        position: absolute;
                        top: rem(-180px);
                        right: 0;

                        font-size: rem(45px);

                        width: 35%;
                    }
    
                    &>p {
                        font-size: rem(28px);
                    }
                }
            }
        }
    
        .block9 {
            padding-left: rem(40px);
            padding-right: rem(40px);
            padding-bottom: rem(40px);
            padding-top: rem(50px);

            order: 9;

            .container {
                flex-direction: column-reverse;
    
                .video {
                    width: 71vw;
                    height: 71vw;

                    margin-right: rem(-350px);
        
                    video {
                        width: 71vw;
                        height: 71vw;
                    }
                }
    
                .texts {
                    margin-top: rem(15px);
                    margin-left: 0;
    
                    &>.title {
                        position: absolute;
                        top: rem(-110px);
                        left: 0;

                        font-size: rem(45px);

                        width: 45%;
                    }
    
                    &>p {
                        font-size: rem(28px);
                    }
    
                }
            }
        }
    
        .slideFromTop {
            
    
            &.active {
                
            }
        }
    
        .slideFromBottom {
            
    
            &.active {
                
            }
        }
    
        .slideFromLeft {
            
    
            &.active {
                
            }
        }
    
        .slideFromRight {
            
    
            &.active {
                
            }
        }
    
        .scaleTo {
            
    
            &.active {
                
            }
        }

        .bottom {
            order: 10;
        }
    }

    .contacts-main {
        flex-direction: column;
    
        .logo {
            display: none;
        }
    
        .left {
            width: 100%;
            height: rem(112px);

            top: rem(112px);

            overflow: hidden;

            cursor: pointer;

            transition: 0.5s;

            &.active {
                height: rem(500px);
            }
    
            .wrap {
                width: 92%;
                
                top: rem(15px);
                bottom: initial;
    
                .title {
                    font-size: rem(62px);
                    position: relative;
                    

                    &:before {
                        content: '';

                        background: url(../img/arrow-right.svg) 50% 50% no-repeat;
                        background-size: contain;

                        width: 2.4%;
                        height: 28%;

                        position: absolute;
                        top: 16%;
                        bottom: 0;
                        left: 39.9%;
                        margin: auto;

                        filter: brightness(0) invert(1);
                    }
                }
                .items {
                    .line {
                        height: calc(100% - 2rem);
                    }
                    .item {
                        font-size: rem(40px);
                    }
                }
            }
        }
    
        .right {
            width: 100%;

            padding-left: rem(60px);
            padding-right: rem(60px);
    
            .screen {
                .map {
                    width: rem(650px);
                    height: rem(410px);
                }
    
                .departments {
                    .block {
                        .title {
                            padding-left: 0;
                            font-size: rem(28px);
    
                            span.email {
                                font-size: rem(28px);
    
                                &:before {
                                    
                                }
                            }
                        }
    
                        .list {
                            font-size: rem(28px);
    
                            .item {
                                border-radius: rem(20px);
    
                                &.item-end {
                                    
                                }
    
                                .email {
                                    padding-left: rem(50px);
    
                                    &:before {
                                        width: rem(50px);
                                        height: rem(25px);
                                    }
                                }
                            }
                        }
                    }
                }
    
                .agents {
                    .item {
                        font-size: rem(28px);
                        border-radius: rem(20px);

                        &.item-invert {
                            .email {
                                padding-left: rem(50px);

                                &:before {
                                    width: rem(50px);
                                    height: rem(25px);
                                }
                            }
                        }
    
                        .email {
                            padding-left: rem(50px);
    
                            &:before {
                                width: rem(50px);
                                height: rem(25px);
                            }
                        }
                    }
                }
    
                .sng {
                    .name {
                        font-size: rem(28px);
                    }
    
                    .item {
                        font-size: rem(28px);
                        border-radius: rem(20px);
                        flex-wrap: wrap;
    
                        .phone {
                            margin-top: rem(20px);
                        }
                    }
                }
            }
        }
    }

    .partners-main {
        overflow: hidden;
    
        .block1 {
            padding-left: rem(50px);
    
            .container {
                flex-direction: column;
                margin-top: rem(170px);
    
                .texts {
                    width: 100%;
                    margin-left: 0;
        
                    .logo {
                        margin-left: inherit;
                    }
        
                    .text {
                        margin-top: rem(240px);

                        .title {
                            font-size: rem(62px);
                            line-height: rem(67px);

                            width: 50%;
                        }
        
                        p {
                            font-size: rem(28px);
                            line-height: rem(34px);
                        }
                    }
                }
                
                .pic {
                    margin-left: 0;
                    margin-top: 0;

                    position: absolute;
                    top: rem(-200px);
                    right: rem(-270px);

                    width: rem(710px);
                    height: rem(710px);
    
                    img {
                        width: rem(710px);
                        height: rem(710px);
                    }
                }
            }
        }
    
        .block2 {
            padding-left: rem(50px);
            padding-top: rem(100px);
    
            .container {
                .texts {
                    width: 100%;

                    padding-top: rem(480px);
        
                    .text {
                        .title {
                            width: 45%;

                            margin-left: auto;
                        
                            font-size: rem(62px);
                            line-height: rem(67px);
                        }
        
                        p {
                            width: 95%;

                            font-size: rem(28px);
                            line-height: rem(34px);
                        }
    
                        a.btn {
                            margin: rem(50px auto 80px);
                        }
                    }
                }
                
                .pic {
                    width: rem(610px);
                    height: rem(610px);

                    position: absolute;
                    top: 0;
                    left: rem(-170px);

                    margin-left: 0;
    
                    img {
                        width: rem(610px);
                        height: rem(610px);
                    }
                }
            }
        }
    
        .block3 {
            padding-left: rem(50px);
            padding-top: rem(100px);
    
            .container {
                .texts {
                    width: 100%;

                    padding-top: rem(480px);

                    margin-left: 0;
    
                    .text {
                        .title {
                            width: 45%;

                            margin-right: auto;
                        
                            font-size: rem(62px);
                            line-height: rem(67px);
                        }
        
                        p {
                            width: 95%;

                            font-size: rem(28px);
                            line-height: rem(34px);
                        }
    
                        a.btn {
                            margin: rem(50px auto 80px);
                        }
                    }
                }
                
                .pic {
                    width: rem(610px);
                    height: rem(610px);

                    position: absolute;
                    top: 0;
                    right: rem(-140px);

                    margin-right: 0;
    
                    img {
                        width: rem(610px);
                        height: rem(610px);
                    }
                }
            }
        }
    
        .forms {
            flex-direction: column;
    
            .left {
                width: 100%;

                margin-top: rem(120px);
                
                padding-left: rem(50px);
                padding-right: rem(50px);
    
                .form {
    
                    .title {
                        font-size: rem(62px);
                        line-height: rem(67px);
                    }
    
                    .title2 {
                        font-size: rem(50px);
                        line-height: rem(80px);
                    }
    
                    form {
                        .inputs {
                            input {
                                font-size: rem(32px);

                                height: rem(90px);

                                padding: rem(0 40px);

                                margin-bottom: rem(30px);
                            }
                        }
    
                        label {
                            font-size: rem(28px);
    
                            input {
                                width: rem(80px);
                                height: rem(40px);
                            }
                        }
    
                        .bottom {
                            flex-direction: column-reverse;
    
                            input[type="submit"] {
                                margin-top: rem(40px);

                                font-size: rem(31px);
                                
                                width: rem(340px);
                                height: rem(72px);
                            }
    
                            .captcha {
                                width: 95%;
                            }
                        }
    
                        
                    }
                }
            }
    
            .right {
                width: 100%;
    
                .logo {
                    display: none;
                }
    
                .texts {
                    margin-top: rem(50px);
    
                    .title {
                        font-size: rem(62px);
                        line-height: rem(67px);
                    }
    
                    .title2 {
                        font-size: rem(36px);
                        line-height: rem(43px);
                    }
    
                    p {
                        font-size: rem(28px);
                        line-height: rem(34px);

                        padding-left: rem(30px);
    
                        &:before {
                            width: rem(10px);
                            height: rem(10px);

                            bottom: initial;
                            top: rem(12px);
                            left: 0;
                        }
                    }
    
                    &.texts-vendor {
                        margin-top: rem(50px);
    
                        p {
                            padding-left: rem(0);
                        }
                    }
                }
            }
        }
    }

    .support-main {
        overflow: hidden;
    
        .block1 {
            padding-left: rem(50px);
    
            .container {
                flex-direction: column;
                align-items: flex-start;

                margin-top: rem(170px);
    
                .texts {
                    width: 95%;
                    margin-left: 0;
        
                    .logo {
                        margin-left: inherit;
                    }
        
                    .text {
                        margin-top: rem(400px);
    
                        .title {
                            font-size: rem(50px);
                            line-height: rem(57px);

                            width: 50%;
                        }
        
                        p {
                            font-size: rem(28px);
                            line-height: rem(34px);
                        }
                    }
                }
                
                .pic {
                    margin-left: 0;
                    margin-top: 0;

                    position: absolute;
                    top: rem(-130px);
                    right: rem(-200px);

                    width: rem(680px);
                    height: rem(680px);
    
                    img {
                        width: rem(680px);
                        height: rem(680px);
                    }
                }
            }
        }
    
        .block2,
        .block4,
        .block6,
        .block8 {
            padding-left: rem(50px);
            padding-top: rem(70px);
    
            .container {
                .texts {
                    width: 100%;

                    padding-top: rem(400px);
                    margin-left: 0;
        
                    .text {
                        .title {
                            width: 45%;

                            margin-left: auto;
                            margin-bottom: rem(70px);
                        
                            font-size: rem(50px);
                            line-height: rem(57px);
                        }
        
                        p {
                            width: 95%;

                            font-size: rem(28px);
                            line-height: rem(34px);
                        }
                    }
                }
                
                .pic {
                    width: rem(610px);
                    height: rem(610px);

                    position: absolute;
                    top: 0;
                    left: rem(-170px);

                    margin-left: 0;
    
                    img {
                        width: rem(610px);
                        height: rem(610px);
                    }
                }
            }
        }

        .block4,
        .block6,
        .block8 {
            .container {
                .texts {
                    padding-top: rem(450px);
        
                    .text {
                        .title {
                            margin-bottom: rem(70px);
                        }
                    }
                }
            }
        }

        .block6 {
            .container {
                .texts {
                    padding-top: rem(500px);
        
                    .text {
                        .title {
                            width: 59%;
                            margin-bottom: rem(30px);
                        }
                    }
                }
            }
        }

        .block8 {
            .container {
                .texts {
                    padding-top: rem(500px);
        
                    .text {
                        .title {
                            width: 60%;
                            margin-bottom: rem(30px);
                        }
                    }
                }
            }
        }
    
        .block3,
        .block5,
        .block7 {
            padding-left: rem(50px);
            padding-top: rem(70px);
    
            .container {
                .texts {
                    width: 100%;

                    padding-top: rem(470px);
                    margin-left: 0;
    
                    .text {
                        .title {
                            width: 45%;

                            margin-right: auto;
                            margin-bottom: rem(50px);
                        
                            font-size: rem(50px);
                            line-height: rem(57px);
                        }
        
                        p {
                            width: 95%;

                            font-size: rem(28px);
                            line-height: rem(34px);
                        }
                    }
                }
                
                .pic {
                    width: rem(610px);
                    height: rem(610px);

                    position: absolute;
                    top: 0;
                    right: rem(-170px);

                    margin-left: 0;
    
                    img {
                        width: rem(610px);
                        height: rem(610px);
                    }
                }
            }
        }
        
        .block7 {
    
            .container {
                
                .pic {
                    width: rem(610px);
                    height: rem(610px);

                    position: absolute;
                    top: rem(-50px);
                    right: rem(-140px);

                    margin-left: 0;
    
                    img {
                        width: rem(610px);
                        height: rem(610px);
                    }
                }
            }
        }
    }

    .manufacturers-main {
        .header {
            padding-top: rem(150px);
            padding-left: rem(50px);
            padding-right: rem(50px);
    
            .pagename {
                font-size: rem(47px);
            }
    
            .search {
                margin-top: rem(20px);

                select {
                    width: 100%;
                    height: rem(98px);

                    margin-top: rem(15px);
                    margin-right: 0;

                    font-size: rem(30px);

                    background-size: rem(26px);
    
                    option {
                        
                    }

                    &.alphabet {
                        display: block;
                    }
                }
            }
    
            .logo {
                display: none;
            }
        }
    
        .links {
            display: none;
        }
    
        .main {
            padding-top: rem(30px);
            padding-left: rem(50px);
            padding-right: rem(50px);
    
            .logos {
                width: 100%;

                .separate {
                    display: block;
                }

                .logos-hide {
                    display: none;

                    &.active {

                    }
                }
    
                .item {
                    width: 50%;
                    height: rem(313px);
    
                    &:hover {
                        
                    }
    
                    img {
                        
                    }
                }
            }
        }
    }

    .presscenter-main {
        .first-screen {
            flex-direction: column;
    
            .left {
                position: relative;
                width: 100%;
                height: auto;
                margin-top: rem(110px);
    
                .wrap {
                    position: relative;
                    width: 86%;
                    padding-top: rem(40px);
                    padding-bottom: rem(40px);
                    padding-left: 0;
                    padding-right: 0;
    
                    .pagename {
                        font-size: rem(62px);
                    }
        
                    .search {
                        input,
                        select {
                            width: 44%;
                            height: rem(77px);

                            font-size: rem(31px);

                            border-radius: rem(25px);
    
                            option {
                                
                            }
    
                            &.year {
                                width: rem(192px);
                                font-size: rem(32px);
                                text-align: center;
                                text-align-last: center;
                                border-radius: rem(10px);
                                
                                option {
                                    width: rem(192px);
                                }
                            }
    
                            &.month {
                                width: rem(380px);
                                font-size: rem(32px);
                                text-align: center;
                                text-align-last: center;
                                border-radius: rem(10px);
                            }
                        }
                    }
        
                    .presscenter-select {
                        .pic {
                            width: 100%;
        
                            img {
                                width: 100%;
                                max-height: initial;
                            }
                        }
        
                        .new-presscenter {
                            font-size: rem(60px);
                        }
        
                        .category {
                            font-size: rem(25px);
                            line-height: rem(75px);
                        }
        
                        .title {
                            font-size: rem(44px);
                            line-height: rem(50px);
                        }
        
                        .desc {
                            font-size: rem(27px);
                            line-height: rem(27px);
                        }
                        
                        .date {
                            font-size: rem(30px);
                            line-height: rem(62px);
                        }
    
                        .profile {
                            .photo {
                                width: rem(170px);
                                height: rem(170px);
                            }
    
                            .texts {
                                .name {
                                    font-size: rem(34px);
                                }
                                .position {
                                    font-size: rem(22px);

                                    margin-top: rem(20px);
                                }

                                .contact {
                                    font-size: rem(22px);
                                    margin-top: rem(20px);

                                    a {
                                        width: rem(30px);
                                        height: rem(22px);

                                        img {
                                            width: rem(30px);
                                            height: rem(22px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        
            .right {
                position: relative;
                width: 100%;
                
                .presscenter-list {
                    width: 93%;
                    margin-left: 0;
                    margin: auto;
                    padding-left: rem(55px);
        
                    .line {
                        top: rem(180px);
                        left: rem(7px);
                    }
                    
                    .presscenter-item {
                        margin-top: rem(30px);
        
                        .pic {
                            width: rem(246px);
                            height: rem(208px);
        
                            img {
                                width: rem(246px);
                                height: rem(208px);
                            }
                        }
        
                        .texts {
                            .category {
                                font-size: rem(16.8px);
                            }
        
                            .title {
                                font-size: rem(28.8px);
                            }
        
                            .desc {
                                font-size: rem(19px);
                            }
        
                            .date {
                                font-size: rem(16.8px);
                            }
                        }
        
                        .dot {
                            left: rem(-55px);
                        }
        
                        &:after {
                            width: rem(40px);
                            left: rem(-40px);
                        }
                    }
                }
            }
        }
    
        .search-result {
            .header {
                position: relative;
                width: 86%;
                margin: auto;

                padding-top: rem(150px);
                padding-bottom: 0;
                padding-left: 0;
    
                .pagename {
                    font-size: rem(62px);
                }
    
                .search {
                    input,
                    select {
                        width: 44%;
                        height: rem(77px);

                        font-size: rem(31px);

                        border-radius: rem(25px);
    
                        option {
                            
                        }
    
                        &.year {
                            width: rem(192px);
                            font-size: rem(32px);
                            text-align: center;
                            text-align-last: center;
                            border-radius: rem(10px);
                            
                            option {
                                width: rem(192px);
                            }
                        }

                        &.month {
                            width: rem(380px);
                            font-size: rem(32px);
                            text-align: center;
                            text-align-last: center;
                            border-radius: rem(10px);
                        }
                    }
    
                    a {
                        font-size: rem(31px);
                    }
                }
    
                .logo {
                    display: none;
                }
            }
    
            .main {
                flex-direction: column;
    
                .left {
                    width: 100%;
                    position: relative;
        
                    .presscenter-list {
                        width: 93%;
                        margin-left: 0;
                        margin: auto;
                        padding-left: rem(55px);
                        padding-bottom: 0;
            
                        .line {
                            top: rem(180px);
                            left: rem(7px);
                        }
                        
                        .presscenter-item {
                            margin-top: rem(30px);
            
                            .pic {
                                width: rem(246px);
                                height: rem(208px);
            
                                img {
                                    width: rem(246px);
                                    height: rem(208px);
                                }
                            }
            
                            .texts {
                                .category {
                                    font-size: rem(16.8px);
                                }
            
                                .title {
                                    font-size: rem(28.8px);
                                }
            
                                .desc {
                                    font-size: rem(19px);
                                }
            
                                .date {
                                    font-size: rem(16.8px);
                                }
                            }
            
                            .dot {
                                left: rem(-55px);
                            }
            
                            &:after {
                                width: rem(40px);
                                left: rem(-40px);
                            }
                        }
                    }
                }
            
                .right {
                    width: 100%;
        
                    .presscenter-list {
                        width: 93%;
                        margin-left: 0;
                        margin: auto;
                        padding-left: rem(55px);
                        padding-top: 0;
            
                        .line {
                            top: rem(180px);
                            left: rem(7px);
                        }
                        
                        .presscenter-item {
                            margin-top: rem(30px);
            
                            .pic {
                                width: rem(246px);
                                height: rem(208px);
            
                                img {
                                    width: rem(246px);
                                    height: rem(208px);
                                }
                            }
            
                            .texts {
                                .category {
                                    font-size: rem(16.8px);
                                }
            
                                .title {
                                    font-size: rem(28.8px);
                                }
            
                                .desc {
                                    font-size: rem(19px);
                                }
            
                                .date {
                                    font-size: rem(16.8px);
                                }
                            }
            
                            .dot {
                                left: rem(-55px);
                            }
            
                            &:after {
                                width: rem(40px);
                                left: rem(-40px);
                            }
                        }
                    }
                }
            }
        }
    
        .presscenter-single {
    
            .left {
                width: 86%;
                margin: auto;
                padding: rem(150px 0 50px);
    
                .pagename {
                    
                }
    
                .pic {
                    
    
                    img {
                        
                    }
                }
    
                .meta {
                    .text_wrap {
                        .category {
                            font-size: rem(17px);
                        }
    
                        .title {
                            font-size: rem(52.5px);
                        }
                    }
                }
    
                .presscenter-single-text {
                    font-size: rem(26.3px);
                    line-height: rem(27px);
                }
    
                .footer {
                    .social {
                        span {
                            font-size: rem(25px);

                            margin-right: rem(15px);
                        }
    
                        .icon {
                            width: rem(60px);
                            height: rem(60px);

                            margin-right: rem(10px);
                        }
                    }
                }
            }
        
            .right {
                display: none;
            }
        }
    }

    .direction-details-main {
        flex-direction: column;
    
        .left {
            width: 100%;
            height: rem(112px);

            top: rem(112px);

            overflow: hidden;

            cursor: pointer;

            transition: 0.5s;

            &.active {
                height: rem(590px);
            }
    
            .logo {
                display: none;
            }
    
            .wrap {
                width: calc(92% - 3.75rem);
                
                position: absolute;
                top: rem(15px);
                bottom: initial;
                left: rem(60px);

                margin-left: 0;

                .title {
                    font-size: rem(62px);
                    position: relative;

                    display: inline-block;

                    &:before {
                        content: '';

                        background: url(../img/arrow-right.svg) 50% 50% no-repeat;
                        background-size: contain;

                        width: rem(18px);
                        height: rem(20px);

                        position: absolute;
                        top: 16%;
                        bottom: 0;
                        right: -12.5%;
                        margin: auto;

                        filter: brightness(0) invert(1);
                    }
                }
                form {
                    select {
                        width: 100%;
                        height: rem(89px);

                        font-size: rem(28px);
    
                        option {
                            font-size: rem(28px);
                        }
                    }
                }
                .items {
                    .line {
                        height: calc(100% - 4rem);
                        top: 5%;
                    }

                    .item {
                        font-size: rem(40px);
                    }
                }
            }
        }
    
        .right {
            width: 100%;
    
            .screen {
                margin-top: rem(70px);
    
                &>.title {
                    padding-left: rem(50px);
                    padding-right: rem(50px);

                    font-size: rem(50px);
                }
    
                &>p {
                    padding-left: rem(50px);
                    padding-right: rem(50px);

                    font-size: rem(28px);
                }
    
                .title2 {
    
                    span {
                        font-size: rem(40px);
                    }
    
                    &:after {
                        
                    }
                }
    
                .contacts {
                    padding-left: rem(50px);
                    padding-right: rem(50px);

                    .items {
                        .wrap {
                            width: 100%;

                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            opacity: 0;
                            display: none;

                            transition: 1s;

                            &.active {
                                opacity: 1;
                                display: flex !important;
                            }
                        }

                        .separate {
                            display: block;
    
                            &.hide {
                                display: none;
                            }
                        }
    
    
                        .item {
                            width: 100%;
    
                            .photo {
                                width: rem(195px);
                                height: rem(195px);
    
                                img {
                                    width: rem(195px);
                                    height: rem(195px);
                                }
                            }
    
                            .texts {
                                width: calc(100% - 12.1875rem - .9375rem);

                                .name {
                                    font-size: rem(39px);
                                }
                                .position {
                                    font-size: rem(25px);
                                    margin-top: rem(25px);
                                    line-height: rem(30px);
                                }
                                .contact {
                                    font-size: rem(25px);
                                    margin-top: rem(25px);
    
                                    a {
                                        width: rem(34px);
                                        height: rem(26px);
                                        
                                        img {
                                            width: rem(34px);
                                            height: rem(26px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                .brands {
                    padding-left: rem(50px);
                    padding-right: rem(50px);
    
                    .logos {
                        .separate {
                            color: #b5b6b6;
                            font-size: rem(24px);
                            font-family: 'PT Sans', sans-serif;
                            font-weight: 400;
                            line-height: rem(32px);
                            margin-bottom: rem(20px);
                            margin-top: rem(20px);
                            text-align: center;
    
                            &:after {
                                display: none;
                            }
                        }
            
                        .item {
                            width: 50%;
                            height: rem(310px);
                        }
                    }
                }
    
                .news,
                .promos,
                .events {
                    padding-left: rem(50px);
                    padding-right: rem(50px);
    
                    .slider {
                        .slide {
                            a {
                                img {
                                    width: 100%;
                                    height: rem(242px);
                                }
        
                                .title {
                                    font-size: rem(40px);
                                    line-height: rem(43px);
                                }
        
                                .desk {
                                    font-size: rem(28px);
                                }
        
                                .date {
                                    font-size: rem(24px);
                                }
                            }
                        }
                    }
                }
    
                .popup {
                    width: 90%;
                    height: auto;

                    position: fixed;

                    top: rem(250px);
                    right: 0;
                    bottom: initial;
                    left: 0;
                    margin: auto;
    
                    .title {
                        font-size: rem(47px);
                    }
    
                    .form {
                        padding-bottom: rem(20px);

                        form {
                            label {
                                p {
                                    font-size: rem(25px);
                                }
                            }
        
                            .bottom {
                                flex-direction: column-reverse;
        
                                input[type="submit"] {
                                    margin-top: rem(20px);
                                }
        
                                .captcha {
                                    width: 80%;
                                }
                            }
        
                            
                        }
                    }
                }
            }
        }
    }

    .brand-main {
        flex-direction: column;
    
        .left {
            width: 100%;
            height: auto;

            top: rem(112px);

            overflow: hidden;

            cursor: pointer;

            transition: 0.5s;
    
            .logo {
                display: none;
            }
    
            .wrap {
                width: calc(92% - 3.75rem);
                
                position: relative;
                top: initial;
                left: initial;

                margin-top: rem(15px);
                margin-left: 0;
    
                .title {
                    font-size: rem(50px);
                    position: relative;

                    display: table-caption;

                    &:before {
                        content: '';

                        background: url(../img/arrow-right.svg) 50% 50% no-repeat;
                        background-size: contain;

                        width: rem(18px);
                        height: rem(20px);

                        position: absolute;
                        top: 16%;
                        bottom: 0;
                        right: -12.5%;
                        margin: auto;

                        filter: brightness(0) invert(1);
                    }
                }
    
                form {
                    display: none;

                    select {
                        width: 100%;
                        height: rem(89px);

                        font-size: rem(28px);
    
                        option {
                            font-size: rem(28px);
                        }
                    }
                }
    
                .items {
                    display: none;
                    padding-bottom: rem(30px);
    
                    .line {
                        height: calc(100% - 4rem - 1.875rem);
                        top: calc(-0.6% - 1.875rem);
                    }
    
                    .item {
                        font-size: rem(40px);
    
                        p {
                            font-size: rem(35px);
                        }
                    }
    
                    .toggle-list-2 {
                        .line {
                            height: calc(100% - 1.875rem);
                        }
    
                        .item {
                            font-size: rem(35px);
                        }
                    }
    
                    .toggle-list-3 {
                        .item {
                            font-size: rem(35px);
                        }
                    }
                }
            }
        }
    
        .right {
            width: 100%;
    
            .screen {
                .logo-brand {
                    margin: rem(80px auto 30px);
                    padding-left: 0;
                    padding-right: 0;

                    height: rem(105px);
    
                    img {
                        height: rem(105px);
                        object-position: 0 50%;
                    }
                }
    
                &>.title {
                    text-align: center;
                    font-size: rem(50px);
                    padding-left: rem(50px);
                    padding-right: rem(50px);
                }
    
                .title2 {
                    font-size: rem(34px);
                }
    
                .video {
                    padding-left: rem(50px);
                    padding-right: rem(50px);

                    margin: 0 auto;
                    text-align: center;
    
                    iframe {
                        
                    }
                }
    
                .title_sm {
                    padding-left: rem(50px);
                    padding-right: rem(50px);

                    font-size: rem(40px);
                    line-height: rem(48px);
                }
    
                &>p {
                    padding-left: rem(50px);
                    padding-right: rem(50px);

                    font-size: rem(28px);
                    line-height: rem(34px);
    
                    a {
                        
                    }
                }
    
                ul {
                    padding-left: rem(50px);
                    padding-right: rem(50px);
    
                    li {
                        font-size: rem(28px);
                        line-height: rem(34px);
    
                        a {
                            
                        }
    
                        &:before { 
                            
                        }
                    }
                }
    
                ol {
                    padding-left: rem(50px);
                    padding-right: rem(50px);
    
                    li {
                        font-size: rem(28px);
                        line-height: rem(34px);
    
                        a {
                            
                        }
                    }
                }
    
                .cols {
                    flex-direction: column;
    
                    .col {
                        width: 100%;
                    }
                }
    
                .pic {
                    padding-left: rem(50px);
                    padding-right: rem(50px);
    
                    img {
                        
                    }
                }
    
                .contacts {
                    .items {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
    
                        .item {
                            width: 100%;
    
                            .photo {
                                width: rem(195px);
                                height: rem(195px);
    
                                img {
                                    width: rem(195px);
                                    height: rem(195px);
                                }
                            }
    
                            .texts {
                                width: calc(100% - 12.1875rem - .9375rem);

                                .name {
                                    font-size: rem(39px);
                                }
                                .position {
                                    font-size: rem(25px);
                                    margin-top: rem(25px);
                                    line-height: rem(30px);
                                }
                                .contact {
                                    font-size: rem(25px);
                                    margin-top: rem(25px);
    
                                    a {
                                        width: rem(34px);
                                        height: rem(26px);
                                        
                                        img {
                                            width: rem(34px);
                                            height: rem(26px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                .brands {
                    
    
                    .logos {
                        
    
                        .separate {
                            
    
                            &.hide {
                                
                            }
    
                            span {
                                
                            }
    
                            &:after {
                                
                            }
                        }
    
                        .logos-hide {
                            
    
                            &.active {
                                
                            }
                        }
            
                        .item {
                            
            
                            &:hover {
                                
                            }
            
                            img {
                                
                            }
                        }
                    }
                }
    
                .news,
                .promos,
                .events {
                    .slider {
    
                        .slide {
    
                            a {
                                img {
                                    width: 100%;
                                    height: rem(242px);
                                }
        
                                .title {
                                    font-size: rem(40px);
                                    line-height: rem(43px);
                                }
        
                                .desk {
                                    font-size: rem(28px);
                                }
        
                                .date {
                                    font-size: rem(24px);
                                }
                            }
                        }
                    }
                }
    
                .events {
                    
    
                    .slider {
                        
                    }
    
                    .title2 {
                        span {
                            
                        }
        
                        &:after {
                            
                        }
                    }
                }
    
                .shadow {
                    
                }
    
                .popup {
                    
    
    
                    .title {
                        
                    }
    
                    .title2 {
                        
    
                        &:after {
                            
                        }
                    }
    
                    .form {
                        
        
                        form {
                            .inputs {
                                
                                
                                input {
                                    
                                }
    
                                textarea {
                                    
                                }
                            }
        
                            label {
                                
        
                                input {
                                    
                                }
        
                                p {
                                    
        
                                    a {
                                        
                                    }
                                }
                            }
        
                            .bottom {
                                
        
                                input[type="submit"] {
                                    
                                }
        
                                .captcha {
                                    
                                    
                                    img {
                                        
                                    }
                                }
                            }
        
                            
                        }
                    }
                }
    
            }
        }
    }

    .error {
        .wrap {
            width: 110vw;
            height: 110vw;
        }
    }

    .condition-main {
        display: flex;
    
        .logo {
            width: rem(198px);
            height: rem(164px);
    
            position: absolute;
            top: rem(30px);
            right: rem(100px);
        }
    
        .left {
            background-color: #00aeef;
            width: rem(600px);
            height: 100vh;
    
            position: sticky;
            top: 0;
            left: 0;
    
            box-shadow: rem(7px 7px 5px rgba(0, 0, 0, 0.1));
    
            z-index: 10;
    
            box-sizing: border-box;
    
            .wrap {
                width: rem(370px);
                height: 34%;
    
                position: absolute;
                top: 0;
                left: rem(64px);
                bottom: 0;
                right: 0;
                margin: auto;
    
                .title {
                    font-family: 'PT Sans', sans-serif;
                    font-weight: 700;
                    font-size: rem(48px);
                    margin-bottom: rem(30px);
                }
                .items {
                    position: relative;
    
                    .line {
                        width: 1px;
                        height: calc(100% - 4.5rem);
    
                        background-color: #fff;
    
                        position: absolute;
                        top: 0;
                        left: rem(7px);
                        bottom: 0;
                        margin: auto;
                    }
                    .item {
                        font-family: 'PT Sans', sans-serif;
                        font-size: rem(24.5px);
    
                        margin-bottom: rem(25px);
                        padding-left: rem(30px);
    
                        position: relative;
    
                        cursor: pointer;
    
                        .dot {
                            width: rem(16px);
                            height: rem(16px);
    
                            background-color: #fff;
                            border-radius: 50%;
    
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                }
            }
        }
    
        .right {
            width: calc(100% - 37.5rem);
    
            padding-top: rem(90px);
            padding-left: rem(80px);
            padding-right: rem(80px);
    
            box-sizing: border-box;
    
            position: relative;
    
            .screen {
                display: none;
    
                .title {
                    color: #00aeef;
                    font-size: rem(48px);
                    font-family: 'PT Sans Caption', sans-serif;
                    font-weight: 700;
                    line-height: rem(57.5px);
                    margin-bottom: rem(60px);
                }
    
                .title2 {
                    color: #000;
                    font-size: rem(29px);
                    font-family: 'PT Sans', sans-serif;
                    font-weight: 700;
                    line-height: rem(34px);
                    margin-top: rem(25px);
                    margin-bottom: rem(25px);
                }
    
                &>p {
                    color: #000;
                    font-size: rem(25px);
                    line-height: rem(30px);
                    font-family: 'PT Sans', sans-serif;
                    margin: rem(20px 0);
    
                    span {
                        font-weight: 700;
                    }
                }
                
                ul {
                    padding-left: 0;
                    list-style: none;
    
                    li {
                        color: #000;
                        font-size: rem(25px);
                        line-height: rem(30px);
                        font-family: 'PT Sans', sans-serif;
                    }
                }
            }
        }
    }


    .cisco-landing {
        .hero {
            background: url(../img/cisco-landing/bg-mob.png) 50% 50% no-repeat;
            background-size: cover;

            height: auto;

            .f1 {
                
            }
    
            .container {
                height: auto;

                .man {
                    visibility: hidden !important;
                }
    
                .header {
                    position: relative;

                    margin-top: rem(160px);
                    top: initial;

                    padding-left: rem(40px);
                    padding-right: rem(40px);

                    height: auto;
    
                    .logos {
                        width: 100%;
                    }
    
                    .menu {
                        display: none;
                    }
                }
    
                .texts {
                    display: block;

                    width: 100%;
                    height: auto;
                    
                    padding-left: rem(40px);
                    padding-right: rem(40px);

                    box-sizing: border-box;

                    position: relative;

                    top: initial;

                    margin-top: rem(150px);
    
                    .title {
                        font-size: rem(68px);
                        width: 90%;
                    }
                    .desc {
                        font-size: rem(36px);
                        line-height: rem(43px);
                        margin-top: rem(70px);
                    }
                    .btn {
                        width: rem(610px);
                        height: rem(117px);

                        border-radius: rem(55px);

                        margin: 0 auto;

                        font-size: rem(34px);

                        margin-top: rem(120px);
                    }
                }
            }
        }
    
        .advantages {
            width: 100%;
            
            .wrap {
                flex-direction: column;
                margin-top: 0;
    
                .item {
                    width: 100%;
                    height: rem(630px);

                    padding-top: rem(130px);
                    padding-left: rem(50px);

                    &.item1 {
                        order: 1;
                    }
                    &.item2 {
                        order: 4;
                    }
                    &.item3 {
                        order: 5;
                    }
                    &.item4 {
                        order: 8;
                    }
                    &.item5 {
                        order: 2;
                    }
                    &.item6 {
                        order: 3;
                    }
                    &.item7 {
                        order: 6;
                    }
                    &.item8 {
                        order: 7;
                    }
    
                    &.blue {
                        
                    }
    
                    .line {
                        display: none;
                    }
    
                    .title {
                        font-size: rem(56px);
                    }
    
                    .desc {
                        font-size: rem(40px);
                    }
    
                    .pic {
                        max-width: rem(360px);
                        max-height: rem(360px);
                    }
                    
                }
            }
        }
    
        .smartnet {
            margin-top: 0;
            
            .container {
                height: auto;

                display: flex;
                flex-direction: column-reverse;

                .scheme {
                    position: relative;

                    width: rem(638px);
                    height: rem(1813px);

                    left: initial;

                    margin-top: rem(100px);
                    margin-bottom: rem(100px);

                    img {
                        object-fit: contain;
                        object-position: 50% 0%;
                    }
                }
    
                .slider-smart-net {
                    background: url(../img/cisco-landing/bg-slider-smart-sm.svg) 50% 100% no-repeat;
                    background-size: cover;

                    width: 100%;
                    height: rem(1100px);

                    position: relative;
                    top: rem(0);

                    &:before {
                        display: none;
                    }

                    .slider-nav {
                        color: #000;
                        position: absolute;

                        bottom: 12%;
                        left: 0;
                        right: 0;
                        margin: auto;

                        z-index: 10;

                        font-size: rem(42px);
                        color: #fff;
                        font-weight: 700;

                        width: 95%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        visibility: visible;

                        .slick-prev,
                        .slick-next {
                            cursor: pointer;
                            width: 35%;
                        }
                        
                        .slick-next {
                            text-align: right;
                        }
                    }

                    .carousel_wrap {
                        width: 100%;
                        height: auto;

                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;

                        .slick-track {
                            top: rem(0);
                        }

                        .item {
                            height: auto;

                            margin: rem(0);

                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;

                            cursor: pointer;

                            transform: scale(1) translateY(0);
                            transition: 0.4s;

                            outline: none;

                            margin: rem(0 40px);

                            .title {
                                font-size: rem(68px);
                                font-weight: 700;
                                text-align: center;
                                margin-top: rem(30px);

                                order: 1;

                                visibility: visible;
                                opacity: 1;
                            }

                            .desc {
                                font-family: 'PT Sans Caption', sans-serif;
                                font-size: rem(34px);
                                margin-top: rem(60px);
                                text-align: center;
                                order: 2;

                                opacity: 0;

                                transition: 0;

                                visibility: visible;
                                opacity: 1;
                            }

                            .btn {
                                font-family: 'Open Sans', sans-serif;
                                color: #fff;
                                background-color: #00aeef;
                                text-decoration: none;
                                font-size: rem(44px);

                                border: 2px solid #fff;
                                box-sizing: border-box;
                                border-radius: rem(70px);

                                display: inline-flex;
                                justify-content: center;
                                align-items: center;

                                order: 3;

                                width: rem(494px);
                                height: rem(154px);

                                outline: none;

                                margin-top: rem(60px);

                                visibility: visible;
                                opacity: 1;

                                transition: opacity 0s, background-color 0.3s, color 0.3s;
                                
                                &:hover {
                                    background-color: #fff;
                                    color: #00aeef;
                                }
                            }

                            &.slick-active {
                                transform: scale(1) translateY(0);

                                .desc {
                                    opacity: 1;
                                    visibility: visible;
                                }

                                .btn {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }
        }
    
        .promo {
            
    
            .container {
                padding-right: rem(40px);
                padding-left: rem(40px);
    
                .title {
                    font-size: rem(54px);
                }
    
                .desc {
                    font-size: rem(38px);
                }
    
                .btns {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    width: 100%;
    
                    .btn {
                        font-size: rem(45px);
                        text-align: center;
                        height: rem(180px);
    
                        &.btn1 {
                            padding: rem(0 40px);

                            width: rem(632px);
                        }
    
                        &.btn2 {
                            margin-top: rem(50px);

                            width: rem(498px);
                        }
                    }
                }
            }
        }
    
        .capabilities {
            .container {
                >.title {
                    width: 87%;
                    margin: 0 auto;
                    font-size: rem(68px);
                    line-height: rem(81px);
                }
    
                >.desс {
                    font-size: rem(36px);
                    line-height: rem(43px);
                }
    
                >.wrap {
                    width: 90%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
    
                    .item {
                        margin-bottom: rem(100px);
    
                        .pic {
                            width: rem(357px);
                            height: rem(357px);
                        }
    
                        .text {
                            font-size: rem(40px);
                            line-height: rem(40px);
                        }
                    }
                }
    
                .list_wrap {
                    padding-left: rem(40px);
                    padding-right: rem(40px);

                    margin-top: rem(50px);
    
                    .title {
                        font-weight: 700;
                        font-size: rem(68px);
                        line-height: rem(81px);
                        text-align: center;
                    }
    
                    .list {
                        &:before {
                            top: -0.6%;
                            left: rem(15px);
                        }
    
                        .item {
                            font-size: rem(40px);
                            line-height: rem(40px);
                            
                            margin-top: rem(80px);
    
                            &:before {
                                width: rem(33px);
                                height: rem(33px);
                            }
                        }
                    }
                }
            }
        }
    
        .help {
            .container {
                .wrap {
                    width: rem(640px);
                    height: rem(640px);
    
                    .title {
                        font-size: rem(31px);
                        line-height: rem(31px);
                    }
    
                    .list {
    
                        .item {
                            font-size: rem(27px);
                            line-height: rem(31px);
    
                            &:before {
                                width: rem(17px);
                                height: rem(17px);

                                top: rem(5px);
                            }
                        }
    
                        &:before {
                            height: 58%;
                            right: rem(-40px);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 414px) {
    .directions-mob {
        .line {
            background-color: #fff;

            width: 1px;

            position: absolute;
            top: 0;
            left: rem(71px);

            transition: 0.35s;
        }
    }
}
