@charset "UTF-8";
@import url(../../bower_components/normalize.css/normalize.css);
@import url(partials/animate.css);
html {
  font-family: 'PT Sans', sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

@media (min-width: 769px) and (max-width: 1920px) {
  html {
    font-size: calc(6px + (16 - 6) * ((100vw - 769px) / (1920 - 769))); } }

@media (min-width: 290px) and (max-width: 768px) {
  html {
    font-size: calc(6px + (16 - 6) * ((100vw - 300px) / (768 - 300))); } }

body {
  margin: 0;
  padding: 0; }

picture img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto; }

br.desk {
  display: block; }

br.mob {
  display: none; }

h1 {
  font-family: 'PT Sans Bold', sans-serif;
  color: #12b9f3;
  font-size: 3.65187rem;
  margin-top: 3.125rem;
  margin-bottom: 2.1875rem;
  padding-left: 5rem; }

h2 {
  font-family: 'PT Sans Bold', sans-serif;
  color: #12b9f3;
  font-size: 3.32031rem;
  margin-top: 2.5rem;
  margin-bottom: 1.875rem;
  padding-left: 5rem; }

h3 {
  font-family: 'PT Sans Bold', sans-serif;
  color: #12b9f3;
  font-size: 2.905rem;
  margin-top: 1.875rem;
  margin-bottom: 1.25rem;
  padding-left: 5rem; }

h4 {
  font-family: 'PT Sans Bold CAPS', sans-serif;
  color: #000;
  font-size: 2.24063rem;
  margin-top: 1.875rem;
  margin-bottom: 1.25rem;
  padding-left: 5rem; }

h5 {
  font-family: 'PT Sans Bold ', sans-serif;
  color: #000;
  font-size: 1.66rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-left: 5rem; }

.container {
  position: relative;
  width: 100%;
  margin: 0 auto; }

.mb-bar {
  background-color: rgba(255, 255, 255, 0.9); }

.mb-track {
  background-color: rgba(255, 255, 255, 0.25); }

.p-left-160 {
  padding-left: 10rem;
  box-sizing: border-box; }

.p-right-160 {
  padding-right: 10rem;
  box-sizing: border-box; }

.p-left-190 {
  padding-left: 11.875rem;
  box-sizing: border-box; }

.p-right-190 {
  padding-right: 11.875rem;
  box-sizing: border-box; }

.cookie-warning {
  background-color: #00aeef;
  width: 25rem;
  height: 12.5rem;
  position: fixed;
  top: 0;
  right: 2%;
  opacity: 0.95;
  transition: opacity 0.3s, visibility 0.3s 0s; }
  .cookie-warning.hide {
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }
  .cookie-warning .wrap {
    width: 88%;
    height: 90%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto; }
    .cookie-warning .wrap p {
      color: #fff;
      font-size: 0.6875rem; }
    .cookie-warning .wrap button {
      appearance: none;
      background-color: #fff;
      color: #00aeef;
      border: none;
      box-shadow: none;
      font-size: 1.0625rem;
      padding: 0.625rem 2.5rem;
      cursor: pointer;
      margin-top: 0.625rem; }

.preloader-global {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 100000;
  background-color: #fff;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s, visibility 0.3s 0s; }
  .preloader-global.hide {
    opacity: 0;
    visibility: hidden; }
  .preloader-global .load {
    background: url(../img/logo_blue.svg) 50% 50% no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 12.5rem;
    height: 12.5rem;
    animation-name: loader;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite; }

@keyframes loader {
  0% {
    transform: scale(1, 1); }
  50% {
    transform: scale(-1, 1); }
  100% {
    transform: scale(1, 1); } }

.hero {
  background-color: #12b9f3;
  height: 100vh;
  overflow: hidden; }
  .hero .container {
    height: 100vh; }
    .hero .container .texts {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 36%;
      height: 85vh;
      position: absolute;
      top: 0;
      left: 11.875rem;
      bottom: 0;
      margin: auto;
      z-index: 11; }
      .hero .container .texts .logo {
        width: 29.4375rem;
        height: 24.375rem;
        position: relative; }
      .hero .container .texts .circle_mob {
        display: none; }
      .hero .container .texts .title {
        font-family: 'PT Sans Caption', sans-serif;
        font-size: 3rem; }
      .hero .container .texts .btns {
        width: 36.75rem;
        height: 5.4375rem;
        position: relative; }
        .hero .container .texts .btns a {
          color: #fff;
          text-decoration: none;
          font-size: 1.5625rem;
          border: none;
          box-sizing: border-box;
          border-radius: 2.1875rem;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 17.75rem;
          height: 5.4375rem;
          outline: none;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto; }
          .hero .container .texts .btns a:last-child {
            left: initial;
            right: 0; }
        .hero .container .texts .btns .border {
          width: 17.75rem;
          height: 5.4375rem;
          border: 2px solid #fff;
          box-sizing: border-box;
          border-radius: 2.1875rem;
          outline: none;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          transition: .3s ease-out all; }
    .hero .container .video {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      overflow: hidden;
      z-index: 10; }
      .hero .container .video:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        background: #12b9f3;
        background: linear-gradient(90deg, #12b9f3 75%, rgba(18, 185, 243, 0) 100%);
        width: 60%;
        height: 100vh; }
      .hero .container .video video {
        height: 100vh;
        object-fit: cover;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -9.375rem;
        margin: auto; }
    .hero .container .plane_banner {
      background: url(../img/_temp-pics/plane_banner.svg) 50% 50% no-repeat;
      background-size: contain;
      width: 43%;
      height: 12%;
      position: absolute;
      bottom: 7%;
      right: 5%;
      margin: auto;
      z-index: 100;
      opacity: 0;
      transform: translateX(150%);
      transition: transform 0.8s, opacity 0.3s; }
      .hero .container .plane_banner.active {
        opacity: 1;
        transform: translateX(0%); }
      .hero .container .plane_banner.hide {
        opacity: 0;
        transform: translateX(-50%); }

.slider {
  /* Slider */
  /* Icons */
  /* Arrows */
  /* Dots */
  margin-top: 2.5rem;
  overflow: hidden;
  outline: none;
  height: 37.5rem; }
  .slick-loading .slider .slick-list {
    background: #fff url("./ajax-loader.gif") center center no-repeat; }

@font-face {
  .slider {
    font-family: "slick";
    src: url("./fonts/slick.eot");
    src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal; } }
  .slider .slick-prev,
  .slider .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none; }
    .slider .slick-prev:hover, .slider .slick-prev:focus,
    .slider .slick-next:hover,
    .slider .slick-next:focus {
      outline: none;
      background: transparent;
      color: transparent; }
      .slider .slick-prev:hover:before, .slider .slick-prev:focus:before,
      .slider .slick-next:hover:before,
      .slider .slick-next:focus:before {
        opacity: 1; }
    .slider .slick-prev.slick-disabled:before,
    .slider .slick-next.slick-disabled:before {
      opacity: 0.8; }
    .slider .slick-prev:before,
    .slider .slick-next:before {
      font-family: "slick";
      font-size: 20px;
      line-height: 1;
      color: white;
      opacity: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .slider .slick-prev {
    left: -25px; }
    [dir="rtl"] .slider .slick-prev {
      left: auto;
      right: -25px; }
    .slider .slick-prev:before {
      content: "←"; }
      [dir="rtl"] .slider .slick-prev:before {
        content: "→"; }
  .slider .slick-next {
    right: -25px; }
    [dir="rtl"] .slider .slick-next {
      left: -25px;
      right: auto; }
    .slider .slick-next:before {
      content: "→"; }
      [dir="rtl"] .slider .slick-next:before {
        content: "←"; }
  .slider .slick-dotted.slick-slider {
    margin-bottom: 0; }
  .slider .slick-dots {
    position: absolute;
    bottom: 3.7%;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    right: -48.4%;
    left: 0;
    margin: auto;
    width: auto; }
    .slider .slick-dots li {
      position: relative;
      display: inline-block;
      height: 1.25rem;
      width: 1.25rem;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      .slider .slick-dots li button {
        border: 0;
        background: transparent;
        display: block;
        height: 1.25rem;
        width: 1.25rem;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer; }
        .slider .slick-dots li button:hover, .slider .slick-dots li button:focus {
          outline: none; }
          .slider .slick-dots li button:hover:before, .slider .slick-dots li button:focus:before {
            opacity: 1; }
        .slider .slick-dots li button:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "•";
          width: 1.25rem;
          height: 1.25rem;
          font-family: "slick";
          font-size: 5.625rem;
          line-height: 1.25rem;
          text-align: center;
          color: white;
          opacity: 0.8;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
      .slider .slick-dots li.slick-active button:before {
        color: #00aeef;
        opacity: 1; }
  .slider .slide {
    position: relative;
    outline: none;
    height: 37.5rem; }
    .slider .slide iframe {
      width: 100%;
      height: 100%; }
    .slider .slide img {
      height: 100%; }
    .slider .slide .text_wrap {
      background-color: #00aeef;
      width: 38.75rem;
      height: 30.625rem;
      position: absolute;
      top: 0;
      left: 11.875rem;
      bottom: 0;
      margin: auto;
      padding: 4.375rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start; }
      .slider .slide .text_wrap .title {
        font-family: 'PT Sans Caption', sans-serif;
        font-size: 3rem; }
      .slider .slide .text_wrap .btn {
        color: #fff;
        text-decoration: none;
        font-size: 1.5625rem;
        border: 2px solid #fff;
        box-sizing: border-box;
        border-radius: 2.1875rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 17.75rem;
        height: 5.4375rem;
        outline: none; }

.announcement {
  margin-top: 3.125rem; }
  .announcement .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .announcement .container .video_wrap {
      width: 48.625rem;
      height: 22.9375rem;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      background-color: #000; }
      .announcement .container .video_wrap video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto; }
      .announcement .container .video_wrap .poster {
        width: 100%;
        height: 100%;
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 1;
        transition: opacity 0.25s;
        visibility: visible; }
        .announcement .container .video_wrap .poster img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .announcement .container .video_wrap .poster.hide {
          opacity: 0;
          visibility: hidden; }
      .announcement .container .video_wrap .sound {
        background: url("../img/volume.svg") 50% 50% no-repeat;
        background-size: 50%;
        position: absolute;
        width: 2.8125rem;
        height: 2.8125rem;
        background-color: #fff;
        border-radius: 50%;
        bottom: 5%;
        right: 2%;
        transition: 0.3s;
        opacity: 0;
        visibility: hidden; }
        .announcement .container .video_wrap .sound.active {
          opacity: 0.8;
          visibility: visible; }
      .announcement .container .video_wrap .mute {
        background: url("../img/mute.svg") 50% 50% no-repeat;
        background-size: 50%;
        position: absolute;
        width: 2.8125rem;
        height: 2.8125rem;
        background-color: #fff;
        border-radius: 50%;
        bottom: 5%;
        right: 2%;
        transition: 0.3s;
        opacity: 0;
        visibility: hidden; }
        .announcement .container .video_wrap .mute.active {
          opacity: 0.8;
          visibility: visible; }
      .announcement .container .video_wrap .texts {
        font-family: 'PT Sans Caption', sans-serif;
        background-color: #00aeef;
        width: 100%;
        height: 6.25rem;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        font-size: 3rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 3.125rem;
        box-sizing: border-box;
        opacity: 1;
        transition: opacity 0.25s;
        visibility: visible; }
        .announcement .container .video_wrap .texts.hide {
          opacity: 0;
          visibility: hidden; }

.feed {
  margin-top: 3.75rem; }
  .feed .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .feed .container .feed-wrap {
      position: relative; }
      .feed .container .feed-wrap .feed-news,
      .feed .container .feed-wrap .feed-promo,
      .feed .container .feed-wrap .feed-events {
        width: 46.25rem;
        position: absolute;
        top: 0;
        opacity: 0;
        visibility: hidden; }
        .feed .container .feed-wrap .feed-news.active,
        .feed .container .feed-wrap .feed-promo.active,
        .feed .container .feed-wrap .feed-events.active {
          position: relative;
          opacity: 1;
          visibility: visible; }
        .feed .container .feed-wrap .feed-news .item,
        .feed .container .feed-wrap .feed-promo .item,
        .feed .container .feed-wrap .feed-events .item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 1.875rem;
          cursor: pointer; }
          .feed .container .feed-wrap .feed-news .item .pic,
          .feed .container .feed-wrap .feed-promo .item .pic,
          .feed .container .feed-wrap .feed-events .item .pic {
            width: 17.25rem;
            height: 14.6875rem;
            filter: none;
            transition: 0.25s; }
            .feed .container .feed-wrap .feed-news .item .pic img,
            .feed .container .feed-wrap .feed-promo .item .pic img,
            .feed .container .feed-wrap .feed-events .item .pic img {
              width: 17.25rem;
              height: 14.6875rem;
              object-fit: cover; }
          .feed .container .feed-wrap .feed-news .item .texts,
          .feed .container .feed-wrap .feed-promo .item .texts,
          .feed .container .feed-wrap .feed-events .item .texts {
            margin-left: 3.125rem; }
            .feed .container .feed-wrap .feed-news .item .texts .category,
            .feed .container .feed-wrap .feed-promo .item .texts .category,
            .feed .container .feed-wrap .feed-events .item .texts .category {
              font-size: 1rem;
              color: #b7b7b7; }
            .feed .container .feed-wrap .feed-news .item .texts .title,
            .feed .container .feed-wrap .feed-promo .item .texts .title,
            .feed .container .feed-wrap .feed-events .item .texts .title {
              font-size: 2rem;
              color: #00aeef;
              font-weight: 700;
              margin-top: 1.25rem; }
            .feed .container .feed-wrap .feed-news .item .texts .desc,
            .feed .container .feed-wrap .feed-promo .item .texts .desc,
            .feed .container .feed-wrap .feed-events .item .texts .desc {
              font-size: 1.0625rem;
              color: #000;
              margin-top: 0.9375rem; }
            .feed .container .feed-wrap .feed-news .item .texts .date,
            .feed .container .feed-wrap .feed-promo .item .texts .date,
            .feed .container .feed-wrap .feed-events .item .texts .date {
              font-size: 1.1875rem;
              color: #b7b7b7;
              margin-top: 1.25rem; }
          .feed .container .feed-wrap .feed-news .item:hover .pic,
          .feed .container .feed-wrap .feed-promo .item:hover .pic,
          .feed .container .feed-wrap .feed-events .item:hover .pic {
            filter: contrast(0.3) brightness(1.5); }
      .feed .container .feed-wrap .loader {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        background-color: #fff;
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s, visibility 0.3s 0s; }
        .feed .container .feed-wrap .loader.hide {
          opacity: 0;
          visibility: hidden; }
        .feed .container .feed-wrap .loader .load {
          position: sticky;
          top: 50%;
          left: 0;
          right: 0;
          margin: auto;
          margin-top: 40%;
          margin-bottom: 40%;
          width: 6.25rem;
          height: 6.25rem; }
          .feed .container .feed-wrap .loader .load hr {
            border: 0;
            margin: 0;
            width: 40%;
            height: 40%;
            position: absolute;
            border-radius: 50%;
            animation: spin 2s ease infinite; }
          .feed .container .feed-wrap .loader .load :first-child {
            background: #ffe000;
            animation-delay: -1.5s; }
          .feed .container .feed-wrap .loader .load :nth-child(2) {
            background: #009cd7;
            animation-delay: -1s; }
          .feed .container .feed-wrap .loader .load :nth-child(3) {
            background: #ffe000;
            animation-delay: -0.5s; }
          .feed .container .feed-wrap .loader .load :last-child {
            background: #009cd7; }

@keyframes spin {
  0% {
    transform: translate(0); }
  25% {
    transform: translate(160%); }
  50% {
    transform: translate(160%, 160%); }
  75% {
    transform: translate(0, 160%); }
  100% {
    transform: translate(0); } }
    .feed .container .revol {
      background: url(../img/bg_promo.svg) 0% 50% no-repeat;
      background-size: cover;
      width: 57.5rem;
      height: 100vh;
      position: sticky;
      top: 0; }
      .feed .container .revol:before {
        background: url(../img/arrow.svg) 0% 50% no-repeat;
        background-size: contain;
        width: 20%;
        height: 83%;
        content: '';
        position: absolute;
        top: 0;
        left: 10%;
        bottom: 0;
        margin: auto; }
      .feed .container .revol .slider-nav {
        visibility: hidden; }
      .feed .container .revol .carousel_wrap {
        width: 70%;
        height: 85vh;
        position: absolute;
        top: 0;
        left: 10%;
        bottom: 0;
        right: 0;
        margin: auto; }
        .feed .container .revol .carousel_wrap .slick-list {
          padding: 3.125rem 0 !important; }
        .feed .container .revol .carousel_wrap .slick-track {
          top: 35vh; }
        .feed .container .revol .carousel_wrap .item {
          width: 100%;
          height: 35vh;
          margin: -3.125rem 0 6.25rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          cursor: pointer;
          transform: scale(0.7) translateY(0);
          transition: 0.4s;
          outline: none; }
          .feed .container .revol .carousel_wrap .item .title {
            font-size: 3rem;
            font-weight: 700; }
          .feed .container .revol .carousel_wrap .item .desc {
            font-family: 'PT Sans Caption', sans-serif;
            font-size: 1.0625rem;
            margin-top: 1.25rem;
            opacity: 0;
            transition: 0.5s; }
          .feed .container .revol .carousel_wrap .item .btn {
            font-family: 'Open Sans', sans-serif;
            color: #fff;
            background-color: #00aeef;
            text-decoration: none;
            font-size: 1.5625rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 15.5rem;
            height: 4.8125rem;
            border: 2px solid #fff;
            box-sizing: border-box;
            border-radius: 2rem;
            outline: none;
            margin-top: 1.5625rem;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s, background-color 0.3s, color 0.3s; }
            .feed .container .revol .carousel_wrap .item .btn:hover {
              background-color: #fff;
              color: #00aeef; }
          .feed .container .revol .carousel_wrap .item.slick-center {
            transform: scale(1) translateY(-8%); }
            .feed .container .revol .carousel_wrap .item.slick-center .desc {
              opacity: 1; }
            .feed .container .revol .carousel_wrap .item.slick-center .btn {
              opacity: 1;
              visibility: visible; }

.vendors {
  margin-top: 3.75rem; }
  .vendors .container .logos_wrap {
    width: 100%; }
    .vendors .container .logos_wrap .item {
      width: 11.25rem;
      height: 5.625rem;
      position: relative;
      outline: none;
      opacity: 0.5;
      filter: grayscale(1);
      transition: 0.3s;
      cursor: pointer; }
      .vendors .container .logos_wrap .item:hover {
        opacity: 1;
        filter: grayscale(0); }
      .vendors .container .logos_wrap .item img {
        width: 11.25rem;
        height: 5.625rem;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto; }

.bottom {
  /* background: url(../img/bg_hero.jpg) 50% 0% no-repeat;
    background-size: cover; */
  background-color: #00aeef;
  margin-top: 3.75rem; }
  .bottom.bottom-direction {
    padding-top: 3.75rem;
    margin-top: 0; }
  .bottom.bottom-events {
    padding-top: 3.75rem;
    margin-top: 0; }
  .bottom > .container {
    height: 100%;
    height: 55.625rem;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .bottom > .container .video {
      width: 54.0625rem;
      height: 100%;
      overflow: hidden;
      position: relative; }
      .bottom > .container .video video {
        width: 100%; }
      .bottom > .container .video:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 101%;
        width: 25%;
        background: url(../img/video-clip.svg) 0% 50% no-repeat;
        background-size: cover; }
    .bottom > .container .texts {
      width: 54.375rem; }
      .bottom > .container .texts .logo-slogan {
        width: 50rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; }
        .bottom > .container .texts .logo-slogan p {
          margin: 0;
          font-size: 3rem; }
        .bottom > .container .texts .logo-slogan img {
          width: 18.3125rem; }
      .bottom > .container .texts .counters {
        width: 52.5rem;
        margin-top: 8.125rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; }
        .bottom > .container .texts .counters p {
          margin: 0;
          transition: 0.2s; }
          .bottom > .container .texts .counters p:first-child {
            font-size: 5.75rem;
            font-weight: 700; }
          .bottom > .container .texts .counters p:last-child {
            font-size: 2.875rem; }
  .bottom .footer {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
    .bottom .footer .container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; }
      .bottom .footer .container .left .logo {
        width: 16.25rem; }
        .bottom .footer .container .left .logo img {
          width: 100%;
          position: relative; }
      .bottom .footer .container .left .copyright {
        margin-top: 1.875rem;
        font-size: 1.0625rem; }
      .bottom .footer .container .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .bottom .footer .container .right .feed p {
          font-size: 1.5rem; }
        .bottom .footer .container .right .feed form {
          display: flex;
          flex-direction: row;
          align-items: center; }
          .bottom .footer .container .right .feed form input {
            font-size: 1.5rem;
            background-color: transparent;
            box-shadow: none;
            border: 1px solid #fff;
            color: #fff;
            padding: 0.625rem;
            box-sizing: border-box;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            box-shadow: none;
            border-radius: 0; }
          .bottom .footer .container .right .feed form input::placeholder {
            color: #fff; }
          .bottom .footer .container .right .feed form input::-webkit-input-placeholder {
            color: #fff; }
          .bottom .footer .container .right .feed form input::-moz-placeholder {
            color: #fff; }
          .bottom .footer .container .right .feed form input:-moz-placeholder {
            color: #fff; }
          .bottom .footer .container .right .feed form input:-ms-input-placeholder {
            color: #fff; }
          .bottom .footer .container .right .feed form input[type="email"] {
            margin-left: 2.1875rem;
            border-right: none;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            box-shadow: none;
            border-radius: 0; }
          .bottom .footer .container .right .feed form input[type="submit"] {
            cursor: pointer;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            box-shadow: none;
            border-radius: 0; }
        .bottom .footer .container .right .links {
          width: 28.125rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .bottom .footer .container .right .links a {
            color: #fff;
            font-size: 1.5rem;
            text-decoration: none; }

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 4rem;
  height: 100vh;
  background-color: #00aeef;
  border-right: 1px solid #fff;
  transition: width 0.3s, transform 0.5s;
  z-index: 10000;
  cursor: pointer; }
  .nav .top {
    height: 15.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #fff;
    text-decoration: none;
    transition: 0.3s; }
    .nav .top .text {
      transform: rotate(-90deg);
      font-family: 'PT Sans Caption', sans-serif;
      font-size: 1.625rem;
      color: #fff;
      transition: 0.3s; }
    .nav .top:hover {
      background-color: #fff; }
      .nav .top:hover .text {
        color: #00aeef; }
  .nav .bot {
    height: calc(100vh - 250px);
    display: flex;
    justify-content: center;
    align-items: center; }
    .nav .bot .icon {
      width: 1.6875rem;
      height: 1.375rem;
      position: relative; }
      .nav .bot .icon .line {
        width: 1.6875rem;
        height: 0.125rem;
        background-color: #fff;
        border-radius: 0.125rem;
        position: absolute;
        transition: 0.25s; }
        .nav .bot .icon .line:first-child {
          top: 0;
          left: 0;
          right: 0;
          margin: auto; }
        .nav .bot .icon .line:nth-child(2) {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto; }
        .nav .bot .icon .line:last-child {
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto; }
  .nav.nav-cisco-landing .bot {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; }
    .nav.nav-cisco-landing .bot .icon {
      width: 1.6875rem;
      height: 1.375rem;
      position: relative; }
  .nav:hover {
    width: 4.75rem; }
    .nav:hover .bot .icon .line:first-child {
      transform: translateY(-0.3125rem); }
    .nav:hover .bot .icon .line:last-child {
      transform: translateY(0.3125rem); }
  .nav.active {
    transform: translateX(49.375rem); }

.nav-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 49.375rem;
  height: 100vh;
  background-color: #00aeef;
  border-right: 1px solid #fff;
  padding-top: 5rem;
  padding-left: 10.3125rem;
  padding-right: 3.125rem;
  box-sizing: border-box;
  transform: translateX(-49.375rem);
  z-index: 10000;
  transition: 0.5s; }
  .nav-menu.active {
    transform: translateX(0); }
  .nav-menu .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .nav-menu .top .logo {
      width: 9.3125rem; }
    .nav-menu .top .info {
      text-align: right;
      font-family: 'PT Sans Caption', sans-serif; }
      .nav-menu .top .info .phone {
        font-size: 1.75rem; }
      .nav-menu .top .info .address {
        margin-top: 0.625rem;
        font-size: 0.96875rem; }
  .nav-menu .list {
    margin-top: 3.75rem;
    margin-top: 5.1875rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start; }
    .nav-menu .list a {
      font-family: 'PT Sans Caption', sans-serif;
      font-size: 1.75rem;
      color: #fff;
      text-decoration: none;
      transition: 0.3s;
      margin: 0.34375rem 0; }
      .nav-menu .list a:hover {
        color: #ffdc00; }
    .nav-menu .list .item span {
      display: inline-block;
      font-size: 1.25rem;
      transition: 0.2s; }
    .nav-menu .list .item.active span {
      transform: rotate(-90deg); }
    .nav-menu .list .item.subitem {
      font-size: 1.5rem;
      line-height: 2.125rem;
      position: relative;
      margin: 0.1875rem 0; }
      .nav-menu .list .item.subitem:before {
        content: '';
        position: absolute;
        top: 0;
        left: -1.5625rem;
        bottom: 0;
        margin: auto;
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
        background-color: #fff; }
    .nav-menu .list .submenu {
      width: 100%;
      padding-left: 1.875rem;
      box-sizing: border-box;
      position: relative;
      display: none; }
      .nav-menu .list .submenu a {
        display: block; }
      .nav-menu .list .submenu.submenu1:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0.5625rem;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: calc(100% - 1.5rem - .35rem);
        border-radius: 50%;
        background-color: #fff; }
  .nav-menu.nav-menu-cisco-landing .list .item.subitem {
    /* font-size: rem(20px);
                    line-height: rem(30px); */
    font-size: 2.1vh;
    line-height: 3.2vh; }
  .nav-menu .search {
    position: absolute;
    bottom: 5.625rem;
    width: 77%; }
    .nav-menu .search .search-wrap {
      position: relative; }
      .nav-menu .search .search-wrap input {
        width: 0.5%;
        height: 1.875rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        box-shadow: none;
        border-radius: 0;
        padding-left: 0.0625rem;
        box-sizing: border-box;
        color: #000;
        font-size: 1rem;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: none;
        border-radius: 0;
        transition: 0.3s; }
        .nav-menu .search .search-wrap input.active {
          width: 90%;
          padding-left: 3.125rem; }
      .nav-menu .search .search-wrap .icon {
        width: 1.625rem;
        height: 1.625rem;
        position: absolute;
        top: 0;
        left: 2%;
        bottom: 0;
        margin: auto;
        filter: grayscale(1) brightness(0) invert(1);
        transition: 0.3s;
        cursor: pointer; }
        .nav-menu .search .search-wrap .icon.active {
          filter: none; }
        .nav-menu .search .search-wrap .icon svg {
          width: 100%;
          object-fit: contain; }

.close-menu-btn {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 9999; }
  .close-menu-btn.active {
    visibility: visible;
    opacity: 0.7; }

.hero-direction {
  background: url(../img/bg_hero.jpg) 50% 0% no-repeat;
  background-size: cover;
  height: 100vh;
  padding-top: 6.25rem;
  box-sizing: border-box; }
  .hero-direction .container {
    height: 100%; }
    .hero-direction .container .video {
      width: 100%;
      height: calc(100% + 6.25rem);
      position: absolute;
      top: -6.25rem;
      left: 0;
      right: 0;
      margin: auto;
      overflow: hidden; }
      .hero-direction .container .video video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center; }
      .hero-direction .container .video .interactive_menu {
        width: 80%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 100; }
        .hero-direction .container .video .interactive_menu .circle1 {
          background: url(../img/directions_hero/circle1.svg) 50% 50% no-repeat;
          background-size: contain;
          width: 28%;
          height: 46%;
          position: absolute;
          top: -7%;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto; }
        .hero-direction .container .video .interactive_menu .circle2 {
          background: url(../img/directions_hero/circle2.svg) 50% 50% no-repeat;
          background-size: contain;
          width: 45%;
          height: 72%;
          position: absolute;
          top: -7%;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          animation-name: circle;
          animation-iteration-count: infinite;
          animation-duration: 50s;
          animation-timing-function: linear; }
          .hero-direction .container .video .interactive_menu .circle2.pause {
            animation-play-state: paused; }
          .hero-direction .container .video .interactive_menu .circle2 .icon {
            display: block;
            text-decoration: none;
            color: #fff;
            width: 20.2%;
            height: 20.4%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            cursor: pointer;
            transition: 0.3s; }
            .hero-direction .container .video .interactive_menu .circle2 .icon:after {
              white-space: pre;
              position: absolute;
              top: 0;
              left: 110%;
              bottom: 0;
              margin: auto;
              text-transform: uppercase;
              font-size: 1.75rem;
              transition: opacity 0.5s; }
            .hero-direction .container .video .interactive_menu .circle2 .icon.icon1 {
              background: url(../img/directions_hero/icon1.svg) 50% 50% no-repeat;
              background-size: contain;
              transform: scale(0.75) rotate(0deg);
              left: -3.75rem;
              right: initial;
              animation-name: icon1;
              animation-iteration-count: infinite;
              animation-duration: 50s;
              animation-timing-function: linear; }
              .hero-direction .container .video .interactive_menu .circle2 .icon.icon1:after {
                content: 'Инфраструктура';
                height: 20%;
                width: 165%;
                animation-name: after1;
                animation-iteration-count: infinite;
                animation-duration: 50s;
                animation-timing-function: linear; }
              .hero-direction .container .video .interactive_menu .circle2 .icon.icon1.pause {
                animation-play-state: paused; }
                .hero-direction .container .video .interactive_menu .circle2 .icon.icon1.pause:after {
                  opacity: 0;
                  animation-play-state: paused; }
              .hero-direction .container .video .interactive_menu .circle2 .icon.icon1.active:after {
                opacity: 1 !important;
                animation-play-state: paused; }

@keyframes after1 {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  65% {
    opacity: 1; }
  85% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes icon1 {
  0% {
    transform: scale(0.75) rotate(0deg); }
  25% {
    transform: scale(0.5) rotate(-90deg); }
  50% {
    transform: scale(0.75) rotate(-180deg); }
  75% {
    transform: scale(2) rotate(-270deg); }
  100% {
    transform: scale(0.75) rotate(-360deg); } }
            .hero-direction .container .video .interactive_menu .circle2 .icon.icon2 {
              background: url(../img/directions_hero/icon2.svg) 50% 50% no-repeat;
              background-size: contain;
              transform: scale(0.5) rotate(0deg);
              top: -3.75rem;
              bottom: initial;
              animation-name: icon2;
              animation-iteration-count: infinite;
              animation-duration: 50s;
              animation-timing-function: linear; }
              .hero-direction .container .video .interactive_menu .circle2 .icon.icon2:after {
                content: 'АВТОМАТИЧЕСКАЯ \0a ИДЕНТИФИКАЦИЯ \0a И СБОР ДАННЫХ';
                height: 70%;
                width: 165%;
                animation-name: after2;
                animation-iteration-count: infinite;
                animation-duration: 50s;
                animation-timing-function: linear; }
              .hero-direction .container .video .interactive_menu .circle2 .icon.icon2.pause {
                animation-play-state: paused; }
                .hero-direction .container .video .interactive_menu .circle2 .icon.icon2.pause:after {
                  opacity: 0;
                  animation-play-state: paused; }
              .hero-direction .container .video .interactive_menu .circle2 .icon.icon2.active:after {
                opacity: 1 !important;
                animation-play-state: paused; }

@keyframes after2 {
  0% {
    opacity: 0; }
  25% {
    opacity: 0; }
  40% {
    opacity: 1; }
  60% {
    opacity: 1; }
  75% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes icon2 {
  0% {
    transform: scale(0.5) rotate(0deg); }
  25% {
    transform: scale(0.75) rotate(-90deg); }
  50% {
    transform: scale(2) rotate(-180deg); }
  75% {
    transform: scale(0.75) rotate(-270deg); }
  100% {
    transform: scale(0.5) rotate(-360deg); } }
            .hero-direction .container .video .interactive_menu .circle2 .icon.icon3 {
              background: url(../img/directions_hero/icon3.svg) 50% 50% no-repeat;
              background-size: contain;
              transform: scale(0.5) rotate(0deg);
              right: -3.75rem;
              left: initial;
              animation-name: icon3;
              animation-iteration-count: infinite;
              animation-duration: 50s;
              animation-timing-function: linear; }
              .hero-direction .container .video .interactive_menu .circle2 .icon.icon3:after {
                content: 'ИНФОРМАЦИОННАЯ \0a БЕЗОПАСНОСТЬ';
                height: 45%;
                width: 185%;
                animation-name: after3;
                animation-iteration-count: infinite;
                animation-duration: 50s;
                animation-timing-function: linear; }
              .hero-direction .container .video .interactive_menu .circle2 .icon.icon3.pause {
                animation-play-state: paused; }
                .hero-direction .container .video .interactive_menu .circle2 .icon.icon3.pause:after {
                  opacity: 0;
                  animation-play-state: paused; }
              .hero-direction .container .video .interactive_menu .circle2 .icon.icon3.active:after {
                opacity: 1 !important;
                animation-play-state: paused; }

@keyframes after3 {
  0% {
    opacity: 0; }
  15% {
    opacity: 1; }
  35% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes icon3 {
  0% {
    transform: scale(0.75) rotate(0deg); }
  25% {
    transform: scale(2) rotate(-90deg); }
  50% {
    transform: scale(0.75) rotate(-180deg); }
  75% {
    transform: scale(0.5) rotate(-270deg); }
  100% {
    transform: scale(0.75) rotate(-360deg); } }
            .hero-direction .container .video .interactive_menu .circle2 .icon.icon4 {
              background: url(../img/directions_hero/icon4.svg) 50% 50% no-repeat;
              background-size: contain;
              transform: scale(0.5) rotate(0deg);
              bottom: -3.75rem;
              top: initial;
              animation-name: icon4;
              animation-iteration-count: infinite;
              animation-duration: 50s;
              animation-timing-function: linear; }
              .hero-direction .container .video .interactive_menu .circle2 .icon.icon4:after {
                content: 'СЕТИ И ПЕРЕДАЧА \0a ДАННЫХ';
                height: 45%;
                width: 155%;
                animation-name: after4;
                animation-iteration-count: infinite;
                animation-duration: 50s;
                animation-timing-function: linear; }
              .hero-direction .container .video .interactive_menu .circle2 .icon.icon4.pause {
                animation-play-state: paused; }
                .hero-direction .container .video .interactive_menu .circle2 .icon.icon4.pause:after {
                  opacity: 0;
                  animation-play-state: paused; }
              .hero-direction .container .video .interactive_menu .circle2 .icon.icon4.active:after {
                opacity: 1 !important;
                animation-play-state: paused; }

@keyframes after4 {
  0% {
    opacity: 1; }
  10% {
    opacity: 1; }
  25% {
    opacity: 0; }
  75% {
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes icon4 {
  0% {
    transform: scale(2) rotate(0deg); }
  25% {
    transform: scale(0.75) rotate(-90deg); }
  50% {
    transform: scale(0.5) rotate(-180deg); }
  75% {
    transform: scale(0.75) rotate(-270deg); }
  100% {
    transform: scale(2) rotate(-360deg); } }
        .hero-direction .container .video .interactive_menu .circle3 {
          background: url(../img/directions_hero/circle3.svg) 50% 50% no-repeat;
          background-size: contain;
          width: 79%;
          height: 121%;
          position: absolute;
          top: -7%;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          animation-name: circle;
          animation-iteration-count: infinite;
          animation-duration: 40s;
          animation-timing-function: linear; }

@keyframes circle {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
    .hero-direction .container .mob-version {
      display: none; }
    .hero-direction .container .texts {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 20vh;
      position: relative; }
      .hero-direction .container .texts .logo {
        width: 12.5rem; }
      .hero-direction .container .texts .title {
        font-family: 'PT Sans Caption', sans-serif;
        font-size: 3rem; }

.directions {
  background-color: #00aeef;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh; }
  .directions .left {
    width: 49.6875rem;
    position: relative;
    box-shadow: 0.9375rem 0rem 0.5rem -0.4375rem #009cd7;
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
    .directions .left .line {
      background-color: #fff;
      width: 1px;
      height: calc(100% - 11.125rem);
      position: absolute;
      top: 4.6875rem;
      right: 3.125rem;
      margin: auto; }
    .directions .left .item {
      text-align: right;
      margin-right: 6.25rem;
      margin-bottom: 1.875rem;
      position: relative;
      cursor: pointer;
      transition: 0.5s; }
      .directions .left .item .title {
        font-family: 'PT Sans Caption', sans-serif;
        font-size: 2.0625rem;
        color: #fff;
        transition: 0.5s, color 0.2s; }
        .directions .left .item .title:hover {
          color: #ffe000; }
      .directions .left .item .desc {
        font-family: 'PT Sans Caption', sans-serif;
        font-size: 1.0625rem;
        margin-top: 1.875rem;
        opacity: 0;
        display: none;
        transition: opacity 0.5s; }
      .directions .left .item .btn {
        font-family: 'Open Sans', sans-serif;
        color: #fff;
        background-color: #00aeef;
        text-decoration: none;
        font-size: 1.5625rem;
        border: 2px solid #fff;
        box-sizing: border-box;
        border-radius: 2.1875rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 18.4375rem;
        height: 5rem;
        outline: none;
        margin-top: 1.875rem;
        opacity: 0;
        display: none;
        transition: opacity 0.5s, 0.25s; }
        .directions .left .item .btn:hover {
          color: #00aeef;
          background-color: #fff; }
      .directions .left .item .point {
        position: absolute;
        top: 0.625rem;
        right: -3.625rem;
        width: 1.0625rem;
        height: 1.0625rem;
        border-radius: 50%;
        background-color: #fff; }
      .directions .left .item.active {
        margin-bottom: 9.375rem;
        margin-top: 9.375rem; }
        .directions .left .item.active .title {
          text-transform: uppercase;
          font-size: 3rem;
          font-weight: 700;
          color: #fff; }
          .directions .left .item.active .title:hover {
            color: #fff; }
        .directions .left .item.active .desc {
          opacity: 1; }
        .directions .left .item.active .btn {
          opacity: 1;
          display: inline-flex; }
        .directions .left .item.active:after {
          top: 4.6875rem; }
      .directions .left .item.item1.active {
        margin-bottom: 7.1875rem;
        margin-top: 7.1875rem; }
  .directions .right {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    width: calc(100% - 49.0625rem);
    position: relative; }
    .directions .right .line1 {
      width: 0;
      height: 1px;
      position: absolute;
      /* top: rem(505px); */
      top: 42vh;
      left: 0;
      background-color: #fff;
      transition: width 0.25s; }
      .directions .right .line1.active {
        width: 3.75rem; }
    .directions .right .line2 {
      width: 0.0625rem;
      height: 24.25rem;
      position: absolute;
      bottom: 46.5rem;
      left: 3.75rem;
      background-color: #fff;
      transition: height 0.25s; }
    .directions .right .line3 {
      width: 0;
      height: 1px;
      position: absolute;
      top: 7.375rem;
      left: 3.75rem;
      background-color: #fff;
      transition: width 0.25s; }
      .directions .right .line3.active {
        width: 3.75rem; }
    .directions .right .list {
      width: calc(100% - 9.375rem);
      height: calc(100% - 3.125rem);
      position: absolute;
      top: 3.125rem;
      left: 9.375rem;
      right: 0;
      margin: auto;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.5s; }
      .directions .right .list .item {
        margin-bottom: 1.875rem;
        position: relative;
        color: #fff;
        transition: 0.2s;
        cursor: default; }
        .directions .right .list .item:hover {
          color: #ffe000; }
        .directions .right .list .item .title {
          font-size: 1.875rem;
          font-weight: 700; }
        .directions .right .list .item .desc {
          font-size: 1.375rem; }
        .directions .right .list .item .point {
          position: absolute;
          top: 0.9375rem;
          left: -1.875rem;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: #fff; }
      .directions .right .list .vendor-logos {
        background-color: #fff;
        margin-left: -9.375rem;
        padding: 1.25rem 4.375rem 1.25rem 6.25rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        left: -9.375rem;
        bottom: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        transition: 0.5s; }
        .directions .right .list .vendor-logos .slide {
          width: 10.625rem;
          height: 10.625rem;
          padding-left: 0.9375rem;
          padding-right: 0.9375rem;
          box-sizing: border-box;
          outline: none; }
          .directions .right .list .vendor-logos .slide img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: 50% 50%; }
      .directions .right .list.active {
        visibility: visible;
        opacity: 1; }
        .directions .right .list.active .vendor-logos {
          opacity: 1; }

.directions-mob {
  display: none; }

.events-main {
  position: relative; }
  .events-main .left {
    width: 45%;
    height: 100vh;
    background-color: #00AEEF;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0.4375rem 0.4375rem 0.3125rem rgba(0, 0, 0, 0.1);
    z-index: 10; }
    .events-main .left .logo {
      width: 10.8125rem;
      height: 8.9375rem;
      position: absolute;
      top: 3.4375rem;
      right: 5rem; }
    .events-main .left .texts {
      position: absolute;
      top: 0;
      left: 10.625rem;
      bottom: 0;
      margin: auto;
      height: 21.25rem; }
      .events-main .left .texts .title {
        font-weight: 700;
        font-size: 3rem; }
      .events-main .left .texts .search {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem; }
        .events-main .left .texts .search input {
          width: 31.25rem;
          height: 3.625rem;
          font-size: 1.5rem;
          box-sizing: border-box;
          padding-left: 1.25rem;
          border-radius: 0.625rem;
          border: none;
          box-shadow: none;
          appearance: none; }
      .events-main .left .texts .list {
        margin-top: 1.875rem;
        position: relative; }
        .events-main .left .texts .list .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 2.5rem;
          cursor: pointer; }
          .events-main .left .texts .list .item .dot {
            width: 1rem;
            height: 1rem;
            background-color: #fff;
            border-radius: 50%;
            position: relative; }
            .events-main .left .texts .list .item .dot:before {
              content: '';
              width: 0.375rem;
              height: 0.375rem;
              background-color: #00aeef;
              border-radius: 50%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              opacity: 0;
              transition: 0.3s; }
          .events-main .left .texts .list .item.active .dot:before {
            opacity: 1; }
          .events-main .left .texts .list .item .text {
            font-size: 1.5rem;
            margin-left: 0.9375rem; }
        .events-main .left .texts .list:before {
          content: '';
          width: 0.0625rem;
          height: 9rem;
          background-color: #fff;
          position: absolute;
          top: 0.6875rem;
          left: 0.4375rem; }
  .events-main .right {
    width: 55%;
    margin-top: -100vh;
    margin-left: auto;
    font-size: 2.5rem;
    color: #000;
    position: relative; }
    .events-main .right .preloader-local {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      z-index: 100000;
      background-color: #fff;
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s, visibility 0.3s 0s; }
      .events-main .right .preloader-local.hide {
        opacity: 0;
        visibility: hidden; }
      .events-main .right .preloader-local .load {
        background: url(../img/logo_blue.svg) 50% 50% no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 12.5rem;
        height: 12.5rem;
        animation-name: loader;
        animation-duration: 0.8s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite; }

@keyframes loader {
  0% {
    transform: scale(1, 1); }
  50% {
    transform: scale(-1, 1); }
  100% {
    transform: scale(1, 1); } }
    .events-main .right .event-list {
      width: 46.25rem;
      margin-left: 8.125rem;
      padding-left: 4.375rem;
      box-sizing: border-box;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      display: none; }
      .events-main .right .event-list.active {
        display: block; }
      .events-main .right .event-list .line {
        position: absolute;
        top: 7.625rem;
        left: 8.5625rem;
        width: 1px;
        height: auto;
        background-color: #b6b7b7;
        transition: height 1s; }
      .events-main .right .event-list .event-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1.875rem;
        position: relative;
        cursor: pointer; }
        .events-main .right .event-list .event-item .pic {
          width: 16.25rem;
          height: 13.75rem;
          filter: none;
          transition: 0.25s; }
          .events-main .right .event-list .event-item .pic img {
            width: 16.25rem;
            height: 13.75rem;
            object-fit: cover; }
        .events-main .right .event-list .event-item .texts {
          margin-left: 3.125rem; }
          .events-main .right .event-list .event-item .texts .category {
            font-size: 0.9375rem;
            color: #b7b7b7; }
          .events-main .right .event-list .event-item .texts .title {
            font-size: 1.875rem;
            color: #00aeef;
            font-weight: 700;
            margin-top: 1.25rem; }
          .events-main .right .event-list .event-item .texts .desc {
            font-size: 1rem;
            color: #000;
            margin-top: 0.9375rem; }
          .events-main .right .event-list .event-item .texts .date {
            font-size: 1.125rem;
            color: #b7b7b7;
            margin-top: 1.25rem; }
        .events-main .right .event-list .event-item .dot {
          position: absolute;
          top: 0;
          left: -4.375rem;
          bottom: 0;
          margin: auto;
          width: 1rem;
          height: 1rem;
          background-color: #b6b7b7;
          border-radius: 50%; }
        .events-main .right .event-list .event-item:after {
          content: '';
          position: absolute;
          top: 0;
          left: -4.375rem;
          bottom: 0;
          margin: auto;
          width: 4.375rem;
          height: 0.0625rem;
          background-color: #b6b7b7; }
        .events-main .right .event-list .event-item:hover .pic {
          filter: contrast(0.3) brightness(1.5); }
    .events-main .right .calendar-wrap {
      display: none;
      width: 100%;
      height: 100vh;
      position: relative;
      overflow: hidden; }
      .events-main .right .calendar-wrap.active {
        display: block; }
      .events-main .right .calendar-wrap .shadow {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        background-color: rgba(124, 124, 124, 0.37);
        display: none; }
      .events-main .right .calendar-wrap .calendar {
        width: 52.25rem;
        height: 50.3125rem;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto; }
        .events-main .right .calendar-wrap .calendar .calendar-nav {
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          width: 100%;
          height: 2.1875rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          color: #00aeef; }
          .events-main .right .calendar-wrap .calendar .calendar-nav .calendar-nav-btn {
            cursor: pointer;
            transition: 0.2s; }
            .events-main .right .calendar-wrap .calendar .calendar-nav .calendar-nav-btn:hover {
              color: #b7b7b7; }
            .events-main .right .calendar-wrap .calendar .calendar-nav .calendar-nav-btn.disabled {
              color: #b7b7b7;
              pointer-events: none;
              cursor: default; }
        .events-main .right .calendar-wrap .calendar .daynames {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          font-size: 1.625rem;
          color: #808285;
          width: 99%; }
        .events-main .right .calendar-wrap .calendar .days {
          margin-top: 0.9375rem;
          position: absolute;
          transition: opacity 0.3s, visibility 0.3s 0s; }
          .events-main .right .calendar-wrap .calendar .days .day {
            width: 7.375rem;
            height: 7.375rem;
            box-sizing: border-box;
            position: relative; }
            .events-main .right .calendar-wrap .calendar .days .day.active {
              border-color: #231f20; }
            .events-main .right .calendar-wrap .calendar .days .day span {
              color: #000;
              font-size: 1.5rem;
              position: absolute;
              top: 0.625rem;
              right: 0.625rem; }
            .events-main .right .calendar-wrap .calendar .days .day .list {
              position: absolute;
              right: -24.375rem;
              top: 0;
              width: 23.75rem;
              color: #00aeef;
              border-radius: 0.9375rem;
              padding: 0.9375rem 0;
              box-sizing: border-box;
              background-color: #fff;
              box-shadow: 0.4375rem 0.4375rem 0.25rem rgba(0, 0, 0, 0.15);
              z-index: 1;
              opacity: 0;
              visibility: hidden;
              transition: opacity 0.3s, visibility 0s; }
              .events-main .right .calendar-wrap .calendar .days .day .list .item {
                font-size: 1.875rem;
                font-weight: 700;
                padding: 0.9375rem 2.5rem; }
                .events-main .right .calendar-wrap .calendar .days .day .list .item span {
                  color: #b7b7b7;
                  display: block;
                  position: relative;
                  top: 0;
                  right: 0; }
                .events-main .right .calendar-wrap .calendar .days .day .list .item:hover {
                  background-color: #00aeef;
                  color: #fff; }
                  .events-main .right .calendar-wrap .calendar .days .day .list .item:hover span {
                    color: #fff; }
            .events-main .right .calendar-wrap .calendar .days .day:nth-child(7) .list {
              right: 8.125rem; }
            .events-main .right .calendar-wrap .calendar .days .day:nth-child(6) .list {
              right: 8.125rem; }
            .events-main .right .calendar-wrap .calendar .days .day:nth-child(5) .list {
              right: 8.125rem; }
            .events-main .right .calendar-wrap .calendar .days .day.hasEvent {
              background-color: #00aeef;
              cursor: pointer; }
              .events-main .right .calendar-wrap .calendar .days .day.hasEvent span {
                color: #fff; }
              .events-main .right .calendar-wrap .calendar .days .day.hasEvent .list.active {
                opacity: 1;
                visibility: visible; }
        .events-main .right .calendar-wrap .calendar .mounth-name {
          margin-top: 0.9375rem;
          text-transform: uppercase;
          font-size: 1.5625rem;
          color: #b7b7b7;
          text-align: center;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto; }
    .events-main .right .event-single {
      display: none;
      width: 100%;
      position: relative;
      padding: 0 6.25rem 5.625rem;
      box-sizing: border-box; }
      .events-main .right .event-single.active {
        display: block; }
      .events-main .right .event-single .registration_btn {
        position: sticky;
        top: 25.3125rem;
        left: 0;
        margin-left: -18.75rem;
        width: 20rem;
        height: 4.3125rem;
        border-bottom-right-radius: 1.875rem;
        border-bottom-left-radius: 1.875rem;
        background-color: #00aeef;
        color: #fff;
        font-size: 1.6875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;
        will-change: transform;
        transform: rotate(-90deg);
        cursor: pointer;
        transition: 0.6s; }
        .events-main .right .event-single .registration_btn.active {
          margin-left: -14.1875rem; }
      .events-main .right .event-single .logo {
        width: 100%;
        height: 3.75rem;
        position: relative; }
        .events-main .right .event-single .logo img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: left center; }
      .events-main .right .event-single .pic {
        margin-top: 1.5625rem;
        width: 100%;
        height: 25rem; }
        .events-main .right .event-single .pic img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: left center; }
      .events-main .right .event-single .meta {
        width: 100%;
        height: auto;
        margin-top: 2.5rem;
        padding-bottom: 2.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #b6b7b7; }
        .events-main .right .event-single .meta .date_wrap {
          width: 8.125rem;
          height: 9.6875rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          text-align: center; }
          .events-main .right .event-single .meta .date_wrap .icon {
            width: 4.9375rem;
            height: 5.5rem; }
          .events-main .right .event-single .meta .date_wrap .date {
            color: #b7b7b7;
            font-size: 1.3125rem;
            line-height: 1.5625rem; }
        .events-main .right .event-single .meta .text_wrap {
          margin-left: 0.9375rem; }
          .events-main .right .event-single .meta .text_wrap .category {
            font-size: 1rem;
            color: #b7b7b7;
            line-height: 3rem; }
          .events-main .right .event-single .meta .text_wrap .title {
            font-size: 3.0625rem;
            color: #00aeef;
            line-height: 3.625rem;
            font-weight: 700; }
      .events-main .right .event-single .event-single-text {
        margin-top: 1.875rem;
        font-size: 1.25rem;
        line-height: 1.5rem;
        padding-bottom: 1.875rem;
        border-bottom: 1px solid #b6b7b7; }
      .events-main .right .event-single .footer {
        margin-top: 1.875rem;
        margin-left: 0.625rem; }
        .events-main .right .event-single .footer .social {
          color: #9bb3be;
          font-size: 1rem;
          display: flex;
          flex-direction: row;
          align-items: center; }
          .events-main .right .event-single .footer .social span {
            margin-right: 0.625rem; }
          .events-main .right .event-single .footer .social .icon {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background-color: #00aeef;
            margin-right: 0.3125rem;
            padding: 0.0625rem;
            box-sizing: border-box;
            transition: 0.3s; }
            .events-main .right .event-single .footer .social .icon:hover {
              background-color: #009edf; }
    .events-main .right .registration-wrap {
      display: none;
      width: 100%;
      position: relative; }
      .events-main .right .registration-wrap.active {
        display: block; }
      .events-main .right .registration-wrap .registration-container {
        padding: 6.25rem 6.25rem 5.625rem 6.25rem;
        box-sizing: border-box; }
        .events-main .right .registration-wrap .registration-container .meta {
          width: 100%;
          height: auto;
          margin-top: 2.5rem;
          padding-bottom: 2.5rem;
          display: flex;
          flex-direction: row;
          align-items: center; }
          .events-main .right .registration-wrap .registration-container .meta .date_wrap {
            width: 8.125rem;
            height: 9.6875rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            text-align: center; }
            .events-main .right .registration-wrap .registration-container .meta .date_wrap .icon {
              width: 4.9375rem;
              height: 5.5rem; }
            .events-main .right .registration-wrap .registration-container .meta .date_wrap .date {
              color: #b7b7b7;
              font-size: 1.3125rem;
              line-height: 1.5625rem; }
          .events-main .right .registration-wrap .registration-container .meta .text_wrap {
            margin-left: 0.9375rem; }
            .events-main .right .registration-wrap .registration-container .meta .text_wrap .category {
              font-size: 1rem;
              color: #b7b7b7;
              line-height: 3rem; }
            .events-main .right .registration-wrap .registration-container .meta .text_wrap .title {
              font-size: 3.0625rem;
              color: #00aeef;
              line-height: 3.625rem;
              font-weight: 700; }
        .events-main .right .registration-wrap .registration-container .registration-form form .block {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 2.5rem; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block .col {
            width: 100%; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block .col-2 {
            width: 48%; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block .col-3 {
            width: 30%; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block .col-4 {
            width: 24%; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block .table table tr {
            font-size: 1.875rem; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block .table table tr.caption {
              font-size: 1.25rem; }
              .events-main .right .registration-wrap .registration-container .registration-form form .block .table table tr.caption td {
                padding-bottom: 0.9375rem; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block .table table tr td {
              text-align: center;
              width: 10.625rem; }
              .events-main .right .registration-wrap .registration-container .registration-form form .block .table table tr td label {
                justify-content: center; }
                .events-main .right .registration-wrap .registration-container .registration-form form .block .table table tr td label span:before {
                  margin-right: 0; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block .table table tr td:first-child {
              text-align: left;
              width: 14.375rem; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block h4 {
            font-family: 'PT Sans',sans-serif;
            font-size: 2.5rem;
            margin: 0;
            margin-top: 1.5625rem;
            margin-bottom: 1.5625rem;
            font-weight: normal;
            padding-left: 0; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block label {
            font-size: 1.75rem;
            margin-top: 0.625rem;
            display: block; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block label > span {
              display: block;
              margin-bottom: 0.625rem; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block label p {
              margin: 0; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block label.checkbox {
              font-size: 1.875rem;
              display: flex;
              flex-direction: row;
              align-items: center; }
              .events-main .right .registration-wrap .registration-container .registration-form form .block label.checkbox span {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center; }
                .events-main .right .registration-wrap .registration-container .registration-form form .block label.checkbox span:before {
                  content: '';
                  width: 3.6875rem;
                  height: 3.6875rem;
                  border: 1px solid #b6b7b7;
                  box-sizing: border-box;
                  margin-right: 1.25rem;
                  border-radius: 0.9375rem; }
                .events-main .right .registration-wrap .registration-container .registration-form form .block label.checkbox span:after {
                  content: '';
                  width: 3.6875rem;
                  height: 3.6875rem;
                  background: url(../img/check.svg) 50% 50% no-repeat;
                  background-size: 50%;
                  background-color: #00aeef;
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  margin: auto;
                  border-radius: 0.9375rem;
                  opacity: 0; }
              .events-main .right .registration-wrap .registration-container .registration-form form .block label.checkbox input[type='checkbox']:checked + span:after {
                opacity: 1; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block label.radio {
              font-size: 1.875rem;
              display: flex;
              flex-direction: row; }
              .events-main .right .registration-wrap .registration-container .registration-form form .block label.radio span {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center; }
                .events-main .right .registration-wrap .registration-container .registration-form form .block label.radio span:before {
                  content: '';
                  width: 3.6875rem;
                  height: 3.6875rem;
                  border: 1px solid #b6b7b7;
                  box-sizing: border-box;
                  margin-right: 1.25rem;
                  border-radius: 50%; }
                .events-main .right .registration-wrap .registration-container .registration-form form .block label.radio span:after {
                  content: '';
                  width: 3.6875rem;
                  height: 3.6875rem;
                  background: url(../img/check-radio.svg) 50% 50% no-repeat;
                  background-size: 50%;
                  background-color: #00aeef;
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  margin: auto;
                  border-radius: 50%;
                  opacity: 0; }
              .events-main .right .registration-wrap .registration-container .registration-form form .block label.radio input[type='radio']:checked + span:after {
                opacity: 1; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block label.file {
              background-color: #00aeef;
              color: #fff;
              font-size: 1.875rem;
              width: 25.4375rem;
              height: 3.75rem;
              border-radius: 0.9375rem;
              display: flex;
              justify-content: center;
              align-items: center; }
              .events-main .right .registration-wrap .registration-container .registration-form form .block label.file span {
                position: relative;
                display: block;
                width: 100%;
                text-align: center; }
                .events-main .right .registration-wrap .registration-container .registration-form form .block label.file span img {
                  width: 2.5rem;
                  height: 2.3125rem;
                  position: absolute;
                  top: 0;
                  left: 0.9375rem;
                  bottom: 0;
                  margin: auto; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block .filename {
            font-size: 1.5rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #8b8b8b; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block .filename:before {
              content: 'Выбранный файл: '; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block input {
            appearance: none;
            width: 100%;
            border: 1px solid #b6b7b7;
            font-size: 1.5rem;
            color: #000;
            padding: 0.9375rem 1.5625rem;
            box-sizing: border-box;
            border-radius: 0.9375rem; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block input[type='checkbox'] {
              visibility: hidden;
              width: 0;
              height: 0;
              padding: 0; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block input[type='radio'] {
              visibility: hidden;
              width: 0;
              height: 0;
              padding: 0; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block input[type='file'] {
              visibility: hidden;
              width: 0;
              height: 0;
              padding: 0; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block input[type='checkbox'] + span + [type="text"] {
              width: 77%;
              height: 3.6875rem; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block input[type='submit'] {
              background-color: #00aeef;
              width: 19.0625rem;
              height: 3.6875rem;
              color: #fff;
              font-size: 2.25rem;
              border: none;
              box-shadow: none;
              text-transform: none;
              padding: 0;
              cursor: pointer; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block select {
            appearance: none;
            width: 100%;
            border: 1px solid #b6b7b7;
            font-size: 1.5rem;
            color: #b6b7b7;
            padding: 0.9375rem 1.5625rem;
            box-sizing: border-box;
            border-radius: 0.9375rem;
            background-image: url(../img/icon_select_arrow.svg);
            background-position: 95% 50%;
            background-size: 0.9375rem;
            background-repeat: no-repeat; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block select option {
              color: #b6b7b7; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block textarea {
            appearance: none;
            width: 100%;
            border: 1px solid #b6b7b7;
            font-size: 1.5rem;
            color: #b6b7b7;
            padding: 0.9375rem 1.5625rem;
            box-sizing: border-box;
            border-radius: 0.9375rem; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block a {
            display: inline-block;
            margin-top: 1.25rem;
            font-weight: 700;
            font-size: 2.25rem;
            color: #00aeef;
            text-decoration: none;
            transition: 0.3s; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block a:hover {
              text-decoration: underline; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block a.hide {
              display: none; }
          .events-main .right .registration-wrap .registration-container .registration-form form .block .rating {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block .rating > span {
              font-size: 1.875rem;
              margin-top: 1.875rem; }
            .events-main .right .registration-wrap .registration-container .registration-form form .block .rating label.radio span {
              flex-direction: column-reverse; }
              .events-main .right .registration-wrap .registration-container .registration-form form .block .rating label.radio span:before {
                margin-right: initial;
                margin-top: 0.625rem; }
              .events-main .right .registration-wrap .registration-container .registration-form form .block .rating label.radio span:after {
                top: initial; }
      .events-main .right .registration-wrap .popup-shadow {
        display: none;
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0; }
        .events-main .right .registration-wrap .popup-shadow.active {
          display: block; }
      .events-main .right .registration-wrap .popup-success {
        display: none;
        background-color: #fff;
        width: 43.75rem;
        height: 15.625rem;
        position: fixed;
        top: 30%;
        left: 50%;
        padding: 4.6875rem 3.125rem;
        box-sizing: border-box;
        border-radius: 1.25rem; }
        .events-main .right .registration-wrap .popup-success span {
          color: #00aeef; }
        .events-main .right .registration-wrap .popup-success.active {
          display: block; }

.promo-main .first-screen {
  flex-direction: row;
  display: none; }
  .promo-main .first-screen.active {
    display: flex; }
  .promo-main .first-screen .left {
    width: 50%;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0.4375rem 0.4375rem 0.3125rem rgba(0, 0, 0, 0.1);
    z-index: 10;
    box-sizing: border-box; }
    .promo-main .first-screen .left .wrap {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 88%;
      padding-left: 9.375rem;
      padding-right: 5rem; }
      .promo-main .first-screen .left .wrap .pagename {
        font-size: 3rem;
        color: #00aeef;
        font-weight: 700; }
      .promo-main .first-screen .left .wrap .search input,
      .promo-main .first-screen .left .wrap .search select {
        appearance: none;
        margin-top: 2.1875rem;
        width: 31.25rem;
        border: 1px solid #b6b7b7;
        font-size: 1.5rem;
        color: #b6b7b7;
        padding: 0.9375rem 1.5625rem;
        box-sizing: border-box;
        border-radius: 0.9375rem; }
      .promo-main .first-screen .left .wrap .search select option {
        color: #b6b7b7; }
      .promo-main .first-screen .left .wrap .promo-select {
        position: relative;
        height: 71vh;
        margin-top: 2.1875rem; }
        .promo-main .first-screen .left .wrap .promo-select .slick-dots {
          position: absolute;
          right: -3.125rem;
          top: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .promo-main .first-screen .left .wrap .promo-select .slick-dots li {
            box-sizing: border-box;
            padding: 0.3125rem 0;
            opacity: 0.4;
            transition: 0.3s; }
            .promo-main .first-screen .left .wrap .promo-select .slick-dots li::marker {
              opacity: 0;
              content: ''; }
            .promo-main .first-screen .left .wrap .promo-select .slick-dots li.slick-active {
              opacity: 1; }
          .promo-main .first-screen .left .wrap .promo-select .slick-dots button {
            color: transparent;
            background-color: #b6b7b7;
            border-radius: 50%;
            box-shadow: none;
            border: none;
            font-size: 1.25rem;
            line-height: initial;
            width: 1.25rem;
            height: 1.25rem;
            cursor: pointer;
            outline: none; }
        .promo-main .first-screen .left .wrap .promo-select .promo-slide {
          outline: none;
          height: 71vh; }
          .promo-main .first-screen .left .wrap .promo-select .promo-slide .pic {
            margin-top: 0.3125rem; }
            .promo-main .first-screen .left .wrap .promo-select .promo-slide .pic img {
              max-height: 18.75rem;
              width: 100%;
              object-fit: cover;
              object-position: 50% 50%; }
          .promo-main .first-screen .left .wrap .promo-select .promo-slide .new-promo {
            font-size: 3rem;
            color: #00aeef;
            font-weight: 700;
            margin-top: 2.5rem; }
          .promo-main .first-screen .left .wrap .promo-select .promo-slide .category {
            font-size: 1.25rem;
            color: #b7b7b7;
            line-height: 3.75rem; }
          .promo-main .first-screen .left .wrap .promo-select .promo-slide .title {
            font-size: 2.125rem;
            color: #00aeef;
            font-weight: 700;
            line-height: 2.625rem; }
          .promo-main .first-screen .left .wrap .promo-select .promo-slide .desc {
            font-size: 1.3125rem;
            color: #000;
            line-height: 1.5625rem;
            margin-top: 0.625rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 3.125rem;
            /* overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;  */ }
          .promo-main .first-screen .left .wrap .promo-select .promo-slide .date {
            font-size: 1.5rem;
            color: #b7b7b7;
            line-height: 3.125rem;
            margin-top: 0.625rem; }
  .promo-main .first-screen .right {
    width: 50%; }
    .promo-main .first-screen .right .promo-list {
      width: 46.25rem;
      margin-left: 8.125rem;
      padding-left: 4.375rem;
      box-sizing: border-box;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      position: relative; }
      .promo-main .first-screen .right .promo-list .line {
        position: absolute;
        top: 7.625rem;
        left: 0.4375rem;
        width: 1px;
        height: auto;
        background-color: #b6b7b7;
        transition: height 1s; }
      .promo-main .first-screen .right .promo-list .promo-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1.875rem;
        position: relative;
        cursor: pointer; }
        .promo-main .first-screen .right .promo-list .promo-item .pic {
          width: 16.25rem;
          height: 13.75rem;
          filter: none;
          transition: 0.25s; }
          .promo-main .first-screen .right .promo-list .promo-item .pic img {
            width: 16.25rem;
            height: 13.75rem;
            object-fit: cover; }
        .promo-main .first-screen .right .promo-list .promo-item .texts {
          margin-left: 3.125rem; }
          .promo-main .first-screen .right .promo-list .promo-item .texts .category {
            font-size: 0.9375rem;
            color: #b7b7b7; }
          .promo-main .first-screen .right .promo-list .promo-item .texts .title {
            font-size: 1.875rem;
            color: #00aeef;
            font-weight: 700;
            margin-top: 1.25rem; }
          .promo-main .first-screen .right .promo-list .promo-item .texts .desc {
            font-size: 1rem;
            color: #000;
            margin-top: 0.9375rem; }
          .promo-main .first-screen .right .promo-list .promo-item .texts .date {
            font-size: 1.125rem;
            color: #b7b7b7;
            margin-top: 1.25rem; }
        .promo-main .first-screen .right .promo-list .promo-item .dot {
          position: absolute;
          top: 0;
          left: -4.375rem;
          bottom: 0;
          margin: auto;
          width: 1rem;
          height: 1rem;
          background-color: #b6b7b7;
          border-radius: 50%; }
        .promo-main .first-screen .right .promo-list .promo-item:after {
          content: '';
          position: absolute;
          top: 0;
          left: -4.375rem;
          bottom: 0;
          margin: auto;
          width: 4.375rem;
          height: 0.0625rem;
          background-color: #b6b7b7; }
        .promo-main .first-screen .right .promo-list .promo-item:hover .pic {
          filter: contrast(0.3) brightness(1.5); }

.promo-main .search-result {
  display: none; }
  .promo-main .search-result.active {
    display: block; }
  .promo-main .search-result .header {
    width: 100%;
    padding-top: 4.375rem;
    padding-left: 9.375rem;
    box-sizing: border-box;
    position: relative; }
    .promo-main .search-result .header .pagename {
      font-size: 3rem;
      color: #00aeef;
      font-weight: 700; }
    .promo-main .search-result .header .search input {
      appearance: none;
      margin-top: 2.1875rem;
      width: 31.25rem;
      border: 1px solid #b6b7b7;
      font-size: 1.5rem;
      color: #b6b7b7;
      padding: 0.9375rem 1.5625rem;
      box-sizing: border-box;
      border-radius: 0.9375rem; }
    .promo-main .search-result .header .logo {
      width: 12.375rem;
      height: 10.25rem;
      position: absolute;
      bottom: 0;
      right: 6.25rem; }
  .promo-main .search-result .main {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .promo-main .search-result .main .left {
      width: 50%; }
      .promo-main .search-result .main .left .promo-list {
        width: 46.25rem;
        margin-left: 9.375rem;
        padding-left: 4.375rem;
        box-sizing: border-box;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        position: relative; }
        .promo-main .search-result .main .left .promo-list .line {
          position: absolute;
          top: 7.625rem;
          left: 0.4375rem;
          width: 1px;
          height: auto;
          background-color: #b6b7b7;
          transition: height 1s; }
        .promo-main .search-result .main .left .promo-list .promo-item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 1.875rem;
          position: relative;
          cursor: pointer; }
          .promo-main .search-result .main .left .promo-list .promo-item .pic {
            width: 16.25rem;
            height: 13.75rem;
            filter: none;
            transition: 0.25s; }
            .promo-main .search-result .main .left .promo-list .promo-item .pic img {
              width: 16.25rem;
              height: 13.75rem;
              object-fit: cover; }
          .promo-main .search-result .main .left .promo-list .promo-item .texts {
            margin-left: 3.125rem; }
            .promo-main .search-result .main .left .promo-list .promo-item .texts .category {
              font-size: 0.9375rem;
              color: #b7b7b7; }
            .promo-main .search-result .main .left .promo-list .promo-item .texts .title {
              font-size: 1.875rem;
              color: #00aeef;
              font-weight: 700;
              margin-top: 1.25rem; }
            .promo-main .search-result .main .left .promo-list .promo-item .texts .desc {
              font-size: 1rem;
              color: #000;
              margin-top: 0.9375rem; }
            .promo-main .search-result .main .left .promo-list .promo-item .texts .date {
              font-size: 1.125rem;
              color: #b7b7b7;
              margin-top: 1.25rem; }
          .promo-main .search-result .main .left .promo-list .promo-item .dot {
            position: absolute;
            top: 0;
            left: -4.375rem;
            bottom: 0;
            margin: auto;
            width: 1rem;
            height: 1rem;
            background-color: #b6b7b7;
            border-radius: 50%; }
          .promo-main .search-result .main .left .promo-list .promo-item:after {
            content: '';
            position: absolute;
            top: 0;
            left: -4.375rem;
            bottom: 0;
            margin: auto;
            width: 4.375rem;
            height: 0.0625rem;
            background-color: #b6b7b7; }
          .promo-main .search-result .main .left .promo-list .promo-item:hover .pic {
            filter: contrast(0.3) brightness(1.5); }
    .promo-main .search-result .main .right {
      width: 50%; }
      .promo-main .search-result .main .right .promo-list {
        width: 46.25rem;
        margin-left: 9.375rem;
        padding-left: 0;
        box-sizing: border-box;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        position: relative; }
        .promo-main .search-result .main .right .promo-list .line {
          position: absolute;
          top: 7.625rem;
          left: -3.9375rem;
          width: 1px;
          height: auto;
          background-color: #b6b7b7;
          transition: height 1s; }
        .promo-main .search-result .main .right .promo-list .promo-item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 1.875rem;
          position: relative;
          cursor: pointer; }
          .promo-main .search-result .main .right .promo-list .promo-item .pic {
            width: 16.25rem;
            height: 13.75rem;
            filter: none;
            transition: 0.25s; }
            .promo-main .search-result .main .right .promo-list .promo-item .pic img {
              width: 16.25rem;
              height: 13.75rem;
              object-fit: cover; }
          .promo-main .search-result .main .right .promo-list .promo-item .texts {
            margin-left: 3.125rem; }
            .promo-main .search-result .main .right .promo-list .promo-item .texts .category {
              font-size: 0.9375rem;
              color: #b7b7b7; }
            .promo-main .search-result .main .right .promo-list .promo-item .texts .title {
              font-size: 1.875rem;
              color: #00aeef;
              font-weight: 700;
              margin-top: 1.25rem; }
            .promo-main .search-result .main .right .promo-list .promo-item .texts .desc {
              font-size: 1rem;
              color: #000;
              margin-top: 0.9375rem; }
            .promo-main .search-result .main .right .promo-list .promo-item .texts .date {
              font-size: 1.125rem;
              color: #b7b7b7;
              margin-top: 1.25rem; }
          .promo-main .search-result .main .right .promo-list .promo-item .dot {
            position: absolute;
            top: 0;
            left: -4.375rem;
            bottom: 0;
            margin: auto;
            width: 1rem;
            height: 1rem;
            background-color: #b6b7b7;
            border-radius: 50%; }
          .promo-main .search-result .main .right .promo-list .promo-item:after {
            content: '';
            position: absolute;
            top: 0;
            left: -4.375rem;
            bottom: 0;
            margin: auto;
            width: 4.375rem;
            height: 0.0625rem;
            background-color: #b6b7b7; }
          .promo-main .search-result .main .right .promo-list .promo-item:hover .pic {
            filter: contrast(0.3) brightness(1.5); }

.promo-main .promo-single {
  display: none; }
  .promo-main .promo-single.active {
    display: flex; }
  .promo-main .promo-single .left {
    width: 63%;
    padding-left: 12.5rem;
    padding-right: 7.1875rem;
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    box-sizing: border-box; }
    .promo-main .promo-single .left .logo {
      width: 100%;
      height: 3.75rem;
      position: relative; }
      .promo-main .promo-single .left .logo img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left center; }
    .promo-main .promo-single .left .pic {
      margin-top: 1.5625rem;
      width: 100%;
      height: 25rem; }
      .promo-main .promo-single .left .pic img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left center; }
    .promo-main .promo-single .left .meta {
      width: 100%;
      height: auto;
      margin-top: 2.5rem;
      padding-bottom: 2.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #b6b7b7; }
      .promo-main .promo-single .left .meta .date_wrap {
        width: 8.125rem;
        height: 9.6875rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center; }
        .promo-main .promo-single .left .meta .date_wrap .icon {
          width: 4.9375rem;
          height: 5.5rem; }
        .promo-main .promo-single .left .meta .date_wrap .date {
          color: #b7b7b7;
          font-size: 1.3125rem;
          line-height: 1.5625rem; }
      .promo-main .promo-single .left .meta .text_wrap {
        margin-left: 0.9375rem; }
        .promo-main .promo-single .left .meta .text_wrap .category {
          font-size: 1rem;
          color: #b7b7b7;
          line-height: 3rem; }
        .promo-main .promo-single .left .meta .text_wrap .title {
          font-size: 3.0625rem;
          color: #00aeef;
          line-height: 3.625rem;
          font-weight: 700; }
    .promo-main .promo-single .left .promo-single-text {
      margin-top: 1.875rem;
      color: #000;
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding-bottom: 1.875rem;
      border-bottom: 1px solid #b6b7b7; }
    .promo-main .promo-single .left .footer {
      margin-top: 1.875rem;
      margin-left: 0.625rem; }
      .promo-main .promo-single .left .footer .social {
        color: #9bb3be;
        font-size: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .promo-main .promo-single .left .footer .social span {
          margin-right: 0.625rem; }
        .promo-main .promo-single .left .footer .social .icon {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          background-color: #00aeef;
          margin-right: 0.3125rem;
          padding: 0.0625rem;
          box-sizing: border-box;
          transition: 0.3s; }
          .promo-main .promo-single .left .footer .social .icon:hover {
            background-color: #009edf; }
  .promo-main .promo-single .right {
    width: 37%;
    height: 100vh;
    position: sticky;
    top: 0;
    right: 0;
    z-index: 10;
    background-color: #00aeef; }
    .promo-main .promo-single .right .logo {
      width: 10.8125rem;
      height: 8.9375rem;
      position: absolute;
      top: 3.125rem;
      right: 5rem; }
    .promo-main .promo-single .right .promo-list-small {
      padding-top: 12.5rem;
      padding-left: 4.375rem;
      box-sizing: border-box; }
      .promo-main .promo-single .right .promo-list-small .line {
        position: absolute;
        top: 15.25rem;
        left: 2.9375rem;
        width: 1px;
        height: auto;
        background-color: #fff;
        transition: height 0.3s; }
      .promo-main .promo-single .right .promo-list-small .promo-item {
        display: flex;
        flex-direction: column;
        margin-top: 1.875rem;
        position: relative;
        cursor: pointer; }
        .promo-main .promo-single .right .promo-list-small .promo-item .texts {
          margin-left: 0.625rem; }
          .promo-main .promo-single .right .promo-list-small .promo-item .texts .title {
            font-size: 2rem;
            color: #fff;
            font-weight: 700;
            line-height: 2.375rem; }
          .promo-main .promo-single .right .promo-list-small .promo-item .texts .category {
            font-size: 1rem;
            color: #fff;
            line-height: 1.875rem; }
          .promo-main .promo-single .right .promo-list-small .promo-item .texts .date {
            font-size: 1.1875rem;
            color: #fff;
            line-height: 1.875rem; }
        .promo-main .promo-single .right .promo-list-small .promo-item .dot {
          position: absolute;
          top: 0.8125rem;
          left: -1.875rem;
          width: 1rem;
          height: 1rem;
          background-color: #fff;
          border-radius: 50%; }

.news-main .first-screen {
  flex-direction: row;
  display: none; }
  .news-main .first-screen.active {
    display: flex; }
  .news-main .first-screen .left {
    width: 50%;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0.4375rem 0.4375rem 0.3125rem rgba(0, 0, 0, 0.1);
    z-index: 10;
    box-sizing: border-box; }
    .news-main .first-screen .left .wrap {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 88%;
      padding-left: 9.375rem;
      padding-right: 5rem; }
      .news-main .first-screen .left .wrap .pagename {
        font-size: 3rem;
        color: #00aeef;
        font-weight: 700; }
      .news-main .first-screen .left .wrap .search input,
      .news-main .first-screen .left .wrap .search select {
        appearance: none;
        margin-top: 2.1875rem;
        width: 16.875rem;
        border: 1px solid #b6b7b7;
        font-size: 1.5rem;
        color: #b6b7b7;
        padding: 0.9375rem 1.5625rem;
        box-sizing: border-box;
        border-radius: 0.9375rem; }
        .news-main .first-screen .left .wrap .search input option,
        .news-main .first-screen .left .wrap .search select option {
          color: #b6b7b7; }
        .news-main .first-screen .left .wrap .search input.year,
        .news-main .first-screen .left .wrap .search select.year {
          width: 0; }
          .news-main .first-screen .left .wrap .search input.year option,
          .news-main .first-screen .left .wrap .search select.year option {
            width: 8.4375rem; }
        .news-main .first-screen .left .wrap .search input.month,
        .news-main .first-screen .left .wrap .search select.month {
          width: 0;
          margin-left: 0.625rem; }
        .news-main .first-screen .left .wrap .search input.year, .news-main .first-screen .left .wrap .search input.month,
        .news-main .first-screen .left .wrap .search select.year,
        .news-main .first-screen .left .wrap .search select.month {
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.3s, width 0.2s 0.3s, visibility 0.3s 0.3s; }
          .news-main .first-screen .left .wrap .search input.year.active, .news-main .first-screen .left .wrap .search input.month.active,
          .news-main .first-screen .left .wrap .search select.year.active,
          .news-main .first-screen .left .wrap .search select.month.active {
            visibility: visible;
            opacity: 1; }
        .news-main .first-screen .left .wrap .search input.year.active,
        .news-main .first-screen .left .wrap .search select.year.active {
          width: 8.4375rem;
          visibility: visible;
          opacity: 1; }
        .news-main .first-screen .left .wrap .search input.month.active,
        .news-main .first-screen .left .wrap .search select.month.active {
          width: 10.625rem;
          visibility: visible;
          opacity: 1; }
      .news-main .first-screen .left .wrap .search a {
        font-size: 1.5rem;
        color: #b6b7b7;
        text-decoration: none;
        margin-left: 0.625rem;
        display: inline-block;
        transform: scale(1);
        transition: 0.3s; }
        .news-main .first-screen .left .wrap .search a.hide {
          visibility: hidden;
          opacity: 0;
          transform: scale(0);
          width: 0; }
      .news-main .first-screen .left .wrap .news-select .pic {
        margin-top: 2.1875rem; }
        .news-main .first-screen .left .wrap .news-select .pic img {
          max-height: 18.75rem;
          width: 100%;
          object-fit: cover;
          object-position: 50% 50%; }
      .news-main .first-screen .left .wrap .news-select .new-news {
        font-size: 3rem;
        color: #00aeef;
        font-weight: 700;
        margin-top: 2.5rem; }
      .news-main .first-screen .left .wrap .news-select .category {
        font-size: 1.25rem;
        color: #b7b7b7;
        line-height: 3.75rem; }
      .news-main .first-screen .left .wrap .news-select .title {
        font-size: 2.125rem;
        color: #00aeef;
        font-weight: 700;
        line-height: 2.625rem; }
      .news-main .first-screen .left .wrap .news-select .desc {
        font-size: 1.3125rem;
        color: #000;
        line-height: 1.5625rem;
        margin-top: 0.625rem; }
      .news-main .first-screen .left .wrap .news-select .date {
        font-size: 1.5rem;
        color: #b7b7b7;
        line-height: 3.125rem;
        margin-top: 0.625rem; }
  .news-main .first-screen .right {
    width: 50%; }
    .news-main .first-screen .right .news-list {
      width: 46.25rem;
      margin-left: 8.125rem;
      padding-left: 4.375rem;
      box-sizing: border-box;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      position: relative; }
      .news-main .first-screen .right .news-list .line {
        position: absolute;
        top: 7.625rem;
        left: 0.4375rem;
        width: 1px;
        height: auto;
        background-color: #b6b7b7;
        transition: height 1s; }
      .news-main .first-screen .right .news-list .news-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1.875rem;
        position: relative;
        cursor: pointer; }
        .news-main .first-screen .right .news-list .news-item .pic {
          width: 16.25rem;
          height: 13.75rem;
          filter: none;
          transition: 0.25s; }
          .news-main .first-screen .right .news-list .news-item .pic img {
            width: 16.25rem;
            height: 13.75rem;
            object-fit: cover; }
        .news-main .first-screen .right .news-list .news-item .texts {
          margin-left: 3.125rem; }
          .news-main .first-screen .right .news-list .news-item .texts .category {
            font-size: 0.9375rem;
            color: #b7b7b7; }
          .news-main .first-screen .right .news-list .news-item .texts .title {
            font-size: 1.875rem;
            color: #00aeef;
            font-weight: 700;
            margin-top: 1.25rem; }
          .news-main .first-screen .right .news-list .news-item .texts .desc {
            font-size: 1rem;
            color: #000;
            margin-top: 0.9375rem; }
          .news-main .first-screen .right .news-list .news-item .texts .date {
            font-size: 1.125rem;
            color: #b7b7b7;
            margin-top: 1.25rem; }
        .news-main .first-screen .right .news-list .news-item .dot {
          position: absolute;
          top: 0;
          left: -4.375rem;
          bottom: 0;
          margin: auto;
          width: 1rem;
          height: 1rem;
          background-color: #b6b7b7;
          border-radius: 50%; }
        .news-main .first-screen .right .news-list .news-item:after {
          content: '';
          position: absolute;
          top: 0;
          left: -4.375rem;
          bottom: 0;
          margin: auto;
          width: 4.375rem;
          height: 0.0625rem;
          background-color: #b6b7b7; }
        .news-main .first-screen .right .news-list .news-item:hover .pic {
          filter: contrast(0.3) brightness(1.5); }

.news-main .search-result {
  display: none; }
  .news-main .search-result.active {
    display: block; }
  .news-main .search-result .header {
    width: 100%;
    padding-top: 4.375rem;
    padding-left: 9.375rem;
    box-sizing: border-box;
    position: relative; }
    .news-main .search-result .header .pagename {
      font-size: 3rem;
      color: #00aeef;
      font-weight: 700; }
    .news-main .search-result .header .search input,
    .news-main .search-result .header .search select {
      appearance: none;
      margin-top: 2.1875rem;
      width: 16.875rem;
      border: 1px solid #b6b7b7;
      font-size: 1.5rem;
      color: #b6b7b7;
      padding: 0.9375rem 1.5625rem;
      box-sizing: border-box;
      border-radius: 0.9375rem; }
      .news-main .search-result .header .search input option,
      .news-main .search-result .header .search select option {
        color: #b6b7b7; }
      .news-main .search-result .header .search input.year,
      .news-main .search-result .header .search select.year {
        width: 0; }
        .news-main .search-result .header .search input.year option,
        .news-main .search-result .header .search select.year option {
          width: 8.4375rem; }
      .news-main .search-result .header .search input.month,
      .news-main .search-result .header .search select.month {
        width: 0;
        margin-left: 0.625rem; }
      .news-main .search-result .header .search input.year, .news-main .search-result .header .search input.month,
      .news-main .search-result .header .search select.year,
      .news-main .search-result .header .search select.month {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s, width 0.2s 0.3s, visibility 0.3s 0.3s; }
        .news-main .search-result .header .search input.year.active, .news-main .search-result .header .search input.month.active,
        .news-main .search-result .header .search select.year.active,
        .news-main .search-result .header .search select.month.active {
          visibility: visible;
          opacity: 1; }
      .news-main .search-result .header .search input.year.active,
      .news-main .search-result .header .search select.year.active {
        width: 8.4375rem;
        visibility: visible;
        opacity: 1; }
      .news-main .search-result .header .search input.month.active,
      .news-main .search-result .header .search select.month.active {
        width: 10.625rem;
        visibility: visible;
        opacity: 1; }
    .news-main .search-result .header .search a {
      font-size: 1.5rem;
      color: #b6b7b7;
      text-decoration: none;
      margin-left: 0.625rem;
      display: inline-block;
      transform: scale(1);
      transition: 0.3s; }
      .news-main .search-result .header .search a.hide {
        visibility: hidden;
        opacity: 0;
        transform: scale(0);
        width: 0; }
    .news-main .search-result .header .logo {
      width: 12.375rem;
      height: 10.25rem;
      position: absolute;
      bottom: 0;
      right: 6.25rem; }
  .news-main .search-result .main {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .news-main .search-result .main .left {
      width: 50%; }
      .news-main .search-result .main .left .news-list {
        width: 46.25rem;
        margin-left: 9.375rem;
        padding-left: 4.375rem;
        box-sizing: border-box;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        position: relative; }
        .news-main .search-result .main .left .news-list .line {
          position: absolute;
          top: 7.625rem;
          left: 0.4375rem;
          width: 1px;
          height: auto;
          background-color: #b6b7b7;
          transition: height 1s; }
        .news-main .search-result .main .left .news-list .news-item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 1.875rem;
          position: relative;
          cursor: pointer; }
          .news-main .search-result .main .left .news-list .news-item .pic {
            width: 16.25rem;
            height: 13.75rem;
            filter: none;
            transition: 0.25s; }
            .news-main .search-result .main .left .news-list .news-item .pic img {
              width: 16.25rem;
              height: 13.75rem;
              object-fit: cover; }
          .news-main .search-result .main .left .news-list .news-item .texts {
            margin-left: 3.125rem; }
            .news-main .search-result .main .left .news-list .news-item .texts .category {
              font-size: 0.9375rem;
              color: #b7b7b7; }
            .news-main .search-result .main .left .news-list .news-item .texts .title {
              font-size: 1.875rem;
              color: #00aeef;
              font-weight: 700;
              margin-top: 1.25rem; }
            .news-main .search-result .main .left .news-list .news-item .texts .desc {
              font-size: 1rem;
              color: #000;
              margin-top: 0.9375rem; }
            .news-main .search-result .main .left .news-list .news-item .texts .date {
              font-size: 1.125rem;
              color: #b7b7b7;
              margin-top: 1.25rem; }
          .news-main .search-result .main .left .news-list .news-item .dot {
            position: absolute;
            top: 0;
            left: -4.375rem;
            bottom: 0;
            margin: auto;
            width: 1rem;
            height: 1rem;
            background-color: #b6b7b7;
            border-radius: 50%; }
          .news-main .search-result .main .left .news-list .news-item:after {
            content: '';
            position: absolute;
            top: 0;
            left: -4.375rem;
            bottom: 0;
            margin: auto;
            width: 4.375rem;
            height: 0.0625rem;
            background-color: #b6b7b7; }
          .news-main .search-result .main .left .news-list .news-item:hover .pic {
            filter: contrast(0.3) brightness(1.5); }
    .news-main .search-result .main .right {
      width: 50%; }
      .news-main .search-result .main .right .news-list {
        width: 46.25rem;
        margin-left: 9.375rem;
        padding-left: 0;
        box-sizing: border-box;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        position: relative; }
        .news-main .search-result .main .right .news-list .line {
          position: absolute;
          top: 7.625rem;
          left: -3.9375rem;
          width: 1px;
          height: auto;
          background-color: #b6b7b7;
          transition: height 1s; }
        .news-main .search-result .main .right .news-list .news-item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 1.875rem;
          position: relative;
          cursor: pointer; }
          .news-main .search-result .main .right .news-list .news-item .pic {
            width: 16.25rem;
            height: 13.75rem;
            filter: none;
            transition: 0.25s; }
            .news-main .search-result .main .right .news-list .news-item .pic img {
              width: 16.25rem;
              height: 13.75rem;
              object-fit: cover; }
          .news-main .search-result .main .right .news-list .news-item .texts {
            margin-left: 3.125rem; }
            .news-main .search-result .main .right .news-list .news-item .texts .category {
              font-size: 0.9375rem;
              color: #b7b7b7; }
            .news-main .search-result .main .right .news-list .news-item .texts .title {
              font-size: 1.875rem;
              color: #00aeef;
              font-weight: 700;
              margin-top: 1.25rem; }
            .news-main .search-result .main .right .news-list .news-item .texts .desc {
              font-size: 1rem;
              color: #000;
              margin-top: 0.9375rem; }
            .news-main .search-result .main .right .news-list .news-item .texts .date {
              font-size: 1.125rem;
              color: #b7b7b7;
              margin-top: 1.25rem; }
          .news-main .search-result .main .right .news-list .news-item .dot {
            position: absolute;
            top: 0;
            left: -4.375rem;
            bottom: 0;
            margin: auto;
            width: 1rem;
            height: 1rem;
            background-color: #b6b7b7;
            border-radius: 50%; }
          .news-main .search-result .main .right .news-list .news-item:after {
            content: '';
            position: absolute;
            top: 0;
            left: -4.375rem;
            bottom: 0;
            margin: auto;
            width: 4.375rem;
            height: 0.0625rem;
            background-color: #b6b7b7; }
          .news-main .search-result .main .right .news-list .news-item:hover .pic {
            filter: contrast(0.3) brightness(1.5); }

.news-main .news-single {
  display: none; }
  .news-main .news-single.active {
    display: flex; }
  .news-main .news-single .left {
    width: 63%;
    padding-left: 12.5rem;
    padding-right: 7.1875rem;
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    box-sizing: border-box; }
    .news-main .news-single .left .logo {
      width: 100%;
      height: 3.75rem;
      position: relative; }
      .news-main .news-single .left .logo img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left center; }
    .news-main .news-single .left .pic {
      margin-top: 1.5625rem;
      width: 100%;
      height: 25rem; }
      .news-main .news-single .left .pic img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left center; }
    .news-main .news-single .left .meta {
      width: 100%;
      height: auto;
      margin-top: 2.5rem;
      padding-bottom: 2.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #b6b7b7; }
      .news-main .news-single .left .meta .date_wrap {
        width: 8.125rem;
        height: 9.6875rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center; }
        .news-main .news-single .left .meta .date_wrap .icon {
          width: 4.9375rem;
          height: 5.5rem; }
        .news-main .news-single .left .meta .date_wrap .date {
          color: #b7b7b7;
          font-size: 1.3125rem;
          line-height: 1.5625rem; }
      .news-main .news-single .left .meta .text_wrap {
        margin-left: 0.9375rem; }
        .news-main .news-single .left .meta .text_wrap .category {
          font-size: 1rem;
          color: #b7b7b7;
          line-height: 3rem; }
        .news-main .news-single .left .meta .text_wrap .title {
          font-size: 3.0625rem;
          color: #00aeef;
          line-height: 3.625rem;
          font-weight: 700; }
    .news-main .news-single .left .news-single-text {
      margin-top: 1.875rem;
      color: #000;
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding-bottom: 1.875rem;
      border-bottom: 1px solid #b6b7b7; }
    .news-main .news-single .left .footer {
      margin-top: 1.875rem;
      margin-left: 0.625rem; }
      .news-main .news-single .left .footer .social {
        color: #9bb3be;
        font-size: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .news-main .news-single .left .footer .social span {
          margin-right: 0.625rem; }
        .news-main .news-single .left .footer .social .icon {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          background-color: #00aeef;
          margin-right: 0.3125rem;
          padding: 0.0625rem;
          box-sizing: border-box;
          transition: 0.3s; }
          .news-main .news-single .left .footer .social .icon:hover {
            background-color: #009edf; }
  .news-main .news-single .right {
    width: 37%;
    height: 100vh;
    position: sticky;
    top: 0;
    right: 0;
    z-index: 10;
    background-color: #00aeef; }
    .news-main .news-single .right .logo {
      width: 10.8125rem;
      height: 8.9375rem;
      position: absolute;
      top: 3.125rem;
      right: 5rem; }
    .news-main .news-single .right .news-list-small {
      padding-top: 12.5rem;
      padding-left: 4.375rem;
      box-sizing: border-box; }
      .news-main .news-single .right .news-list-small .line {
        position: absolute;
        top: 15.25rem;
        left: 2.9375rem;
        width: 1px;
        height: auto;
        background-color: #fff;
        transition: height 0.3s; }
      .news-main .news-single .right .news-list-small .news-item {
        display: flex;
        flex-direction: column;
        margin-top: 1.875rem;
        position: relative;
        cursor: pointer; }
        .news-main .news-single .right .news-list-small .news-item .texts {
          margin-left: 0.625rem; }
          .news-main .news-single .right .news-list-small .news-item .texts .title {
            font-size: 2rem;
            color: #fff;
            font-weight: 700;
            line-height: 2.375rem; }
          .news-main .news-single .right .news-list-small .news-item .texts .category {
            font-size: 1rem;
            color: #fff;
            line-height: 1.875rem; }
          .news-main .news-single .right .news-list-small .news-item .texts .date {
            font-size: 1.1875rem;
            color: #fff;
            line-height: 1.875rem; }
        .news-main .news-single .right .news-list-small .news-item .dot {
          position: absolute;
          top: 0.8125rem;
          left: -1.875rem;
          width: 1rem;
          height: 1rem;
          background-color: #fff;
          border-radius: 50%; }

.about-main {
  position: relative; }
  .about-main .f1 {
    /* background: url(../img/f1.svg) 100% 0% no-repeat;
        background-size: contain; */
    width: 81.25rem;
    height: 119.9375rem;
    position: absolute;
    top: -27.125rem;
    right: 0; }
  .about-main .f2 {
    /* background: url(../img/f2.svg) 0% 0% no-repeat;
        background-size: contain; */
    width: 115.75rem;
    height: 69.875rem;
    position: absolute;
    top: 78.125rem;
    left: -20.625rem; }
  .about-main .f3 {
    /* background: url(../img/f3.svg) 0% 0% no-repeat;
        background-size: contain; */
    width: 159.6875rem;
    height: 83.625rem;
    position: absolute;
    top: 171.875rem;
    left: -33.75rem; }
  .about-main .f4 {
    /* background: url(../img/f4.svg) 0% 0% no-repeat;
        background-size: contain; */
    width: 126.5625rem;
    height: 88.625rem;
    position: absolute;
    top: 280rem;
    right: -0.625rem; }
  .about-main .f5 {
    /* background: url(../img/f5.svg) 0% 0% no-repeat;
        background-size: contain; */
    width: 129.875rem;
    height: 93.9375rem;
    position: absolute;
    top: 299.375rem;
    right: -0.625rem;
    z-index: 1; }
  .about-main .f6 {
    /* background: url(../img/f6.svg) 0% 0% no-repeat;
        background-size: contain; */
    width: 120.375rem;
    height: 112.125rem;
    position: absolute;
    top: 395.1875rem;
    right: -24.375rem;
    z-index: 1; }
  .about-main canvas {
    display: block;
    vertical-align: bottom; }
  .about-main #particles-js1 {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent; }
  .about-main .block1 {
    width: 100%;
    box-sizing: border-box;
    /* padding-top: rem(100px);
        padding-bottom: rem(100px); */
    padding-left: 15rem;
    position: relative; }
    .about-main .block1 .container {
      width: 100%;
      display: flex;
      flex-direction: row;
      z-index: 1; }
      .about-main .block1 .container .texts {
        width: 52%;
        /* margin-right: rem(150px); */ }
        .about-main .block1 .container .texts .logo {
          width: 31.125rem; }
        .about-main .block1 .container .texts .text {
          margin-top: 5.625rem; }
          .about-main .block1 .container .texts .text p {
            font-family: 'PT Sans Caption', sans-serif;
            color: #000;
            font-size: 1.5625rem;
            line-height: 1.875rem; }
        .about-main .block1 .container .texts .stat {
          color: #00aeef;
          font-weight: 700;
          font-size: 2.4375rem;
          width: 85%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 4.6875rem; }
          .about-main .block1 .container .texts .stat span {
            font-size: 4.875rem;
            display: block; }
      .about-main .block1 .container .video {
        width: 97vh;
        height: 97vh;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -12.5rem;
        margin: auto; }
        .about-main .block1 .container .video video {
          width: 100%;
          height: 100%;
          object-fit: cover; }
  .about-main .block2 {
    width: 100%;
    box-sizing: border-box;
    padding-left: 15rem;
    position: relative; }
    .about-main .block2 .container {
      z-index: 1; }
      .about-main .block2 .container img {
        width: 85.625rem;
        height: auto; }
  .about-main .block3 {
    width: 100%;
    box-sizing: border-box;
    padding-left: 15rem;
    position: relative; }
    .about-main .block3 .container {
      z-index: 1; }
      .about-main .block3 .container p {
        color: #00aeef;
        font-weight: 700;
        font-size: 2.90625rem; }
        .about-main .block3 .container p span {
          display: inline-block;
          width: 70%; }
  .about-main .block4 {
    width: 100%;
    box-sizing: border-box;
    /* padding-top: rem(150px); */
    padding-left: 15rem;
    position: relative; }
    .about-main .block4 .container {
      z-index: 1; }
      .about-main .block4 .container .video_wrap {
        position: relative; }
        .about-main .block4 .container .video_wrap .video {
          width: 87vh;
          height: 87vh; }
          .about-main .block4 .container .video_wrap .video video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            overflow: hidden; }
          .about-main .block4 .container .video_wrap .video .before {
            position: absolute;
            top: -0.625rem;
            left: -6.875rem;
            font-size: 3.125rem;
            color: #00aeef;
            font-weight: 700; }
        .about-main .block4 .container .video_wrap .items {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 66%;
          height: 98%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start; }
          .about-main .block4 .container .video_wrap .items .item {
            display: flex;
            flex-direction: row;
            align-items: center; }
            .about-main .block4 .container .video_wrap .items .item .icon {
              width: 10.625rem;
              height: 10.625rem; }
            .about-main .block4 .container .video_wrap .items .item .text {
              font-size: 2.5rem;
              color: #00aeef;
              font-weight: 700;
              margin-left: 1.25rem; }
            .about-main .block4 .container .video_wrap .items .item.item2, .about-main .block4 .container .video_wrap .items .item.item3 {
              margin-left: 9.0625rem; }
  .about-main .block5 {
    width: 100%;
    box-sizing: border-box;
    /* padding-top: rem(200px); */
    padding-left: 15rem;
    position: relative; }
    .about-main .block5 .container {
      z-index: 1; }
      .about-main .block5 .container p {
        color: #00aeef;
        font-weight: 700;
        font-size: 2.90625rem; }
  .about-main .block6 {
    width: 100%;
    box-sizing: border-box;
    /* padding-top: rem(550px); */
    padding-left: 15rem;
    position: relative; }
    .about-main .block6 .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 1; }
      .about-main .block6 .container .video {
        width: 48.4375rem;
        height: 48.4375rem;
        border-radius: 50%;
        overflow: hidden; }
        .about-main .block6 .container .video video {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .about-main .block6 .container .texts {
        width: 42.8125rem;
        margin-left: 6.875rem; }
        .about-main .block6 .container .texts .title {
          color: #00aeef;
          font-size: 3.3125rem;
          font-weight: 700; }
        .about-main .block6 .container .texts p {
          font-family: 'PT Sans Caption', sans-serif;
          color: #000;
          font-size: 1.5625rem; }
        .about-main .block6 .container .texts .ul {
          font-family: 'PT Sans Caption', sans-serif;
          color: #000;
          font-size: 1.5625rem;
          padding-left: 2.8125rem;
          position: relative; }
          .about-main .block6 .container .texts .ul:before {
            content: '';
            background-color: #00aeef;
            width: 1px;
            height: 87%;
            position: absolute;
            top: 0;
            left: 0.4375rem;
            bottom: 0;
            margin: auto; }
          .about-main .block6 .container .texts .ul .li {
            margin: 1.25rem auto;
            position: relative; }
            .about-main .block6 .container .texts .ul .li:before {
              content: '';
              background-color: #00aeef;
              width: 0.9375rem;
              height: 0.9375rem;
              border-radius: 50%;
              position: absolute;
              top: 0;
              left: -2.8125rem;
              bottom: 0;
              margin: auto; }
  .about-main .block7 {
    width: 100%;
    box-sizing: border-box;
    /* padding-top: rem(200px);
        padding-bottom: rem(180px); */
    padding-left: 15rem;
    position: relative; }
    .about-main .block7 .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 1; }
      .about-main .block7 .container .video {
        width: 48.4375rem;
        height: 48.4375rem;
        border-radius: 50%;
        overflow: hidden; }
        .about-main .block7 .container .video video {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .about-main .block7 .container .texts {
        width: 37.5rem;
        margin-right: 6.875rem; }
        .about-main .block7 .container .texts > .title {
          color: #00aeef;
          font-size: 3.3125rem;
          font-weight: 700; }
        .about-main .block7 .container .texts > p {
          font-family: 'PT Sans Caption', sans-serif;
          color: #000;
          font-size: 1.5625rem; }
        .about-main .block7 .container .texts .dropdowns {
          position: relative;
          padding-left: 0.9375rem; }
          .about-main .block7 .container .texts .dropdowns:before {
            content: '';
            width: 1px;
            height: calc(100% - 2rem);
            position: absolute;
            top: 1rem;
            left: 0.4375rem;
            background-color: #00aeef; }
          .about-main .block7 .container .texts .dropdowns .item {
            padding-left: 0.9375rem;
            position: relative; }
            .about-main .block7 .container .texts .dropdowns .item .dot {
              width: 0.9375rem;
              height: 0.9375rem;
              position: absolute;
              top: 1rem;
              left: -0.9375rem;
              background-color: #00aeef;
              border-radius: 50%; }
            .about-main .block7 .container .texts .dropdowns .item .title {
              font-size: 2.3125rem;
              color: #00aeef;
              font-weight: 700;
              margin: 1.25rem auto;
              cursor: pointer; }
              .about-main .block7 .container .texts .dropdowns .item .title img {
                width: 1.1875rem;
                height: 1rem;
                transform-origin: 50% 50%;
                transition: 0.4s; }
              .about-main .block7 .container .texts .dropdowns .item .title.active img {
                transform: rotate(-90deg); }
            .about-main .block7 .container .texts .dropdowns .item .text {
              font-family: 'PT Sans Caption', sans-serif;
              color: #000;
              font-size: 1.3125rem;
              display: none; }
  .about-main .block8 {
    width: 100%;
    box-sizing: border-box;
    /* padding-top: rem(140px);
        padding-bottom: rem(150px); */
    padding-left: 15rem;
    position: relative;
    background-color: #00aeef; }
    .about-main .block8 .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 1; }
      .about-main .block8 .container .video {
        width: 48.4375rem;
        height: 48.4375rem;
        border-radius: 50%;
        overflow: hidden;
        border: 3px solid #fff;
        box-sizing: border-box; }
        .about-main .block8 .container .video video {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .about-main .block8 .container .texts {
        width: 37.5rem;
        margin-left: 6.875rem; }
        .about-main .block8 .container .texts > .title {
          color: #fff;
          font-size: 3.3125rem;
          font-weight: 700; }
        .about-main .block8 .container .texts > p {
          font-family: 'PT Sans Caption', sans-serif;
          color: #fff;
          font-size: 1.5625rem; }
        .about-main .block8 .container .texts .dropdowns {
          position: relative;
          padding-left: 0.9375rem; }
          .about-main .block8 .container .texts .dropdowns:before {
            content: '';
            width: 1px;
            height: calc(100% - 2rem);
            position: absolute;
            top: 1rem;
            left: 0.4375rem;
            background-color: #fff; }
          .about-main .block8 .container .texts .dropdowns .item {
            padding-left: 0.9375rem;
            position: relative; }
            .about-main .block8 .container .texts .dropdowns .item .dot {
              width: 0.9375rem;
              height: 0.9375rem;
              position: absolute;
              top: 1rem;
              left: -0.9375rem;
              background-color: #fff;
              border-radius: 50%; }
            .about-main .block8 .container .texts .dropdowns .item .title {
              font-size: 2.3125rem;
              color: #fff;
              font-weight: 700;
              margin: 1.25rem auto;
              cursor: pointer; }
              .about-main .block8 .container .texts .dropdowns .item .title img {
                width: 1.1875rem;
                height: 1rem;
                transform-origin: 50% 50%;
                transition: 0.4s; }
              .about-main .block8 .container .texts .dropdowns .item .title.active img {
                transform: rotate(-90deg); }
            .about-main .block8 .container .texts .dropdowns .item .text {
              font-family: 'PT Sans Caption', sans-serif;
              color: #fff;
              font-size: 1.3125rem;
              display: none; }
  .about-main .block9 {
    width: 100%;
    box-sizing: border-box;
    /* padding-top: rem(150px); */
    padding-left: 15rem;
    position: relative;
    background-color: #00aeef; }
    .about-main .block9 .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 1; }
      .about-main .block9 .container .video {
        width: 48.4375rem;
        height: 48.4375rem;
        border-radius: 50%;
        overflow: hidden;
        border: 3px solid #fff;
        box-sizing: border-box; }
        .about-main .block9 .container .video video {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .about-main .block9 .container .texts {
        width: 37.5rem;
        margin-right: 6.875rem; }
        .about-main .block9 .container .texts > .title {
          color: #fff;
          font-size: 3.3125rem;
          font-weight: 700; }
        .about-main .block9 .container .texts > p {
          font-family: 'PT Sans Caption', sans-serif;
          color: #fff;
          font-size: 1.5625rem; }
        .about-main .block9 .container .texts .dropdowns {
          position: relative;
          padding-left: 0.9375rem; }
          .about-main .block9 .container .texts .dropdowns:before {
            content: '';
            width: 1px;
            height: calc(100% - 2rem);
            position: absolute;
            top: 1rem;
            left: 0.4375rem;
            background-color: #fff; }
          .about-main .block9 .container .texts .dropdowns .item {
            padding-left: 0.9375rem;
            position: relative; }
            .about-main .block9 .container .texts .dropdowns .item .dot {
              width: 0.9375rem;
              height: 0.9375rem;
              position: absolute;
              top: 1rem;
              left: -0.9375rem;
              background-color: #fff;
              border-radius: 50%; }
            .about-main .block9 .container .texts .dropdowns .item .title {
              font-size: 2.3125rem;
              color: #fff;
              font-weight: 700;
              margin: 1.25rem auto;
              cursor: pointer; }
              .about-main .block9 .container .texts .dropdowns .item .title img {
                width: 1.1875rem;
                height: 1rem;
                transform-origin: 50% 50%;
                transition: 0.4s; }
              .about-main .block9 .container .texts .dropdowns .item .title.active img {
                transform: rotate(-90deg); }
            .about-main .block9 .container .texts .dropdowns .item .text {
              font-family: 'PT Sans Caption', sans-serif;
              color: #fff;
              font-size: 1.3125rem;
              display: none; }
  .about-main .slideFromTop {
    opacity: 0;
    transform: translateY(-100%);
    transition: 0.8s; }
    .about-main .slideFromTop.active {
      opacity: 1;
      transform: translateY(0%); }
  .about-main .slideFromBottom {
    opacity: 0;
    transform: translateY(100%);
    transition: 0.8s; }
    .about-main .slideFromBottom.active {
      opacity: 1;
      transform: translateY(0%); }
  .about-main .slideFromLeft {
    opacity: 0;
    transform: translateX(-100%);
    transition: 0.8s; }
    .about-main .slideFromLeft.active {
      opacity: 1;
      transform: translateY(0%); }
  .about-main .slideFromRight {
    opacity: 0;
    transform: translateX(100%);
    transition: 0.8s; }
    .about-main .slideFromRight.active {
      opacity: 1;
      transform: translateY(0%); }
  .about-main .scaleTo {
    opacity: 0;
    transform: scale(0.5);
    transition: 0.8s; }
    .about-main .scaleTo.active {
      opacity: 1;
      transform: scale(1); }

.contacts-main {
  display: flex; }
  .contacts-main .logo {
    width: 12.375rem;
    height: 10.25rem;
    position: absolute;
    top: 1.875rem;
    right: 6.25rem; }
  .contacts-main .left {
    background-color: #00aeef;
    width: 37.5rem;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0.4375rem 0.4375rem 0.3125rem rgba(0, 0, 0, 0.1);
    z-index: 10;
    box-sizing: border-box; }
    .contacts-main .left .wrap {
      width: 21.875rem;
      height: 34%;
      position: absolute;
      top: 0;
      left: 4rem;
      bottom: 0;
      right: 0;
      margin: auto; }
      .contacts-main .left .wrap .title {
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        font-size: 3rem;
        margin-bottom: 1.875rem; }
      .contacts-main .left .wrap .items {
        position: relative; }
        .contacts-main .left .wrap .items .line {
          width: 1px;
          height: calc(100% - 1rem);
          background-color: #fff;
          position: absolute;
          top: 0;
          left: 0.375rem;
          bottom: 0;
          margin: auto; }
        .contacts-main .left .wrap .items .item {
          font-family: 'PT Sans', sans-serif;
          font-size: 1.53125rem;
          margin-bottom: 1.5625rem;
          padding-left: 1.875rem;
          position: relative;
          cursor: pointer; }
          .contacts-main .left .wrap .items .item .dot {
            width: 1rem;
            height: 1rem;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto; }
  .contacts-main .right {
    width: calc(100% - 37.5rem);
    padding-top: 5.625rem;
    padding-left: 5rem;
    padding-right: 5rem;
    box-sizing: border-box;
    position: relative; }
    .contacts-main .right .screen {
      display: none; }
      .contacts-main .right .screen .title {
        color: #00aeef;
        font-size: 3rem;
        font-family: 'PT Sans Caption', sans-serif;
        font-weight: 700;
        line-height: 3.59375rem;
        margin-bottom: 1.25rem;
        margin-top: 4.6875rem; }
      .contacts-main .right .screen .address {
        color: #000;
        font-size: 1.8125rem;
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        margin-bottom: 0.9375rem;
        padding-left: 2.5rem;
        position: relative; }
        .contacts-main .right .screen .address:before {
          content: '';
          background: url(../img/icon_pin.svg) 50% 50% no-repeat;
          background-size: contain;
          width: 1.5625rem;
          height: 2.25rem;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto; }
      .contacts-main .right .screen .tel {
        color: #000;
        font-size: 1.8125rem;
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        line-height: 3.125rem;
        padding-left: 2.5rem;
        position: relative; }
        .contacts-main .right .screen .tel:before {
          content: '';
          background: url(../img/icon_tel.svg) 50% 50% no-repeat;
          background-size: contain;
          width: 1.6875rem;
          height: 1.6875rem;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto; }
      .contacts-main .right .screen .tel-two {
        color: #000;
        font-size: 1.8125rem;
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        line-height: 3.125rem;
        padding-left: 2.5rem; }
      .contacts-main .right .screen .worktime {
        color: #000;
        font-size: 1.5625rem;
        font-family: 'PT Sans', sans-serif;
        line-height: 3.125rem;
        padding-left: 2.5rem;
        position: relative; }
        .contacts-main .right .screen .worktime:before {
          content: '';
          background: url(../img/icon-worktime.svg) 50% 50% no-repeat;
          background-size: contain;
          width: 1.875rem;
          height: 1.875rem;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto; }
      .contacts-main .right .screen .map {
        width: 44.0625rem;
        height: 26.25rem;
        margin-top: 1.875rem; }
      .contacts-main .right .screen .photo-office p {
        color: #00aeef;
        font-size: 1.5rem;
        font-family: 'PT Sans', sans-serif;
        position: relative;
        padding-left: 1.5625rem;
        margin: 0.9375rem 0;
        cursor: pointer; }
        .contacts-main .right .screen .photo-office p:before {
          content: '';
          background: url(../img/icon-down-contacts.svg) 50% 50% no-repeat;
          background-size: contain;
          width: 0.875rem;
          height: 0.75rem;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          transition: 0.3s; }
        .contacts-main .right .screen .photo-office p.active:before {
          transform: rotate(-90deg); }
      .contacts-main .right .screen .photo-office .photo {
        display: none;
        max-width: 44.0625rem; }
        .contacts-main .right .screen .photo-office .photo img {
          width: 100%; }
      .contacts-main .right .screen > p {
        color: #000;
        font-size: 1.5rem;
        font-family: 'PT Sans', sans-serif;
        margin: 0.625rem 0; }
        .contacts-main .right .screen > p span {
          font-weight: 700; }
      .contacts-main .right .screen ul {
        padding-left: 0; }
        .contacts-main .right .screen ul li {
          color: #000;
          font-size: 1.5rem;
          font-family: 'PT Sans', sans-serif;
          margin: 0.625rem 0;
          list-style-type: none;
          position: relative;
          padding-left: 1.875rem; }
          .contacts-main .right .screen ul li span {
            font-weight: 700; }
          .contacts-main .right .screen ul li:before {
            content: '';
            background-color: #00aeef;
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            position: absolute;
            top: 0.4375rem;
            left: 0; }
      .contacts-main .right .screen .departments .block .title {
        color: #000;
        font-size: 1.5rem;
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        padding-left: 2.5rem;
        margin-top: 1.5625rem; }
        .contacts-main .right .screen .departments .block .title span.email {
          text-transform: capitalize;
          font-size: 1.5rem;
          font-weight: 400;
          font-family: 'PT Sans', sans-serif;
          position: relative;
          padding-left: 1.875rem;
          margin-left: 1.25rem; }
          .contacts-main .right .screen .departments .block .title span.email:before {
            content: '';
            background: url(../img/icon_email.svg) 50% 50% no-repeat;
            background-size: contain;
            width: 1.3125rem;
            height: 1rem;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto; }
      .contacts-main .right .screen .departments .block .list {
        width: 100%;
        color: #000;
        font-size: 1.5rem;
        font-family: 'PT Sans', sans-serif; }
        .contacts-main .right .screen .departments .block .list .item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 1.875rem 2.5rem;
          border-radius: 2.3125rem;
          background-color: #ededed;
          margin-bottom: 0.9375rem; }
          .contacts-main .right .screen .departments .block .list .item.item-end {
            background-color: transparent; }
          .contacts-main .right .screen .departments .block .list .item .email {
            position: relative;
            padding-left: 1.875rem; }
            .contacts-main .right .screen .departments .block .list .item .email:before {
              content: '';
              background: url(../img/icon_email.svg) 50% 50% no-repeat;
              background-size: contain;
              width: 1.3125rem;
              height: 1rem;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              margin: auto; }
      .contacts-main .right .screen .agents {
        width: 100%;
        color: #000;
        font-size: 1.5rem;
        font-family: 'PT Sans', sans-serif; }
        .contacts-main .right .screen .agents .title {
          margin-bottom: 2.5rem; }
        .contacts-main .right .screen .agents .item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 1.875rem 2.5rem;
          border-radius: 2.3125rem;
          background-color: #ededed;
          margin-bottom: 0.9375rem; }
          .contacts-main .right .screen .agents .item.item-end {
            background-color: transparent; }
          .contacts-main .right .screen .agents .item.item-invert {
            background-color: #00aeef;
            color: #fff; }
            .contacts-main .right .screen .agents .item.item-invert .email:before {
              filter: brightness(0) invert(1); }
          .contacts-main .right .screen .agents .item .email {
            position: relative;
            padding-left: 1.875rem; }
            .contacts-main .right .screen .agents .item .email:before {
              content: '';
              background: url(../img/icon_email.svg) 50% 50% no-repeat;
              background-size: contain;
              width: 1.3125rem;
              height: 1rem;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              margin: auto; }
      .contacts-main .right .screen .sng {
        width: 100%;
        color: #000;
        font-size: 1.5rem;
        font-family: 'PT Sans', sans-serif; }
        .contacts-main .right .screen .sng .name {
          font-weight: 700;
          margin-bottom: 0.9375rem; }
        .contacts-main .right .screen .sng .item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 1.875rem 2.5rem;
          border-radius: 2.3125rem;
          background-color: #ededed;
          margin-bottom: 0.9375rem; }
          .contacts-main .right .screen .sng .item.item-end {
            background-color: transparent; }
          .contacts-main .right .screen .sng .item .text {
            width: 44%; }
            .contacts-main .right .screen .sng .item .text a {
              color: #000;
              text-decoration: none; }
              .contacts-main .right .screen .sng .item .text a:hover {
                text-decoration: underline; }
          .contacts-main .right .screen .sng .item .phone {
            position: relative;
            padding-left: 1.875rem; }
            .contacts-main .right .screen .sng .item .phone:before {
              content: '';
              background: url(../img/icon_tel.svg) 50% 50% no-repeat;
              background-size: contain;
              width: 1.3125rem;
              height: 1.3125rem;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              margin: auto; }
          .contacts-main .right .screen .sng .item .email {
            position: relative;
            padding-left: 1.875rem; }
            .contacts-main .right .screen .sng .item .email:before {
              content: '';
              background: url(../img/icon_email.svg) 50% 50% no-repeat;
              background-size: contain;
              width: 1.3125rem;
              height: 1rem;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              margin: auto; }

.manufacturers-main .header {
  width: 100%;
  padding-top: 4.375rem;
  padding-left: 9.375rem;
  box-sizing: border-box;
  position: relative; }
  .manufacturers-main .header .pagename {
    font-size: 3rem;
    color: #00aeef;
    font-weight: 700; }
  .manufacturers-main .header .search select {
    appearance: none;
    margin-top: 2.1875rem;
    margin-right: 0.9375rem;
    width: 21.875rem;
    border: 1px solid #b6b7b7;
    font-size: 1.5rem;
    color: #b6b7b7;
    padding: 0.9375rem 3.125rem 0.9375rem 1.5625rem;
    box-sizing: border-box;
    border-radius: 0.9375rem;
    background-image: url(../img/icon_select_arrow.svg);
    background-position: 95% 50%;
    background-size: 0.9375rem;
    background-repeat: no-repeat; }
    .manufacturers-main .header .search select option {
      color: #b6b7b7; }
    .manufacturers-main .header .search select.alphabet {
      display: none; }
  .manufacturers-main .header .logo {
    width: 12.375rem;
    height: 10.25rem;
    position: absolute;
    bottom: 0;
    right: 6.25rem; }

.manufacturers-main .links {
  padding-left: 10.625rem;
  margin: 1.25rem 0; }
  .manufacturers-main .links a {
    color: #b6b7b7;
    font-size: 1.5rem;
    text-decoration: none;
    margin: 0 0.25rem; }
    .manufacturers-main .links a:hover {
      text-decoration: underline; }

.manufacturers-main .main {
  padding-left: 9.375rem;
  padding-bottom: 3.125rem; }
  .manufacturers-main .main .logos {
    width: 95%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .manufacturers-main .main .logos .separate {
      color: #b5b6b6;
      font-size: 1.875rem;
      font-family: 'PT Sans', sans-serif;
      line-height: 2rem;
      margin-bottom: 1.25rem;
      margin-top: 1.25rem;
      text-align: center;
      width: 100%;
      position: relative;
      cursor: pointer;
      display: none; }
      .manufacturers-main .main .logos .separate.hide {
        display: none; }
    .manufacturers-main .main .logos .logos-hide {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      opacity: 1;
      display: flex;
      transition: 1s; }
      .manufacturers-main .main .logos .logos-hide.active {
        opacity: 1;
        display: flex !important; }
    .manufacturers-main .main .logos .item {
      width: 12.5%;
      height: 13.125rem;
      border: 1px solid #e1e1e1;
      box-sizing: border-box;
      position: relative; }
      .manufacturers-main .main .logos .item:hover {
        border: 1px solid #00aeef;
        box-shadow: 0 0 1.25rem rgba(0, 105, 241, 0.2); }
      .manufacturers-main .main .logos .item img {
        width: 80%;
        height: 80%;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto; }

.support-main {
  position: relative; }
  .support-main .f1 {
    width: 81.25rem;
    height: 119.9375rem;
    position: absolute;
    top: -27.125rem;
    right: 0; }
  .support-main .f2 {
    width: 115.75rem;
    height: 69.875rem;
    position: absolute;
    top: 78.125rem;
    left: -20.625rem; }
  .support-main .f3 {
    width: 159.6875rem;
    height: 83.625rem;
    position: absolute;
    top: 171.875rem;
    left: -33.75rem; }
  .support-main .f4 {
    width: 126.5625rem;
    height: 88.625rem;
    position: absolute;
    top: 280rem;
    right: -0.625rem; }
  .support-main .f5 {
    width: 129.875rem;
    height: 93.9375rem;
    position: absolute;
    top: 299.375rem;
    right: -0.625rem;
    z-index: 1; }
  .support-main .f6 {
    width: 120.375rem;
    height: 112.125rem;
    position: absolute;
    top: 395.1875rem;
    right: -24.375rem;
    z-index: 1; }
  .support-main canvas {
    display: block;
    vertical-align: bottom; }
  .support-main #particles-js1 {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent; }
  .support-main .block1 {
    width: 100%;
    box-sizing: border-box;
    padding-left: 9.375rem;
    position: relative; }
    .support-main .block1 .container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 1; }
      .support-main .block1 .container .texts {
        width: 45%; }
        .support-main .block1 .container .texts .logo {
          width: 27.5625rem;
          height: 10.1875rem;
          margin-left: auto; }
          .support-main .block1 .container .texts .logo img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: 0% 50%; }
        .support-main .block1 .container .texts .text {
          margin-top: 5.625rem; }
          .support-main .block1 .container .texts .text .title {
            color: #00aeef;
            font-family: 'PT Sans', sans-serif;
            font-weight: 700;
            font-size: 3.3125rem;
            line-height: 3.25rem;
            margin-bottom: 1.875rem; }
          .support-main .block1 .container .texts .text p {
            font-family: 'PT Sans Caption', sans-serif;
            color: #000;
            font-size: 1.5rem;
            line-height: 1.75rem; }
      .support-main .block1 .container .pic {
        width: 46.5rem;
        height: 46.5rem;
        margin-left: 6.25rem;
        margin-top: 3.75rem; }
        .support-main .block1 .container .pic img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 50% 50%; }
  .support-main .block2,
  .support-main .block4,
  .support-main .block6,
  .support-main .block8 {
    width: 100%;
    box-sizing: border-box;
    padding-left: 9.375rem;
    position: relative; }
    .support-main .block2 .container,
    .support-main .block4 .container,
    .support-main .block6 .container,
    .support-main .block8 .container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 1; }
      .support-main .block2 .container .texts,
      .support-main .block4 .container .texts,
      .support-main .block6 .container .texts,
      .support-main .block8 .container .texts {
        width: 45%;
        margin-left: 9.375rem; }
        .support-main .block2 .container .texts .text,
        .support-main .block4 .container .texts .text,
        .support-main .block6 .container .texts .text,
        .support-main .block8 .container .texts .text {
          margin-top: 5.625rem; }
          .support-main .block2 .container .texts .text .title,
          .support-main .block4 .container .texts .text .title,
          .support-main .block6 .container .texts .text .title,
          .support-main .block8 .container .texts .text .title {
            color: #00aeef;
            font-family: 'PT Sans', sans-serif;
            font-weight: 700;
            font-size: 3.3125rem;
            line-height: 3.25rem;
            margin-bottom: 1.875rem; }
          .support-main .block2 .container .texts .text p,
          .support-main .block4 .container .texts .text p,
          .support-main .block6 .container .texts .text p,
          .support-main .block8 .container .texts .text p {
            font-family: 'PT Sans Caption', sans-serif;
            color: #000;
            font-size: 1.5rem;
            line-height: 1.75rem; }
      .support-main .block2 .container .pic,
      .support-main .block4 .container .pic,
      .support-main .block6 .container .pic,
      .support-main .block8 .container .pic {
        width: 43.75rem;
        height: 43.75rem; }
        .support-main .block2 .container .pic img,
        .support-main .block4 .container .pic img,
        .support-main .block6 .container .pic img,
        .support-main .block8 .container .pic img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 50% 50%; }
  .support-main .block3,
  .support-main .block5,
  .support-main .block7 {
    width: 100%;
    box-sizing: border-box;
    padding-left: 9.375rem;
    position: relative; }
    .support-main .block3 .container,
    .support-main .block5 .container,
    .support-main .block7 .container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 1; }
      .support-main .block3 .container .texts,
      .support-main .block5 .container .texts,
      .support-main .block7 .container .texts {
        width: 45%; }
        .support-main .block3 .container .texts .text,
        .support-main .block5 .container .texts .text,
        .support-main .block7 .container .texts .text {
          margin-top: 5.625rem; }
          .support-main .block3 .container .texts .text .title,
          .support-main .block5 .container .texts .text .title,
          .support-main .block7 .container .texts .text .title {
            color: #00aeef;
            font-family: 'PT Sans', sans-serif;
            font-weight: 700;
            font-size: 3.3125rem;
            line-height: 3.25rem;
            margin-bottom: 1.875rem; }
          .support-main .block3 .container .texts .text p,
          .support-main .block5 .container .texts .text p,
          .support-main .block7 .container .texts .text p {
            font-family: 'PT Sans Caption', sans-serif;
            color: #000;
            font-size: 1.5rem;
            line-height: 1.75rem; }
      .support-main .block3 .container .pic,
      .support-main .block5 .container .pic,
      .support-main .block7 .container .pic {
        width: 43.75rem;
        height: 43.75rem;
        margin-left: 6.25rem; }
        .support-main .block3 .container .pic img,
        .support-main .block5 .container .pic img,
        .support-main .block7 .container .pic img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 50% 50%; }
  .support-main .slideFromTop {
    opacity: 0;
    transform: translateY(-100%);
    transition: 0.8s; }
    .support-main .slideFromTop.active {
      opacity: 1;
      transform: translateY(0%); }
  .support-main .slideFromBottom {
    opacity: 0;
    transform: translateY(100%);
    transition: 0.8s; }
    .support-main .slideFromBottom.active {
      opacity: 1;
      transform: translateY(0%); }
  .support-main .slideFromLeft {
    opacity: 0;
    transform: translateX(-100%);
    transition: 0.8s; }
    .support-main .slideFromLeft.active {
      opacity: 1;
      transform: translateY(0%); }
  .support-main .slideFromRight {
    opacity: 0;
    transform: translateX(100%);
    transition: 0.8s; }
    .support-main .slideFromRight.active {
      opacity: 1;
      transform: translateY(0%); }
  .support-main .scaleTo {
    opacity: 0;
    transform: scale(0.5);
    transition: 0.8s; }
    .support-main .scaleTo.active {
      opacity: 1;
      transform: scale(1); }

.partners-main {
  position: relative; }
  .partners-main .f1 {
    width: 81.25rem;
    height: 119.9375rem;
    position: absolute;
    top: -27.125rem;
    right: 0; }
  .partners-main .f2 {
    width: 115.75rem;
    height: 69.875rem;
    position: absolute;
    top: 78.125rem;
    left: -20.625rem; }
  .partners-main canvas {
    display: block;
    vertical-align: bottom; }
  .partners-main #particles-js1 {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent; }
  .partners-main .block1 {
    width: 100%;
    box-sizing: border-box;
    padding-left: 9.375rem;
    position: relative; }
    .partners-main .block1 .container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 1; }
      .partners-main .block1 .container .texts {
        width: 53%;
        margin-left: 6.25rem;
        margin-top: 0; }
        .partners-main .block1 .container .texts .logo {
          width: 27.5625rem;
          height: 10.1875rem;
          margin-left: auto; }
          .partners-main .block1 .container .texts .logo img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: 0% 50%; }
        .partners-main .block1 .container .texts .text {
          margin-top: 3.125rem; }
          .partners-main .block1 .container .texts .text .title {
            color: #00aeef;
            font-family: 'PT Sans', sans-serif;
            font-weight: 700;
            font-size: 3.3125rem;
            line-height: 3.25rem;
            margin-bottom: 1.875rem; }
          .partners-main .block1 .container .texts .text p {
            font-family: 'PT Sans Caption', sans-serif;
            color: #000;
            font-size: 1.375rem;
            line-height: 1.625rem; }
      .partners-main .block1 .container .pic {
        width: 56.25rem;
        height: 56.25rem;
        margin-left: -15.625rem;
        margin-top: 3.125rem; }
        .partners-main .block1 .container .pic img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 50% 50%; }
  .partners-main .block2 {
    width: 100%;
    box-sizing: border-box;
    padding-left: 15rem;
    position: relative; }
    .partners-main .block2 .container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 1; }
      .partners-main .block2 .container .texts {
        width: 41%; }
        .partners-main .block2 .container .texts .text .title {
          color: #00aeef;
          font-family: 'PT Sans', sans-serif;
          font-weight: 700;
          font-size: 3.3125rem;
          line-height: 3.25rem;
          margin-bottom: 1.875rem; }
        .partners-main .block2 .container .texts .text p {
          font-family: 'PT Sans Caption', sans-serif;
          color: #000;
          font-size: 1.5rem;
          line-height: 1.75rem; }
        .partners-main .block2 .container .texts .text a.btn {
          background-color: #00aeef;
          color: #fff;
          font-family: 'PT Sans Caption', sans-serif;
          font-weight: 700;
          font-size: 1.5625rem;
          width: 22.375rem;
          height: 4.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          border-radius: 0.625rem;
          margin-top: 2.5rem;
          cursor: pointer; }
      .partners-main .block2 .container .pic {
        width: 50rem;
        height: 50rem;
        margin-left: 6.25rem; }
        .partners-main .block2 .container .pic img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 50% 50%; }
  .partners-main .block3 {
    width: 100%;
    box-sizing: border-box;
    padding-left: 9.375rem;
    position: relative;
    background-color: #00aeef; }
    .partners-main .block3 .container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 1; }
      .partners-main .block3 .container .texts {
        width: 41%;
        margin-left: 6.25rem; }
        .partners-main .block3 .container .texts .text .title {
          color: #ffffff;
          font-family: 'PT Sans', sans-serif;
          font-weight: 700;
          font-size: 3.3125rem;
          line-height: 3.25rem;
          margin-bottom: 1.875rem; }
        .partners-main .block3 .container .texts .text p {
          font-family: 'PT Sans Caption', sans-serif;
          color: #ffffff;
          font-size: 1.5rem;
          line-height: 1.75rem; }
        .partners-main .block3 .container .texts .text a.btn {
          background-color: #fff;
          color: #00aeef;
          font-family: 'PT Sans Caption', sans-serif;
          font-weight: 700;
          font-size: 1.5625rem;
          width: 22.375rem;
          height: 4.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          border-radius: 0.625rem;
          margin-top: 2.5rem;
          cursor: pointer; }
      .partners-main .block3 .container .pic {
        width: 50rem;
        height: 50rem; }
        .partners-main .block3 .container .pic img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 50% 50%; }
  .partners-main .forms {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    z-index: 2; }
    .partners-main .forms .left {
      width: 45%;
      padding-left: 9.375rem;
      padding-right: 3.75rem;
      box-sizing: border-box;
      box-shadow: 0.4375rem 0.4375rem 0.3125rem rgba(0, 0, 0, 0.1); }
      .partners-main .forms .left .form {
        padding-bottom: 3.125rem; }
        .partners-main .forms .left .form .title {
          color: #00aeef;
          font-family: 'PT Sans Caption', sans-serif;
          font-weight: 700;
          font-size: 3rem;
          margin-top: 3.125rem; }
        .partners-main .forms .left .form .title2 {
          color: #b6b7b7;
          font-family: 'PT Sans', sans-serif;
          font-weight: 700;
          font-size: 2.1875rem; }
        .partners-main .forms .left .form form .inputs {
          display: flex;
          flex-direction: column;
          margin-top: 2.5rem; }
          .partners-main .forms .left .form form .inputs input {
            width: 100%;
            height: 3.625rem;
            border: 1px solid #b6b7b7;
            border-radius: 0.625rem;
            box-shadow: none;
            color: #1d1d1b;
            font-family: 'PT Sans', sans-serif;
            font-weight: 400;
            font-size: 1.5rem;
            padding: 0 1.25rem;
            box-sizing: border-box;
            margin-bottom: 1.25rem; }
        .partners-main .forms .left .form form label {
          color: #1d1d1b;
          font-family: 'PT Sans', sans-serif;
          font-weight: 400;
          font-size: 1.1875rem;
          display: flex;
          flex-direction: row; }
          .partners-main .forms .left .form form label input {
            width: 2.75rem;
            height: 1.6875rem;
            border: 1px solid #b6b7b7;
            border-radius: 0.625rem;
            box-shadow: none; }
          .partners-main .forms .left .form form label p {
            margin: 0;
            margin-left: 0.625rem; }
            .partners-main .forms .left .form form label p a {
              color: #1d1d1b; }
        .partners-main .forms .left .form form .bottom {
          background-color: transparent;
          margin-top: 2.5rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .partners-main .forms .left .form form .bottom input[type="submit"] {
            background-color: #00aeef;
            color: #fff;
            font-family: 'PT Sans Caption', sans-serif;
            font-weight: 700;
            font-size: 1.5625rem;
            width: 17.0625rem;
            height: 3.625rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.625rem;
            box-shadow: none;
            border: none;
            cursor: pointer; }
          .partners-main .forms .left .form form .bottom .captcha {
            width: 21.125rem; }
            .partners-main .forms .left .form form .bottom .captcha img {
              width: 100%; }
    .partners-main .forms .right {
      width: 55%;
      padding-bottom: 3.125rem; }
      .partners-main .forms .right .logo {
        width: 12.375rem;
        height: 10.25rem;
        position: absolute;
        top: 2.5rem;
        right: 6.25rem; }
        .partners-main .forms .right .logo img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 50% 50%; }
      .partners-main .forms .right .texts {
        margin-top: 16.875rem;
        padding-left: 4.375rem;
        padding-right: 4.375rem; }
        .partners-main .forms .right .texts .title {
          color: #00aeef;
          font-family: 'PT Sans Caption', sans-serif;
          font-weight: 700;
          font-size: 2.75rem; }
        .partners-main .forms .right .texts .title2 {
          color: #1d1d1b;
          font-family: 'PT Sans', sans-serif;
          font-weight: 700;
          font-size: 1.6875rem;
          margin-top: 2.5rem;
          margin-bottom: 2.5rem;
          text-transform: uppercase; }
        .partners-main .forms .right .texts p {
          color: #1d1d1b;
          font-family: 'PT Sans', sans-serif;
          font-weight: 400;
          font-size: 1.375rem;
          position: relative; }
          .partners-main .forms .right .texts p:before {
            content: '';
            background-color: #00aeef;
            width: 0.375rem;
            height: 0.375rem;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: -0.9375rem;
            bottom: 0;
            margin: auto; }
          .partners-main .forms .right .texts p.text_end {
            margin-top: 3.125rem; }
            .partners-main .forms .right .texts p.text_end:before {
              display: none; }
        .partners-main .forms .right .texts.texts-vendor {
          margin-top: 18.75rem; }
          .partners-main .forms .right .texts.texts-vendor p:before {
            display: none; }
  .partners-main .slideFromTop {
    opacity: 0;
    transform: translateY(-100%);
    transition: 0.8s; }
    .partners-main .slideFromTop.active {
      opacity: 1;
      transform: translateY(0%); }
  .partners-main .slideFromBottom {
    opacity: 0;
    transform: translateY(100%);
    transition: 0.8s; }
    .partners-main .slideFromBottom.active {
      opacity: 1;
      transform: translateY(0%); }
  .partners-main .slideFromLeft {
    opacity: 0;
    transform: translateX(-100%);
    transition: 0.8s; }
    .partners-main .slideFromLeft.active {
      opacity: 1;
      transform: translateY(0%); }
  .partners-main .slideFromRight {
    opacity: 0;
    transform: translateX(100%);
    transition: 0.8s; }
    .partners-main .slideFromRight.active {
      opacity: 1;
      transform: translateY(0%); }
  .partners-main .scaleTo {
    opacity: 0;
    transform: scale(0.5);
    transition: 0.8s; }
    .partners-main .scaleTo.active {
      opacity: 1;
      transform: scale(1); }

.presscenter-main .first-screen {
  flex-direction: row;
  display: none; }
  .presscenter-main .first-screen.active {
    display: flex; }
  .presscenter-main .first-screen .left {
    width: 50%;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0.4375rem 0.4375rem 0.3125rem rgba(0, 0, 0, 0.1);
    z-index: 10;
    box-sizing: border-box; }
    .presscenter-main .first-screen .left .wrap {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 88%;
      padding-left: 9.375rem;
      padding-right: 5rem; }
      .presscenter-main .first-screen .left .wrap .pagename {
        font-size: 3rem;
        color: #00aeef;
        font-weight: 700; }
      .presscenter-main .first-screen .left .wrap .search input,
      .presscenter-main .first-screen .left .wrap .search select {
        appearance: none;
        margin-top: 2.1875rem;
        width: 16.875rem;
        border: 1px solid #b6b7b7;
        font-size: 1.5rem;
        color: #b6b7b7;
        padding: 0.9375rem 1.5625rem;
        box-sizing: border-box;
        border-radius: 0.9375rem; }
        .presscenter-main .first-screen .left .wrap .search input option,
        .presscenter-main .first-screen .left .wrap .search select option {
          color: #b6b7b7; }
        .presscenter-main .first-screen .left .wrap .search input.year,
        .presscenter-main .first-screen .left .wrap .search select.year {
          width: 8.4375rem; }
          .presscenter-main .first-screen .left .wrap .search input.year option,
          .presscenter-main .first-screen .left .wrap .search select.year option {
            width: 8.4375rem; }
        .presscenter-main .first-screen .left .wrap .search input.month,
        .presscenter-main .first-screen .left .wrap .search select.month {
          width: 10.625rem;
          margin-left: 0.625rem; }
      .presscenter-main .first-screen .left .wrap .search a {
        font-size: 1.5rem;
        color: #b6b7b7;
        text-decoration: none;
        margin-left: 0.625rem;
        display: inline-block;
        transform: scale(1);
        transition: 0.3s; }
        .presscenter-main .first-screen .left .wrap .search a.hide {
          visibility: hidden;
          opacity: 0;
          transform: scale(0);
          width: 0; }
      .presscenter-main .first-screen .left .wrap .presscenter-select .pic {
        margin-top: 2.1875rem; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .pic img {
          max-height: 18.75rem;
          width: 100%;
          object-fit: cover;
          object-position: 50% 50%; }
      .presscenter-main .first-screen .left .wrap .presscenter-select .new-presscenter {
        font-size: 3rem;
        color: #00aeef;
        font-weight: 700;
        margin-top: 2.5rem; }
      .presscenter-main .first-screen .left .wrap .presscenter-select .category {
        font-size: 1.25rem;
        color: #b7b7b7;
        line-height: 3.75rem; }
      .presscenter-main .first-screen .left .wrap .presscenter-select .title {
        font-size: 2.125rem;
        color: #00aeef;
        font-weight: 700;
        line-height: 2.625rem; }
      .presscenter-main .first-screen .left .wrap .presscenter-select .desc {
        font-size: 1.3125rem;
        color: #000;
        line-height: 1.5625rem;
        margin-top: 0.625rem; }
      .presscenter-main .first-screen .left .wrap .presscenter-select .date {
        font-size: 1.5rem;
        color: #b7b7b7;
        line-height: 3.125rem;
        margin-top: 0.625rem; }
      .presscenter-main .first-screen .left .wrap .presscenter-select .line {
        width: 100%;
        height: 1px;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
        background-color: #b6b7b7; }
      .presscenter-main .first-screen .left .wrap .presscenter-select .profile {
        display: flex;
        flex-direction: row;
        align-items: center; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .profile .photo {
          width: 6.875rem;
          height: 6.875rem;
          border-radius: 50%;
          margin-right: 1.25rem; }
          .presscenter-main .first-screen .left .wrap .presscenter-select .profile .photo img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: 50% 50%; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .profile .texts {
          color: #1d1d1b; }
          .presscenter-main .first-screen .left .wrap .presscenter-select .profile .texts .name {
            font-size: 1.5rem;
            line-height: 1.8125rem;
            font-weight: 700; }
          .presscenter-main .first-screen .left .wrap .presscenter-select .profile .texts .position {
            font-size: 1rem;
            line-height: 1.1875rem;
            margin-top: 0.625rem; }
          .presscenter-main .first-screen .left .wrap .presscenter-select .profile .texts .contact {
            font-size: 1rem;
            line-height: 1.1875rem;
            margin-top: 0.625rem; }
            .presscenter-main .first-screen .left .wrap .presscenter-select .profile .texts .contact a {
              margin-left: 0.625rem; }
              .presscenter-main .first-screen .left .wrap .presscenter-select .profile .texts .contact a img {
                width: 1.3125rem; }
  .presscenter-main .first-screen .right {
    width: 50%; }
    .presscenter-main .first-screen .right .presscenter-list {
      width: 46.25rem;
      margin-left: 8.125rem;
      padding-left: 4.375rem;
      box-sizing: border-box;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      position: relative; }
      .presscenter-main .first-screen .right .presscenter-list .line {
        position: absolute;
        top: 7.625rem;
        left: 0.4375rem;
        width: 1px;
        height: auto;
        background-color: #b6b7b7;
        transition: height 1s; }
      .presscenter-main .first-screen .right .presscenter-list .presscenter-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1.875rem;
        position: relative;
        cursor: pointer; }
        .presscenter-main .first-screen .right .presscenter-list .presscenter-item .pic {
          width: 16.25rem;
          height: 13.75rem;
          filter: none;
          transition: 0.25s; }
          .presscenter-main .first-screen .right .presscenter-list .presscenter-item .pic img {
            width: 16.25rem;
            height: 13.75rem;
            object-fit: cover; }
        .presscenter-main .first-screen .right .presscenter-list .presscenter-item .texts {
          margin-left: 3.125rem; }
          .presscenter-main .first-screen .right .presscenter-list .presscenter-item .texts .category {
            font-size: 0.9375rem;
            color: #b7b7b7; }
          .presscenter-main .first-screen .right .presscenter-list .presscenter-item .texts .title {
            font-size: 1.875rem;
            color: #00aeef;
            font-weight: 700;
            margin-top: 1.25rem; }
          .presscenter-main .first-screen .right .presscenter-list .presscenter-item .texts .desc {
            font-size: 1rem;
            color: #000;
            margin-top: 0.9375rem; }
          .presscenter-main .first-screen .right .presscenter-list .presscenter-item .texts .date {
            font-size: 1.125rem;
            color: #b7b7b7;
            margin-top: 1.25rem; }
        .presscenter-main .first-screen .right .presscenter-list .presscenter-item .dot {
          position: absolute;
          top: 0;
          left: -4.375rem;
          bottom: 0;
          margin: auto;
          width: 1rem;
          height: 1rem;
          background-color: #b6b7b7;
          border-radius: 50%; }
        .presscenter-main .first-screen .right .presscenter-list .presscenter-item:after {
          content: '';
          position: absolute;
          top: 0;
          left: -4.375rem;
          bottom: 0;
          margin: auto;
          width: 4.375rem;
          height: 0.0625rem;
          background-color: #b6b7b7; }
        .presscenter-main .first-screen .right .presscenter-list .presscenter-item:hover .pic {
          filter: contrast(0.3) brightness(1.5); }

.presscenter-main .search-result {
  display: none; }
  .presscenter-main .search-result.active {
    display: block; }
  .presscenter-main .search-result .header {
    width: 100%;
    padding-top: 4.375rem;
    padding-left: 9.375rem;
    box-sizing: border-box;
    position: relative; }
    .presscenter-main .search-result .header .pagename {
      font-size: 3rem;
      color: #00aeef;
      font-weight: 700; }
    .presscenter-main .search-result .header .search input,
    .presscenter-main .search-result .header .search select {
      appearance: none;
      margin-top: 2.1875rem;
      width: 16.875rem;
      border: 1px solid #b6b7b7;
      font-size: 1.5rem;
      color: #b6b7b7;
      padding: 0.9375rem 1.5625rem;
      box-sizing: border-box;
      border-radius: 0.9375rem; }
      .presscenter-main .search-result .header .search input option,
      .presscenter-main .search-result .header .search select option {
        color: #b6b7b7; }
      .presscenter-main .search-result .header .search input.year,
      .presscenter-main .search-result .header .search select.year {
        width: 8.4375rem; }
        .presscenter-main .search-result .header .search input.year option,
        .presscenter-main .search-result .header .search select.year option {
          width: 8.4375rem; }
      .presscenter-main .search-result .header .search input.month,
      .presscenter-main .search-result .header .search select.month {
        width: 10.625rem;
        margin-left: 0.625rem; }
    .presscenter-main .search-result .header .search a {
      font-size: 1.5rem;
      color: #b6b7b7;
      text-decoration: none;
      margin-left: 0.625rem;
      display: inline-block;
      transform: scale(1);
      transition: 0.3s; }
      .presscenter-main .search-result .header .search a.hide {
        visibility: hidden;
        opacity: 0;
        transform: scale(0);
        width: 0; }
    .presscenter-main .search-result .header .logo {
      width: 12.375rem;
      height: 10.25rem;
      position: absolute;
      bottom: 0;
      right: 6.25rem; }
  .presscenter-main .search-result .main {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .presscenter-main .search-result .main .left {
      width: 50%; }
      .presscenter-main .search-result .main .left .presscenter-list {
        width: 46.25rem;
        margin-left: 9.375rem;
        padding-left: 4.375rem;
        box-sizing: border-box;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        position: relative; }
        .presscenter-main .search-result .main .left .presscenter-list .line {
          position: absolute;
          top: 7.625rem;
          left: 0.4375rem;
          width: 1px;
          height: auto;
          background-color: #b6b7b7;
          transition: height 1s; }
        .presscenter-main .search-result .main .left .presscenter-list .presscenter-item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 1.875rem;
          position: relative;
          cursor: pointer; }
          .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .pic {
            width: 16.25rem;
            height: 13.75rem;
            filter: none;
            transition: 0.25s; }
            .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .pic img {
              width: 16.25rem;
              height: 13.75rem;
              object-fit: cover; }
          .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .texts {
            margin-left: 3.125rem; }
            .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .texts .category {
              font-size: 0.9375rem;
              color: #b7b7b7; }
            .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .texts .title {
              font-size: 1.875rem;
              color: #00aeef;
              font-weight: 700;
              margin-top: 1.25rem; }
            .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .texts .desc {
              font-size: 1rem;
              color: #000;
              margin-top: 0.9375rem; }
            .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .texts .date {
              font-size: 1.125rem;
              color: #b7b7b7;
              margin-top: 1.25rem; }
          .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .dot {
            position: absolute;
            top: 0;
            left: -4.375rem;
            bottom: 0;
            margin: auto;
            width: 1rem;
            height: 1rem;
            background-color: #b6b7b7;
            border-radius: 50%; }
          .presscenter-main .search-result .main .left .presscenter-list .presscenter-item:after {
            content: '';
            position: absolute;
            top: 0;
            left: -4.375rem;
            bottom: 0;
            margin: auto;
            width: 4.375rem;
            height: 0.0625rem;
            background-color: #b6b7b7; }
          .presscenter-main .search-result .main .left .presscenter-list .presscenter-item:hover .pic {
            filter: contrast(0.3) brightness(1.5); }
    .presscenter-main .search-result .main .right {
      width: 50%; }
      .presscenter-main .search-result .main .right .presscenter-list {
        width: 46.25rem;
        margin-left: 9.375rem;
        padding-left: 0;
        box-sizing: border-box;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        position: relative; }
        .presscenter-main .search-result .main .right .presscenter-list .line {
          position: absolute;
          top: 7.625rem;
          left: -3.9375rem;
          width: 1px;
          height: auto;
          background-color: #b6b7b7;
          transition: height 1s; }
        .presscenter-main .search-result .main .right .presscenter-list .presscenter-item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 1.875rem;
          position: relative;
          cursor: pointer; }
          .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .pic {
            width: 16.25rem;
            height: 13.75rem;
            filter: none;
            transition: 0.25s; }
            .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .pic img {
              width: 16.25rem;
              height: 13.75rem;
              object-fit: cover; }
          .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .texts {
            margin-left: 3.125rem; }
            .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .texts .category {
              font-size: 0.9375rem;
              color: #b7b7b7; }
            .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .texts .title {
              font-size: 1.875rem;
              color: #00aeef;
              font-weight: 700;
              margin-top: 1.25rem; }
            .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .texts .desc {
              font-size: 1rem;
              color: #000;
              margin-top: 0.9375rem; }
            .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .texts .date {
              font-size: 1.125rem;
              color: #b7b7b7;
              margin-top: 1.25rem; }
          .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .dot {
            position: absolute;
            top: 0;
            left: -4.375rem;
            bottom: 0;
            margin: auto;
            width: 1rem;
            height: 1rem;
            background-color: #b6b7b7;
            border-radius: 50%; }
          .presscenter-main .search-result .main .right .presscenter-list .presscenter-item:after {
            content: '';
            position: absolute;
            top: 0;
            left: -4.375rem;
            bottom: 0;
            margin: auto;
            width: 4.375rem;
            height: 0.0625rem;
            background-color: #b6b7b7; }
          .presscenter-main .search-result .main .right .presscenter-list .presscenter-item:hover .pic {
            filter: contrast(0.3) brightness(1.5); }

.presscenter-main .presscenter-single {
  display: none; }
  .presscenter-main .presscenter-single.active {
    display: flex; }
  .presscenter-main .presscenter-single .left {
    width: 63%;
    padding-left: 12.5rem;
    padding-right: 7.1875rem;
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    box-sizing: border-box; }
    .presscenter-main .presscenter-single .left .pagename {
      font-size: 3rem;
      color: #00aeef;
      font-weight: 700; }
    .presscenter-main .presscenter-single .left .pic {
      margin-top: 1.5625rem;
      width: 100%;
      height: 25rem; }
      .presscenter-main .presscenter-single .left .pic img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left center; }
    .presscenter-main .presscenter-single .left .meta {
      width: 100%;
      height: auto;
      margin-top: 2.5rem;
      padding-bottom: 2.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #b6b7b7; }
      .presscenter-main .presscenter-single .left .meta .text_wrap {
        margin-bottom: 1.25rem; }
        .presscenter-main .presscenter-single .left .meta .text_wrap .category {
          font-size: 1rem;
          color: #b7b7b7;
          line-height: 3rem; }
        .presscenter-main .presscenter-single .left .meta .text_wrap .title {
          font-size: 3.0625rem;
          color: #00aeef;
          line-height: 3.625rem;
          font-weight: 700; }
    .presscenter-main .presscenter-single .left .presscenter-single-text {
      margin-top: 1.875rem;
      color: #000;
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding-bottom: 1.875rem;
      border-bottom: 1px solid #b6b7b7; }
    .presscenter-main .presscenter-single .left .footer {
      margin-top: 1.875rem;
      margin-left: 0.625rem; }
      .presscenter-main .presscenter-single .left .footer .social {
        color: #9bb3be;
        font-size: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .presscenter-main .presscenter-single .left .footer .social span {
          margin-right: 0.625rem; }
        .presscenter-main .presscenter-single .left .footer .social .icon {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          background-color: #00aeef;
          margin-right: 0.3125rem;
          padding: 0.0625rem;
          box-sizing: border-box;
          transition: 0.3s; }
          .presscenter-main .presscenter-single .left .footer .social .icon:hover {
            background-color: #009edf; }
  .presscenter-main .presscenter-single .right {
    width: 37%;
    height: 100vh;
    position: sticky;
    top: 0;
    right: 0;
    z-index: 10;
    background-color: #00aeef; }
    .presscenter-main .presscenter-single .right .logo {
      width: 10.8125rem;
      height: 8.9375rem;
      position: absolute;
      top: 3.125rem;
      right: 5rem; }
    .presscenter-main .presscenter-single .right .presscenter-list-small {
      padding-top: 12.5rem;
      padding-left: 4.375rem;
      box-sizing: border-box; }
      .presscenter-main .presscenter-single .right .presscenter-list-small .line {
        position: absolute;
        top: 15.25rem;
        left: 2.9375rem;
        width: 1px;
        height: auto;
        background-color: #fff;
        transition: height 0.3s; }
      .presscenter-main .presscenter-single .right .presscenter-list-small .presscenter-item {
        display: flex;
        flex-direction: column;
        margin-top: 1.875rem;
        position: relative;
        cursor: pointer; }
        .presscenter-main .presscenter-single .right .presscenter-list-small .presscenter-item .texts {
          margin-left: 0.625rem; }
          .presscenter-main .presscenter-single .right .presscenter-list-small .presscenter-item .texts .title {
            font-size: 2rem;
            color: #fff;
            font-weight: 700;
            line-height: 2.375rem; }
          .presscenter-main .presscenter-single .right .presscenter-list-small .presscenter-item .texts .category {
            font-size: 1rem;
            color: #fff;
            line-height: 1.875rem; }
          .presscenter-main .presscenter-single .right .presscenter-list-small .presscenter-item .texts .date {
            font-size: 1.1875rem;
            color: #fff;
            line-height: 1.875rem; }
        .presscenter-main .presscenter-single .right .presscenter-list-small .presscenter-item .dot {
          position: absolute;
          top: 0.8125rem;
          left: -1.875rem;
          width: 1rem;
          height: 1rem;
          background-color: #fff;
          border-radius: 50%; }

.direction-details-main {
  display: flex; }
  .direction-details-main .left {
    background-color: #00aeef;
    width: 45%;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0.4375rem 0.4375rem 0.3125rem rgba(0, 0, 0, 0.1);
    z-index: 10;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center; }
    .direction-details-main .left .logo {
      width: 10.8125rem;
      height: 8.9375rem;
      position: absolute;
      top: 1.875rem;
      right: 3.125rem; }
    .direction-details-main .left .wrap {
      width: 75%;
      margin-left: 4rem; }
      .direction-details-main .left .wrap .title {
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        font-size: 3rem;
        margin-bottom: 1.875rem; }
      .direction-details-main .left .wrap form select {
        appearance: none;
        margin-bottom: 1.5625rem;
        width: 31.25rem;
        border: 1px solid #b6b7b7;
        font-size: 1.5rem;
        color: #b6b7b7;
        padding: 0.9375rem 1.5625rem;
        box-sizing: border-box;
        border-radius: 0.9375rem;
        background-image: url(../img/icon_select_arrow.svg);
        background-position: 95% 50%;
        background-size: 0.9375rem;
        background-repeat: no-repeat; }
        .direction-details-main .left .wrap form select option {
          color: #b6b7b7; }
      .direction-details-main .left .wrap .items {
        position: relative; }
        .direction-details-main .left .wrap .items .line {
          width: 1px;
          height: calc(100% - 2.8rem);
          background-color: #fff;
          position: absolute;
          top: 0;
          left: 0.375rem;
          bottom: 0;
          margin: auto; }
        .direction-details-main .left .wrap .items .item {
          font-family: 'PT Sans', sans-serif;
          font-size: 1.53125rem;
          margin-bottom: 1.5625rem;
          padding-left: 1.875rem;
          position: relative;
          cursor: pointer; }
          .direction-details-main .left .wrap .items .item .dot {
            width: 1rem;
            height: 1rem;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto; }
  .direction-details-main .right {
    width: 55%;
    padding-top: 4.375rem;
    box-sizing: border-box;
    position: relative; }
    .direction-details-main .right .screen {
      display: none; }
      .direction-details-main .right .screen > .title {
        color: #00aeef;
        font-size: 3rem;
        font-family: 'PT Sans Caption', sans-serif;
        font-weight: 700;
        line-height: 3.59375rem;
        margin-bottom: 1.25rem;
        margin-top: 0rem;
        padding-left: 5rem;
        padding-right: 5rem; }
      .direction-details-main .right .screen > p {
        color: #1d1d1b;
        font-size: 1.4375rem;
        font-family: 'PT Sans', sans-serif;
        margin: 0.625rem 0 1.875rem 0;
        padding-left: 5rem;
        padding-right: 5rem; }
      .direction-details-main .right .screen .title2 {
        color: #1d1d1b;
        font-size: 1.6875rem;
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 2rem;
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
        position: relative; }
        .direction-details-main .right .screen .title2 span {
          display: inline-block;
          background-color: #fff;
          position: relative;
          z-index: 2;
          padding-right: 0.625rem; }
        .direction-details-main .right .screen .title2:after {
          content: '';
          width: 90%;
          height: 1px;
          background-color: #ccc;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          z-index: 1; }
      .direction-details-main .right .screen .contacts {
        padding-left: 5rem;
        padding-right: 5rem; }
        .direction-details-main .right .screen .contacts .items {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between; }
          .direction-details-main .right .screen .contacts .items .separate {
            color: #b5b6b6;
            font-size: 1.5rem;
            font-family: 'PT Sans', sans-serif;
            font-weight: 400;
            line-height: 2rem;
            margin-bottom: 1.25rem;
            margin-top: 1.25rem;
            text-align: center;
            width: 100%;
            position: relative;
            cursor: pointer;
            display: none; }
            .direction-details-main .right .screen .contacts .items .separate.hide {
              display: none; }
          .direction-details-main .right .screen .contacts .items .wrap {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between; }
          .direction-details-main .right .screen .contacts .items .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 47%;
            margin-bottom: 1.875rem; }
            .direction-details-main .right .screen .contacts .items .item .photo {
              width: 7.5rem;
              height: 7.5rem;
              margin-right: 0.9375rem;
              border-radius: 50%;
              overflow: hidden;
              background-color: #57575c; }
              .direction-details-main .right .screen .contacts .items .item .photo img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 50%; }
            .direction-details-main .right .screen .contacts .items .item .texts {
              color: #1d1d1b;
              width: calc(100% - 7.5rem - .9375rem); }
              .direction-details-main .right .screen .contacts .items .item .texts .name {
                font-size: 1.5rem;
                line-height: 1.8125rem;
                font-weight: 700; }
              .direction-details-main .right .screen .contacts .items .item .texts .position {
                font-family: 'PT Sans', sans-serif;
                font-size: 1rem;
                line-height: 1.1875rem;
                margin-top: 0.625rem; }
              .direction-details-main .right .screen .contacts .items .item .texts .contact {
                font-family: 'PT Sans', sans-serif;
                font-size: 1rem;
                line-height: 1.1875rem;
                margin-top: 0.625rem; }
                .direction-details-main .right .screen .contacts .items .item .texts .contact a {
                  margin-left: 0.625rem; }
                  .direction-details-main .right .screen .contacts .items .item .texts .contact a img {
                    width: 1.3125rem; }
      .direction-details-main .right .screen .brands {
        padding-left: 5rem;
        padding-right: 5rem; }
        .direction-details-main .right .screen .brands .logos {
          width: 95%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start; }
          .direction-details-main .right .screen .brands .logos .separate {
            color: #00aeef;
            font-size: 1.5rem;
            font-family: 'PT Sans', sans-serif;
            font-weight: 700;
            line-height: 2rem;
            margin-bottom: 1.25rem;
            margin-top: 1.25rem;
            width: 100%;
            position: relative;
            cursor: pointer; }
            .direction-details-main .right .screen .brands .logos .separate.hide {
              display: none; }
            .direction-details-main .right .screen .brands .logos .separate span {
              display: inline-block;
              background-color: #fff;
              position: relative;
              z-index: 2;
              padding-right: 0.625rem; }
            .direction-details-main .right .screen .brands .logos .separate:after {
              content: '';
              width: 100%;
              height: 1px;
              background-color: #1d1d1b;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              z-index: 1; }
          .direction-details-main .right .screen .brands .logos .logos-hide {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            opacity: 0;
            display: none;
            transition: 1s; }
            .direction-details-main .right .screen .brands .logos .logos-hide.active {
              opacity: 1;
              display: flex !important; }
          .direction-details-main .right .screen .brands .logos .item {
            width: 20%;
            height: 10.625rem;
            border: 1px solid #e1e1e1;
            box-sizing: border-box;
            position: relative; }
            .direction-details-main .right .screen .brands .logos .item:hover {
              border: 1px solid #00aeef;
              box-shadow: 0 0 1.25rem rgba(0, 105, 241, 0.2); }
            .direction-details-main .right .screen .brands .logos .item img {
              width: 80%;
              height: 80%;
              object-fit: contain;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto; }
      .direction-details-main .right .screen .news,
      .direction-details-main .right .screen .promos,
      .direction-details-main .right .screen .events {
        padding-left: 5rem;
        padding-right: 5rem; }
        .direction-details-main .right .screen .news .slider,
        .direction-details-main .right .screen .promos .slider,
        .direction-details-main .right .screen .events .slider {
          width: 100%;
          height: auto;
          overflow: visible; }
          .direction-details-main .right .screen .news .slider .slick-next,
          .direction-details-main .right .screen .news .slider .slick-prev,
          .direction-details-main .right .screen .promos .slider .slick-next,
          .direction-details-main .right .screen .promos .slider .slick-prev,
          .direction-details-main .right .screen .events .slider .slick-next,
          .direction-details-main .right .screen .events .slider .slick-prev {
            width: 1.25rem;
            height: 1.25rem;
            top: 30%;
            z-index: 10; }
            .direction-details-main .right .screen .news .slider .slick-next:before,
            .direction-details-main .right .screen .news .slider .slick-prev:before,
            .direction-details-main .right .screen .promos .slider .slick-next:before,
            .direction-details-main .right .screen .promos .slider .slick-prev:before,
            .direction-details-main .right .screen .events .slider .slick-next:before,
            .direction-details-main .right .screen .events .slider .slick-prev:before {
              display: none; }
          .direction-details-main .right .screen .news .slider .slick-disabled,
          .direction-details-main .right .screen .promos .slider .slick-disabled,
          .direction-details-main .right .screen .events .slider .slick-disabled {
            opacity: 0; }
          .direction-details-main .right .screen .news .slider .slick-prev,
          .direction-details-main .right .screen .promos .slider .slick-prev,
          .direction-details-main .right .screen .events .slider .slick-prev {
            background: url(../img/arrow-left.svg) 50% 50% no-repeat;
            background-size: contain;
            left: -2.8125rem; }
          .direction-details-main .right .screen .news .slider .slick-next,
          .direction-details-main .right .screen .promos .slider .slick-next,
          .direction-details-main .right .screen .events .slider .slick-next {
            background: url(../img/arrow-right.svg) 50% 50% no-repeat;
            background-size: contain;
            right: 0; }
          .direction-details-main .right .screen .news .slider .slide,
          .direction-details-main .right .screen .promos .slider .slide,
          .direction-details-main .right .screen .events .slider .slide {
            height: auto;
            margin-bottom: 0.625rem; }
            .direction-details-main .right .screen .news .slider .slide a,
            .direction-details-main .right .screen .promos .slider .slide a,
            .direction-details-main .right .screen .events .slider .slide a {
              display: flex;
              flex-direction: column;
              text-decoration: none;
              width: 87%; }
              .direction-details-main .right .screen .news .slider .slide a img,
              .direction-details-main .right .screen .promos .slider .slide a img,
              .direction-details-main .right .screen .events .slider .slide a img {
                width: 15.625rem;
                height: 15.625rem;
                object-fit: cover;
                object-position: 50% 50%; }
              .direction-details-main .right .screen .news .slider .slide a .title,
              .direction-details-main .right .screen .promos .slider .slide a .title,
              .direction-details-main .right .screen .events .slider .slide a .title {
                font-size: 1.875rem;
                color: #00aeef;
                line-height: 2.0625rem;
                font-weight: 700;
                margin-top: 0.9375rem; }
              .direction-details-main .right .screen .news .slider .slide a .desk,
              .direction-details-main .right .screen .promos .slider .slide a .desk,
              .direction-details-main .right .screen .events .slider .slide a .desk {
                font-size: 1rem;
                color: #000000;
                margin: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 0.625rem; }
              .direction-details-main .right .screen .news .slider .slide a .date,
              .direction-details-main .right .screen .promos .slider .slide a .date,
              .direction-details-main .right .screen .events .slider .slide a .date {
                font-size: 1.125rem;
                color: #b7b7b7;
                margin-top: 0.625rem; }
      .direction-details-main .right .screen .events {
        background-color: #f2f2f2;
        padding-left: 5rem;
        padding-right: 5rem;
        padding-bottom: 3.125rem;
        padding-top: 1.25rem;
        margin-top: 1.875rem; }
        .direction-details-main .right .screen .events .slider {
          width: 100%;
          height: auto; }
        .direction-details-main .right .screen .events .title2 span {
          background-color: transparent; }
        .direction-details-main .right .screen .events .title2:after {
          display: none; }
      .direction-details-main .right .screen .shadow {
        background-color: rgba(124, 124, 124, 0.36);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        z-index: 3;
        display: none; }
      .direction-details-main .right .screen .popup {
        background-color: #fff;
        width: 47.1875rem;
        height: 46.5625rem;
        position: fixed;
        top: 0;
        right: 7%;
        bottom: 0;
        margin: auto;
        border-radius: 1.25rem;
        padding: 1.875rem;
        box-sizing: border-box;
        z-index: 4;
        display: none; }
        .direction-details-main .right .screen .popup .title {
          color: #00aeef;
          font-family: 'PT Sans Caption', sans-serif;
          font-weight: 700;
          font-size: 3rem; }
        .direction-details-main .right .screen .popup .title2 {
          color: #b6b7b7;
          font-family: 'PT Sans', sans-serif;
          font-weight: 700;
          font-size: 2.1875rem;
          text-transform: none;
          line-height: initial;
          margin-bottom: initial;
          margin-top: initial;
          position: relative; }
          .direction-details-main .right .screen .popup .title2:after {
            display: none; }
        .direction-details-main .right .screen .popup .form {
          padding-bottom: 3.125rem; }
          .direction-details-main .right .screen .popup .form form .inputs {
            display: flex;
            flex-direction: column;
            margin-top: 1.875rem; }
            .direction-details-main .right .screen .popup .form form .inputs input {
              width: 100%;
              height: 3.625rem;
              border: 1px solid #b6b7b7;
              border-radius: 0.625rem;
              box-shadow: none;
              color: #1d1d1b;
              font-family: 'PT Sans', sans-serif;
              font-weight: 400;
              font-size: 1.5rem;
              padding: 0 1.25rem;
              box-sizing: border-box;
              margin-bottom: 1.25rem; }
            .direction-details-main .right .screen .popup .form form .inputs textarea {
              width: 100%;
              border: 1px solid #b6b7b7;
              border-radius: 0.625rem;
              box-shadow: none;
              color: #1d1d1b;
              font-family: 'PT Sans', sans-serif;
              font-weight: 400;
              font-size: 1.5rem;
              padding: 0.625rem 1.25rem 0 1.25rem;
              box-sizing: border-box;
              margin-bottom: 1.25rem; }
          .direction-details-main .right .screen .popup .form form label {
            color: #1d1d1b;
            font-family: 'PT Sans', sans-serif;
            font-weight: 400;
            font-size: 1.1875rem;
            display: flex;
            flex-direction: row; }
            .direction-details-main .right .screen .popup .form form label input {
              width: 2.75rem;
              height: 1.6875rem;
              border: 1px solid #b6b7b7;
              border-radius: 0.625rem;
              box-shadow: none; }
            .direction-details-main .right .screen .popup .form form label p {
              margin: 0;
              margin-left: 0.625rem; }
              .direction-details-main .right .screen .popup .form form label p a {
                color: #1d1d1b; }
          .direction-details-main .right .screen .popup .form form .bottom {
            background-color: transparent;
            margin-top: 2.5rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center; }
            .direction-details-main .right .screen .popup .form form .bottom input[type="submit"] {
              background-color: #00aeef;
              color: #fff;
              font-family: 'PT Sans Caption', sans-serif;
              font-weight: 700;
              font-size: 1.5625rem;
              width: 17.0625rem;
              height: 3.625rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0.625rem;
              box-shadow: none;
              border: none;
              cursor: pointer; }
            .direction-details-main .right .screen .popup .form form .bottom .captcha {
              width: 21.125rem; }
              .direction-details-main .right .screen .popup .form form .bottom .captcha img {
                width: 100%; }

.brand-main {
  display: flex; }
  .brand-main .left {
    background-color: #00aeef;
    width: 45%;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0.4375rem 0.4375rem 0.3125rem rgba(0, 0, 0, 0.1);
    z-index: 10;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center; }
    .brand-main .left .logo {
      width: 10.8125rem;
      height: 8.9375rem;
      position: absolute;
      top: 1.875rem;
      right: 3.125rem; }
    .brand-main .left .wrap {
      width: 76%;
      padding-bottom: 3.75rem;
      margin-top: 3.125rem;
      margin-left: 4rem;
      max-height: calc(60% - 3.125rem); }
      .brand-main .left .wrap:after {
        content: '';
        background: rgba(0, 175, 239, 0);
        background: linear-gradient(0deg, #00aeef 0, #00aeef 73%, rgba(246, 246, 246, 0) 100%);
        width: 100%;
        height: 14%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        pointer-events: none;
        z-index: 10; }
      .brand-main .left .wrap .title {
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
        margin-bottom: 1.875rem; }
      .brand-main .left .wrap form select {
        appearance: none;
        margin-bottom: 1.5625rem;
        width: 31.25rem;
        border: 1px solid #b6b7b7;
        font-size: 1.5rem;
        color: #b6b7b7;
        padding: 0.9375rem 1.5625rem;
        box-sizing: border-box;
        border-radius: 0.9375rem;
        background-image: url(../img/icon_select_arrow.svg);
        background-position: 95% 50%;
        background-size: 0.9375rem;
        background-repeat: no-repeat; }
        .brand-main .left .wrap form select option {
          color: #b6b7b7; }
      .brand-main .left .wrap .items {
        position: relative; }
        .brand-main .left .wrap .items .line {
          width: 1px;
          height: calc(100% - 2rem);
          background-color: #fff;
          position: absolute;
          top: 0;
          left: 0.4375rem;
          bottom: 0;
          margin: auto; }
        .brand-main .left .wrap .items .item {
          font-family: 'PT Sans', sans-serif;
          font-size: 1.5rem;
          margin-bottom: 0.9375rem;
          padding-left: 1.875rem;
          position: relative;
          cursor: pointer; }
          .brand-main .left .wrap .items .item p {
            margin: 0;
            display: inline-block; }
          .brand-main .left .wrap .items .item span {
            display: inline-block;
            font-size: 1.25rem;
            transition: 0.2s; }
            .brand-main .left .wrap .items .item span.active {
              transform: rotate(-90deg); }
          .brand-main .left .wrap .items .item .dot {
            width: 1rem;
            height: 1rem;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto; }
          .brand-main .left .wrap .items .item.active .dot:before {
            content: '';
            width: 0.375rem;
            height: 0.375rem;
            background-color: #00aeef;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto; }
        .brand-main .left .wrap .items .toggle-list-2 {
          margin-left: 2.1875rem;
          position: relative;
          display: none; }
          .brand-main .left .wrap .items .toggle-list-2 .line {
            width: 1px;
            height: calc(100% - 1.375rem);
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0.3125rem;
            bottom: 0;
            margin: auto; }
          .brand-main .left .wrap .items .toggle-list-2 .item {
            margin-bottom: 0.625rem; }
            .brand-main .left .wrap .items .toggle-list-2 .item .dot {
              width: 0.6875rem;
              height: 0.6875rem; }
            .brand-main .left .wrap .items .toggle-list-2 .item.active .dot:before {
              width: 0.3125rem;
              height: 0.3125rem; }
        .brand-main .left .wrap .items .toggle-list-3 {
          margin-left: 2.1875rem;
          display: none; }
          .brand-main .left .wrap .items .toggle-list-3 .item {
            padding-left: 1.25rem; }
  .brand-main .right {
    width: 55%;
    padding-top: 4.375rem;
    box-sizing: border-box;
    position: relative; }
    .brand-main .right .screen {
      display: none; }
      .brand-main .right .screen .logo-brand {
        width: 15.625rem;
        height: 10rem;
        padding-left: 5rem;
        padding-right: 5rem; }
        .brand-main .right .screen .logo-brand img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 0% 0%; }
      .brand-main .right .screen > .title {
        color: #00aeef;
        font-size: 2.75rem;
        font-family: 'PT Sans Caption', sans-serif;
        font-weight: 700;
        line-height: 3.28125rem;
        margin-bottom: 1.25rem;
        margin-top: 0rem;
        padding-left: 5rem;
        padding-right: 5rem; }
      .brand-main .right .screen .title2 {
        color: #1d1d1b;
        font-size: 1.6875rem;
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 2rem;
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
        position: relative; }
        .brand-main .right .screen .title2 span {
          display: inline-block;
          background-color: #fff;
          position: relative;
          z-index: 2;
          padding-right: 0.625rem; }
        .brand-main .right .screen .title2:after {
          content: '';
          width: 90%;
          height: 1px;
          background-color: #ccc;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          z-index: 1; }
      .brand-main .right .screen .video {
        padding-left: 5rem;
        padding-right: 5rem; }
        .brand-main .right .screen .video iframe {
          width: 40.25rem;
          height: 22rem; }
      .brand-main .right .screen .title_sm {
        color: #1d1d1b;
        font-size: 1.6875rem;
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 2rem;
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
        padding-left: 5rem;
        padding-right: 5rem; }
      .brand-main .right .screen > p {
        color: #1d1d1b;
        font-size: 1.375rem;
        line-height: 1.625rem;
        font-family: 'PT Sans', sans-serif;
        margin: 0.625rem 0 1.875rem 0;
        padding-left: 5rem;
        padding-right: 5rem; }
        .brand-main .right .screen > p a {
          color: #000;
          font-weight: 700;
          text-decoration: none; }
      .brand-main .right .screen ul {
        color: #1d1d1b;
        font-size: 1.375rem;
        line-height: 1.625rem;
        font-family: 'PT Sans', sans-serif;
        margin: 0.625rem 0 1.875rem 0;
        padding-left: 5rem;
        padding-right: 5rem; }
        .brand-main .right .screen ul li {
          list-style: none;
          position: relative;
          margin: 0.9375rem 0; }
          .brand-main .right .screen ul li a {
            color: #1d1d1b; }
          .brand-main .right .screen ul li:before {
            content: '';
            background-color: #00aeef;
            display: block;
            height: 0.375rem;
            width: 0.375rem;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: -1.25rem;
            bottom: 0;
            margin: auto; }
      .brand-main .right .screen ol {
        color: #1d1d1b;
        font-size: 1.375rem;
        line-height: 1.625rem;
        font-family: 'PT Sans', sans-serif;
        margin: 0.625rem 0 1.875rem 0;
        padding-left: 6.25rem;
        padding-right: 5rem; }
        .brand-main .right .screen ol li {
          position: relative;
          margin: 0.9375rem 0; }
          .brand-main .right .screen ol li a {
            color: #1d1d1b; }
      .brand-main .right .screen .cols {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 5rem;
        padding-right: 5rem; }
        .brand-main .right .screen .cols ul {
          padding-left: 0;
          padding-right: 0; }
        .brand-main .right .screen .cols .col-2 {
          width: 47%; }
      .brand-main .right .screen .pic {
        max-width: 100%;
        padding-left: 5rem;
        padding-right: 5rem; }
        .brand-main .right .screen .pic img {
          max-width: 100%; }
      .brand-main .right .screen .contacts {
        padding-left: 5rem;
        padding-right: 5rem; }
        .brand-main .right .screen .contacts .items {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between; }
          .brand-main .right .screen .contacts .items .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 47%;
            margin-bottom: 1.875rem; }
            .brand-main .right .screen .contacts .items .item .photo {
              width: 7.5rem;
              height: 7.5rem;
              margin-right: 0.9375rem;
              border-radius: 50%;
              overflow: hidden;
              background-color: #57575c; }
              .brand-main .right .screen .contacts .items .item .photo img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: 50% 50%; }
            .brand-main .right .screen .contacts .items .item .texts {
              color: #1d1d1b;
              width: calc(100% - 7.5rem - .9375rem); }
              .brand-main .right .screen .contacts .items .item .texts .name {
                font-size: 1.5rem;
                line-height: 1.8125rem;
                font-weight: 700; }
              .brand-main .right .screen .contacts .items .item .texts .position {
                font-family: 'PT Sans', sans-serif;
                font-size: 1rem;
                line-height: 1.1875rem;
                margin-top: 0.625rem; }
              .brand-main .right .screen .contacts .items .item .texts .contact {
                font-family: 'PT Sans', sans-serif;
                font-size: 1rem;
                line-height: 1.1875rem;
                margin-top: 0.625rem; }
                .brand-main .right .screen .contacts .items .item .texts .contact a {
                  margin-left: 0.625rem; }
                  .brand-main .right .screen .contacts .items .item .texts .contact a img {
                    width: 1.3125rem; }
      .brand-main .right .screen .brands {
        padding-left: 5rem;
        padding-right: 5rem; }
        .brand-main .right .screen .brands .logos {
          width: 95%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start; }
          .brand-main .right .screen .brands .logos .separate {
            color: #00aeef;
            font-size: 1.5rem;
            font-family: 'PT Sans', sans-serif;
            font-weight: 700;
            line-height: 2rem;
            margin-bottom: 1.25rem;
            margin-top: 1.25rem;
            width: 100%;
            position: relative;
            cursor: pointer; }
            .brand-main .right .screen .brands .logos .separate.hide {
              display: none; }
            .brand-main .right .screen .brands .logos .separate span {
              display: inline-block;
              background-color: #fff;
              position: relative;
              z-index: 2;
              padding-right: 0.625rem; }
            .brand-main .right .screen .brands .logos .separate:after {
              content: '';
              width: 100%;
              height: 1px;
              background-color: #1d1d1b;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              z-index: 1; }
          .brand-main .right .screen .brands .logos .logos-hide {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            opacity: 0;
            display: none;
            transition: 1s; }
            .brand-main .right .screen .brands .logos .logos-hide.active {
              opacity: 1;
              display: flex !important; }
          .brand-main .right .screen .brands .logos .item {
            width: 20%;
            height: 10.625rem;
            border: 1px solid #e1e1e1;
            box-sizing: border-box;
            position: relative; }
            .brand-main .right .screen .brands .logos .item:hover {
              border: 1px solid #00aeef;
              box-shadow: 0 0 1.25rem rgba(0, 105, 241, 0.2); }
            .brand-main .right .screen .brands .logos .item img {
              width: 80%;
              height: 80%;
              object-fit: contain;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto; }
      .brand-main .right .screen .news,
      .brand-main .right .screen .promos,
      .brand-main .right .screen .events {
        padding-left: 5rem;
        padding-right: 5rem; }
        .brand-main .right .screen .news .slider,
        .brand-main .right .screen .promos .slider,
        .brand-main .right .screen .events .slider {
          width: 100%;
          height: auto;
          overflow: visible; }
          .brand-main .right .screen .news .slider .slick-next,
          .brand-main .right .screen .news .slider .slick-prev,
          .brand-main .right .screen .promos .slider .slick-next,
          .brand-main .right .screen .promos .slider .slick-prev,
          .brand-main .right .screen .events .slider .slick-next,
          .brand-main .right .screen .events .slider .slick-prev {
            width: 1.25rem;
            height: 1.25rem;
            top: 30%;
            z-index: 10; }
            .brand-main .right .screen .news .slider .slick-next:before,
            .brand-main .right .screen .news .slider .slick-prev:before,
            .brand-main .right .screen .promos .slider .slick-next:before,
            .brand-main .right .screen .promos .slider .slick-prev:before,
            .brand-main .right .screen .events .slider .slick-next:before,
            .brand-main .right .screen .events .slider .slick-prev:before {
              display: none; }
          .brand-main .right .screen .news .slider .slick-disabled,
          .brand-main .right .screen .promos .slider .slick-disabled,
          .brand-main .right .screen .events .slider .slick-disabled {
            opacity: 0; }
          .brand-main .right .screen .news .slider .slick-prev,
          .brand-main .right .screen .promos .slider .slick-prev,
          .brand-main .right .screen .events .slider .slick-prev {
            background: url(../img/arrow-left.svg) 50% 50% no-repeat;
            background-size: contain;
            left: -2.8125rem; }
          .brand-main .right .screen .news .slider .slick-next,
          .brand-main .right .screen .promos .slider .slick-next,
          .brand-main .right .screen .events .slider .slick-next {
            background: url(../img/arrow-right.svg) 50% 50% no-repeat;
            background-size: contain;
            right: 0; }
          .brand-main .right .screen .news .slider .slide,
          .brand-main .right .screen .promos .slider .slide,
          .brand-main .right .screen .events .slider .slide {
            height: auto;
            margin-bottom: 0.625rem; }
            .brand-main .right .screen .news .slider .slide a,
            .brand-main .right .screen .promos .slider .slide a,
            .brand-main .right .screen .events .slider .slide a {
              display: flex;
              flex-direction: column;
              text-decoration: none;
              width: 87%; }
              .brand-main .right .screen .news .slider .slide a img,
              .brand-main .right .screen .promos .slider .slide a img,
              .brand-main .right .screen .events .slider .slide a img {
                width: 15.625rem;
                height: 15.625rem;
                object-fit: cover;
                object-position: 50% 50%; }
              .brand-main .right .screen .news .slider .slide a .title,
              .brand-main .right .screen .promos .slider .slide a .title,
              .brand-main .right .screen .events .slider .slide a .title {
                font-size: 1.875rem;
                color: #00aeef;
                line-height: 2.0625rem;
                font-weight: 700;
                margin-top: 0.9375rem; }
              .brand-main .right .screen .news .slider .slide a .desk,
              .brand-main .right .screen .promos .slider .slide a .desk,
              .brand-main .right .screen .events .slider .slide a .desk {
                font-size: 1rem;
                color: #000000;
                margin: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 0.625rem; }
              .brand-main .right .screen .news .slider .slide a .date,
              .brand-main .right .screen .promos .slider .slide a .date,
              .brand-main .right .screen .events .slider .slide a .date {
                font-size: 1.125rem;
                color: #b7b7b7;
                margin-top: 0.625rem; }
      .brand-main .right .screen .events {
        background-color: #f2f2f2;
        padding-left: 5rem;
        padding-right: 5rem;
        padding-bottom: 3.125rem;
        padding-top: 1.25rem;
        margin-top: 1.875rem; }
        .brand-main .right .screen .events .slider {
          width: 100%;
          height: auto; }
        .brand-main .right .screen .events .title2 span {
          background-color: transparent; }
        .brand-main .right .screen .events .title2:after {
          display: none; }
      .brand-main .right .screen .brand-shop {
        padding-left: 5rem;
        padding-right: 5rem;
        margin-top: 3.125rem; }
        .brand-main .right .screen .brand-shop .item-list {
          width: 100%;
          display: flex;
          flex-direction: column; }
          .brand-main .right .screen .brand-shop .item-list .item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: 1.875rem; }
            .brand-main .right .screen .brand-shop .item-list .item .photo {
              width: 21.875rem;
              height: 12.5rem; }
              .brand-main .right .screen .brand-shop .item-list .item .photo img {
                width: 100%;
                height: 100%;
                object-fit: contain; }
            .brand-main .right .screen .brand-shop .item-list .item .info {
              width: 58%;
              height: 12.5rem;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 1.25rem; }
              .brand-main .right .screen .brand-shop .item-list .item .info .title {
                color: #000000;
                font-size: 1.5rem; }
              .brand-main .right .screen .brand-shop .item-list .item .info .btn {
                color: #00aeef;
                font-size: 1.25rem;
                width: 15.5rem;
                height: 3.25rem;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border: 1px solid #00aeef;
                box-sizing: border-box;
                padding-left: 3.25rem;
                cursor: pointer;
                will-change: filter;
                transition: 0.2s; }
                .brand-main .right .screen .brand-shop .item-list .item .info .btn:hover {
                  filter: grayscale(1); }
                .brand-main .right .screen .brand-shop .item-list .item .info .btn:before {
                  content: '';
                  background: url(../img/shop/cart.svg) 50% 50% no-repeat;
                  background-size: 1.875rem;
                  background-color: #00aeef;
                  width: 3.25rem;
                  height: 3.25rem;
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  margin: auto; }
      .brand-main .right .screen .shadow {
        background-color: rgba(124, 124, 124, 0.36);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        z-index: 3;
        display: none; }
      .brand-main .right .screen .popup {
        background-color: #fff;
        width: 47.1875rem;
        height: 46.5625rem;
        position: fixed;
        top: 0;
        right: 7%;
        bottom: 0;
        margin: auto;
        border-radius: 1.25rem;
        padding: 1.875rem;
        box-sizing: border-box;
        z-index: 4;
        display: none; }
        .brand-main .right .screen .popup .title {
          color: #00aeef;
          font-family: 'PT Sans Caption', sans-serif;
          font-weight: 700;
          font-size: 3rem; }
        .brand-main .right .screen .popup .title2 {
          color: #b6b7b7;
          font-family: 'PT Sans', sans-serif;
          font-weight: 700;
          font-size: 2.1875rem;
          text-transform: none;
          line-height: initial;
          margin-bottom: initial;
          margin-top: initial;
          position: relative; }
          .brand-main .right .screen .popup .title2:after {
            display: none; }
        .brand-main .right .screen .popup .form {
          margin-top: 1.25rem;
          padding-bottom: 3.125rem; }
          .brand-main .right .screen .popup .form form .cart-list {
            margin-top: 1.25rem; }
            .brand-main .right .screen .popup .form form .cart-list .name {
              color: #000;
              font-size: 1.5rem; }
            .brand-main .right .screen .popup .form form .cart-list .count {
              margin-top: 1.25rem;
              height: 3.625rem;
              display: flex; }
              .brand-main .right .screen .popup .form form .cart-list .count .value {
                width: auto;
                height: 3.625rem;
                background-color: #00aeef;
                border-radius: 0.625rem;
                color: #fff;
                font-family: 'PT Sans', sans-serif;
                font-weight: 400;
                font-size: 2.25rem;
                padding: 0 1.25rem;
                box-sizing: border-box;
                display: inline-flex;
                justify-content: center;
                align-items: center; }
              .brand-main .right .screen .popup .form form .cart-list .count .arrows {
                display: inline-flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                height: 3.625rem;
                margin-left: 0.9375rem; }
                .brand-main .right .screen .popup .form form .cart-list .count .arrows .arrow_top {
                  /* background: url(../img/shop/arrow_top.svg) 50% 50% no-repeat;
                                        background-size: contain; */
                  width: 1.3125rem;
                  height: 0.8125rem;
                  cursor: pointer; }
                  .brand-main .right .screen .popup .form form .cart-list .count .arrows .arrow_top:hover .cls-1 {
                    stroke: #00aeef; }
                .brand-main .right .screen .popup .form form .cart-list .count .arrows .arrow_bottom {
                  /* background: url(../img/shop/arrow_bottom.svg) 50% 50% no-repeat;
                                        background-size: contain; */
                  width: 1.3125rem;
                  height: 0.8125rem;
                  cursor: pointer; }
                  .brand-main .right .screen .popup .form form .cart-list .count .arrows .arrow_bottom:hover .cls-1 {
                    stroke: #00aeef; }
          .brand-main .right .screen .popup .form form .inputs {
            display: flex;
            flex-direction: column;
            margin-top: 1.25rem; }
            .brand-main .right .screen .popup .form form .inputs input {
              width: 100%;
              height: 3.625rem;
              border: 1px solid #b6b7b7;
              border-radius: 0.625rem;
              box-shadow: none;
              color: #1d1d1b;
              font-family: 'PT Sans', sans-serif;
              font-weight: 400;
              font-size: 1.5rem;
              padding: 0 1.25rem;
              box-sizing: border-box;
              margin-bottom: 1.25rem; }
            .brand-main .right .screen .popup .form form .inputs textarea {
              width: 100%;
              border: 1px solid #b6b7b7;
              border-radius: 0.625rem;
              box-shadow: none;
              color: #1d1d1b;
              font-family: 'PT Sans', sans-serif;
              font-weight: 400;
              font-size: 1.5rem;
              padding: 0.625rem 1.25rem 0 1.25rem;
              box-sizing: border-box;
              margin-bottom: 1.25rem; }
          .brand-main .right .screen .popup .form form label {
            color: #1d1d1b;
            font-family: 'PT Sans', sans-serif;
            font-weight: 400;
            font-size: 1.1875rem;
            display: flex;
            flex-direction: row; }
            .brand-main .right .screen .popup .form form label input {
              width: 2.75rem;
              height: 1.6875rem;
              border: 1px solid #b6b7b7;
              border-radius: 0.625rem;
              box-shadow: none; }
            .brand-main .right .screen .popup .form form label p {
              margin: 0;
              margin-left: 0.625rem; }
              .brand-main .right .screen .popup .form form label p a {
                color: #1d1d1b; }
          .brand-main .right .screen .popup .form form .bottom {
            background-color: transparent;
            margin-top: 2.5rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center; }
            .brand-main .right .screen .popup .form form .bottom input[type="submit"] {
              background-color: #00aeef;
              color: #fff;
              font-family: 'PT Sans Caption', sans-serif;
              font-weight: 700;
              font-size: 1.5625rem;
              width: 17.0625rem;
              height: 3.625rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0.625rem;
              box-shadow: none;
              border: none;
              cursor: pointer; }
            .brand-main .right .screen .popup .form form .bottom .captcha {
              width: 21.125rem; }
              .brand-main .right .screen .popup .form form .bottom .captcha img {
                width: 100%; }
      .brand-main .right .screen .popup-offer {
        height: 48.75rem; }

.error {
  background: url(../img/bg-error.jpg) 50% 50% no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  overflow: hidden; }
  .error .wrap {
    background-color: #fff;
    width: 70vw;
    height: 70vw;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .error .wrap img {
      width: 71%;
      height: 36%;
      position: absolute;
      top: -15%;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto; }
    .error .wrap p {
      font-size: 2.1875rem;
      font-family: 'PT Sans Caption', sans-serif;
      font-weight: 700;
      color: #000;
      text-align: center;
      width: 100%;
      height: 6%;
      position: absolute;
      top: 42%;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto; }
      .error .wrap p a {
        color: #000; }

.condition-main {
  display: flex; }
  .condition-main .logo {
    width: 12.375rem;
    height: 10.25rem;
    position: absolute;
    top: 1.875rem;
    right: 6.25rem; }
  .condition-main .left {
    background-color: #00aeef;
    width: 37.5rem;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0.4375rem 0.4375rem 0.3125rem rgba(0, 0, 0, 0.1);
    z-index: 10;
    box-sizing: border-box; }
    .condition-main .left .wrap {
      width: 23.125rem;
      height: 34%;
      position: absolute;
      top: 0;
      left: 4rem;
      bottom: 0;
      right: 0;
      margin: auto; }
      .condition-main .left .wrap .title {
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        font-size: 3rem;
        margin-bottom: 1.875rem; }
      .condition-main .left .wrap .items {
        position: relative; }
        .condition-main .left .wrap .items .line {
          width: 1px;
          height: calc(100% - 4.5rem);
          background-color: #fff;
          position: absolute;
          top: 0;
          left: 0.4375rem;
          bottom: 0;
          margin: auto; }
        .condition-main .left .wrap .items .item {
          font-family: 'PT Sans', sans-serif;
          font-size: 1.53125rem;
          margin-bottom: 1.5625rem;
          padding-left: 1.875rem;
          position: relative;
          cursor: pointer; }
          .condition-main .left .wrap .items .item .dot {
            width: 1rem;
            height: 1rem;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto; }
  .condition-main .right {
    width: calc(100% - 37.5rem);
    padding-top: 5.625rem;
    padding-left: 5rem;
    padding-right: 5rem;
    box-sizing: border-box;
    position: relative; }
    .condition-main .right .screen {
      display: none; }
      .condition-main .right .screen .title {
        color: #00aeef;
        font-size: 3rem;
        font-family: 'PT Sans Caption', sans-serif;
        font-weight: 700;
        line-height: 3.59375rem;
        margin-bottom: 3.75rem; }
      .condition-main .right .screen .title2 {
        color: #000;
        font-size: 1.8125rem;
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        line-height: 2.125rem;
        margin-top: 1.5625rem;
        margin-bottom: 1.5625rem; }
      .condition-main .right .screen > p {
        color: #000;
        font-size: 1.5625rem;
        line-height: 1.875rem;
        font-family: 'PT Sans', sans-serif;
        margin: 1.25rem 0; }
        .condition-main .right .screen > p span {
          font-weight: 700; }
      .condition-main .right .screen ul {
        padding-left: 0;
        list-style: none; }
        .condition-main .right .screen ul li {
          color: #000;
          font-size: 1.5625rem;
          line-height: 1.875rem;
          font-family: 'PT Sans', sans-serif; }

.innovationlab-main {
  display: flex; }
  .innovationlab-main .left {
    background-color: #00aeef;
    width: 45%;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0.4375rem 0.4375rem 0.3125rem rgba(0, 0, 0, 0.1);
    z-index: 10;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center; }
    .innovationlab-main .left .logo {
      width: 9.375rem;
      height: 7.1875rem;
      position: absolute;
      top: 3.4375rem;
      right: 5rem; }
    .innovationlab-main .left .wrap {
      width: 75%;
      margin-left: 4rem;
      margin-top: 12.5rem; }
      .innovationlab-main .left .wrap .title {
        font-family: 'PT Sans', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
        margin-bottom: 1.875rem; }
      .innovationlab-main .left .wrap form select {
        appearance: none;
        margin-bottom: 1.5625rem;
        width: 31.25rem;
        border: 1px solid #b6b7b7;
        font-size: 1.5rem;
        color: #b6b7b7;
        padding: 0.9375rem 1.5625rem;
        box-sizing: border-box;
        border-radius: 0.9375rem;
        background-image: url(../img/icon_select_arrow.svg);
        background-position: 95% 50%;
        background-size: 0.9375rem;
        background-repeat: no-repeat; }
        .innovationlab-main .left .wrap form select option {
          color: #b6b7b7; }
      .innovationlab-main .left .wrap .calendar-wrap {
        width: 80%;
        height: 32.5rem;
        position: relative; }
        .innovationlab-main .left .wrap .calendar-wrap .shadow {
          width: 96%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          background-color: rgba(124, 124, 124, 0.37);
          display: none; }
        .innovationlab-main .left .wrap .calendar-wrap .calendar {
          width: 31.25rem;
          height: 31.5625rem;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto; }
          .innovationlab-main .left .wrap .calendar-wrap .calendar .calendar-nav {
            position: absolute;
            left: 0;
            bottom: 0;
            margin: auto;
            width: 98%;
            height: 2.1875rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: #00aeef; }
            .innovationlab-main .left .wrap .calendar-wrap .calendar .calendar-nav .calendar-nav-btn {
              cursor: pointer;
              filter: brightness(0) invert(1);
              transition: 0.2s; }
              .innovationlab-main .left .wrap .calendar-wrap .calendar .calendar-nav .calendar-nav-btn:hover {
                color: #b7b7b7;
                filter: none; }
              .innovationlab-main .left .wrap .calendar-wrap .calendar .calendar-nav .calendar-nav-btn.disabled {
                color: #b7b7b7;
                pointer-events: none;
                cursor: default; }
          .innovationlab-main .left .wrap .calendar-wrap .calendar .daynames {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            font-size: 1.25rem;
            color: #ffffff;
            width: 99%; }
          .innovationlab-main .left .wrap .calendar-wrap .calendar .days {
            margin-top: 0.9375rem;
            position: absolute;
            background-color: #fff;
            transition: opacity 0.3s, visibility 0.3s 0s; }
            .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day {
              width: 4.375rem;
              height: 4.375rem;
              box-sizing: border-box;
              position: relative; }
              .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day.active {
                border-color: #231f20; }
              .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day span {
                color: #000;
                font-size: 1.5rem;
                position: absolute;
                top: 0.625rem;
                right: 0.625rem; }
              .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day .list {
                position: absolute;
                right: -24.375rem;
                top: 0;
                width: 23.75rem;
                color: #00aeef;
                border-radius: 0.9375rem;
                padding: 0.9375rem 0;
                box-sizing: border-box;
                background-color: #fff;
                box-shadow: 0.4375rem 0.4375rem 0.25rem rgba(0, 0, 0, 0.15);
                z-index: 1;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.3s, visibility 0s; }
                .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day .list .item {
                  font-size: 1.5rem;
                  font-weight: 700;
                  padding: 0.9375rem 2.5rem; }
                  .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day .list .item span {
                    font-size: 1.25rem;
                    color: #b7b7b7;
                    display: block;
                    position: relative;
                    top: 0;
                    right: 0; }
                  .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day .list .item:hover {
                    background-color: #00aeef;
                    color: #fff; }
                    .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day .list .item:hover span {
                      color: #fff; }
              .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day:nth-child(7) .list {
                right: 8.125rem; }
              .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day:nth-child(6) .list {
                right: 8.125rem; }
              .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day:nth-child(5) .list {
                right: 8.125rem;
                width: 17.1875rem; }
              .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day.hasEvent {
                background-color: #00aeef;
                cursor: pointer; }
                .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day.hasEvent span {
                  color: #fff; }
                .innovationlab-main .left .wrap .calendar-wrap .calendar .days .day.hasEvent .list.active {
                  opacity: 1;
                  visibility: visible; }
          .innovationlab-main .left .wrap .calendar-wrap .calendar .mounth-name {
            margin-top: 0.9375rem;
            text-transform: uppercase;
            font-size: 1.5625rem;
            color: #fff;
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto; }
  .innovationlab-main .right {
    width: 55%;
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
    padding-left: 5rem;
    padding-right: 5rem;
    box-sizing: border-box;
    position: relative; }
    .innovationlab-main .right .logo {
      width: 9.375rem;
      height: 8.125rem; }
    .innovationlab-main .right p {
      color: #000;
      font-size: 1.25rem; }
    .innovationlab-main .right .news .title2 {
      color: #1d1d1b;
      font-size: 1.6875rem;
      font-family: 'PT Sans', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 2rem;
      margin-bottom: 1.25rem;
      margin-top: 1.25rem;
      position: relative; }
      .innovationlab-main .right .news .title2 span {
        display: inline-block;
        background-color: #fff;
        position: relative;
        z-index: 2;
        padding-right: 0.625rem; }
      .innovationlab-main .right .news .title2:after {
        content: '';
        width: 90%;
        height: 1px;
        background-color: #ccc;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        z-index: 1; }
    .innovationlab-main .right .news .slider {
      width: 100%;
      height: auto;
      overflow: visible; }
      .innovationlab-main .right .news .slider .slick-next,
      .innovationlab-main .right .news .slider .slick-prev {
        width: 1.25rem;
        height: 1.25rem;
        top: 30%;
        z-index: 10; }
        .innovationlab-main .right .news .slider .slick-next:before,
        .innovationlab-main .right .news .slider .slick-prev:before {
          display: none; }
      .innovationlab-main .right .news .slider .slick-disabled {
        opacity: 0; }
      .innovationlab-main .right .news .slider .slick-prev {
        background: url(../img/arrow-left.svg) 50% 50% no-repeat;
        background-size: contain;
        left: -2.8125rem; }
      .innovationlab-main .right .news .slider .slick-next {
        background: url(../img/arrow-right.svg) 50% 50% no-repeat;
        background-size: contain;
        right: 0; }
      .innovationlab-main .right .news .slider .slide {
        height: auto;
        margin-bottom: 0.625rem; }
        .innovationlab-main .right .news .slider .slide a {
          display: flex;
          flex-direction: column;
          text-decoration: none;
          width: 87%; }
          .innovationlab-main .right .news .slider .slide a img {
            width: 15.625rem;
            height: 15.625rem;
            object-fit: cover;
            object-position: 50% 50%; }
          .innovationlab-main .right .news .slider .slide a .title {
            font-size: 1.875rem;
            color: #00aeef;
            line-height: 2.0625rem;
            font-weight: 700;
            margin-top: 0.9375rem; }
          .innovationlab-main .right .news .slider .slide a .desk {
            font-size: 1rem;
            color: #000000;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 0.625rem; }
          .innovationlab-main .right .news .slider .slide a .date {
            font-size: 1.125rem;
            color: #b7b7b7;
            margin-top: 0.625rem; }

/* - cisco-landing - */
.cisco-landing {
  overflow: hidden; }
  .cisco-landing .hero {
    background: url(../img/cisco-landing/bg.png) 50% 50% no-repeat;
    background-size: cover;
    position: relative; }
    .cisco-landing .hero .f1 {
      width: 81.25rem;
      height: 119.9375rem;
      position: absolute;
      top: 0;
      left: 0; }
    .cisco-landing .hero .container .man {
      background: url(../img/cisco-landing/man.png) 50% 50% no-repeat;
      background-size: contain;
      width: 42.5rem;
      height: 44.0625rem;
      position: absolute;
      top: 2%;
      bottom: 0;
      right: 7%;
      margin: auto; }
    .cisco-landing .hero .container .header {
      width: 100%;
      height: 10.125rem;
      position: absolute;
      top: 3.75rem;
      left: 0;
      padding-left: 11.5625rem;
      padding-right: 7.5rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .cisco-landing .hero .container .header .logos {
        width: 26.5rem;
        height: 100%; }
      .cisco-landing .hero .container .header .menu {
        font-family: 'PT Sans Caption', sans-serif;
        font-size: 1.5625rem;
        width: 40.625rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between; }
        .cisco-landing .hero .container .header .menu a {
          color: #fff;
          text-decoration: none; }
    .cisco-landing .hero .container .texts {
      width: 72%;
      height: 59vh;
      top: 20%; }
      .cisco-landing .hero .container .texts .title {
        color: #000;
        font-weight: 700; }
      .cisco-landing .hero .container .texts .desc {
        font-family: 'PT Sans Caption', sans-serif;
        font-size: 1.875rem;
        line-height: 2.25rem; }
      .cisco-landing .hero .container .texts .btn {
        font-family: 'PT Sans Caption', sans-serif;
        font-size: 1.5625rem;
        color: #00aeef;
        background-color: #fff;
        border-radius: 2.1875rem;
        width: 28.125rem;
        height: 5.375rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer; }
  .cisco-landing .advantages {
    width: calc(100% - 4rem);
    margin-left: auto; }
    .cisco-landing .advantages .wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 1.875rem; }
      .cisco-landing .advantages .wrap .item {
        width: 25%;
        height: 25.3125rem;
        font-family: 'PT Sans Caption', sans-serif;
        padding-top: 3.125rem;
        padding-left: 5rem;
        box-sizing: border-box;
        position: relative;
        background-color: #fff; }
        .cisco-landing .advantages .wrap .item.blue {
          background-color: #00aeef; }
        .cisco-landing .advantages .wrap .item .line {
          width: 0.125rem;
          height: 50%;
          position: absolute;
          left: 10%; }
          .cisco-landing .advantages .wrap .item .line:before {
            content: '';
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            position: absolute;
            left: -0.4375rem; }
          .cisco-landing .advantages .wrap .item .line.blue {
            background-color: #00aeef; }
            .cisco-landing .advantages .wrap .item .line.blue:before {
              background-color: #00aeef; }
          .cisco-landing .advantages .wrap .item .line.white {
            background-color: #fff; }
            .cisco-landing .advantages .wrap .item .line.white:before {
              background-color: #fff; }
          .cisco-landing .advantages .wrap .item .line.top {
            top: 0; }
            .cisco-landing .advantages .wrap .item .line.top:before {
              bottom: 0; }
          .cisco-landing .advantages .wrap .item .line.bottom {
            bottom: 0; }
            .cisco-landing .advantages .wrap .item .line.bottom:before {
              top: 0; }
        .cisco-landing .advantages .wrap .item .title {
          font-size: 1.875rem;
          font-weight: 700;
          color: #000; }
        .cisco-landing .advantages .wrap .item .desc {
          font-size: 1.3125rem;
          color: #565656;
          margin-top: 3.125rem; }
          .cisco-landing .advantages .wrap .item .desc a {
            color: #00aeef;
            margin-top: 0.9375rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12.5rem;
            height: 3.75rem;
            border: 2px solid #00aeef;
            box-sizing: border-box;
            border-radius: 2.1875rem;
            text-decoration: none; }
        .cisco-landing .advantages .wrap .item .pic {
          max-width: 13.75rem;
          max-height: 10rem;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto; }
          .cisco-landing .advantages .wrap .item .pic img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
  .cisco-landing .smartnet {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
    .cisco-landing .smartnet .container {
      position: relative;
      height: 100vh; }
      .cisco-landing .smartnet .container .scheme {
        width: 59rem;
        height: 90vh;
        position: absolute;
        top: 0;
        left: 11.25rem;
        bottom: 0;
        margin: auto; }
        .cisco-landing .smartnet .container .scheme img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 0 50%; }
      .cisco-landing .smartnet .container .slider-smart-net {
        background: url(../img/cisco-landing/bg-slider-smart.svg) 0% 50% no-repeat;
        background-size: cover;
        width: 48.75rem;
        height: 100vh;
        position: absolute;
        top: 0;
        right: 0; }
        .cisco-landing .smartnet .container .slider-smart-net:before {
          background: url(../img/arrow.svg) 0% 50% no-repeat;
          background-size: contain;
          width: 20%;
          height: 83%;
          content: '';
          position: absolute;
          top: 0;
          left: 10%;
          bottom: 0;
          margin: auto; }
        .cisco-landing .smartnet .container .slider-smart-net .slider-nav {
          visibility: hidden; }
        .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap {
          width: 70%;
          height: 85vh;
          position: absolute;
          top: 0;
          left: 10%;
          bottom: 0;
          right: 0;
          margin: auto; }
          .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .slick-list {
            padding: 3.125rem 0 !important; }
          .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .slick-track {
            top: 35vh; }
          .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item {
            width: 100%;
            height: 35vh;
            margin: -3.125rem 0 6.25rem;
            cursor: pointer;
            transform: scale(0.7) translateY(0);
            transition: 0.4s;
            outline: none; }
            .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item .title {
              font-size: 3rem;
              font-weight: 700; }
            .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item .desc {
              font-family: 'PT Sans Caption', sans-serif;
              font-size: 1.5rem;
              margin-top: 2.5rem;
              color: #000;
              opacity: 0;
              transition: 0.5s; }
            .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item .btn {
              font-family: 'Open Sans', sans-serif;
              color: #fff;
              background-color: #00aeef;
              text-decoration: none;
              font-size: 1.5625rem;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 17.3125rem;
              height: 5.375rem;
              border: 2px solid #fff;
              box-sizing: border-box;
              border-radius: 2.1875rem;
              outline: none;
              margin-top: 2.8125rem;
              opacity: 0;
              visibility: hidden;
              transition: opacity 0.5s, background-color 0.3s, color 0.3s; }
              .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item .btn:hover {
                background-color: #fff;
                color: #00aeef; }
            .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item.slick-center {
              transform: scale(1) translateY(-43%); }
              .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item.slick-center .desc {
                opacity: 1; }
              .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item.slick-center .btn {
                opacity: 1;
                visibility: visible; }
  .cisco-landing .promo {
    background: url(../img/cisco-landing/bg2.png) 50% 50% no-repeat;
    background-size: cover; }
    .cisco-landing .promo .container {
      padding-top: 5.625rem;
      padding-left: 11.5625rem;
      padding-bottom: 3.75rem;
      box-sizing: border-box; }
      .cisco-landing .promo .container .title {
        font-family: 'PT Sans Caption', sans-serif;
        font-size: 3.75rem;
        line-height: 4.5rem;
        font-weight: 700; }
      .cisco-landing .promo .container .desc {
        font-family: 'PT Sans', sans-serif;
        font-size: 1.875rem;
        margin-top: 3.75rem; }
      .cisco-landing .promo .container .btns {
        margin-top: 3.75rem;
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between; }
        .cisco-landing .promo .container .btns .btn {
          font-family: 'PT Sans Caption', sans-serif;
          font-size: 1.5625rem;
          border-radius: 2.1875rem;
          height: 5.375rem;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          text-decoration: none;
          cursor: pointer; }
          .cisco-landing .promo .container .btns .btn.btn1 {
            border: 2px solid #fff;
            width: 35.3125rem;
            color: #fff; }
          .cisco-landing .promo .container .btns .btn.btn2 {
            width: 17.3125rem;
            background-color: #fff;
            color: #00aeef; }
  .cisco-landing .capabilities .container {
    padding-top: 5.625rem;
    box-sizing: border-box; }
    .cisco-landing .capabilities .container > .title {
      font-family: 'PT Sans Caption', sans-serif;
      font-size: 3.75rem;
      line-height: 4.5rem;
      color: #00aeef;
      text-align: center; }
    .cisco-landing .capabilities .container > .desс {
      font-family: 'PT Sans', sans-serif;
      font-size: 2.25rem;
      line-height: 2.6875rem;
      color: #000;
      text-align: center;
      margin-top: 1.25rem; }
    .cisco-landing .capabilities .container > .wrap {
      width: 70%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 5rem; }
      .cisco-landing .capabilities .container > .wrap .item {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .cisco-landing .capabilities .container > .wrap .item .pic {
          width: 15rem;
          height: 15rem; }
        .cisco-landing .capabilities .container > .wrap .item .text {
          font-family: 'PT Sans', sans-serif;
          font-size: 1.875rem;
          line-height: 2.25rem;
          color: #000;
          margin-top: 2.5rem;
          text-align: center; }
    .cisco-landing .capabilities .container .list_wrap {
      padding-left: 11.5625rem;
      box-sizing: border-box;
      margin-top: 8.125rem; }
      .cisco-landing .capabilities .container .list_wrap .title {
        color: #00aeef;
        font-family: 'PT Sans', sans-serif;
        font-size: 2.875rem;
        line-height: 3.4375rem; }
      .cisco-landing .capabilities .container .list_wrap .list {
        margin-top: 3.75rem;
        position: relative; }
        .cisco-landing .capabilities .container .list_wrap .list:before {
          content: '';
          position: absolute;
          top: -2%;
          left: 0.375rem;
          bottom: 0;
          margin: auto;
          width: 0.0625rem;
          height: 89%;
          background-color: #00aeef; }
        .cisco-landing .capabilities .container .list_wrap .list .item {
          font-family: 'PT Sans', sans-serif;
          font-size: 2.25rem;
          line-height: 2.6875rem;
          color: #000;
          margin-top: 2.1875rem;
          padding-left: 3.75rem;
          box-sizing: border-box;
          position: relative; }
          .cisco-landing .capabilities .container .list_wrap .list .item:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            width: 0.875rem;
            height: 0.875rem;
            border-radius: 50%;
            background-color: #00aeef; }
  .cisco-landing .help {
    position: relative; }
    .cisco-landing .help .f2 {
      width: 100%;
      height: 110vh;
      position: absolute;
      top: -6.25rem;
      left: 0; }
    .cisco-landing .help .container {
      margin-top: 3.75rem; }
      .cisco-landing .help .container .wrap {
        width: 95vh;
        height: 95vh;
        background-color: #00aeef;
        border-radius: 50%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: right; }
        .cisco-landing .help .container .wrap .title {
          font-family: 'PT Sans', sans-serif;
          font-weight: 700;
          font-size: 2.875rem;
          color: #fff; }
        .cisco-landing .help .container .wrap .list {
          position: relative; }
          .cisco-landing .help .container .wrap .list .item {
            font-family: 'PT Sans', sans-serif;
            font-weight: 700;
            font-size: 2.25rem;
            color: #000;
            font-weight: 400;
            margin-top: 3.75rem;
            position: relative; }
            .cisco-landing .help .container .wrap .list .item:before {
              content: '';
              width: 0.9375rem;
              height: 0.9375rem;
              position: absolute;
              top: 0.9375rem;
              bottom: 0;
              right: -3.125rem;
              background-color: #fff;
              border-radius: 50%; }
          .cisco-landing .help .container .wrap .list:before {
            content: '';
            width: 0.0625rem;
            height: 53%;
            position: absolute;
            top: 5rem;
            right: -2.6875rem;
            background-color: #fff; }
  .cisco-landing .popup {
    background-color: #fff;
    width: 70%;
    height: 70vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 12;
    box-shadow: 0 0 0.9375rem rgba(0, 0, 0, 0.1);
    display: none; }
    .cisco-landing .popup .list {
      width: 95%;
      height: 90%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto; }
      .cisco-landing .popup .list .title {
        font-family: 'PT Sans Caption', sans-serif;
        font-size: 2.875rem;
        color: #00aeef;
        text-align: center;
        margin-bottom: 3.125rem; }
      .cisco-landing .popup .list ul {
        list-style: lower-alpha;
        color: #000;
        font-size: 1.5rem; }
        .cisco-landing .popup .list ul li {
          margin-bottom: 0.9375rem; }
          .cisco-landing .popup .list ul li a {
            color: #00aeef;
            transition: 0.15s; }
            .cisco-landing .popup .list ul li a:visited {
              color: #006e96; }
            .cisco-landing .popup .list ul li a:hover {
              color: #000; }
    .cisco-landing .popup .wrap {
      width: 65%;
      display: flex;
      flex-direction: column;
      margin: 0 auto; }
      .cisco-landing .popup .wrap a {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #000;
        font-size: 1.5rem;
        margin-bottom: 1.5625rem;
        text-decoration: none; }
        .cisco-landing .popup .wrap a .name {
          width: 90%; }
        .cisco-landing .popup .wrap a .date {
          width: 10%; }
    .cisco-landing .popup .close {
      background: url(../img/cancel.svg) 50% 50% no-repeat;
      background-size: contain;
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      top: 0.9375rem;
      right: 0.9375rem;
      cursor: pointer; }

.to-top {
  width: 3.125rem;
  height: 3.125rem;
  position: fixed;
  left: 0;
  bottom: 5%;
  right: 0;
  margin: auto;
  border-radius: 50%;
  border: 1px solid #fff;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s 0s;
  cursor: pointer;
  filter: drop-shadow(0px 0px 0.2vw #00adee); }
  .to-top.active {
    opacity: 0.7;
    visibility: visible; }
    .to-top.active:hover {
      opacity: 1; }
  .to-top img {
    width: 3.125rem;
    height: 3.125rem; }

p a {
  border-bottom: 2px solid #5be;
  color: #555;
  background-image: linear-gradient(180deg, transparent 65%, #5bf 65%);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 100% 0%; }

p a:hover {
  background-size: 100% 100%; }

@media (max-width: 768px) {
  .p-left-160 {
    padding-left: 0;
    box-sizing: border-box; }
  .p-right-160 {
    padding-right: 0;
    box-sizing: border-box; }
  .p-left-190 {
    padding-left: 6.125rem;
    box-sizing: border-box; }
  .p-right-190 {
    padding-right: 6.125rem;
    box-sizing: border-box; }
  .br-hide {
    display: none; }
  .nav {
    width: 100%;
    height: 7rem;
    border-right: none;
    display: flex;
    flex-direction: row-reverse;
    border-bottom: 2px solid #fff;
    box-sizing: border-box; }
    .nav .top {
      width: 50%;
      height: 100%;
      border-bottom: none; }
      .nav .top .text {
        transform: none;
        margin-left: 45%;
        font-size: 2.5rem; }
    .nav .bot {
      width: 50%;
      height: 100%; }
      .nav .bot .icon {
        width: 2.875rem;
        height: 2.25rem;
        margin-right: 55%; }
        .nav .bot .icon .line {
          width: 100%;
          height: 0.25rem; }
    .nav.nav-cisco-landing .bot {
      width: 50%;
      height: 100%;
      margin-right: auto; }
      .nav.nav-cisco-landing .bot .icon {
        width: 2.875rem;
        height: 2.25rem;
        margin-right: 55%; }
        .nav.nav-cisco-landing .bot .icon .line {
          width: 100%;
          height: 0.25rem; }
    .nav.nav-cisco-landing.active {
      transform: translateY(90vh); }
    .nav:hover {
      width: 100%; }
      .nav:hover .bot .icon .line:first-child {
        transform: translateY(-0.3125rem); }
      .nav:hover .bot .icon .line:last-child {
        transform: translateY(0.3125rem); }
    .nav.active {
      transform: translateY(57.5rem); }
  .nav-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 57.5rem;
    background-color: #00aeef;
    border-right: none;
    border-bottom: 1px solid #fff;
    padding-top: 3.125rem;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    box-sizing: border-box;
    transform: translateY(-57.5rem);
    transition: 0.5s; }
    .nav-menu.active {
      transform: translateY(0); }
    .nav-menu .top .info .phone {
      font-size: 2.25rem; }
    .nav-menu .top .info .address {
      font-size: 1.25rem; }
    .nav-menu .list {
      height: 31.25rem; }
      .nav-menu .list a {
        font-size: 2.125rem; }
      .nav-menu .list .item.subitem {
        font-size: 1.625rem;
        line-height: 2.625rem; }
      .nav-menu .list .submenu.submenu1:before {
        height: calc(100% - 2.5rem); }
    .nav-menu .search {
      margin-top: 4.375rem; }
      .nav-menu .search .search-wrap input {
        font-size: 16px;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-shadow: none;
        border-radius: 0; }
        .nav-menu .search .search-wrap input.active {
          width: 100%; }
    .nav-menu.nav-menu-cisco-landing {
      height: 90vh;
      transform: translateY(-90vh); }
      .nav-menu.nav-menu-cisco-landing.active {
        transform: translateY(0); }
      .nav-menu.nav-menu-cisco-landing .list {
        height: auto; }
  .hero {
    background: url(../img/bg_hero_mob.jpg) 50% 50% no-repeat;
    background-size: cover;
    overflow: hidden; }
    .hero .container .texts {
      width: 84%;
      height: 100vh;
      left: 0;
      top: initial;
      bottom: 4.375rem;
      position: relative;
      justify-content: flex-end; }
      .hero .container .texts .circle_mob {
        display: block;
        width: 115vw;
        height: 79vh;
        position: absolute;
        top: 11%;
        right: -31%; }
        .hero .container .texts .circle_mob .ball {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto; }
        .hero .container .texts .circle_mob .pic {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          opacity: 0;
          transform: scale(0.2);
          transition: 0.8s; }
          .hero .container .texts .circle_mob .pic.active {
            transform: scale(1);
            opacity: 1; }
      .hero .container .texts .logo {
        display: none; }
      .hero .container .texts .title {
        font-size: 2.75rem;
        margin-bottom: 3.125rem; }
      .hero .container .texts .btns a {
        font-size: 2rem; }
      .hero .container .texts .btns .border {
        border-radius: 3.125rem; }
    .hero .container .video {
      display: none; }
  .slider {
    height: 52.5rem; }
    .slider .slick-dots {
      position: absolute;
      bottom: 3.7%;
      list-style: none;
      display: block;
      text-align: center;
      padding: 0;
      right: 0;
      left: 0;
      margin: auto;
      width: auto; }
    .slider .slide {
      position: relative;
      outline: none;
      height: 52.5rem; }
      .slider .slide img {
        height: auto;
        bottom: initial; }
      .slider .slide .text_wrap {
        background-color: #00aeef;
        width: 100%;
        height: 16.875rem;
        position: absolute;
        top: initial;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        padding: 2.5rem 3.75rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        z-index: 10; }
        .slider .slide .text_wrap .title {
          font-family: 'PT Sans Caption', sans-serif;
          font-size: 2.5rem; }
        .slider .slide .text_wrap .btn {
          color: #fff;
          text-decoration: none;
          font-size: 1.9375rem;
          border: 2px solid #fff;
          box-sizing: border-box;
          border-radius: 2.5rem;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 16.6875rem;
          height: 4.9375rem;
          outline: none; }
  .announcement {
    margin-top: 0; }
    .announcement .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .announcement .container .video_wrap {
        margin-top: 1.875rem;
        width: 100%; }
        .announcement .container .video_wrap .texts {
          justify-content: center;
          align-items: center;
          padding-left: 0; }
  .feed {
    margin-top: 3.125rem; }
    .feed .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .feed .container .feed-wrap .feed-news,
      .feed .container .feed-wrap .feed-promo,
      .feed .container .feed-wrap .feed-events {
        width: 46.25rem; }
        .feed .container .feed-wrap .feed-news .slick-slide,
        .feed .container .feed-wrap .feed-promo .slick-slide,
        .feed .container .feed-wrap .feed-events .slick-slide {
          outline: none; }
        .feed .container .feed-wrap .feed-news .item,
        .feed .container .feed-wrap .feed-promo .item,
        .feed .container .feed-wrap .feed-events .item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-top: 0;
          cursor: pointer;
          width: 17.25rem; }
          .feed .container .feed-wrap .feed-news .item .pic,
          .feed .container .feed-wrap .feed-promo .item .pic,
          .feed .container .feed-wrap .feed-events .item .pic {
            width: 17.25rem;
            height: 14.6875rem;
            filter: none;
            transition: 0.25s; }
            .feed .container .feed-wrap .feed-news .item .pic img,
            .feed .container .feed-wrap .feed-promo .item .pic img,
            .feed .container .feed-wrap .feed-events .item .pic img {
              width: 17.25rem;
              height: 14.6875rem;
              object-fit: cover; }
          .feed .container .feed-wrap .feed-news .item .texts,
          .feed .container .feed-wrap .feed-promo .item .texts,
          .feed .container .feed-wrap .feed-events .item .texts {
            margin-left: 0;
            width: 17.25rem; }
            .feed .container .feed-wrap .feed-news .item .texts .category,
            .feed .container .feed-wrap .feed-promo .item .texts .category,
            .feed .container .feed-wrap .feed-events .item .texts .category {
              margin-top: 1.25rem;
              font-size: 1.5rem;
              color: #b7b7b7; }
            .feed .container .feed-wrap .feed-news .item .texts .title,
            .feed .container .feed-wrap .feed-promo .item .texts .title,
            .feed .container .feed-wrap .feed-events .item .texts .title {
              font-size: 2.5rem;
              color: #00aeef;
              font-weight: 700;
              margin-top: 1.25rem; }
            .feed .container .feed-wrap .feed-news .item .texts .desc,
            .feed .container .feed-wrap .feed-promo .item .texts .desc,
            .feed .container .feed-wrap .feed-events .item .texts .desc {
              font-size: 1.75rem;
              color: #000;
              margin-top: 0.9375rem; }
            .feed .container .feed-wrap .feed-news .item .texts .date,
            .feed .container .feed-wrap .feed-promo .item .texts .date,
            .feed .container .feed-wrap .feed-events .item .texts .date {
              font-size: 1.1875rem;
              color: #b7b7b7;
              margin-top: 1.5rem; }
          .feed .container .feed-wrap .feed-news .item:hover .pic,
          .feed .container .feed-wrap .feed-promo .item:hover .pic,
          .feed .container .feed-wrap .feed-events .item:hover .pic {
            filter: contrast(0.3) brightness(1.5); }
      .feed .container .feed-wrap .loader {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        background-color: #fff;
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s, visibility 0.3s 0s; }
        .feed .container .feed-wrap .loader.hide {
          opacity: 0;
          visibility: hidden; }
        .feed .container .feed-wrap .loader .load {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          margin-top: auto;
          margin-bottom: auto;
          width: 6.25rem;
          height: 6.25rem; }
          .feed .container .feed-wrap .loader .load hr {
            border: 0;
            margin: 0;
            width: 40%;
            height: 40%;
            position: absolute;
            border-radius: 50%;
            animation: spin 2s ease infinite; }
      .feed .container .revol {
        background: url(../img/bg_promo_mob.svg) 50% 0% no-repeat;
        background-size: cover;
        width: 100%;
        height: 21.875rem;
        position: relative;
        top: 6.25rem; }
        .feed .container .revol:before {
          display: none; }
        .feed .container .revol .slider-nav {
          color: #000;
          position: absolute;
          bottom: 5%;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 10;
          font-size: 2.5rem;
          color: #fff;
          font-weight: 700;
          width: 95%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          visibility: visible; }
          .feed .container .revol .slider-nav .slick-prev,
          .feed .container .revol .slider-nav .slick-next {
            cursor: pointer; }
        .feed .container .revol .carousel_wrap {
          width: 100%;
          height: auto;
          position: absolute;
          top: -3.75rem;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto; }
          .feed .container .revol .carousel_wrap .slick-track {
            top: -2.5rem; }
          .feed .container .revol .carousel_wrap .item {
            height: auto;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            transform: scale(1) translateY(0);
            transition: 0.4s;
            outline: none;
            margin: 0 6.25rem; }
            .feed .container .revol .carousel_wrap .item .title {
              font-size: 2.6875rem;
              font-weight: 700;
              text-align: center;
              margin-top: 1.875rem;
              order: 2;
              visibility: visible;
              opacity: 1; }
            .feed .container .revol .carousel_wrap .item .desc {
              font-family: 'PT Sans Caption', sans-serif;
              font-size: 1.0625rem;
              margin-top: 1.25rem;
              text-align: center;
              order: 3;
              opacity: 0;
              transition: 0;
              visibility: visible;
              opacity: 1; }
            .feed .container .revol .carousel_wrap .item .btn {
              font-family: 'Open Sans', sans-serif;
              color: #fff;
              background-color: #00aeef;
              text-decoration: none;
              font-size: 1.5625rem;
              border: 2px solid #fff;
              box-sizing: border-box;
              border-radius: 2rem;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              order: 1;
              width: 15.5rem;
              height: 4.8125rem;
              outline: none;
              margin-top: 1.5625rem;
              visibility: visible;
              opacity: 1;
              transition: opacity 0s, background-color 0.3s, color 0.3s; }
              .feed .container .revol .carousel_wrap .item .btn:hover {
                background-color: #fff;
                color: #00aeef; }
            .feed .container .revol .carousel_wrap .item.slick-active {
              transform: scale(1) translateY(0); }
              .feed .container .revol .carousel_wrap .item.slick-active .desc {
                opacity: 1;
                visibility: visible; }
              .feed .container .revol .carousel_wrap .item.slick-active .btn {
                opacity: 1;
                visibility: visible; }
  .vendors {
    margin-top: 8.75rem; }
    .vendors .container .logos_wrap {
      width: 100%; }
      .vendors .container .logos_wrap .item {
        width: 11.25rem;
        height: 5.625rem;
        position: relative;
        outline: none;
        filter: grayscale(0);
        transition: 0.3s;
        opacity: 1;
        cursor: pointer; }
        .vendors .container .logos_wrap .item:hover {
          filter: grayscale(0); }
        .vendors .container .logos_wrap .item img {
          width: 11.25rem;
          height: 5.625rem;
          object-fit: contain;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto; }
  .bottom {
    /* background: url(../img/bg_hero.jpg) 50% 0% no-repeat;
        background-size: cover; */
    margin-top: 3.75rem; }
    .bottom.bottom-direction {
      padding-top: 0;
      margin-top: 0; }
      .bottom.bottom-direction .footer .container .right .feed {
        margin-top: 0; }
    .bottom.bottom-events {
      padding-top: 0;
      margin-top: 0; }
      .bottom.bottom-events .footer .container .right .feed {
        margin-top: 0; }
    .bottom > .container {
      height: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .bottom > .container .video {
        width: 100%;
        height: auto; }
        .bottom > .container .video:after {
          content: '';
          position: absolute;
          top: initial;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          height: 25%;
          width: 100%;
          background: url(../img/video-clip2.svg) 50% 0% no-repeat;
          background-size: cover; }
      .bottom > .container .texts {
        width: 100%; }
        .bottom > .container .texts .logo-slogan {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center; }
          .bottom > .container .texts .logo-slogan p {
            margin: 0;
            font-size: 3rem;
            text-align: center; }
          .bottom > .container .texts .logo-slogan img {
            display: none; }
        .bottom > .container .texts .counters {
          width: 88%;
          margin: 0 auto;
          margin-top: 3.125rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between; }
          .bottom > .container .texts .counters p {
            margin: 0;
            transition: 0.2s; }
            .bottom > .container .texts .counters p:first-child {
              font-size: 4.375rem;
              font-weight: 700; }
            .bottom > .container .texts .counters p:last-child {
              font-size: 2.125rem; }
    .bottom .footer {
      padding-bottom: 3.75rem; }
      .bottom .footer .container {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center; }
        .bottom .footer .container .left {
          margin-top: 2.5rem; }
          .bottom .footer .container .left .logo {
            margin-left: -0.625rem;
            width: 21.875rem; }
          .bottom .footer .container .left .copyright {
            margin-top: 2.5rem;
            font-size: 1.3125rem; }
        .bottom .footer .container .right {
          display: flex;
          flex-direction: column;
          align-items: flex-end; }
          .bottom .footer .container .right .feed {
            margin-top: 4.6875rem; }
            .bottom .footer .container .right .feed p {
              font-size: 1.9375rem;
              text-align: center; }
            .bottom .footer .container .right .feed form {
              display: block;
              flex-direction: column;
              align-items: center; }
              .bottom .footer .container .right .feed form input {
                font-size: 1.9375rem;
                background-color: transparent;
                box-shadow: none;
                border: 1px solid #fff;
                color: #fff;
                padding: 1.125rem;
                box-sizing: border-box;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                box-shadow: none;
                border-radius: 0; }
              .bottom .footer .container .right .feed form input::placeholder {
                color: #fff; }
              .bottom .footer .container .right .feed form input::-webkit-input-placeholder {
                color: #fff; }
              .bottom .footer .container .right .feed form input::-moz-placeholder {
                color: #fff; }
              .bottom .footer .container .right .feed form input:-moz-placeholder {
                color: #fff; }
              .bottom .footer .container .right .feed form input:-ms-input-placeholder {
                color: #fff; }
              .bottom .footer .container .right .feed form input[type="email"] {
                margin-left: 5.4375rem;
                border-right: none;
                width: 20.3125rem; }
              .bottom .footer .container .right .feed form input[type="submit"] {
                cursor: pointer;
                margin-left: -0.1875rem; }
          .bottom .footer .container .right .links {
            width: 34.375rem;
            margin-top: 3.125rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
            .bottom .footer .container .right .links a {
              color: #fff;
              font-size: 1.75rem;
              text-decoration: none; }
  .hero-direction {
    padding-top: 8.125rem;
    background: url(../img/bg_hero-direction_mob.png) 50% 50% no-repeat;
    background-size: cover; }
    .hero-direction .container {
      height: 100vh; }
      .hero-direction .container.p-right-190 {
        padding: 0 3.125rem; }
      .hero-direction .container .video {
        display: none; }
      .hero-direction .container .mob-version {
        display: block;
        height: 65vh;
        overflow: hidden; }
        .hero-direction .container .mob-version .circle {
          background: url(../img/bg_hero-direction-circle_mob.svg) 50% 50% no-repeat;
          background-size: contain;
          width: 100rem;
          height: 100rem;
          position: absolute;
          top: -2.1875rem;
          left: -67.5rem;
          bottom: 0;
          margin: auto;
          transform: rotate(0deg);
          opacity: 0.8;
          animation-name: rotate-circle;
          animation-timing-function: linear;
          animation-duration: 120s;
          animation-iteration-count: infinite; }
  @keyframes rotate-circle {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
        .hero-direction .container .mob-version .icon {
          position: relative;
          width: 10.9375rem;
          height: 10.9375rem;
          display: block;
          margin-bottom: 1.875rem;
          text-decoration: none;
          display: flex;
          align-items: center;
          width: 100%; }
          .hero-direction .container .mob-version .icon p {
            text-transform: uppercase;
            color: #fff;
            font-family: 'PT Sans Caption', sans-serif;
            font-size: 2.25rem;
            margin-left: 13.4375rem; }
        .hero-direction .container .mob-version .icon1 {
          background: url(../img/directions_hero/icon1.svg) 0% 50% no-repeat;
          background-size: contain; }
        .hero-direction .container .mob-version .icon2 {
          background: url(../img/directions_hero/icon2.svg) 0% 50% no-repeat;
          background-size: contain;
          margin-left: 4.375rem; }
        .hero-direction .container .mob-version .icon3 {
          background: url(../img/directions_hero/icon3.svg) 0% 50% no-repeat;
          background-size: contain;
          margin-left: 4.375rem; }
        .hero-direction .container .mob-version .icon4 {
          background: url(../img/directions_hero/icon4.svg) 0% 50% no-repeat;
          background-size: contain; }
      .hero-direction .container .texts {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 20vh; }
        .hero-direction .container .texts .logo {
          width: 12.5rem; }
        .hero-direction .container .texts .title {
          font-family: 'PT Sans Caption', sans-serif;
          font-size: 3.5rem; }
  .directions {
    display: none; }
  .directions-mob {
    overflow: hidden;
    background-color: #00aeef;
    display: block;
    padding-top: 5.3125rem; }
    .directions-mob .line {
      background-color: #fff;
      width: 2px;
      position: absolute;
      top: 0;
      left: 4.4375rem;
      transition: 0.35s; }
    .directions-mob .items {
      width: 100%;
      margin: 0 auto;
      position: relative; }
      .directions-mob .items > .item {
        box-shadow: none;
        padding: 0 3.125rem 0 6.25rem;
        box-sizing: border-box;
        padding-bottom: 2.5rem;
        position: relative;
        cursor: pointer; }
        .directions-mob .items > .item.active {
          box-shadow: 0 0.75rem 1.375rem -0.3125rem rgba(68, 68, 68, 0.4); }
          .directions-mob .items > .item.active .title {
            font-family: 'PT Sans Caption', sans-serif;
            font-size: 3.625rem;
            font-weight: 700; }
        .directions-mob .items > .item .point {
          background-color: #fff;
          width: 0.9375rem;
          height: 0.9375rem;
          border-radius: 50%;
          position: absolute;
          top: 1.0625rem;
          left: 4.0625rem; }
        .directions-mob .items > .item .title {
          font-family: 'PT Sans Caption', sans-serif;
          font-size: 2.5rem;
          transition: 0.3s; }
        .directions-mob .items > .item .desc {
          font-family: 'PT Sans Caption', sans-serif;
          font-size: 1.75rem;
          margin-top: 1.875rem;
          display: none; }
        .directions-mob .items > .item .btn {
          font-family: 'Open Sans', sans-serif;
          color: #00aeef;
          background-color: #ffe000;
          text-decoration: none;
          font-size: 1.75rem;
          border-radius: 2.8125rem;
          justify-content: center;
          align-items: center;
          width: 20.9375rem;
          height: 5.75rem;
          outline: none;
          margin-top: 1.875rem;
          display: none;
          opacity: 0;
          transition: 0.3s; }
          .directions-mob .items > .item .btn:hover {
            color: #00aeef;
            background-color: #fff; }
          .directions-mob .items > .item .btn.active {
            display: inline-flex !important;
            opacity: 1; }
    .directions-mob .list {
      padding-top: 1.25rem;
      margin-bottom: 3.125rem;
      position: relative;
      display: none; }
      .directions-mob .list .item {
        margin-bottom: 1.25rem;
        padding: 0 3.125rem 0 6.25rem;
        box-sizing: border-box;
        position: relative; }
        .directions-mob .list .item .point {
          background-color: #fff;
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          position: absolute;
          top: 0.9375rem;
          left: 4.0625rem; }
        .directions-mob .list .item .title {
          font-weight: 700;
          font-size: 2.0625rem;
          text-transform: uppercase; }
        .directions-mob .list .item .desc {
          font-size: 1.75rem; }
      .directions-mob .list .vendor-logos {
        background-color: #fff;
        padding: 1.875rem 2.8125rem 1.875rem 1.875rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        margin-right: -1.875rem;
        box-shadow: inset 0 -0.5625rem 0.625rem -0.375rem rgba(68, 68, 68, 0.4); }
        .directions-mob .list .vendor-logos img {
          max-width: 15.625rem;
          max-height: 3.75rem;
          margin-right: 1.875rem;
          margin-bottom: 1.875rem; }
      .directions-mob .list .close-list {
        width: 2.8125rem;
        height: 1.875rem;
        position: absolute;
        left: 0;
        bottom: 0.625rem;
        right: 0;
        margin: auto;
        cursor: pointer; }
  .events-main .left {
    width: 100%;
    height: 43.75rem;
    position: relative; }
    .events-main .left .logo {
      display: none; }
    .events-main .left .texts {
      width: 86%;
      height: 29.375rem;
      top: 6.875rem;
      left: 3.4375rem; }
      .events-main .left .texts .title {
        font-size: 3.875rem; }
      .events-main .left .texts .search {
        margin-top: 2.8125rem;
        margin-bottom: 2.8125rem; }
        .events-main .left .texts .search input {
          width: 100%;
          height: 4.8125rem;
          font-size: 1.9375rem;
          border-radius: 1.5625rem; }
      .events-main .left .texts .list .item .dot {
        width: 1.625rem;
        height: 1.625rem; }
        .events-main .left .texts .list .item .dot:before {
          width: 0.625rem;
          height: 0.625rem; }
      .events-main .left .texts .list .item .text {
        font-size: 2.5rem; }
      .events-main .left .texts .list:before {
        height: 11.25rem;
        top: 0.9375rem;
        left: 0.8125rem; }
  .events-main .right {
    width: 100%;
    margin-top: 0; }
    .events-main .right .event-list {
      width: 93%;
      margin-left: 0;
      margin: auto;
      padding-left: 3.4375rem; }
      .events-main .right .event-list .line {
        top: 11.25rem;
        left: 2.125rem; }
      .events-main .right .event-list .event-item {
        margin-top: 1.875rem; }
        .events-main .right .event-list .event-item .pic {
          width: 15.375rem;
          height: 13rem; }
          .events-main .right .event-list .event-item .pic img {
            width: 15.375rem;
            height: 13rem; }
        .events-main .right .event-list .event-item .texts .category {
          font-size: 1.05rem; }
        .events-main .right .event-list .event-item .texts .title {
          font-size: 1.8rem; }
        .events-main .right .event-list .event-item .texts .desc {
          font-size: 1.1875rem; }
        .events-main .right .event-list .event-item .texts .date {
          font-size: 1.05rem; }
        .events-main .right .event-list .event-item .dot {
          left: -3.4375rem; }
        .events-main .right .event-list .event-item:after {
          width: 2.5rem;
          left: -2.5rem; }
    .events-main .right .calendar-wrap {
      height: 50rem; }
      .events-main .right .calendar-wrap .calendar {
        width: 41.875rem;
        height: 43.125rem; }
        .events-main .right .calendar-wrap .calendar .days .day {
          height: 6.25rem; }
          .events-main .right .calendar-wrap .calendar .days .day .list {
            position: absolute;
            right: -16.875rem;
            top: 0;
            width: 15.625rem;
            color: #00aeef;
            border-radius: 0.9375rem;
            padding: 0.9375rem 0;
            box-sizing: border-box;
            background-color: #fff;
            box-shadow: 0.4375rem 0.4375rem 0.25rem rgba(0, 0, 0, 0.15);
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0s; }
            .events-main .right .calendar-wrap .calendar .days .day .list .item {
              font-size: 1.875rem;
              font-weight: 700;
              padding: 0.9375rem 2.5rem; }
              .events-main .right .calendar-wrap .calendar .days .day .list .item span {
                color: #b7b7b7;
                display: block;
                position: relative;
                top: 0;
                right: 0; }
              .events-main .right .calendar-wrap .calendar .days .day .list .item:hover {
                background-color: #00aeef;
                color: #fff; }
                .events-main .right .calendar-wrap .calendar .days .day .list .item:hover span {
                  color: #fff; }
          .events-main .right .calendar-wrap .calendar .days .day:nth-child(7) .list {
            right: 8.125rem; }
          .events-main .right .calendar-wrap .calendar .days .day:nth-child(6) .list {
            right: 8.125rem; }
          .events-main .right .calendar-wrap .calendar .days .day:nth-child(5) .list {
            right: 8.125rem; }
          .events-main .right .calendar-wrap .calendar .days .day.hasEvent {
            background-color: #00aeef;
            cursor: pointer; }
            .events-main .right .calendar-wrap .calendar .days .day.hasEvent span {
              color: #fff; }
            .events-main .right .calendar-wrap .calendar .days .day.hasEvent .list.active {
              opacity: 1;
              visibility: visible; }
    .events-main .right .event-single {
      padding: 5.625rem 3.125rem;
      margin-top: 4.375rem; }
      .events-main .right .event-single .registration_btn {
        position: absolute;
        top: 4.6875rem;
        left: initial;
        right: 3.4375rem;
        transform: rotate(0deg);
        border-radius: 1.875rem; }
      .events-main .right .event-single .logo {
        width: 40%; }
      .events-main .right .event-single .meta .date_wrap .icon {
        width: 3.75rem;
        height: 5.25rem;
        position: relative; }
        .events-main .right .event-single .meta .date_wrap .icon img {
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto; }
      .events-main .right .event-single .meta .text_wrap .category {
        font-size: 1.0625rem; }
      .events-main .right .event-single .meta .text_wrap .title {
        font-size: 3.28125rem; }
      .events-main .right .event-single .event-single-text {
        font-size: 1.64375rem;
        line-height: 1.6875rem; }
      .events-main .right .event-single .footer .social span {
        font-size: 1.5625rem;
        margin-right: 0.9375rem; }
      .events-main .right .event-single .footer .social .icon {
        width: 3.75rem;
        height: 3.75rem;
        margin-right: 0.625rem; }
    .events-main .right .registration-wrap {
      height: auto; }
      .events-main .right .registration-wrap .registration-container {
        width: 88%;
        height: auto;
        position: relative;
        top: initial;
        margin-top: 9.375rem;
        padding-bottom: 10.625rem; }
        .events-main .right .registration-wrap .registration-container .meta .date_wrap .icon {
          width: 3.75rem;
          height: 5.25rem;
          position: relative; }
          .events-main .right .registration-wrap .registration-container .meta .date_wrap .icon img {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto; }
        .events-main .right .registration-wrap .registration-container .meta .text_wrap .category {
          font-size: 1.0625rem; }
        .events-main .right .registration-wrap .registration-container .meta .text_wrap .title {
          font-size: 3.28125rem; }
        .events-main .right .registration-wrap .registration-container .registration-form form {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          margin-top: 1.875rem; }
          .events-main .right .registration-wrap .registration-container .registration-form form .col {
            width: 95%; }
            .events-main .right .registration-wrap .registration-container .registration-form form .col input {
              font-size: 2.0625rem;
              color: #b6b7b7;
              margin-bottom: 1.875rem;
              padding: 1.25rem 1.875rem;
              border-radius: 1.25rem; }
            .events-main .right .registration-wrap .registration-container .registration-form form .col a {
              position: absolute;
              left: 0;
              right: 0;
              bottom: -6.25rem;
              margin: auto;
              display: block;
              width: 45%;
              font-size: 3.2625rem; }
        .events-main .right .registration-wrap .registration-container .registration-form .success {
          font-size: 2.6875rem;
          text-align: center; }
  .promo-main .first-screen {
    flex-direction: column; }
    .promo-main .first-screen .left {
      position: relative;
      width: 100%;
      height: auto;
      margin-top: 6.875rem; }
      .promo-main .first-screen .left .wrap {
        position: relative;
        width: 86%;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        padding-left: 0;
        padding-right: 0; }
        .promo-main .first-screen .left .wrap .pagename {
          font-size: 3.875rem; }
        .promo-main .first-screen .left .wrap .search input {
          width: 100%;
          height: 4.8125rem;
          font-size: 1.9375rem;
          border-radius: 1.5625rem; }
        .promo-main .first-screen .left .wrap .promo-select .pic {
          width: 100%; }
          .promo-main .first-screen .left .wrap .promo-select .pic img {
            width: 100%;
            max-height: initial; }
        .promo-main .first-screen .left .wrap .promo-select .new-promo {
          font-size: 3.75rem; }
        .promo-main .first-screen .left .wrap .promo-select .category {
          font-size: 1.5625rem;
          line-height: 4.6875rem; }
        .promo-main .first-screen .left .wrap .promo-select .title {
          font-size: 2.75rem;
          line-height: 3.125rem; }
        .promo-main .first-screen .left .wrap .promo-select .desc {
          font-size: 1.6875rem;
          line-height: 1.6875rem; }
        .promo-main .first-screen .left .wrap .promo-select .date {
          font-size: 1.875rem;
          line-height: 3.875rem; }
    .promo-main .first-screen .right {
      position: relative;
      width: 100%; }
      .promo-main .first-screen .right .promo-list {
        width: 93%;
        margin-left: 0;
        margin: auto;
        padding-left: 3.4375rem; }
        .promo-main .first-screen .right .promo-list .line {
          top: 11.25rem;
          left: 0.4375rem; }
        .promo-main .first-screen .right .promo-list .promo-item {
          margin-top: 1.875rem; }
          .promo-main .first-screen .right .promo-list .promo-item .pic {
            width: 15.375rem;
            height: 13rem; }
            .promo-main .first-screen .right .promo-list .promo-item .pic img {
              width: 15.375rem;
              height: 13rem; }
          .promo-main .first-screen .right .promo-list .promo-item .texts .category {
            font-size: 1.05rem; }
          .promo-main .first-screen .right .promo-list .promo-item .texts .title {
            font-size: 1.8rem; }
          .promo-main .first-screen .right .promo-list .promo-item .texts .desc {
            font-size: 1.1875rem; }
          .promo-main .first-screen .right .promo-list .promo-item .texts .date {
            font-size: 1.05rem; }
          .promo-main .first-screen .right .promo-list .promo-item .dot {
            left: -3.4375rem; }
          .promo-main .first-screen .right .promo-list .promo-item:after {
            width: 2.5rem;
            left: -2.5rem; }
  .promo-main .search-result .header {
    position: relative;
    width: 86%;
    margin: auto;
    padding-top: 9.375rem;
    padding-bottom: 0;
    padding-left: 0; }
    .promo-main .search-result .header .pagename {
      font-size: 3.875rem; }
    .promo-main .search-result .header .search input {
      width: 100%;
      height: 4.8125rem;
      font-size: 1.9375rem;
      border-radius: 1.5625rem; }
    .promo-main .search-result .header .logo {
      display: none; }
  .promo-main .search-result .main {
    flex-direction: column; }
    .promo-main .search-result .main .left {
      width: 100%;
      position: relative; }
      .promo-main .search-result .main .left .promo-list {
        width: 93%;
        margin-left: 0;
        margin: auto;
        padding-left: 3.4375rem;
        padding-bottom: 0; }
        .promo-main .search-result .main .left .promo-list .line {
          top: 11.25rem;
          left: 0.4375rem; }
        .promo-main .search-result .main .left .promo-list .promo-item {
          margin-top: 1.875rem; }
          .promo-main .search-result .main .left .promo-list .promo-item .pic {
            width: 15.375rem;
            height: 13rem; }
            .promo-main .search-result .main .left .promo-list .promo-item .pic img {
              width: 15.375rem;
              height: 13rem; }
          .promo-main .search-result .main .left .promo-list .promo-item .texts .category {
            font-size: 1.05rem; }
          .promo-main .search-result .main .left .promo-list .promo-item .texts .title {
            font-size: 1.8rem; }
          .promo-main .search-result .main .left .promo-list .promo-item .texts .desc {
            font-size: 1.1875rem; }
          .promo-main .search-result .main .left .promo-list .promo-item .texts .date {
            font-size: 1.05rem; }
          .promo-main .search-result .main .left .promo-list .promo-item .dot {
            left: -3.4375rem; }
          .promo-main .search-result .main .left .promo-list .promo-item:after {
            width: 2.5rem;
            left: -2.5rem; }
    .promo-main .search-result .main .right {
      width: 100%; }
      .promo-main .search-result .main .right .promo-list {
        width: 93%;
        margin-left: 0;
        margin: auto;
        padding-left: 3.4375rem;
        padding-top: 0; }
        .promo-main .search-result .main .right .promo-list .line {
          top: 11.25rem;
          left: 0.4375rem; }
        .promo-main .search-result .main .right .promo-list .promo-item {
          margin-top: 1.875rem; }
          .promo-main .search-result .main .right .promo-list .promo-item .pic {
            width: 15.375rem;
            height: 13rem; }
            .promo-main .search-result .main .right .promo-list .promo-item .pic img {
              width: 15.375rem;
              height: 13rem; }
          .promo-main .search-result .main .right .promo-list .promo-item .texts .category {
            font-size: 1.05rem; }
          .promo-main .search-result .main .right .promo-list .promo-item .texts .title {
            font-size: 1.8rem; }
          .promo-main .search-result .main .right .promo-list .promo-item .texts .desc {
            font-size: 1.1875rem; }
          .promo-main .search-result .main .right .promo-list .promo-item .texts .date {
            font-size: 1.05rem; }
          .promo-main .search-result .main .right .promo-list .promo-item .dot {
            left: -3.4375rem; }
          .promo-main .search-result .main .right .promo-list .promo-item:after {
            width: 2.5rem;
            left: -2.5rem; }
  .promo-main .promo-single .left {
    width: 86%;
    margin: auto;
    padding: 9.375rem 0 3.125rem; }
    .promo-main .promo-single .left .logo {
      width: 40%; }
    .promo-main .promo-single .left .meta .date_wrap {
      width: 10.625rem; }
      .promo-main .promo-single .left .meta .date_wrap .icon {
        width: 3.75rem;
        height: 5.25rem;
        position: relative; }
        .promo-main .promo-single .left .meta .date_wrap .icon img {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto; }
      .promo-main .promo-single .left .meta .date_wrap .date {
        font-size: 1.375rem; }
    .promo-main .promo-single .left .meta .text_wrap .category {
      font-size: 1.0625rem; }
    .promo-main .promo-single .left .meta .text_wrap .title {
      font-size: 3.28125rem; }
    .promo-main .promo-single .left .promo-single-text {
      font-size: 1.64375rem;
      line-height: 1.6875rem; }
    .promo-main .promo-single .left .footer .social span {
      font-size: 1.5625rem;
      margin-right: 0.9375rem; }
    .promo-main .promo-single .left .footer .social .icon {
      width: 3.75rem;
      height: 3.75rem;
      margin-right: 0.625rem; }
  .promo-main .promo-single .right {
    display: none; }
  .news-main .first-screen {
    flex-direction: column; }
    .news-main .first-screen .left {
      position: relative;
      width: 100%;
      height: auto;
      margin-top: 6.875rem; }
      .news-main .first-screen .left .wrap {
        position: relative;
        width: 86%;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        padding-left: 0;
        padding-right: 0; }
        .news-main .first-screen .left .wrap .pagename {
          font-size: 3.875rem; }
        .news-main .first-screen .left .wrap .search input,
        .news-main .first-screen .left .wrap .search select {
          width: 44%;
          height: 4.8125rem;
          font-size: 1.9375rem;
          border-radius: 1.5625rem; }
          .news-main .first-screen .left .wrap .search input.year,
          .news-main .first-screen .left .wrap .search select.year {
            width: 0; }
            .news-main .first-screen .left .wrap .search input.year option,
            .news-main .first-screen .left .wrap .search select.year option {
              width: 8.4375rem; }
          .news-main .first-screen .left .wrap .search input.month,
          .news-main .first-screen .left .wrap .search select.month {
            width: 0;
            margin-left: 0.625rem; }
          .news-main .first-screen .left .wrap .search input.year.active,
          .news-main .first-screen .left .wrap .search select.year.active {
            width: 20%; }
          .news-main .first-screen .left .wrap .search input.month.active,
          .news-main .first-screen .left .wrap .search select.month.active {
            width: 30%; }
        .news-main .first-screen .left .wrap .search a {
          font-size: 1.9375rem; }
        .news-main .first-screen .left .wrap .news-select .pic {
          width: 100%; }
          .news-main .first-screen .left .wrap .news-select .pic img {
            width: 100%;
            max-height: initial; }
        .news-main .first-screen .left .wrap .news-select .new-news {
          font-size: 3.75rem; }
        .news-main .first-screen .left .wrap .news-select .category {
          font-size: 1.5625rem;
          line-height: 4.6875rem; }
        .news-main .first-screen .left .wrap .news-select .title {
          font-size: 2.75rem;
          line-height: 3.125rem; }
        .news-main .first-screen .left .wrap .news-select .desc {
          font-size: 1.6875rem;
          line-height: 1.6875rem; }
        .news-main .first-screen .left .wrap .news-select .date {
          font-size: 1.875rem;
          line-height: 3.875rem; }
    .news-main .first-screen .right {
      position: relative;
      width: 100%; }
      .news-main .first-screen .right .news-list {
        width: 93%;
        margin-left: 0;
        margin: auto;
        padding-left: 3.4375rem; }
        .news-main .first-screen .right .news-list .line {
          top: 11.25rem;
          left: 0.4375rem; }
        .news-main .first-screen .right .news-list .news-item {
          margin-top: 1.875rem; }
          .news-main .first-screen .right .news-list .news-item .pic {
            width: 15.375rem;
            height: 13rem; }
            .news-main .first-screen .right .news-list .news-item .pic img {
              width: 15.375rem;
              height: 13rem; }
          .news-main .first-screen .right .news-list .news-item .texts .category {
            font-size: 1.05rem; }
          .news-main .first-screen .right .news-list .news-item .texts .title {
            font-size: 1.8rem; }
          .news-main .first-screen .right .news-list .news-item .texts .desc {
            font-size: 1.1875rem; }
          .news-main .first-screen .right .news-list .news-item .texts .date {
            font-size: 1.05rem; }
          .news-main .first-screen .right .news-list .news-item .dot {
            left: -3.4375rem; }
          .news-main .first-screen .right .news-list .news-item:after {
            width: 2.5rem;
            left: -2.5rem; }
  .news-main .search-result .header {
    position: relative;
    width: 86%;
    margin: auto;
    padding-top: 9.375rem;
    padding-bottom: 0;
    padding-left: 0; }
    .news-main .search-result .header .pagename {
      font-size: 3.875rem; }
    .news-main .search-result .header .search input,
    .news-main .search-result .header .search select {
      width: 44%;
      height: 4.8125rem;
      font-size: 1.9375rem;
      border-radius: 1.5625rem; }
      .news-main .search-result .header .search input.year,
      .news-main .search-result .header .search select.year {
        width: 0; }
        .news-main .search-result .header .search input.year option,
        .news-main .search-result .header .search select.year option {
          width: 8.4375rem; }
      .news-main .search-result .header .search input.month,
      .news-main .search-result .header .search select.month {
        width: 0;
        margin-left: 0.625rem; }
      .news-main .search-result .header .search input.year.active,
      .news-main .search-result .header .search select.year.active {
        width: 20%; }
      .news-main .search-result .header .search input.month.active,
      .news-main .search-result .header .search select.month.active {
        width: 30%; }
    .news-main .search-result .header .search a {
      font-size: 1.9375rem; }
    .news-main .search-result .header .logo {
      display: none; }
  .news-main .search-result .main {
    flex-direction: column; }
    .news-main .search-result .main .left {
      width: 100%;
      position: relative; }
      .news-main .search-result .main .left .news-list {
        width: 93%;
        margin-left: 0;
        margin: auto;
        padding-left: 3.4375rem;
        padding-bottom: 0; }
        .news-main .search-result .main .left .news-list .line {
          top: 11.25rem;
          left: 0.4375rem; }
        .news-main .search-result .main .left .news-list .news-item {
          margin-top: 1.875rem; }
          .news-main .search-result .main .left .news-list .news-item .pic {
            width: 15.375rem;
            height: 13rem; }
            .news-main .search-result .main .left .news-list .news-item .pic img {
              width: 15.375rem;
              height: 13rem; }
          .news-main .search-result .main .left .news-list .news-item .texts .category {
            font-size: 1.05rem; }
          .news-main .search-result .main .left .news-list .news-item .texts .title {
            font-size: 1.8rem; }
          .news-main .search-result .main .left .news-list .news-item .texts .desc {
            font-size: 1.1875rem; }
          .news-main .search-result .main .left .news-list .news-item .texts .date {
            font-size: 1.05rem; }
          .news-main .search-result .main .left .news-list .news-item .dot {
            left: -3.4375rem; }
          .news-main .search-result .main .left .news-list .news-item:after {
            width: 2.5rem;
            left: -2.5rem; }
    .news-main .search-result .main .right {
      width: 100%; }
      .news-main .search-result .main .right .news-list {
        width: 93%;
        margin-left: 0;
        margin: auto;
        padding-left: 3.4375rem;
        padding-top: 0; }
        .news-main .search-result .main .right .news-list .line {
          top: 11.25rem;
          left: 0.4375rem; }
        .news-main .search-result .main .right .news-list .news-item {
          margin-top: 1.875rem; }
          .news-main .search-result .main .right .news-list .news-item .pic {
            width: 15.375rem;
            height: 13rem; }
            .news-main .search-result .main .right .news-list .news-item .pic img {
              width: 15.375rem;
              height: 13rem; }
          .news-main .search-result .main .right .news-list .news-item .texts .category {
            font-size: 1.05rem; }
          .news-main .search-result .main .right .news-list .news-item .texts .title {
            font-size: 1.8rem; }
          .news-main .search-result .main .right .news-list .news-item .texts .desc {
            font-size: 1.1875rem; }
          .news-main .search-result .main .right .news-list .news-item .texts .date {
            font-size: 1.05rem; }
          .news-main .search-result .main .right .news-list .news-item .dot {
            left: -3.4375rem; }
          .news-main .search-result .main .right .news-list .news-item:after {
            width: 2.5rem;
            left: -2.5rem; }
  .news-main .news-single .left {
    width: 86%;
    margin: auto;
    padding: 9.375rem 0 3.125rem; }
    .news-main .news-single .left .logo {
      width: 40%; }
    .news-main .news-single .left .meta .date_wrap {
      width: 10.625rem; }
      .news-main .news-single .left .meta .date_wrap .icon {
        width: 3.75rem;
        height: 5.25rem;
        position: relative; }
        .news-main .news-single .left .meta .date_wrap .icon img {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto; }
      .news-main .news-single .left .meta .date_wrap .date {
        font-size: 1.375rem; }
    .news-main .news-single .left .meta .text_wrap .category {
      font-size: 1.0625rem; }
    .news-main .news-single .left .meta .text_wrap .title {
      font-size: 3.28125rem; }
    .news-main .news-single .left .news-single-text {
      font-size: 1.64375rem;
      line-height: 1.6875rem; }
    .news-main .news-single .left .footer .social span {
      font-size: 1.5625rem;
      margin-right: 0.9375rem; }
    .news-main .news-single .left .footer .social .icon {
      width: 3.75rem;
      height: 3.75rem;
      margin-right: 0.625rem; }
  .news-main .news-single .right {
    display: none; }
  .about-main {
    display: flex;
    flex-direction: column;
    overflow: hidden; }
    .about-main .f1,
    .about-main .f2,
    .about-main .f3,
    .about-main .f4,
    .about-main .f5,
    .about-main .f6 {
      width: 100%; }
    .about-main .block1 {
      padding-left: 2.5rem;
      margin-top: 9.375rem;
      order: 1; }
      .about-main .block1 .container .texts {
        width: 95%; }
        .about-main .block1 .container .texts .logo {
          margin: 0 auto; }
        .about-main .block1 .container .texts .text {
          margin-top: 3.75rem; }
          .about-main .block1 .container .texts .text p {
            font-size: 1.75rem; }
        .about-main .block1 .container .texts .stat {
          width: 97%; }
      .about-main .block1 .container .video {
        display: none; }
    .about-main .block2 {
      padding-left: 2.5rem;
      margin-top: 3.125rem;
      height: 40.0625rem;
      order: 2; }
      .about-main .block2 .container {
        height: 100%; }
        .about-main .block2 .container img {
          width: 100%;
          height: 40.0625rem; }
    .about-main .block3 {
      padding-left: 2.5rem;
      margin-top: 3.125rem;
      order: 4; }
      .about-main .block3 .container p {
        font-size: 1.8rem; }
        .about-main .block3 .container p span {
          width: 100%; }
    .about-main .block4 {
      padding-left: 0;
      margin-top: 9.375rem;
      order: 3; }
      .about-main .block4 .container .video_wrap {
        margin-left: -8.75rem; }
        .about-main .block4 .container .video_wrap .video {
          width: 76vw;
          height: 76vw; }
          .about-main .block4 .container .video_wrap .video video {
            width: 76vw;
            height: 76vw; }
          .about-main .block4 .container .video_wrap .video .before {
            font-size: 2rem;
            top: -6.25rem;
            left: 11.25rem; }
        .about-main .block4 .container .video_wrap .items {
          width: 56%; }
          .about-main .block4 .container .video_wrap .items .item {
            width: 7.5rem;
            height: 7.5rem; }
            .about-main .block4 .container .video_wrap .items .item .icon {
              width: 7.5rem;
              height: 7.5rem; }
              .about-main .block4 .container .video_wrap .items .item .icon img {
                width: 7.5rem;
                height: 7.5rem; }
            .about-main .block4 .container .video_wrap .items .item .text {
              font-size: 1.8rem; }
            .about-main .block4 .container .video_wrap .items .item.item2, .about-main .block4 .container .video_wrap .items .item.item3 {
              margin-left: 6.875rem; }
    .about-main .block5 {
      order: 5; }
    .about-main .block6 {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      margin-top: 3.125rem;
      order: 6; }
      .about-main .block6 .container {
        flex-direction: column; }
        .about-main .block6 .container .video {
          width: 71vw;
          height: 71vw;
          margin-left: -28.125rem; }
          .about-main .block6 .container .video video {
            width: 71vw;
            height: 71vw; }
        .about-main .block6 .container .texts {
          margin-left: 0; }
          .about-main .block6 .container .texts .title {
            position: absolute;
            top: -6.75rem;
            right: 0;
            font-size: 2.8125rem;
            width: 45%; }
          .about-main .block6 .container .texts p {
            font-size: 1.75rem; }
          .about-main .block6 .container .texts .ul {
            font-size: 1.75rem; }
    .about-main .block7 {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      padding-bottom: 2.5rem;
      margin-top: 3.125rem;
      order: 7; }
      .about-main .block7 .container {
        flex-direction: column-reverse; }
        .about-main .block7 .container .video {
          width: 71vw;
          height: 71vw;
          margin-right: -18.75rem; }
          .about-main .block7 .container .video video {
            width: 71vw;
            height: 71vw; }
        .about-main .block7 .container .texts {
          margin-top: 0.9375rem;
          margin-left: 0; }
          .about-main .block7 .container .texts > .title {
            position: absolute;
            top: -3.125rem;
            left: 0;
            font-size: 2.8125rem;
            width: 45%; }
          .about-main .block7 .container .texts > p {
            font-size: 1.75rem; }
    .about-main .block8 {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      padding-top: 3.125rem;
      order: 8; }
      .about-main .block8 .container {
        flex-direction: column; }
        .about-main .block8 .container .video {
          width: 71vw;
          height: 71vw;
          margin-left: -25rem; }
          .about-main .block8 .container .video video {
            width: 71vw;
            height: 71vw; }
        .about-main .block8 .container .texts {
          margin-top: 1.25rem;
          margin-left: 0;
          width: 100%; }
          .about-main .block8 .container .texts > .title {
            position: absolute;
            top: -11.25rem;
            right: 0;
            font-size: 2.8125rem;
            width: 35%; }
          .about-main .block8 .container .texts > p {
            font-size: 1.75rem; }
    .about-main .block9 {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      padding-bottom: 2.5rem;
      padding-top: 3.125rem;
      order: 9; }
      .about-main .block9 .container {
        flex-direction: column-reverse; }
        .about-main .block9 .container .video {
          width: 71vw;
          height: 71vw;
          margin-right: -21.875rem; }
          .about-main .block9 .container .video video {
            width: 71vw;
            height: 71vw; }
        .about-main .block9 .container .texts {
          margin-top: 0.9375rem;
          margin-left: 0; }
          .about-main .block9 .container .texts > .title {
            position: absolute;
            top: -6.875rem;
            left: 0;
            font-size: 2.8125rem;
            width: 45%; }
          .about-main .block9 .container .texts > p {
            font-size: 1.75rem; }
    .about-main .bottom {
      order: 10; }
  .contacts-main {
    flex-direction: column; }
    .contacts-main .logo {
      display: none; }
    .contacts-main .left {
      width: 100%;
      height: 7rem;
      top: 7rem;
      overflow: hidden;
      cursor: pointer;
      transition: 0.5s; }
      .contacts-main .left.active {
        height: 31.25rem; }
      .contacts-main .left .wrap {
        width: 92%;
        top: 0.9375rem;
        bottom: initial; }
        .contacts-main .left .wrap .title {
          font-size: 3.875rem;
          position: relative; }
          .contacts-main .left .wrap .title:before {
            content: '';
            background: url(../img/arrow-right.svg) 50% 50% no-repeat;
            background-size: contain;
            width: 2.4%;
            height: 28%;
            position: absolute;
            top: 16%;
            bottom: 0;
            left: 39.9%;
            margin: auto;
            filter: brightness(0) invert(1); }
        .contacts-main .left .wrap .items .line {
          height: calc(100% - 2rem); }
        .contacts-main .left .wrap .items .item {
          font-size: 2.5rem; }
    .contacts-main .right {
      width: 100%;
      padding-left: 3.75rem;
      padding-right: 3.75rem; }
      .contacts-main .right .screen .map {
        width: 40.625rem;
        height: 25.625rem; }
      .contacts-main .right .screen .departments .block .title {
        padding-left: 0;
        font-size: 1.75rem; }
        .contacts-main .right .screen .departments .block .title span.email {
          font-size: 1.75rem; }
      .contacts-main .right .screen .departments .block .list {
        font-size: 1.75rem; }
        .contacts-main .right .screen .departments .block .list .item {
          border-radius: 1.25rem; }
          .contacts-main .right .screen .departments .block .list .item .email {
            padding-left: 3.125rem; }
            .contacts-main .right .screen .departments .block .list .item .email:before {
              width: 3.125rem;
              height: 1.5625rem; }
      .contacts-main .right .screen .agents .item {
        font-size: 1.75rem;
        border-radius: 1.25rem; }
        .contacts-main .right .screen .agents .item.item-invert .email {
          padding-left: 3.125rem; }
          .contacts-main .right .screen .agents .item.item-invert .email:before {
            width: 3.125rem;
            height: 1.5625rem; }
        .contacts-main .right .screen .agents .item .email {
          padding-left: 3.125rem; }
          .contacts-main .right .screen .agents .item .email:before {
            width: 3.125rem;
            height: 1.5625rem; }
      .contacts-main .right .screen .sng .name {
        font-size: 1.75rem; }
      .contacts-main .right .screen .sng .item {
        font-size: 1.75rem;
        border-radius: 1.25rem;
        flex-wrap: wrap; }
        .contacts-main .right .screen .sng .item .phone {
          margin-top: 1.25rem; }
  .partners-main {
    overflow: hidden; }
    .partners-main .block1 {
      padding-left: 3.125rem; }
      .partners-main .block1 .container {
        flex-direction: column;
        margin-top: 10.625rem; }
        .partners-main .block1 .container .texts {
          width: 100%;
          margin-left: 0; }
          .partners-main .block1 .container .texts .logo {
            margin-left: inherit; }
          .partners-main .block1 .container .texts .text {
            margin-top: 15rem; }
            .partners-main .block1 .container .texts .text .title {
              font-size: 3.875rem;
              line-height: 4.1875rem;
              width: 50%; }
            .partners-main .block1 .container .texts .text p {
              font-size: 1.75rem;
              line-height: 2.125rem; }
        .partners-main .block1 .container .pic {
          margin-left: 0;
          margin-top: 0;
          position: absolute;
          top: -12.5rem;
          right: -16.875rem;
          width: 44.375rem;
          height: 44.375rem; }
          .partners-main .block1 .container .pic img {
            width: 44.375rem;
            height: 44.375rem; }
    .partners-main .block2 {
      padding-left: 3.125rem;
      padding-top: 6.25rem; }
      .partners-main .block2 .container .texts {
        width: 100%;
        padding-top: 30rem; }
        .partners-main .block2 .container .texts .text .title {
          width: 45%;
          margin-left: auto;
          font-size: 3.875rem;
          line-height: 4.1875rem; }
        .partners-main .block2 .container .texts .text p {
          width: 95%;
          font-size: 1.75rem;
          line-height: 2.125rem; }
        .partners-main .block2 .container .texts .text a.btn {
          margin: 3.125rem auto 5rem; }
      .partners-main .block2 .container .pic {
        width: 38.125rem;
        height: 38.125rem;
        position: absolute;
        top: 0;
        left: -10.625rem;
        margin-left: 0; }
        .partners-main .block2 .container .pic img {
          width: 38.125rem;
          height: 38.125rem; }
    .partners-main .block3 {
      padding-left: 3.125rem;
      padding-top: 6.25rem; }
      .partners-main .block3 .container .texts {
        width: 100%;
        padding-top: 30rem;
        margin-left: 0; }
        .partners-main .block3 .container .texts .text .title {
          width: 45%;
          margin-right: auto;
          font-size: 3.875rem;
          line-height: 4.1875rem; }
        .partners-main .block3 .container .texts .text p {
          width: 95%;
          font-size: 1.75rem;
          line-height: 2.125rem; }
        .partners-main .block3 .container .texts .text a.btn {
          margin: 3.125rem auto 5rem; }
      .partners-main .block3 .container .pic {
        width: 38.125rem;
        height: 38.125rem;
        position: absolute;
        top: 0;
        right: -8.75rem;
        margin-right: 0; }
        .partners-main .block3 .container .pic img {
          width: 38.125rem;
          height: 38.125rem; }
    .partners-main .forms {
      flex-direction: column; }
      .partners-main .forms .left {
        width: 100%;
        margin-top: 7.5rem;
        padding-left: 3.125rem;
        padding-right: 3.125rem; }
        .partners-main .forms .left .form .title {
          font-size: 3.875rem;
          line-height: 4.1875rem; }
        .partners-main .forms .left .form .title2 {
          font-size: 3.125rem;
          line-height: 5rem; }
        .partners-main .forms .left .form form .inputs input {
          font-size: 2rem;
          height: 5.625rem;
          padding: 0 2.5rem;
          margin-bottom: 1.875rem; }
        .partners-main .forms .left .form form label {
          font-size: 1.75rem; }
          .partners-main .forms .left .form form label input {
            width: 5rem;
            height: 2.5rem; }
        .partners-main .forms .left .form form .bottom {
          flex-direction: column-reverse; }
          .partners-main .forms .left .form form .bottom input[type="submit"] {
            margin-top: 2.5rem;
            font-size: 1.9375rem;
            width: 21.25rem;
            height: 4.5rem; }
          .partners-main .forms .left .form form .bottom .captcha {
            width: 95%; }
      .partners-main .forms .right {
        width: 100%; }
        .partners-main .forms .right .logo {
          display: none; }
        .partners-main .forms .right .texts {
          margin-top: 3.125rem; }
          .partners-main .forms .right .texts .title {
            font-size: 3.875rem;
            line-height: 4.1875rem; }
          .partners-main .forms .right .texts .title2 {
            font-size: 2.25rem;
            line-height: 2.6875rem; }
          .partners-main .forms .right .texts p {
            font-size: 1.75rem;
            line-height: 2.125rem;
            padding-left: 1.875rem; }
            .partners-main .forms .right .texts p:before {
              width: 0.625rem;
              height: 0.625rem;
              bottom: initial;
              top: 0.75rem;
              left: 0; }
          .partners-main .forms .right .texts.texts-vendor {
            margin-top: 3.125rem; }
            .partners-main .forms .right .texts.texts-vendor p {
              padding-left: 0; }
  .support-main {
    overflow: hidden; }
    .support-main .block1 {
      padding-left: 3.125rem; }
      .support-main .block1 .container {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10.625rem; }
        .support-main .block1 .container .texts {
          width: 95%;
          margin-left: 0; }
          .support-main .block1 .container .texts .logo {
            margin-left: inherit; }
          .support-main .block1 .container .texts .text {
            margin-top: 25rem; }
            .support-main .block1 .container .texts .text .title {
              font-size: 3.125rem;
              line-height: 3.5625rem;
              width: 50%; }
            .support-main .block1 .container .texts .text p {
              font-size: 1.75rem;
              line-height: 2.125rem; }
        .support-main .block1 .container .pic {
          margin-left: 0;
          margin-top: 0;
          position: absolute;
          top: -8.125rem;
          right: -12.5rem;
          width: 42.5rem;
          height: 42.5rem; }
          .support-main .block1 .container .pic img {
            width: 42.5rem;
            height: 42.5rem; }
    .support-main .block2,
    .support-main .block4,
    .support-main .block6,
    .support-main .block8 {
      padding-left: 3.125rem;
      padding-top: 4.375rem; }
      .support-main .block2 .container .texts,
      .support-main .block4 .container .texts,
      .support-main .block6 .container .texts,
      .support-main .block8 .container .texts {
        width: 100%;
        padding-top: 25rem;
        margin-left: 0; }
        .support-main .block2 .container .texts .text .title,
        .support-main .block4 .container .texts .text .title,
        .support-main .block6 .container .texts .text .title,
        .support-main .block8 .container .texts .text .title {
          width: 45%;
          margin-left: auto;
          margin-bottom: 4.375rem;
          font-size: 3.125rem;
          line-height: 3.5625rem; }
        .support-main .block2 .container .texts .text p,
        .support-main .block4 .container .texts .text p,
        .support-main .block6 .container .texts .text p,
        .support-main .block8 .container .texts .text p {
          width: 95%;
          font-size: 1.75rem;
          line-height: 2.125rem; }
      .support-main .block2 .container .pic,
      .support-main .block4 .container .pic,
      .support-main .block6 .container .pic,
      .support-main .block8 .container .pic {
        width: 38.125rem;
        height: 38.125rem;
        position: absolute;
        top: 0;
        left: -10.625rem;
        margin-left: 0; }
        .support-main .block2 .container .pic img,
        .support-main .block4 .container .pic img,
        .support-main .block6 .container .pic img,
        .support-main .block8 .container .pic img {
          width: 38.125rem;
          height: 38.125rem; }
    .support-main .block4 .container .texts,
    .support-main .block6 .container .texts,
    .support-main .block8 .container .texts {
      padding-top: 28.125rem; }
      .support-main .block4 .container .texts .text .title,
      .support-main .block6 .container .texts .text .title,
      .support-main .block8 .container .texts .text .title {
        margin-bottom: 4.375rem; }
    .support-main .block6 .container .texts {
      padding-top: 31.25rem; }
      .support-main .block6 .container .texts .text .title {
        width: 59%;
        margin-bottom: 1.875rem; }
    .support-main .block8 .container .texts {
      padding-top: 31.25rem; }
      .support-main .block8 .container .texts .text .title {
        width: 60%;
        margin-bottom: 1.875rem; }
    .support-main .block3,
    .support-main .block5,
    .support-main .block7 {
      padding-left: 3.125rem;
      padding-top: 4.375rem; }
      .support-main .block3 .container .texts,
      .support-main .block5 .container .texts,
      .support-main .block7 .container .texts {
        width: 100%;
        padding-top: 29.375rem;
        margin-left: 0; }
        .support-main .block3 .container .texts .text .title,
        .support-main .block5 .container .texts .text .title,
        .support-main .block7 .container .texts .text .title {
          width: 45%;
          margin-right: auto;
          margin-bottom: 3.125rem;
          font-size: 3.125rem;
          line-height: 3.5625rem; }
        .support-main .block3 .container .texts .text p,
        .support-main .block5 .container .texts .text p,
        .support-main .block7 .container .texts .text p {
          width: 95%;
          font-size: 1.75rem;
          line-height: 2.125rem; }
      .support-main .block3 .container .pic,
      .support-main .block5 .container .pic,
      .support-main .block7 .container .pic {
        width: 38.125rem;
        height: 38.125rem;
        position: absolute;
        top: 0;
        right: -10.625rem;
        margin-left: 0; }
        .support-main .block3 .container .pic img,
        .support-main .block5 .container .pic img,
        .support-main .block7 .container .pic img {
          width: 38.125rem;
          height: 38.125rem; }
    .support-main .block7 .container .pic {
      width: 38.125rem;
      height: 38.125rem;
      position: absolute;
      top: -3.125rem;
      right: -8.75rem;
      margin-left: 0; }
      .support-main .block7 .container .pic img {
        width: 38.125rem;
        height: 38.125rem; }
  .manufacturers-main .header {
    padding-top: 9.375rem;
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
    .manufacturers-main .header .pagename {
      font-size: 2.9375rem; }
    .manufacturers-main .header .search {
      margin-top: 1.25rem; }
      .manufacturers-main .header .search select {
        width: 100%;
        height: 6.125rem;
        margin-top: 0.9375rem;
        margin-right: 0;
        font-size: 1.875rem;
        background-size: 1.625rem; }
        .manufacturers-main .header .search select.alphabet {
          display: block; }
    .manufacturers-main .header .logo {
      display: none; }
  .manufacturers-main .links {
    display: none; }
  .manufacturers-main .main {
    padding-top: 1.875rem;
    padding-left: 3.125rem;
    padding-right: 3.125rem; }
    .manufacturers-main .main .logos {
      width: 100%; }
      .manufacturers-main .main .logos .separate {
        display: block; }
      .manufacturers-main .main .logos .logos-hide {
        display: none; }
      .manufacturers-main .main .logos .item {
        width: 50%;
        height: 19.5625rem; }
  .presscenter-main .first-screen {
    flex-direction: column; }
    .presscenter-main .first-screen .left {
      position: relative;
      width: 100%;
      height: auto;
      margin-top: 6.875rem; }
      .presscenter-main .first-screen .left .wrap {
        position: relative;
        width: 86%;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        padding-left: 0;
        padding-right: 0; }
        .presscenter-main .first-screen .left .wrap .pagename {
          font-size: 3.875rem; }
        .presscenter-main .first-screen .left .wrap .search input,
        .presscenter-main .first-screen .left .wrap .search select {
          width: 44%;
          height: 4.8125rem;
          font-size: 1.9375rem;
          border-radius: 1.5625rem; }
          .presscenter-main .first-screen .left .wrap .search input.year,
          .presscenter-main .first-screen .left .wrap .search select.year {
            width: 12rem;
            font-size: 2rem;
            text-align: center;
            text-align-last: center;
            border-radius: 0.625rem; }
            .presscenter-main .first-screen .left .wrap .search input.year option,
            .presscenter-main .first-screen .left .wrap .search select.year option {
              width: 12rem; }
          .presscenter-main .first-screen .left .wrap .search input.month,
          .presscenter-main .first-screen .left .wrap .search select.month {
            width: 23.75rem;
            font-size: 2rem;
            text-align: center;
            text-align-last: center;
            border-radius: 0.625rem; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .pic {
          width: 100%; }
          .presscenter-main .first-screen .left .wrap .presscenter-select .pic img {
            width: 100%;
            max-height: initial; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .new-presscenter {
          font-size: 3.75rem; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .category {
          font-size: 1.5625rem;
          line-height: 4.6875rem; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .title {
          font-size: 2.75rem;
          line-height: 3.125rem; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .desc {
          font-size: 1.6875rem;
          line-height: 1.6875rem; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .date {
          font-size: 1.875rem;
          line-height: 3.875rem; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .profile .photo {
          width: 10.625rem;
          height: 10.625rem; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .profile .texts .name {
          font-size: 2.125rem; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .profile .texts .position {
          font-size: 1.375rem;
          margin-top: 1.25rem; }
        .presscenter-main .first-screen .left .wrap .presscenter-select .profile .texts .contact {
          font-size: 1.375rem;
          margin-top: 1.25rem; }
          .presscenter-main .first-screen .left .wrap .presscenter-select .profile .texts .contact a {
            width: 1.875rem;
            height: 1.375rem; }
            .presscenter-main .first-screen .left .wrap .presscenter-select .profile .texts .contact a img {
              width: 1.875rem;
              height: 1.375rem; }
    .presscenter-main .first-screen .right {
      position: relative;
      width: 100%; }
      .presscenter-main .first-screen .right .presscenter-list {
        width: 93%;
        margin-left: 0;
        margin: auto;
        padding-left: 3.4375rem; }
        .presscenter-main .first-screen .right .presscenter-list .line {
          top: 11.25rem;
          left: 0.4375rem; }
        .presscenter-main .first-screen .right .presscenter-list .presscenter-item {
          margin-top: 1.875rem; }
          .presscenter-main .first-screen .right .presscenter-list .presscenter-item .pic {
            width: 15.375rem;
            height: 13rem; }
            .presscenter-main .first-screen .right .presscenter-list .presscenter-item .pic img {
              width: 15.375rem;
              height: 13rem; }
          .presscenter-main .first-screen .right .presscenter-list .presscenter-item .texts .category {
            font-size: 1.05rem; }
          .presscenter-main .first-screen .right .presscenter-list .presscenter-item .texts .title {
            font-size: 1.8rem; }
          .presscenter-main .first-screen .right .presscenter-list .presscenter-item .texts .desc {
            font-size: 1.1875rem; }
          .presscenter-main .first-screen .right .presscenter-list .presscenter-item .texts .date {
            font-size: 1.05rem; }
          .presscenter-main .first-screen .right .presscenter-list .presscenter-item .dot {
            left: -3.4375rem; }
          .presscenter-main .first-screen .right .presscenter-list .presscenter-item:after {
            width: 2.5rem;
            left: -2.5rem; }
  .presscenter-main .search-result .header {
    position: relative;
    width: 86%;
    margin: auto;
    padding-top: 9.375rem;
    padding-bottom: 0;
    padding-left: 0; }
    .presscenter-main .search-result .header .pagename {
      font-size: 3.875rem; }
    .presscenter-main .search-result .header .search input,
    .presscenter-main .search-result .header .search select {
      width: 44%;
      height: 4.8125rem;
      font-size: 1.9375rem;
      border-radius: 1.5625rem; }
      .presscenter-main .search-result .header .search input.year,
      .presscenter-main .search-result .header .search select.year {
        width: 12rem;
        font-size: 2rem;
        text-align: center;
        text-align-last: center;
        border-radius: 0.625rem; }
        .presscenter-main .search-result .header .search input.year option,
        .presscenter-main .search-result .header .search select.year option {
          width: 12rem; }
      .presscenter-main .search-result .header .search input.month,
      .presscenter-main .search-result .header .search select.month {
        width: 23.75rem;
        font-size: 2rem;
        text-align: center;
        text-align-last: center;
        border-radius: 0.625rem; }
    .presscenter-main .search-result .header .search a {
      font-size: 1.9375rem; }
    .presscenter-main .search-result .header .logo {
      display: none; }
  .presscenter-main .search-result .main {
    flex-direction: column; }
    .presscenter-main .search-result .main .left {
      width: 100%;
      position: relative; }
      .presscenter-main .search-result .main .left .presscenter-list {
        width: 93%;
        margin-left: 0;
        margin: auto;
        padding-left: 3.4375rem;
        padding-bottom: 0; }
        .presscenter-main .search-result .main .left .presscenter-list .line {
          top: 11.25rem;
          left: 0.4375rem; }
        .presscenter-main .search-result .main .left .presscenter-list .presscenter-item {
          margin-top: 1.875rem; }
          .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .pic {
            width: 15.375rem;
            height: 13rem; }
            .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .pic img {
              width: 15.375rem;
              height: 13rem; }
          .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .texts .category {
            font-size: 1.05rem; }
          .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .texts .title {
            font-size: 1.8rem; }
          .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .texts .desc {
            font-size: 1.1875rem; }
          .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .texts .date {
            font-size: 1.05rem; }
          .presscenter-main .search-result .main .left .presscenter-list .presscenter-item .dot {
            left: -3.4375rem; }
          .presscenter-main .search-result .main .left .presscenter-list .presscenter-item:after {
            width: 2.5rem;
            left: -2.5rem; }
    .presscenter-main .search-result .main .right {
      width: 100%; }
      .presscenter-main .search-result .main .right .presscenter-list {
        width: 93%;
        margin-left: 0;
        margin: auto;
        padding-left: 3.4375rem;
        padding-top: 0; }
        .presscenter-main .search-result .main .right .presscenter-list .line {
          top: 11.25rem;
          left: 0.4375rem; }
        .presscenter-main .search-result .main .right .presscenter-list .presscenter-item {
          margin-top: 1.875rem; }
          .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .pic {
            width: 15.375rem;
            height: 13rem; }
            .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .pic img {
              width: 15.375rem;
              height: 13rem; }
          .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .texts .category {
            font-size: 1.05rem; }
          .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .texts .title {
            font-size: 1.8rem; }
          .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .texts .desc {
            font-size: 1.1875rem; }
          .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .texts .date {
            font-size: 1.05rem; }
          .presscenter-main .search-result .main .right .presscenter-list .presscenter-item .dot {
            left: -3.4375rem; }
          .presscenter-main .search-result .main .right .presscenter-list .presscenter-item:after {
            width: 2.5rem;
            left: -2.5rem; }
  .presscenter-main .presscenter-single .left {
    width: 86%;
    margin: auto;
    padding: 9.375rem 0 3.125rem; }
    .presscenter-main .presscenter-single .left .meta .text_wrap .category {
      font-size: 1.0625rem; }
    .presscenter-main .presscenter-single .left .meta .text_wrap .title {
      font-size: 3.28125rem; }
    .presscenter-main .presscenter-single .left .presscenter-single-text {
      font-size: 1.64375rem;
      line-height: 1.6875rem; }
    .presscenter-main .presscenter-single .left .footer .social span {
      font-size: 1.5625rem;
      margin-right: 0.9375rem; }
    .presscenter-main .presscenter-single .left .footer .social .icon {
      width: 3.75rem;
      height: 3.75rem;
      margin-right: 0.625rem; }
  .presscenter-main .presscenter-single .right {
    display: none; }
  .direction-details-main {
    flex-direction: column; }
    .direction-details-main .left {
      width: 100%;
      height: 7rem;
      top: 7rem;
      overflow: hidden;
      cursor: pointer;
      transition: 0.5s; }
      .direction-details-main .left.active {
        height: 36.875rem; }
      .direction-details-main .left .logo {
        display: none; }
      .direction-details-main .left .wrap {
        width: calc(92% - 3.75rem);
        position: absolute;
        top: 0.9375rem;
        bottom: initial;
        left: 3.75rem;
        margin-left: 0; }
        .direction-details-main .left .wrap .title {
          font-size: 3.875rem;
          position: relative;
          display: inline-block; }
          .direction-details-main .left .wrap .title:before {
            content: '';
            background: url(../img/arrow-right.svg) 50% 50% no-repeat;
            background-size: contain;
            width: 1.125rem;
            height: 1.25rem;
            position: absolute;
            top: 16%;
            bottom: 0;
            right: -12.5%;
            margin: auto;
            filter: brightness(0) invert(1); }
        .direction-details-main .left .wrap form select {
          width: 100%;
          height: 5.5625rem;
          font-size: 1.75rem; }
          .direction-details-main .left .wrap form select option {
            font-size: 1.75rem; }
        .direction-details-main .left .wrap .items .line {
          height: calc(100% - 4rem);
          top: 5%; }
        .direction-details-main .left .wrap .items .item {
          font-size: 2.5rem; }
    .direction-details-main .right {
      width: 100%; }
      .direction-details-main .right .screen {
        margin-top: 4.375rem; }
        .direction-details-main .right .screen > .title {
          padding-left: 3.125rem;
          padding-right: 3.125rem;
          font-size: 3.125rem; }
        .direction-details-main .right .screen > p {
          padding-left: 3.125rem;
          padding-right: 3.125rem;
          font-size: 1.75rem; }
        .direction-details-main .right .screen .title2 span {
          font-size: 2.5rem; }
        .direction-details-main .right .screen .contacts {
          padding-left: 3.125rem;
          padding-right: 3.125rem; }
          .direction-details-main .right .screen .contacts .items .wrap {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            opacity: 0;
            display: none;
            transition: 1s; }
            .direction-details-main .right .screen .contacts .items .wrap.active {
              opacity: 1;
              display: flex !important; }
          .direction-details-main .right .screen .contacts .items .separate {
            display: block; }
            .direction-details-main .right .screen .contacts .items .separate.hide {
              display: none; }
          .direction-details-main .right .screen .contacts .items .item {
            width: 100%; }
            .direction-details-main .right .screen .contacts .items .item .photo {
              width: 12.1875rem;
              height: 12.1875rem; }
              .direction-details-main .right .screen .contacts .items .item .photo img {
                width: 12.1875rem;
                height: 12.1875rem; }
            .direction-details-main .right .screen .contacts .items .item .texts {
              width: calc(100% - 12.1875rem - .9375rem); }
              .direction-details-main .right .screen .contacts .items .item .texts .name {
                font-size: 2.4375rem; }
              .direction-details-main .right .screen .contacts .items .item .texts .position {
                font-size: 1.5625rem;
                margin-top: 1.5625rem;
                line-height: 1.875rem; }
              .direction-details-main .right .screen .contacts .items .item .texts .contact {
                font-size: 1.5625rem;
                margin-top: 1.5625rem; }
                .direction-details-main .right .screen .contacts .items .item .texts .contact a {
                  width: 2.125rem;
                  height: 1.625rem; }
                  .direction-details-main .right .screen .contacts .items .item .texts .contact a img {
                    width: 2.125rem;
                    height: 1.625rem; }
        .direction-details-main .right .screen .brands {
          padding-left: 3.125rem;
          padding-right: 3.125rem; }
          .direction-details-main .right .screen .brands .logos .separate {
            color: #b5b6b6;
            font-size: 1.5rem;
            font-family: 'PT Sans', sans-serif;
            font-weight: 400;
            line-height: 2rem;
            margin-bottom: 1.25rem;
            margin-top: 1.25rem;
            text-align: center; }
            .direction-details-main .right .screen .brands .logos .separate:after {
              display: none; }
          .direction-details-main .right .screen .brands .logos .item {
            width: 50%;
            height: 19.375rem; }
        .direction-details-main .right .screen .news,
        .direction-details-main .right .screen .promos,
        .direction-details-main .right .screen .events {
          padding-left: 3.125rem;
          padding-right: 3.125rem; }
          .direction-details-main .right .screen .news .slider .slide a img,
          .direction-details-main .right .screen .promos .slider .slide a img,
          .direction-details-main .right .screen .events .slider .slide a img {
            width: 100%;
            height: 15.125rem; }
          .direction-details-main .right .screen .news .slider .slide a .title,
          .direction-details-main .right .screen .promos .slider .slide a .title,
          .direction-details-main .right .screen .events .slider .slide a .title {
            font-size: 2.5rem;
            line-height: 2.6875rem; }
          .direction-details-main .right .screen .news .slider .slide a .desk,
          .direction-details-main .right .screen .promos .slider .slide a .desk,
          .direction-details-main .right .screen .events .slider .slide a .desk {
            font-size: 1.75rem; }
          .direction-details-main .right .screen .news .slider .slide a .date,
          .direction-details-main .right .screen .promos .slider .slide a .date,
          .direction-details-main .right .screen .events .slider .slide a .date {
            font-size: 1.5rem; }
        .direction-details-main .right .screen .popup {
          width: 90%;
          height: auto;
          position: fixed;
          top: 15.625rem;
          right: 0;
          bottom: initial;
          left: 0;
          margin: auto; }
          .direction-details-main .right .screen .popup .title {
            font-size: 2.9375rem; }
          .direction-details-main .right .screen .popup .form {
            padding-bottom: 1.25rem; }
            .direction-details-main .right .screen .popup .form form label p {
              font-size: 1.5625rem; }
            .direction-details-main .right .screen .popup .form form .bottom {
              flex-direction: column-reverse; }
              .direction-details-main .right .screen .popup .form form .bottom input[type="submit"] {
                margin-top: 1.25rem; }
              .direction-details-main .right .screen .popup .form form .bottom .captcha {
                width: 80%; }
  .brand-main {
    flex-direction: column; }
    .brand-main .left {
      width: 100%;
      height: auto;
      top: 7rem;
      overflow: hidden;
      cursor: pointer;
      transition: 0.5s; }
      .brand-main .left .logo {
        display: none; }
      .brand-main .left .wrap {
        width: calc(92% - 3.75rem);
        position: relative;
        top: initial;
        left: initial;
        margin-top: 0.9375rem;
        margin-left: 0; }
        .brand-main .left .wrap .title {
          font-size: 3.125rem;
          position: relative;
          display: table-caption; }
          .brand-main .left .wrap .title:before {
            content: '';
            background: url(../img/arrow-right.svg) 50% 50% no-repeat;
            background-size: contain;
            width: 1.125rem;
            height: 1.25rem;
            position: absolute;
            top: 16%;
            bottom: 0;
            right: -12.5%;
            margin: auto;
            filter: brightness(0) invert(1); }
        .brand-main .left .wrap form {
          display: none; }
          .brand-main .left .wrap form select {
            width: 100%;
            height: 5.5625rem;
            font-size: 1.75rem; }
            .brand-main .left .wrap form select option {
              font-size: 1.75rem; }
        .brand-main .left .wrap .items {
          display: none;
          padding-bottom: 1.875rem; }
          .brand-main .left .wrap .items .line {
            height: calc(100% - 4rem - 1.875rem);
            top: calc(-0.6% - 1.875rem); }
          .brand-main .left .wrap .items .item {
            font-size: 2.5rem; }
            .brand-main .left .wrap .items .item p {
              font-size: 2.1875rem; }
          .brand-main .left .wrap .items .toggle-list-2 .line {
            height: calc(100% - 1.875rem); }
          .brand-main .left .wrap .items .toggle-list-2 .item {
            font-size: 2.1875rem; }
          .brand-main .left .wrap .items .toggle-list-3 .item {
            font-size: 2.1875rem; }
    .brand-main .right {
      width: 100%; }
      .brand-main .right .screen .logo-brand {
        margin: 5rem auto 1.875rem;
        padding-left: 0;
        padding-right: 0;
        height: 6.5625rem; }
        .brand-main .right .screen .logo-brand img {
          height: 6.5625rem;
          object-position: 0 50%; }
      .brand-main .right .screen > .title {
        text-align: center;
        font-size: 3.125rem;
        padding-left: 3.125rem;
        padding-right: 3.125rem; }
      .brand-main .right .screen .title2 {
        font-size: 2.125rem; }
      .brand-main .right .screen .video {
        padding-left: 3.125rem;
        padding-right: 3.125rem;
        margin: 0 auto;
        text-align: center; }
      .brand-main .right .screen .title_sm {
        padding-left: 3.125rem;
        padding-right: 3.125rem;
        font-size: 2.5rem;
        line-height: 3rem; }
      .brand-main .right .screen > p {
        padding-left: 3.125rem;
        padding-right: 3.125rem;
        font-size: 1.75rem;
        line-height: 2.125rem; }
      .brand-main .right .screen ul {
        padding-left: 3.125rem;
        padding-right: 3.125rem; }
        .brand-main .right .screen ul li {
          font-size: 1.75rem;
          line-height: 2.125rem; }
      .brand-main .right .screen ol {
        padding-left: 3.125rem;
        padding-right: 3.125rem; }
        .brand-main .right .screen ol li {
          font-size: 1.75rem;
          line-height: 2.125rem; }
      .brand-main .right .screen .cols {
        flex-direction: column; }
        .brand-main .right .screen .cols .col {
          width: 100%; }
      .brand-main .right .screen .pic {
        padding-left: 3.125rem;
        padding-right: 3.125rem; }
      .brand-main .right .screen .contacts .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between; }
        .brand-main .right .screen .contacts .items .item {
          width: 100%; }
          .brand-main .right .screen .contacts .items .item .photo {
            width: 12.1875rem;
            height: 12.1875rem; }
            .brand-main .right .screen .contacts .items .item .photo img {
              width: 12.1875rem;
              height: 12.1875rem; }
          .brand-main .right .screen .contacts .items .item .texts {
            width: calc(100% - 12.1875rem - .9375rem); }
            .brand-main .right .screen .contacts .items .item .texts .name {
              font-size: 2.4375rem; }
            .brand-main .right .screen .contacts .items .item .texts .position {
              font-size: 1.5625rem;
              margin-top: 1.5625rem;
              line-height: 1.875rem; }
            .brand-main .right .screen .contacts .items .item .texts .contact {
              font-size: 1.5625rem;
              margin-top: 1.5625rem; }
              .brand-main .right .screen .contacts .items .item .texts .contact a {
                width: 2.125rem;
                height: 1.625rem; }
                .brand-main .right .screen .contacts .items .item .texts .contact a img {
                  width: 2.125rem;
                  height: 1.625rem; }
      .brand-main .right .screen .news .slider .slide a img,
      .brand-main .right .screen .promos .slider .slide a img,
      .brand-main .right .screen .events .slider .slide a img {
        width: 100%;
        height: 15.125rem; }
      .brand-main .right .screen .news .slider .slide a .title,
      .brand-main .right .screen .promos .slider .slide a .title,
      .brand-main .right .screen .events .slider .slide a .title {
        font-size: 2.5rem;
        line-height: 2.6875rem; }
      .brand-main .right .screen .news .slider .slide a .desk,
      .brand-main .right .screen .promos .slider .slide a .desk,
      .brand-main .right .screen .events .slider .slide a .desk {
        font-size: 1.75rem; }
      .brand-main .right .screen .news .slider .slide a .date,
      .brand-main .right .screen .promos .slider .slide a .date,
      .brand-main .right .screen .events .slider .slide a .date {
        font-size: 1.5rem; }
  .error .wrap {
    width: 110vw;
    height: 110vw; }
  .condition-main {
    display: flex; }
    .condition-main .logo {
      width: 12.375rem;
      height: 10.25rem;
      position: absolute;
      top: 1.875rem;
      right: 6.25rem; }
    .condition-main .left {
      background-color: #00aeef;
      width: 37.5rem;
      height: 100vh;
      position: sticky;
      top: 0;
      left: 0;
      box-shadow: 0.4375rem 0.4375rem 0.3125rem rgba(0, 0, 0, 0.1);
      z-index: 10;
      box-sizing: border-box; }
      .condition-main .left .wrap {
        width: 23.125rem;
        height: 34%;
        position: absolute;
        top: 0;
        left: 4rem;
        bottom: 0;
        right: 0;
        margin: auto; }
        .condition-main .left .wrap .title {
          font-family: 'PT Sans', sans-serif;
          font-weight: 700;
          font-size: 3rem;
          margin-bottom: 1.875rem; }
        .condition-main .left .wrap .items {
          position: relative; }
          .condition-main .left .wrap .items .line {
            width: 1px;
            height: calc(100% - 4.5rem);
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0.4375rem;
            bottom: 0;
            margin: auto; }
          .condition-main .left .wrap .items .item {
            font-family: 'PT Sans', sans-serif;
            font-size: 1.53125rem;
            margin-bottom: 1.5625rem;
            padding-left: 1.875rem;
            position: relative;
            cursor: pointer; }
            .condition-main .left .wrap .items .item .dot {
              width: 1rem;
              height: 1rem;
              background-color: #fff;
              border-radius: 50%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              margin: auto; }
    .condition-main .right {
      width: calc(100% - 37.5rem);
      padding-top: 5.625rem;
      padding-left: 5rem;
      padding-right: 5rem;
      box-sizing: border-box;
      position: relative; }
      .condition-main .right .screen {
        display: none; }
        .condition-main .right .screen .title {
          color: #00aeef;
          font-size: 3rem;
          font-family: 'PT Sans Caption', sans-serif;
          font-weight: 700;
          line-height: 3.59375rem;
          margin-bottom: 3.75rem; }
        .condition-main .right .screen .title2 {
          color: #000;
          font-size: 1.8125rem;
          font-family: 'PT Sans', sans-serif;
          font-weight: 700;
          line-height: 2.125rem;
          margin-top: 1.5625rem;
          margin-bottom: 1.5625rem; }
        .condition-main .right .screen > p {
          color: #000;
          font-size: 1.5625rem;
          line-height: 1.875rem;
          font-family: 'PT Sans', sans-serif;
          margin: 1.25rem 0; }
          .condition-main .right .screen > p span {
            font-weight: 700; }
        .condition-main .right .screen ul {
          padding-left: 0;
          list-style: none; }
          .condition-main .right .screen ul li {
            color: #000;
            font-size: 1.5625rem;
            line-height: 1.875rem;
            font-family: 'PT Sans', sans-serif; }
  .cisco-landing .hero {
    background: url(../img/cisco-landing/bg-mob.png) 50% 50% no-repeat;
    background-size: cover;
    height: auto; }
    .cisco-landing .hero .container {
      height: auto; }
      .cisco-landing .hero .container .man {
        visibility: hidden !important; }
      .cisco-landing .hero .container .header {
        position: relative;
        margin-top: 10rem;
        top: initial;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        height: auto; }
        .cisco-landing .hero .container .header .logos {
          width: 100%; }
        .cisco-landing .hero .container .header .menu {
          display: none; }
      .cisco-landing .hero .container .texts {
        display: block;
        width: 100%;
        height: auto;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        box-sizing: border-box;
        position: relative;
        top: initial;
        margin-top: 9.375rem; }
        .cisco-landing .hero .container .texts .title {
          font-size: 4.25rem;
          width: 90%; }
        .cisco-landing .hero .container .texts .desc {
          font-size: 2.25rem;
          line-height: 2.6875rem;
          margin-top: 4.375rem; }
        .cisco-landing .hero .container .texts .btn {
          width: 38.125rem;
          height: 7.3125rem;
          border-radius: 3.4375rem;
          margin: 0 auto;
          font-size: 2.125rem;
          margin-top: 7.5rem; }
  .cisco-landing .advantages {
    width: 100%; }
    .cisco-landing .advantages .wrap {
      flex-direction: column;
      margin-top: 0; }
      .cisco-landing .advantages .wrap .item {
        width: 100%;
        height: 39.375rem;
        padding-top: 8.125rem;
        padding-left: 3.125rem; }
        .cisco-landing .advantages .wrap .item.item1 {
          order: 1; }
        .cisco-landing .advantages .wrap .item.item2 {
          order: 4; }
        .cisco-landing .advantages .wrap .item.item3 {
          order: 5; }
        .cisco-landing .advantages .wrap .item.item4 {
          order: 8; }
        .cisco-landing .advantages .wrap .item.item5 {
          order: 2; }
        .cisco-landing .advantages .wrap .item.item6 {
          order: 3; }
        .cisco-landing .advantages .wrap .item.item7 {
          order: 6; }
        .cisco-landing .advantages .wrap .item.item8 {
          order: 7; }
        .cisco-landing .advantages .wrap .item .line {
          display: none; }
        .cisco-landing .advantages .wrap .item .title {
          font-size: 3.5rem; }
        .cisco-landing .advantages .wrap .item .desc {
          font-size: 2.5rem; }
        .cisco-landing .advantages .wrap .item .pic {
          max-width: 22.5rem;
          max-height: 22.5rem; }
  .cisco-landing .smartnet {
    margin-top: 0; }
    .cisco-landing .smartnet .container {
      height: auto;
      display: flex;
      flex-direction: column-reverse; }
      .cisco-landing .smartnet .container .scheme {
        position: relative;
        width: 39.875rem;
        height: 113.3125rem;
        left: initial;
        margin-top: 6.25rem;
        margin-bottom: 6.25rem; }
        .cisco-landing .smartnet .container .scheme img {
          object-fit: contain;
          object-position: 50% 0%; }
      .cisco-landing .smartnet .container .slider-smart-net {
        background: url(../img/cisco-landing/bg-slider-smart-sm.svg) 50% 100% no-repeat;
        background-size: cover;
        width: 100%;
        height: 68.75rem;
        position: relative;
        top: 0; }
        .cisco-landing .smartnet .container .slider-smart-net:before {
          display: none; }
        .cisco-landing .smartnet .container .slider-smart-net .slider-nav {
          color: #000;
          position: absolute;
          bottom: 12%;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 10;
          font-size: 2.625rem;
          color: #fff;
          font-weight: 700;
          width: 95%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          visibility: visible; }
          .cisco-landing .smartnet .container .slider-smart-net .slider-nav .slick-prev,
          .cisco-landing .smartnet .container .slider-smart-net .slider-nav .slick-next {
            cursor: pointer;
            width: 35%; }
          .cisco-landing .smartnet .container .slider-smart-net .slider-nav .slick-next {
            text-align: right; }
        .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap {
          width: 100%;
          height: auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto; }
          .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .slick-track {
            top: 0; }
          .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item {
            height: auto;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            transform: scale(1) translateY(0);
            transition: 0.4s;
            outline: none;
            margin: 0 2.5rem; }
            .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item .title {
              font-size: 4.25rem;
              font-weight: 700;
              text-align: center;
              margin-top: 1.875rem;
              order: 1;
              visibility: visible;
              opacity: 1; }
            .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item .desc {
              font-family: 'PT Sans Caption', sans-serif;
              font-size: 2.125rem;
              margin-top: 3.75rem;
              text-align: center;
              order: 2;
              opacity: 0;
              transition: 0;
              visibility: visible;
              opacity: 1; }
            .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item .btn {
              font-family: 'Open Sans', sans-serif;
              color: #fff;
              background-color: #00aeef;
              text-decoration: none;
              font-size: 2.75rem;
              border: 2px solid #fff;
              box-sizing: border-box;
              border-radius: 4.375rem;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              order: 3;
              width: 30.875rem;
              height: 9.625rem;
              outline: none;
              margin-top: 3.75rem;
              visibility: visible;
              opacity: 1;
              transition: opacity 0s, background-color 0.3s, color 0.3s; }
              .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item .btn:hover {
                background-color: #fff;
                color: #00aeef; }
            .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item.slick-active {
              transform: scale(1) translateY(0); }
              .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item.slick-active .desc {
                opacity: 1;
                visibility: visible; }
              .cisco-landing .smartnet .container .slider-smart-net .carousel_wrap .item.slick-active .btn {
                opacity: 1;
                visibility: visible; }
  .cisco-landing .promo .container {
    padding-right: 2.5rem;
    padding-left: 2.5rem; }
    .cisco-landing .promo .container .title {
      font-size: 3.375rem; }
    .cisco-landing .promo .container .desc {
      font-size: 2.375rem; }
    .cisco-landing .promo .container .btns {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%; }
      .cisco-landing .promo .container .btns .btn {
        font-size: 2.8125rem;
        text-align: center;
        height: 11.25rem; }
        .cisco-landing .promo .container .btns .btn.btn1 {
          padding: 0 2.5rem;
          width: 39.5rem; }
        .cisco-landing .promo .container .btns .btn.btn2 {
          margin-top: 3.125rem;
          width: 31.125rem; }
  .cisco-landing .capabilities .container > .title {
    width: 87%;
    margin: 0 auto;
    font-size: 4.25rem;
    line-height: 5.0625rem; }
  .cisco-landing .capabilities .container > .desс {
    font-size: 2.25rem;
    line-height: 2.6875rem; }
  .cisco-landing .capabilities .container > .wrap {
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .cisco-landing .capabilities .container > .wrap .item {
      margin-bottom: 6.25rem; }
      .cisco-landing .capabilities .container > .wrap .item .pic {
        width: 22.3125rem;
        height: 22.3125rem; }
      .cisco-landing .capabilities .container > .wrap .item .text {
        font-size: 2.5rem;
        line-height: 2.5rem; }
  .cisco-landing .capabilities .container .list_wrap {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-top: 3.125rem; }
    .cisco-landing .capabilities .container .list_wrap .title {
      font-weight: 700;
      font-size: 4.25rem;
      line-height: 5.0625rem;
      text-align: center; }
    .cisco-landing .capabilities .container .list_wrap .list:before {
      top: -0.6%;
      left: 0.9375rem; }
    .cisco-landing .capabilities .container .list_wrap .list .item {
      font-size: 2.5rem;
      line-height: 2.5rem;
      margin-top: 5rem; }
      .cisco-landing .capabilities .container .list_wrap .list .item:before {
        width: 2.0625rem;
        height: 2.0625rem; }
  .cisco-landing .help .container .wrap {
    width: 40rem;
    height: 40rem; }
    .cisco-landing .help .container .wrap .title {
      font-size: 1.9375rem;
      line-height: 1.9375rem; }
    .cisco-landing .help .container .wrap .list .item {
      font-size: 1.6875rem;
      line-height: 1.9375rem; }
      .cisco-landing .help .container .wrap .list .item:before {
        width: 1.0625rem;
        height: 1.0625rem;
        top: 0.3125rem; }
    .cisco-landing .help .container .wrap .list:before {
      height: 58%;
      right: -2.5rem; } }

@media (max-width: 414px) {
  .directions-mob .line {
    background-color: #fff;
    width: 1px;
    position: absolute;
    top: 0;
    left: 4.4375rem;
    transition: 0.35s; } }
