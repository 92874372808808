@import "../../bower_components/normalize.css/normalize.css";
@import "../../node_modules/sass-rem/rem";


html {
    font-family: 'PT Sans', sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

@media (min-width: 769px) and (max-width: 1920px) {
    html {
        font-size: calc(6px + (16 - 6) * ((100vw - 769px) / (1920 - 769)));
    }
}

@media (min-width: 290px) and (max-width: 768px) {
    html {
        font-size: calc(6px + (16 - 6) * ((100vw - 300px) / (768 - 300)));
    }
}

body {
    margin: 0;
    padding: 0;
}

picture img {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

br.desk {
    display: block;
}

br.mob {
    display: none;
}

h1 {
    font-family: 'PT Sans Bold', sans-serif;
    color: #12b9f3;
    font-size: rem(58.43px);
    margin-top: rem(50px);
    margin-bottom: rem(35px);
    padding-left: 5rem;
}

h2 {
    font-family: 'PT Sans Bold', sans-serif;
    color: #12b9f3;
    font-size: rem(53.125px);
    margin-top: rem(40px);
    margin-bottom: rem(30px);
    padding-left: 5rem;
}

h3 {
    font-family: 'PT Sans Bold', sans-serif;
    color: #12b9f3;
    font-size: rem(46.48px);
    margin-top: rem(30px);
    margin-bottom: rem(20px);
    padding-left: 5rem;
}

h4 {
    font-family: 'PT Sans Bold CAPS', sans-serif;
    color: #000;
    font-size: rem(35.85px);
    margin-top: rem(30px);
    margin-bottom: rem(20px);
    padding-left: 5rem;
}

h5 {
    font-family: 'PT Sans Bold ', sans-serif;
    color: #000;
    font-size: rem(26.56px);
    margin-top: rem(20px);
    margin-bottom: rem(20px);
    padding-left: 5rem;
}

.container {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.mb-bar { 
    background-color: rgba(255, 255, 255, 0.9); 
}

.mb-track {
  background-color: rgba(255, 255, 255, 0.25);
}


.p-left-160 {
    padding-left: rem(160px);
    box-sizing: border-box;
}

.p-right-160 {
    padding-right: rem(160px);
    box-sizing: border-box;
}

.p-left-190 {
    padding-left: rem(190px);
    box-sizing: border-box;
}

.p-right-190 {
    padding-right: rem(190px);
    box-sizing: border-box;
}

.cookie-warning {
    background-color: #00aeef;

    width: rem(400px);
    height: rem(200px);

    position: fixed;
    top: 0;
    right: 2%;

    opacity: 0.95;

    transition: opacity 0.3s, visibility 0.3s 0s;

    &.hide {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    .wrap {
        width: 88%;
        height: 90%;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;

        p {
            color: #fff;
            font-size: rem(11px);
        }

        button {
            appearance: none;

            background-color: #fff;

            color: #00aeef;

            border: none;
            box-shadow: none;

            font-size: rem(17px);

            padding: rem(10px 40px);

            cursor: pointer;

            margin-top: rem(10px);
        }
    }
}

.preloader-global {
    width: 100%;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    z-index: 100000;

    background-color: #fff;

    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s, visibility 0.3s 0s;

    &.hide {
        opacity: 0;
        visibility: hidden;
    }

    .load {
        background: url(../img/logo_blue.svg) 50% 50% no-repeat;
        background-size: contain;
        
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        width: rem(200px);
        height: rem(200px);

        animation-name: loader;
        animation-duration: 0.8s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }

    @keyframes loader {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(-1, 1);
        }
        100% {
            transform: scale(1, 1);
        }
    }
}

.hero {
    background-color: #12b9f3;

    height: 100vh;

    overflow: hidden;

    .container {
        height: 100vh;

        .texts {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            width: 36%;
            height: 85vh;

            position: absolute;
            top: 0;
            left: rem(190px);
            bottom: 0;
            margin: auto;

            z-index: 11;

            .logo {
                width: rem(471px);
                height: rem(390px);
                position: relative;
            }

            .circle_mob {
                display: none;
            }

            .title {
                font-family: 'PT Sans Caption', sans-serif;
                font-size: rem(48px);
            }

            .btns {
                width: rem(588px);
                height: rem(87px);

                position: relative;

                a {
                    color: #fff;
                    text-decoration: none;
                    font-size: rem(25px);

                    border: none;
                    box-sizing: border-box;

                    border-radius: rem(35px);

                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    width: rem(284px);
                    height: rem(87px);

                    outline: none;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;

                    &:last-child {
                        left: initial;
                        right: 0;
                    }
                }

                .border {
                    width: rem(284px);
                    height: rem(87px);

                    border: 2px solid #fff;
                    box-sizing: border-box;
                    border-radius: rem(35px);

                    outline: none;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;

                    transition: .3s ease-out all;
                }
            }
        }

        .video {
            width: 100%;
            height: 100vh;

            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            overflow: hidden;

            z-index: 10;

            &:after {
                content: '';
                position: absolute;

                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;

                background: rgb(18, 185, 243);
                background: linear-gradient(90deg, rgba(18, 185, 243, 1) 75%, rgba(18, 185, 243, 0) 100%);

                width: 60%;
                height: 100vh;
            }

            video {
                height: 100vh;

                object-fit: cover;

                position: absolute;
                top: 0;
                bottom: 0;
                right: rem(-150px);
                margin: auto;
            }
        }

        

        .plane_banner {
            background: url(../img/_temp-pics/plane_banner.svg) 50% 50% no-repeat;
            background-size: contain;

            width: 43%;
            height: 12%;

            position: absolute;
            bottom: 7%;
            right: 5%;
            margin: auto;

            z-index: 100;

            opacity: 0;
            transform: translateX(150%);

            transition: transform 0.8s, opacity 0.3s;

            &.active {
                opacity: 1;
                transform: translateX(0%);
            }

            &.hide {
                opacity: 0;
                transform: translateX(-50%);
            }
        }
    }
}

.slider {
    @import "partials/slick-theme.scss";

    margin-top: rem(40px);

    overflow: hidden;
    outline: none;

    height: rem(600px);

    .slide {
        position: relative;
        outline: none;
        height: rem(600px);

        iframe {
            width: 100%;
            height: 100%;
        }

        img {
            height: 100%;
        }

        .text_wrap {
            background-color: #00aeef;

            width: rem(620px);
            height: rem(490px);

            position: absolute;
            top: 0;
            left: rem(190px);
            bottom: 0;
            margin: auto;

            padding: rem(70px);
            box-sizing: border-box;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            .title {
                font-family: 'PT Sans Caption', sans-serif;
                font-size: rem(48px);
            }

            .btn {
                color: #fff;
                text-decoration: none;
                font-size: rem(25px);

                border: 2px solid #fff;
                box-sizing: border-box;

                border-radius: rem(35px);

                display: inline-flex;
                justify-content: center;
                align-items: center;

                width: rem(284px);
                height: rem(87px);

                outline: none;
            }
        }
    }
}

.announcement {
    margin-top: rem(50px);

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .video_wrap {
            width: rem(778px);
            height: rem(367px);

            overflow: hidden;

            position: relative;

            cursor: pointer;

            background-color: #000;

            video {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
            }

            .poster {
                width: 100%;
                height: 100%;

                position: absolute;
                top: -1px;
                left: 0;
                right: 0;
                margin: auto;

                opacity: 1;
                transition: opacity 0.25s;
                visibility: visible;

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: cover;
                }

                &.hide {
                    opacity: 0;
                    visibility: hidden;
                }
            }


            .sound {
                background: url('../img/volume.svg') 50% 50% no-repeat;
                background-size: 50%;

                position: absolute;

                width: rem(45px);
                height: rem(45px);

                background-color: #fff;
                border-radius: 50%;

                bottom: 5%;
                right: 2%;

                transition: 0.3s;

                opacity: 0;
                visibility: hidden;

                &.active {
                    opacity: 0.8;
                    visibility: visible;
                }
            }

            .mute {
                background: url('../img/mute.svg') 50% 50% no-repeat;
                background-size: 50%;

                position: absolute;

                width: rem(45px);
                height: rem(45px);

                background-color: #fff;
                border-radius: 50%;

                bottom: 5%;
                right: 2%;

                transition: 0.3s;

                opacity: 0;
                visibility: hidden;

                &.active {
                    opacity: 0.8;
                    visibility: visible;
                }
            }

            .texts {
                font-family: 'PT Sans Caption', sans-serif;

                background-color: #00aeef;

                width: 100%;
                height: rem(100px);

                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;

                font-size: rem(48px);

                display: flex;
                justify-content: flex-start;
                align-items: center;

                padding-left: rem(50px);
                box-sizing: border-box;

                opacity: 1;
                transition: opacity 0.25s;
                visibility: visible;

                &.hide {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
}

.feed {
    margin-top: rem(60px);

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .feed-wrap {
            position: relative;

            .feed-news,
            .feed-promo,
            .feed-events {
                width: rem(740px);

                position: absolute;
                top: 0;
                opacity: 0;
                visibility: hidden;

                &.active {
                    position: relative;
                    opacity: 1;
                    visibility: visible;
                }

                .item {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    margin-top: rem(30px);

                    cursor: pointer;

                    .pic {
                        width: rem(276px);
                        height: rem(235px);

                        filter: none;
                        transition: 0.25s;

                        img {
                            width: rem(276px);
                            height: rem(235px);

                            object-fit: cover;
                        }
                    }

                    .texts {
                        margin-left: rem(50px);

                        .category {
                            font-size: rem(16px);
                            color: #b7b7b7;
                        }

                        .title {
                            font-size: rem(32px);
                            color: #00aeef;
                            font-weight: 700;

                            margin-top: rem(20px);
                        }

                        .desc {
                            font-size: rem(17px);
                            color: #000;

                            margin-top: rem(15px);
                        }

                        .date {
                            font-size: rem(19px);
                            color: #b7b7b7;

                            margin-top: rem(20px);
                        }
                    }

                    &:hover .pic {
                        filter: contrast(0.3) brightness(1.5);
                    }
                }
            }

            .loader {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;

                background-color: #fff;

                visibility: visible;
                opacity: 1;
                transition: opacity 0.3s, visibility 0.3s 0s;

                &.hide {
                    opacity: 0;
                    visibility: hidden;
                }

                .load {
                    position: sticky;
                    top: 50%;
                    left: 0;
                    right: 0;
                    margin: auto;
                    margin-top: 40%;
                    margin-bottom: 40%;

                    width: rem(100px);
                    height: rem(100px);

                    hr {
                        border: 0;
                        margin: 0;
                        width: 40%;
                        height: 40%;
                        position: absolute;
                        border-radius: 50%;
                        animation: spin 2s ease infinite;
                    }

                    :first-child {
                        background: #ffe000;
                        animation-delay: -1.5s;
                    }

                    :nth-child(2) {
                        background: #009cd7;
                        animation-delay: -1s;
                    }

                    :nth-child(3) {
                        background: #ffe000;
                        animation-delay: -0.5s;
                    }

                    :last-child {
                        background: #009cd7;
                    }

                    @keyframes spin {

                        0%{
                            transform: translate(0)
                        }

                        25% {
                            transform: translate(160%)
                        }

                        50% {
                            transform: translate(160%, 160%)
                        }

                        75% {
                            transform: translate(0, 160%)
                        }

                        100% {
                            transform: translate(0)
                        }
                    }
                }
            }
        }

 

        .revol {
            background: url(../img/bg_promo.svg) 0% 50% no-repeat;
            background-size: cover;

            width: rem(920px);
            height: 100vh;

            position: sticky;
            top: 0;

            &:before {
                background: url(../img/arrow.svg) 0% 50% no-repeat;
                background-size: contain;

                width: 20%;
                height: 83%;

                content: '';
                position: absolute;
                top: 0;
                left: 10%;
                bottom: 0;
                margin: auto;
            }

            .slider-nav {
                visibility: hidden;
            }

            .carousel_wrap {
                width: 70%;
                height: 85vh;

                position: absolute;
                top: 0;
                left: 10%;
                bottom: 0;
                right: 0;
                margin: auto;

                .slick-list {
                    padding: rem(50px 0) !important;
                }

                .slick-track {
                    top: 35vh;
                }

                .item {
                    width: 100%;
                    height: 35vh;

                    margin: rem(-50px 0 100px);

                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    cursor: pointer;

                    transform: scale(0.7) translateY(0);
                    transition: 0.4s;

                    outline: none;

                    .title {
                        font-size: rem(48px);
                        font-weight: 700;
                    }

                    .desc {
                        font-family: 'PT Sans Caption', sans-serif;
                        font-size: rem(17px);
                        margin-top: rem(20px);

                        opacity: 0;

                        transition: 0.5s;
                    }

                    .btn {
                        font-family: 'Open Sans', sans-serif;
                        color: #fff;
                        background-color: #00aeef;
                        text-decoration: none;
                        font-size: rem(25px);

                        display: inline-flex;
                        justify-content: center;
                        align-items: center;

                        width: rem(248px);
                        height: rem(77px);

                        border: 2px solid #fff;
                        box-sizing: border-box;
                        border-radius: rem(32px);

                        outline: none;

                        margin-top: rem(25px);

                        opacity: 0;
                        visibility: hidden;

                        transition: opacity 0.5s, background-color 0.3s, color 0.3s;

                        &:hover {
                            background-color: #fff;
                            color: #00aeef;
                        }
                    }

                    &.slick-center {
                        transform: scale(1) translateY(-8%);

                        .desc {
                            opacity: 1;
                        }

                        .btn {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

.vendors {
    margin-top: rem(60px);

    .container {
        .logos_wrap {
            width: 100%;

            .item {
                width: rem(180px);
                height: rem(90px);

                position: relative;

                outline: none;

                opacity: 0.5;
                filter: grayscale(1);
                transition: 0.3s;

                cursor: pointer;

                &:hover {
                    opacity: 1;
                    filter: grayscale(0);
                }

                img {
                    width: rem(180px);
                    height: rem(90px);
                    object-fit: contain;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }
    }
}

.bottom {
    /* background: url(../img/bg_hero.jpg) 50% 0% no-repeat;
    background-size: cover; */
    background-color: #00aeef;

    margin-top: rem(60px);

    &.bottom-direction {
        padding-top: rem(60px);
        margin-top: rem(0);
    }

    &.bottom-events {
        padding-top: rem(60px);
        margin-top: rem(0);
    }

    >.container {
        height: 100%;
        height: rem(890px);

        display: flex;
        flex-direction: row;
        align-items: center;

        .video {
            width: rem(865px);
            height: 100%;
            overflow: hidden;
            position: relative;

            video {
                width: 100%;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;

                height: 101%;
                width: 25%;

                background: url(../img/video-clip.svg) 0% 50% no-repeat;
                background-size: cover;
            }
        }

        .texts {
            width: rem(870px);

            .logo-slogan {
                width: rem(800px);

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                p {
                    margin: 0;
                    font-size: rem(48px);
                }

                img {
                    width: rem(293px);
                }
            }

            .counters {
                width: rem(840px);

                margin-top: rem(130px);

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                p {
                    margin: 0;
                    transition: 0.2s;

                    &:first-child {
                        font-size: rem(92px);
                        font-weight: 700;
                    }

                    &:last-child {
                        font-size: rem(46px);
                    }
                }
            }
        }
    }

    .footer {
        padding-top: rem(60px);
        padding-bottom: rem(60px);

        .container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            .left {
                .logo {
                    width: rem(260px);

                    img {
                        width: 100%;
                        position: relative;
                    }
                }

                .copyright {
                    margin-top: rem(30px);
                    font-size: rem(17px);
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .feed {
                    p {
                        font-size: rem(24px);
                    }

                    form {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        input {
                            font-size: rem(24px);
                            background-color: transparent;
                            box-shadow: none;
                            border: 1px solid #fff;
                            color: #fff;
                            padding: rem(10px);
                            box-sizing: border-box;

                            outline: none;

                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            box-shadow: none;
                            border-radius: 0;
                        }

                        input::placeholder {
                            color: #fff;
                        }

                        input::-webkit-input-placeholder {
                            color: #fff;
                        }

                        input::-moz-placeholder {
                            color: #fff;
                        }

                        input:-moz-placeholder {
                            color: #fff;
                        }

                        input:-ms-input-placeholder {
                            color: #fff;
                        }

                        input[type="email"] {
                            margin-left: rem(35px);
                            border-right: none;

                            outline: none;

                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            box-shadow: none;
                            border-radius: 0;
                        }

                        input[type="submit"] {
                            cursor: pointer;
                            outline: none;

                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            box-shadow: none;
                            border-radius: 0;
                        }
                    }
                }

                .links {
                    width: rem(450px);

                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    a {
                        color: #fff;
                        font-size: rem(24px);
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.nav {
    position: fixed;
    top: 0;
    left: 0;

    width: rem(64px);
    height: 100vh;
    background-color: #00aeef;
    border-right: 1px solid #fff;

    transition: width 0.3s, transform 0.5s;

    z-index: 10000;

    cursor: pointer;

    .top {
        height: rem(250px);

        display: flex;
        align-items: center;
        justify-content: center;

        border-bottom: 1px solid #fff;
        text-decoration: none;

        transition: 0.3s;

        .text {
            transform: rotate(-90deg);

            font-family: 'PT Sans Caption', sans-serif;
            font-size: rem(26px);

            color: #fff;

            transition: 0.3s;
        }

        &:hover {
            background-color: #fff;

            .text {
                color: #00aeef;
            }
        }
    }

    .bot {
        height: rem(calc(100vh - 250px));

        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            width: rem(27px);
            height: rem(22px);

            position: relative;

            .line {
                width: rem(27px);
                height: rem(2px);

                background-color: #fff;

                border-radius: rem(2px);

                position: absolute;

                transition: 0.25s;

                &:first-child {
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }

                &:nth-child(2) {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }

                &:last-child {
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }
    }

    &.nav-cisco-landing {
        .bot {
            height: 100vh;
    
            display: flex;
            justify-content: center;
            align-items: center;
    
            .icon {
                width: rem(27px);
                height: rem(22px);
    
                position: relative;
            }
        }
    }

    &:hover {
        width: rem(76px);

        .bot {
            .icon {
                .line {
                    &:first-child {
                        transform: translateY(rem(-5px));
                    }

                    &:last-child {
                        transform: translateY(rem(5px));
                    }
                }
            }
        }
    }


    &.active {
        transform: translateX(rem(790px));
    }
}

.nav-menu {
    position: fixed;
    top: 0;
    left: 0;

    width: rem(790px);
    height: 100vh;
    background-color: #00aeef;
    border-right: 1px solid #fff;

    padding-top: rem(80px);
    padding-left: rem(165px);
    padding-right: rem(50px);
    box-sizing: border-box;

    transform: translateX(rem(-790px));

    z-index: 10000;

    transition: 0.5s;

    &.active {
        transform: translateX(0);
    }

    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .logo {
            width: rem(149px);
        }

        .info {
            text-align: right;
            font-family: 'PT Sans Caption', sans-serif;

            .phone {
                font-size: rem(28px);
            }

            .address {
                margin-top: rem(10px);
                font-size: rem(15.5px);
            }
        }
    }

    .list {
        margin-top: rem(60px);
        margin-top: rem(83px);

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        a {
            font-family: 'PT Sans Caption', sans-serif;
            font-size: rem(28px);
            color: #fff;
            text-decoration: none;
            transition: 0.3s;
            margin: rem(5.5px 0);

            &:hover {
                color: #ffdc00;
            }
        }

        .item {
            span {
                display: inline-block;
                font-size: rem(20px);

                transition: 0.2s;
            }

            &.active {
                span {
                    transform: rotate(-90deg);
                }
            }

            &.subitem {
                font-size: rem(24px);
                line-height: rem(34px);
                position: relative;
                margin: rem(3px 0);


                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: rem(-25px);
                    bottom: 0;
                    margin: auto;

                    width: rem(10px);
                    height: rem(10px);

                    border-radius: 50%;

                    background-color: #fff;
                }
            }
        }

        .submenu {
            width: 100%;
            padding-left: rem(30px);
            box-sizing: border-box;
            
            position: relative;

            display: none;

            a {
                display: block;
            }

            &.submenu1 {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: rem(9px);
                    bottom: 0;
                    margin: auto;

                    width: 1px;
                    height: calc(100% - 1.5rem - .35rem);

                    border-radius: 50%;

                    background-color: #fff;
                }
            }
        }
    }

    

    &.nav-menu-cisco-landing {
        .list {
            .item {
                &.subitem {
                    /* font-size: rem(20px);
                    line-height: rem(30px); */

                    font-size: 2.1vh;
                    line-height: 3.2vh;
                }
            }
        }
    }

    .search {
        position: absolute;
        bottom: rem(90px);
        width: 77%;

        .search-wrap {
            position: relative;

            input {
                width: 0.5%;
                height: rem(30px);

                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                border: none;
                box-shadow: none;
                border-radius: 0;

                padding-left: rem(1px);
                box-sizing: border-box;

                color: #000;
                font-size: rem(16px);


                outline: none;

                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                box-shadow: none;
                border-radius: 0;
                transition: 0.3s;

                &.active {
                    width: 90%;
                    padding-left: rem(50px);
                }
            }

            .icon {
                width: rem(26px);
                height: rem(26px);

                position: absolute;
                top: 0;
                left: 2%;
                bottom: 0;
                margin: auto;

                filter: grayscale(1) brightness(0) invert(1);
                transition: 0.3s;

                cursor: pointer;

                &.active {
                    filter: none;
                }

                svg {
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}

.close-menu-btn {
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    background-color: #fff;

    opacity: 0;

    visibility: hidden;

    z-index: 9999;

    &.active {
        visibility: visible;
        opacity: 0.7;
    }
}

.hero-direction {
    background: url(../img/bg_hero.jpg) 50% 0% no-repeat;
    background-size: cover;

    height: 100vh;

    padding-top: rem(100px);
    box-sizing: border-box;

    .container {
        height: 100%;

        .video {
            width: 100%;
            height: calc(100% + 6.25rem);

            position: absolute;
            top: rem(-100px);
            left: 0;
            right: 0;
            margin: auto;

            overflow: hidden;

            video {
                width: 100%;
                height: 100%;

                object-fit: cover;
                object-position: top center;
            }

            .interactive_menu {
                width: 80%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;

                z-index: 100;

                .circle1 {
                    background: url(../img/directions_hero/circle1.svg) 50% 50% no-repeat;
                    background-size: contain;

                    width: 28%;
                    height: 46%;

                    position: absolute;
                    top: -7%;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                }

                .circle2 {
                    background: url(../img/directions_hero/circle2.svg) 50% 50% no-repeat;
                    background-size: contain;

                    width: 45%;
                    height: 72%;

                    position: absolute;
                    top: -7%;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                
                    animation-name: circle;
                    animation-iteration-count: infinite;
                    animation-duration: 50s;
                    animation-timing-function: linear;

                    &.pause {
                        animation-play-state: paused;
                    }

                    .icon {
                        display: block;

                        text-decoration: none;
                        color: #fff;

                        width: 20.2%;
                        height: 20.4%;

                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;

                        cursor: pointer;
                        transition: 0.3s;

                        &:after {
                            white-space: pre;

                            position: absolute;
                            top: 0;
                            left: 110%;
                            bottom: 0;
                            margin: auto;

                            text-transform: uppercase;

                            font-size: rem(28px);

                            transition: opacity 0.5s;
                        }

                        &.icon1 {
                            background: url(../img/directions_hero/icon1.svg) 50% 50% no-repeat;
                            background-size: contain;

                            transform: scale(0.75) rotate(0deg);
                            left: rem(-60px);
                            right: initial;

                            animation-name: icon1;
                            animation-iteration-count: infinite;
                            animation-duration: 50s;
                            animation-timing-function: linear;

                            &:after {
                                content: 'Инфраструктура';

                                height: 20%;
                                width: 165%;

                                animation-name: after1;
                                animation-iteration-count: infinite;
                                animation-duration: 50s;
                                animation-timing-function: linear;
                            }

                            &.pause {
                                animation-play-state: paused;

                                &:after {
                                    opacity: 0;
                                    animation-play-state: paused;
                                }
                            }

                            &.active {
                                &:after {
                                    opacity: 1 !important;
                                    animation-play-state: paused;
                                }
                            }
    
                            @keyframes after1 {
                                0% {
                                    opacity: 0;
                                }
                                50% {
                                    opacity: 0;
                                }
                                65% {
                                    opacity: 1;
                                }
                                85% {
                                    opacity: 1;
                                }
                                100% {
                                    opacity: 0;
                                }
                            }
                        }

                        @keyframes icon1 {
                            0% {
                                transform: scale(0.75) rotate(0deg);
                            }
                            25% {
                                transform: scale(0.5) rotate(-90deg);
                            }
                            50% {
                                transform: scale(0.75) rotate(-180deg);
                            }
                            75% {
                                transform: scale(2) rotate(-270deg);
                            }
                            100% {
                                transform: scale(0.75) rotate(-360deg);
                            }
                        }

                        &.icon2 {
                            background: url(../img/directions_hero/icon2.svg) 50% 50% no-repeat;
                            background-size: contain;

                            transform: scale(0.5) rotate(0deg);
                            top: rem(-60px);
                            bottom: initial;

                            animation-name: icon2;
                            animation-iteration-count: infinite;
                            animation-duration: 50s;
                            animation-timing-function: linear;
                            
                            &:after {
                                content: 'АВТОМАТИЧЕСКАЯ \0a ИДЕНТИФИКАЦИЯ \0a И СБОР ДАННЫХ';

                                height: 70%;
                                width: 165%;

                                animation-name: after2;
                                animation-iteration-count: infinite;
                                animation-duration: 50s;
                                animation-timing-function: linear;
                            }

                            &.pause {
                                animation-play-state: paused;

                                &:after {
                                    opacity: 0;
                                    animation-play-state: paused;
                                }
                            }

                            &.active {
                                &:after {
                                    opacity: 1 !important;
                                    animation-play-state: paused;
                                }
                            }
    
                            @keyframes after2 {
                                0% {
                                    opacity: 0;
                                }
                                25% {
                                    opacity: 0;
                                }
                                40% {
                                    opacity: 1;
                                }
                                60% {
                                    opacity: 1;
                                }
                                75% {
                                    opacity: 0;
                                }
                                100% {
                                    opacity: 0;
                                }
                            }
                        }

                        @keyframes icon2 {
                            0% {
                                transform: scale(0.5) rotate(0deg);
                            }
                            25% {
                                transform: scale(0.75) rotate(-90deg);
                            }
                            50% {
                                transform: scale(2) rotate(-180deg);
                            }
                            75% {
                                transform: scale(0.75) rotate(-270deg);
                            }
                            100% {
                                transform: scale(0.5) rotate(-360deg);
                            }
                        }

                        &.icon3 {
                            background: url(../img/directions_hero/icon3.svg) 50% 50% no-repeat;
                            background-size: contain;

                            transform: scale(0.5) rotate(0deg);
                            right: rem(-60px);
                            left: initial;

                            animation-name: icon3;
                            animation-iteration-count: infinite;
                            animation-duration: 50s;
                            animation-timing-function: linear;

                            &:after {
                                content: 'ИНФОРМАЦИОННАЯ \0a БЕЗОПАСНОСТЬ';

                                height: 45%;
                                width: 185%;

                                animation-name: after3;
                                animation-iteration-count: infinite;
                                animation-duration: 50s;
                                animation-timing-function: linear;
                            }

                            &.pause {
                                animation-play-state: paused;

                                &:after {
                                    opacity: 0;
                                    animation-play-state: paused;
                                }
                            }

                            &.active {
                                &:after {
                                    opacity: 1 !important;
                                    animation-play-state: paused;
                                }
                            }
    
                            @keyframes after3 {
                                0% {
                                    opacity: 0;
                                }
                                15% {
                                    opacity: 1;
                                }
                                35% {
                                    opacity: 1;
                                }
                                50% {
                                    opacity: 0;
                                }
                                100% {
                                    opacity: 0;
                                }
                            }
                        }

                        @keyframes icon3 {
                            0% {
                                transform: scale(0.75) rotate(0deg);
                            }
                            25% {
                                transform: scale(2) rotate(-90deg);
                            }
                            50% {
                                transform: scale(0.75) rotate(-180deg);
                            }
                            75% {
                                transform: scale(0.5) rotate(-270deg);
                            }
                            100% {
                                transform: scale(0.75) rotate(-360deg);
                            }
                        }

                        &.icon4 {
                            background: url(../img/directions_hero/icon4.svg) 50% 50% no-repeat;
                            background-size: contain;

                            transform: scale(0.5) rotate(0deg);
                            bottom: rem(-60px);
                            top: initial;

                            animation-name: icon4;
                            animation-iteration-count: infinite;
                            animation-duration: 50s;
                            animation-timing-function: linear;

                            &:after {
                                content: 'СЕТИ И ПЕРЕДАЧА \0a ДАННЫХ';

                                height: 45%;
                                width: 155%;

                                animation-name: after4;
                                animation-iteration-count: infinite;
                                animation-duration: 50s;
                                animation-timing-function: linear;
                            }

                            &.pause {
                                animation-play-state: paused;

                                &:after {
                                    opacity: 0;
                                    animation-play-state: paused;
                                }
                            }

                            &.active {
                                &:after {
                                    opacity: 1 !important;
                                    animation-play-state: paused;
                                }
                            }
    
                            @keyframes after4 {
                                0% {
                                    opacity: 1;
                                }
                                10% {
                                    opacity: 1;
                                }
                                25% {
                                    opacity: 0;
                                }
                                75% {
                                    opacity: 0;
                                }
                                90% {
                                    opacity: 1;
                                }
                                100% {
                                    opacity: 1;
                                }
                            }
                        }

                        @keyframes icon4 {
                            0% {
                                transform: scale(2) rotate(0deg);
                            }
                            25% {
                                transform: scale(0.75) rotate(-90deg);
                            }
                            50% {
                                transform: scale(0.5) rotate(-180deg);
                            }
                            75% {
                                transform: scale(0.75) rotate(-270deg);
                            }
                            100% {
                                transform: scale(2) rotate(-360deg);
                            }
                        }
                    }
                }

                .circle3 {
                    background: url(../img/directions_hero/circle3.svg) 50% 50% no-repeat;
                    background-size: contain;

                    width: 79%;
                    height: 121%;

                    position: absolute;
                    top: -7%;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;

                    animation-name: circle;
                    animation-iteration-count: infinite;
                    animation-duration: 40s;
                    animation-timing-function: linear;
                }

                
                @keyframes circle {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }

        .mob-version {
            display: none;
        }

        .texts {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            width: 100%;
            height: 20vh;

            position: relative;

            .logo {
                width: rem(200px);
            }

            .title {
                font-family: 'PT Sans Caption', sans-serif;
                font-size: rem(48px);
            }
        }
    }
}

.directions {
    background-color: #00aeef;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    height: 100vh;

    .left {
        width: rem(795px);
        position: relative;
        box-shadow: rem(15px 0px 8px -7px #009cd7);

        padding-top: rem(50px);
        padding-bottom: rem(50px);

        .line {
            background-color: #fff;

            width: 1px;
            height: calc(100% - 11.125rem);

            position: absolute;
            top: rem(75px);
            right: rem(50px);
            margin: auto;
        }

        .item {
            text-align: right;
            margin-right: rem(100px);
            margin-bottom: rem(30px);
            position: relative;
            cursor: pointer;
            transition: 0.5s;

            .title {
                font-family: 'PT Sans Caption', sans-serif;
                font-size: rem(33px);

                color: #fff;

                transition: 0.5s, color 0.2s;

                &:hover {
                    color: #ffe000;
                }
            }

            .desc {
                font-family: 'PT Sans Caption', sans-serif;
                font-size: rem(17px);
                margin-top: rem(30px);

                opacity: 0;
                display: none;

                transition: opacity 0.5s;
            }

            .btn {
                font-family: 'Open Sans', sans-serif;
                color: #fff;
                background-color: #00aeef;
                text-decoration: none;
                font-size: rem(25px);

                border: 2px solid #fff;
                box-sizing: border-box;

                border-radius: rem(35px);


                display: inline-flex;
                justify-content: center;
                align-items: center;

                width: rem(295px);
                height: rem(80px);

                outline: none;

                margin-top: rem(30px);

                opacity: 0;
                display: none;

                transition: opacity 0.5s, 0.25s;

                &:hover {
                    color: #00aeef;
                    background-color: #fff;
                }
            }

            .point {
                position: absolute;
                top: rem(10px);
                right: rem(-58px);

                width: rem(17px);
                height: rem(17px);

                border-radius: 50%;

                background-color: #fff;
            }

            &.active {
                margin-bottom: rem(150px);
                margin-top: rem(150px);

                .title {
                    text-transform: uppercase;
                    font-size: rem(48px);
                    font-weight: 700;

                    color: #fff;

                    &:hover {
                        color: #fff;
                    }
                }

                .desc {
                    opacity: 1;
                }

                .btn {
                    opacity: 1;
                    display: inline-flex;
                }

                &:after {
                    top: rem(75px);
                }
            }

            &.item1 {
                &.active {
                    margin-bottom: rem(115px);
                    margin-top: rem(115px)
                }
            }
        }
    }

    .right {
        padding-top: rem(50px);
        padding-bottom: rem(50px);

        width: calc(100% - 49.0625rem);

        position: relative;

        .line1 {
            width: rem(0);
            height: 1px;

            position: absolute;
            /* top: rem(505px); */
            top: 42vh;
            left: 0;

            background-color: #fff;

            transition: width 0.25s;

            &.active {
                width: rem(60px);
            }
        }

        .line2 {
            width: rem(1px);
            height: rem(388px);

            position: absolute;
            bottom: rem(744px);
            left: rem(60px);

            background-color: #fff;

            transition: height 0.25s;
        }

        .line3 {
            width: rem(0);
            height: 1px;

            position: absolute;
            top: rem(118px);
            left: rem(60px);

            background-color: #fff;

            transition: width 0.25s;

            &.active {
                width: rem(60px);
            }
        }

        .list {
            width: calc(100% - 9.375rem);
            height: calc(100% - 3.125rem);

            position: absolute;
            top: rem(50px);
            left: rem(150px);
            right: 0;
            margin: auto;

            visibility: hidden;
            opacity: 0;
            transition: opacity 0.5s;

            .item {
                margin-bottom: rem(30px);
                position: relative;

                color: #fff;

                transition: 0.2s;

                cursor: default;

                &:hover {
                    color: #ffe000;
                }

                .title {
                    font-size: rem(30px);
                    font-weight: 700;
                }

                .desc {
                    font-size: rem(22px);
                }

                .point {
                    position: absolute;
                    top: rem(15px);
                    left: rem(-30px);

                    width: rem(8px);
                    height: rem(8px);

                    border-radius: 50%;

                    background-color: #fff;
                }
            }

            .vendor-logos {
                background-color: #fff;
                margin-left: rem(-150px);

                padding: rem(20px 70px 20px 100px);
                box-sizing: border-box;

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-around;

                position: absolute;
                left: rem(-150px);
                bottom: 0;
                right: 0;
                margin: auto;

                opacity: 0;
                transition: 0.5s;

                .slide {
                    width: rem(170px);
                    height: rem(170px);

                    padding-left: rem(15px);
                    padding-right: rem(15px);

                    box-sizing: border-box;

                    outline: none;

                    img {
                        width: 100%;
                        height: 100%;

                        object-fit: contain;
                        object-position: 50% 50%;
                    }
                }
            }

            &.active {
                visibility: visible;
                opacity: 1;

                .vendor-logos {
                    opacity: 1;
                }
            }
        }
    }
}

.directions-mob {
    display: none;
}

.events-main {
    position: relative;

    .left {
        width: 45%;
        height: 100vh;

        background-color: #00AEEF;

        position: sticky;
        top: 0;
        left: 0;

        box-shadow: rem(7px 7px 5px rgba(0, 0, 0, 0.1));

        z-index: 10;

        .logo {
            width: rem(173px);
            height: rem(143px);

            position: absolute;
            top: rem(55px);
            right: rem(80px);
        }

        .texts {
            position: absolute;
            top: 0;
            left: rem(170px);
            bottom: 0;
            margin: auto;

            height: rem(340px);

            .title {
                font-weight: 700;

                font-size: rem(48px);
            }

            .search {
                margin-top: rem(20px);
                margin-bottom: rem(20px);

                input {
                    width: rem(500px);
                    height: rem(58px);

                    font-size: rem(24px);

                    box-sizing: border-box;
                    padding-left: rem(20px);

                    border-radius: rem(10px);
                    border: none;
                    box-shadow: none;

                    appearance: none;
                }
            }

            .list {
                margin-top: rem(30px);
                position: relative;

                .item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    margin-bottom: rem(40px);

                    cursor: pointer;

                    .dot {
                        width: rem(16px);
                        height: rem(16px);

                        background-color: #fff;
                        border-radius: 50%;

                        position: relative;

                        &:before {
                            content: '';

                            width: rem(6px);
                            height: rem(6px);

                            background-color: #00aeef;
                            border-radius: 50%;

                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            margin: auto;

                            opacity: 0;
                            transition: 0.3s;
                        }
                    }

                    &.active {
                        .dot {
                            &:before {
                                opacity: 1;
                            }
                        }
                    }

                    .text {
                        font-size: rem(24px);
                        margin-left: rem(15px);
                    }
                }

                &:before {
                    content: '';

                    width: rem(1px);
                    height: rem(144px);

                    background-color: #fff;

                    position: absolute;
                    top: rem(11px);
                    left: rem(7px);
                }
            }
        }
    }

    .right {
        width: 55%;

        margin-top: -100vh;
        margin-left: auto;

        font-size: rem(40px);
        color: #000;

        position: relative;

        .preloader-local {
            width: 100%;
            height: 100%;
        
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
        
            z-index: 100000;
        
            background-color: #fff;
        
            visibility: visible;
            opacity: 1;
            transition: opacity 0.3s, visibility 0.3s 0s;
        
            &.hide {
                opacity: 0;
                visibility: hidden;
            }
        
            .load {
                background: url(../img/logo_blue.svg) 50% 50% no-repeat;
                background-size: contain;
                
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
        
                width: rem(200px);
                height: rem(200px);
        
                animation-name: loader;
                animation-duration: 0.8s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
            }
        
            @keyframes loader {
                0% {
                    transform: scale(1, 1);
                }
                50% {
                    transform: scale(-1, 1);
                }
                100% {
                    transform: scale(1, 1);
                }
            }
        }

        .event-list {
            width: rem(740px);
            margin-left: rem(130px);
            padding-left: rem(70px);
            box-sizing: border-box;

            padding-top: rem(40px);
            padding-bottom: rem(40px);

            display: none;

            &.active {
                display: block;
            }

            .line {
                position: absolute;
                top: rem(122px);
                left: rem(137px);

                width: 1px;
                height: auto;

                background-color: #b6b7b7;

                transition: height 1s;
            }
            
            .event-item {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                margin-top: rem(30px);

                position: relative;

                cursor: pointer;

                .pic {
                    width: rem(260px);
                    height: rem(220px);

                    filter: none;
                    transition: 0.25s;

                    img {
                        width: rem(260px);
                        height: rem(220px);

                        object-fit: cover;
                    }
                }

                .texts {
                    margin-left: rem(50px);

                    .category {
                        font-size: rem(15px);
                        color: #b7b7b7;
                    }

                    .title {
                        font-size: rem(30px);
                        color: #00aeef;
                        font-weight: 700;

                        margin-top: rem(20px);
                    }

                    .desc {
                        font-size: rem(16px);
                        color: #000;

                        margin-top: rem(15px);
                    }

                    .date {
                        font-size: rem(18px);
                        color: #b7b7b7;

                        margin-top: rem(20px);
                    }
                }

                .dot {
                    position: absolute;
                    top: 0;
                    left: rem(-70px);
                    bottom: 0;
                    margin: auto;

                    width: rem(16px);
                    height: rem(16px);

                    background-color: #b6b7b7;
                    border-radius: 50%;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: rem(-70px);
                    bottom: 0;
                    margin: auto;

                    width: rem(70px);
                    height: rem(1px);

                    background-color: #b6b7b7;
                }

                &:hover .pic {
                    filter: contrast(0.3) brightness(1.5);
                }
            }
        }

        .calendar-wrap {
            display: none;

            width: 100%;
            height: 100vh;

            position: relative;
            
            overflow: hidden;

            &.active {
                display: block;
            }

            .shadow {
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;

                background-color: rgba(124, 124, 124, 0.37);

                display: none;
            }

            .calendar {
                width: rem(836px);
                height: rem(805px);

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;

                .calendar-nav {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;

                    width: 100%;
                    height: rem(35px);

                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    color: #00aeef;

                    .calendar-nav-btn {
                        cursor: pointer;

                        transition: 0.2s;

                        &:hover {
                            color: #b7b7b7;
                        }

                        &.disabled {
                            color: #b7b7b7;
                            pointer-events: none;
                            cursor: default;
                        }
                    }
                }

                .daynames {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;

                    font-size: rem(26px);
                    color: #808285;
                    
                    width: 99%;
                }

                .days {
                    margin-top: rem(15px);
                    position: absolute;

                    transition: opacity 0.3s, visibility 0.3s 0s;

                    .day {
                        width: rem(118px);
                        height: rem(118px);
    
                        box-sizing: border-box;

                        position: relative;

                        &.active {
                            border-color: #231f20;
                        }

                        span {
                            color: #000;
                            font-size: rem(24px);

                            position: absolute;
                            top: rem(10px);
                            right: rem(10px);
                        }

                        .list {
                            position: absolute;
                            right: rem(-390px);
                            top: 0;

                            width: rem(380px);

                            color: #00aeef;

                            border-radius: rem(15px);
                            padding: rem(15px 0);
                            box-sizing: border-box;

                            background-color: #fff;
                            box-shadow: rem(7px 7px 4px rgba(0, 0, 0, 0.15));

                            z-index: 1;

                            opacity: 0;
                            visibility: hidden;

                            transition: opacity 0.3s, visibility 0s;

                            .item {
                                font-size: rem(30px);
                                font-weight: 700;
                                padding: rem(15px 40px);

                                span {
                                    color: #b7b7b7;
                                    display: block;
                                    position: relative;
                                    top: 0;
                                    right: 0;
                                }

                                &:hover {
                                    background-color: #00aeef;
                                    color: #fff;
                                    
                                    span {
                                        color: #fff;
                                    }
                                }
                            }
                        }

                        &:nth-child(7) {
                            .list {
                                right: rem(130px);
                            }
                        }

                        &:nth-child(6) {
                            .list {
                                right: rem(130px);
                            }
                        }

                        &:nth-child(5) {
                            .list {
                                right: rem(130px);
                            }
                        }

                        &.hasEvent {
                            background-color: #00aeef;
                            cursor: pointer;

                            span {
                                color: #fff;
                            }

                            .list {
                                &.active {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }

                .mounth-name {
                    margin-top: rem(15px);
                    text-transform: uppercase;
                    font-size: rem(25px);
                    color: #b7b7b7;
                    text-align: center;

                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }

        .event-single {
            display: none;

            width: 100%;

            position: relative;

            padding: rem(0 100px 90px);
            box-sizing: border-box;
            
            &.active {
                display: block;
            }

            
            .registration_btn {
                position: sticky;
                top: rem(405px);
                left: 0;
                
                margin-left: rem(-300px);

                width: rem(320px);
                height: rem(69px);

                border-bottom-right-radius: rem(30px);
                border-bottom-left-radius: rem(30px);

                background-color: #00aeef;
                color: #fff;
                font-size: rem(27px);

                display: flex;
                align-items: center;
                justify-content: center;

                z-index: 9;

                will-change: transform;

                transform: rotate(-90deg);

                cursor: pointer;

                transition: 0.6s;

                &.active {
                    margin-left: rem(-227px);
                }
            }

            .logo {
                width: 100%;
                height: rem(60px);

                position: relative;

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: left center;
                }
            }

            .pic {
                margin-top: rem(25px);

                width: 100%;
                height: rem(400px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: left center;
                }
            }

            .meta {
                width: 100%;
                height: auto;

                margin-top: rem(40px);
                padding-bottom: rem(40px);

                display: flex;
                flex-direction: row;
                align-items: center;

                border-bottom: 1px solid #b6b7b7;

                .date_wrap {
                    width: rem(130px);
                    height: rem(155px);

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;

                    text-align: center;

                    .icon {
                        width: rem(79px);
                        height: rem(88px);
                    }

                    .date {
                        color: #b7b7b7;
                        font-size: rem(21px);
                        line-height: rem(25px);
                    }
                }

                .text_wrap {
                    margin-left: rem(15px);

                    .category {
                        font-size: rem(16px);
                        color: #b7b7b7;
                        line-height: rem(48px);
                    }

                    .title {
                        font-size: rem(49px);
                        color: #00aeef;
                        line-height: rem(58px);
                        font-weight: 700;
                    }
                }
            }

            .event-single-text {
                margin-top: rem(30px);

                font-size: rem(20px);
                line-height: rem(24px);

                padding-bottom: rem(30px);

                border-bottom: 1px solid #b6b7b7;
            }

            .footer {
                margin-top: rem(30px);
                margin-left: rem(10px);

                .social {
                    color: #9bb3be;
                    font-size: rem(16px);

                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {
                        margin-right: rem(10px);
                    }

                    .icon {
                        width: rem(32px);
                        height: rem(32px);

                        border-radius: 50%;

                        background-color: #00aeef;

                        margin-right: rem(5px);

                        padding: rem(1px);
                        box-sizing: border-box;

                        transition: 0.3s;

                        &:hover {
                            background-color: #009edf;
                        }
                    }
                }
            }
        }

        .registration-wrap {
            display: none;

            width: 100%;

            position: relative;
            
            &.active {
                display: block;
            }

            .registration-container {
                padding: rem(100px 100px 90px 100px);
                box-sizing: border-box;

                .meta {
                    width: 100%;
                    height: auto;

                    margin-top: rem(40px);
                    padding-bottom: rem(40px);

                    display: flex;
                    flex-direction: row;
                    align-items: center;
    
                    .date_wrap {
                        width: rem(130px);
                        height: rem(155px);
    
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
    
                        text-align: center;
    
                        .icon {
                            width: rem(79px);
                            height: rem(88px);
                        }
    
                        .date {
                            color: #b7b7b7;
                            font-size: rem(21px);
                            line-height: rem(25px);
                        }
                    }
    
                    .text_wrap {
                        margin-left: rem(15px);
    
                        .category {
                            font-size: rem(16px);
                            color: #b7b7b7;
                            line-height: rem(48px);
                        }
    
                        .title {
                            font-size: rem(49px);
                            color: #00aeef;
                            line-height: rem(58px);
                            font-weight: 700;
                        }
                    }
                }

                .registration-form {
                    form {
                        .block {
                            width: 100%;

                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            flex-wrap: wrap;

                            margin-bottom: rem(40px);

                            .col {
                                width: 100%;
                            }

                            .col-2 {
                                width: 48%;
                            }

                            .col-3 {
                                width: 30%;
                            }

                            .col-4 {
                                width: 24%;
                            }

                            .table {
                                table {
                                    tr {
                                        font-size: rem(30px);

                                        &.caption {
                                            font-size: rem(20px);

                                            td {
                                                padding-bottom: rem(15px);
                                            }
                                        }

                                        td {
                                            text-align: center;

                                            width: rem(170px);

                                            label {
                                                justify-content: center;

                                                span:before {
                                                    margin-right: 0;
                                                }
                                            }
                                        }

                                        td:first-child {
                                            text-align: left;

                                            width: rem(230px);
                                        }
                                    }
                                }
                            }

                            h4 {
                                font-family: 'PT Sans',sans-serif;
                                font-size: rem(40px);
                                margin: 0;
                                margin-top: rem(25px);
                                margin-bottom: rem(25px);
                                font-weight: normal;
                                padding-left: 0;
                            }

                            label {
                                font-size: rem(28px);

                                margin-top: rem(10px);

                                display: block;

                                &>span {
                                    display: block;
                                    margin-bottom: rem(10px);
                                }

                                p {
                                    margin: 0;
                                }

                                &.checkbox {
                                    font-size: rem(30px);

                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;

                                    span {
                                        position: relative;
                                        
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;

                                        &:before {
                                            content: '';
    
                                            width: rem(59px);
                                            height: rem(59px);
        
                                            border: 1px solid #b6b7b7;
                                            box-sizing: border-box;
    
                                            margin-right: rem(20px);
    
                                            border-radius: rem(15px);
                                        }
    
                                        &:after {
                                            content: '';
    
                                            width: rem(59px);
                                            height: rem(59px);
    
                                            background: url(../img/check.svg) 50% 50% no-repeat;
                                            background-size: 50%;
                                            background-color: #00aeef;
    
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            bottom: 0;
                                            margin: auto;
    
                                            border-radius: rem(15px);

                                            opacity: 0;
                                        }
                                    }

                                    input[type='checkbox']:checked + span:after {
                                        opacity: 1;
                                    }
                                }

                                &.radio {
                                    font-size: rem(30px);

                                    display: flex;
                                    flex-direction: row;

                                    span {
                                        position: relative;
                                        
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;

                                        &:before {
                                            content: '';
    
                                            width: rem(59px);
                                            height: rem(59px);
        
                                            border: 1px solid #b6b7b7;
                                            box-sizing: border-box;
    
                                            margin-right: rem(20px);
    
                                            border-radius: 50%;
                                        }
    
                                        &:after {
                                            content: '';
    
                                            width: rem(59px);
                                            height: rem(59px);
    
                                            background: url(../img/check-radio.svg) 50% 50% no-repeat;
                                            background-size: 50%;
                                            background-color: #00aeef;
    
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            bottom: 0;
                                            margin: auto;
    
                                            border-radius: 50%;

                                            opacity: 0;
                                        }
                                    }

                                    input[type='radio']:checked + span:after {
                                        opacity: 1;
                                    }
                                }

                                &.file {
                                    background-color: #00aeef;

                                    color: #fff;
                                    font-size: rem(30px);

                                    width: rem(407px);
                                    height: rem(60px);

                                    border-radius: rem(15px);

                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    span {
                                        position: relative;
                                        display: block;
                                        width: 100%;
                                        text-align: center;

                                        img {
                                            width: rem(40px);
                                            height: rem(37px);

                                            position: absolute;
                                            top: 0;
                                            left: rem(15px);
                                            bottom: 0;
                                            margin: auto;
                                        }
                                    }
                                }
                            }

                            .filename {
                                font-size: rem(24px);
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                color: #8b8b8b;

                                &:before {
                                    content: 'Выбранный файл: ';
                                }
                            }

                            input {
                                appearance: none;

                                width: 100%;

                                border: 1px solid #b6b7b7;

                                font-size: rem(24px);
                                color: #000;

                                padding: rem(15px 25px);
                                box-sizing: border-box;
                                
                                border-radius: rem(15px);

                                &[type='checkbox'] {
                                    visibility: hidden;

                                    width: 0;
                                    height: 0;

                                    padding: 0;
                                }

                                &[type='radio'] {
                                    visibility: hidden;

                                    width: 0;
                                    height: 0;

                                    padding: 0;
                                }

                                &[type='file'] {
                                    visibility: hidden;

                                    width: 0;
                                    height: 0;

                                    padding: 0;
                                }

                                &[type='checkbox'] + span + [type="text"] {
                                    width: 77%;
                                    height: rem(59px);
                                }

                                &[type='submit'] {
                                    background-color: #00aeef;

                                    width: rem(305px);
                                    height: rem(59px);

                                    color: #fff;
                                    font-size: rem(36px);

                                    border: none;

                                    box-shadow: none;

                                    text-transform: none;

                                    padding: 0;

                                    cursor: pointer;
                                }
                            }

                            select {
                                appearance: none;
            
                                width: 100%;
            
                                border: 1px solid #b6b7b7;
            
                                font-size: rem(24px);
                                color: #b6b7b7;
            
                                padding: rem(15px 25px);
                                box-sizing: border-box;
                                
                                border-radius: rem(15px);

                                background-image: url(../img/icon_select_arrow.svg);
                                background-position: 95% 50%;
                                background-size: rem(15px);
                                background-repeat: no-repeat;

                                option {
                                    color: #b6b7b7;
                                }
                            }

                            textarea {
                                appearance: none;

                                width: 100%;

                                border: 1px solid #b6b7b7;

                                font-size: rem(24px);
                                color: #b6b7b7;

                                padding: rem(15px 25px);
                                box-sizing: border-box;
                                
                                border-radius: rem(15px);
                            }

                            a {
                                display: inline-block;

                                margin-top: rem(20px);

                                font-weight: 700;
                                font-size: rem(36px);
                                color: #00aeef;

                                text-decoration: none;

                                transition: 0.3s;

                                &:hover {
                                    text-decoration: underline;
                                }

                                &.hide {
                                    display: none;
                                }
                            }

                            .rating {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;

                                &>span {
                                    font-size: rem(30px);
                                    margin-top: rem(30px);
                                }

                                label {
                                    &.radio {
                                        span {
                                            flex-direction: column-reverse;

                                            &:before {
                                                margin-right: initial;
                                                margin-top: rem(10px);
                                            }

                                            &:after {
                                                top: initial;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


            .popup-shadow {
                display: none;

                background-color: rgba(0, 0, 0, 0.3);

                width: 100%;
                height: 100%;

                position: fixed;
                top: 0;
                left: 0;

                &.active {
                    display: block;
                }
            }

            .popup-success {
                display: none;

                background-color: #fff;

                width: rem(700px);
                height: rem(250px);

                position: fixed;
                top: 30%;
                left: 50%;

                padding: rem(75px 50px);
                box-sizing: border-box;

                border-radius: rem(20px);

                span {
                    color: #00aeef;
                }

                &.active {
                    display: block;
                }
            }
        }
    }
}

.promo-main {
    .first-screen {
        flex-direction: row;

        display: none;

        &.active {
            display: flex;
        }

        .left {
            width: 50%;
            height: 100vh;
    
            position: sticky;
            top: 0;
            left: 0;
    
            box-shadow: rem(7px 7px 5px rgba(0, 0, 0, 0.1));
    
            z-index: 10;

            box-sizing: border-box;

            .wrap {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;

                height: 88%;

                padding-left: rem(150px);
                padding-right: rem(80px);

                .pagename {
                    font-size: rem(48px);
                    color: #00aeef;
                    font-weight: 700;
                }
    
                .search {
                    input,
                    select {
                        appearance: none;
    
                        margin-top: rem(35px);
    
                        width: rem(500px);
    
                        border: 1px solid #b6b7b7;
    
                        font-size: rem(24px);
                        color: #b6b7b7;
    
                        padding: rem(15px 25px);
                        box-sizing: border-box;
                        
                        border-radius: rem(15px);
                    }

                    select {
                        option {
                            color: #b6b7b7;
                        }
                    }
                }
    
                .promo-select {
                    position: relative;
                    height: 71vh;
                    margin-top: rem(35px);

                    .slick-dots {
                        position: absolute;
                        right: rem(-50px);
                        top: 0;
                        bottom: 0;
                        margin: auto;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        li {
                            box-sizing: border-box;
                            padding: rem(5px 0);
                            opacity: 0.4;

                            transition: 0.3s;

                            &::marker {
                                opacity: 0;
                                content: '';
                            }

                            &.slick-active {
                                opacity: 1;
                            }
                        }

                        button {
                            color: transparent;

                            background-color: #b6b7b7;
                            border-radius: 50%;

                            box-shadow: none;
                            border: none;

                            font-size: rem(20px);
                            line-height: initial;

                            width: rem(20px);
                            height: rem(20px);

                            cursor: pointer;

                            outline: none;
                        }
                    }

                    .promo-slide {
                        outline: none;
                        height: 71vh;

                        .pic {
                            margin-top: rem(5px);
        
                            img {
                                max-height: rem(300px);
                                width: 100%;
    
                                object-fit: cover;
                                object-position: 50% 50%;
                            }
                        }
        
                        .new-promo {
                            font-size: rem(48px);
                            color: #00aeef;
                            font-weight: 700;
        
                            margin-top: rem(40px);
                        }
        
                        .category {
                            font-size: rem(20px);
                            color: #b7b7b7;
                            line-height: rem(60px);
                        }
        
                        .title {
                            font-size: rem(34px);
                            color: #00aeef;
                            font-weight: 700;
                            line-height: rem(42px);
                        }
        
                        .desc {
                            font-size: rem(21px);
                            color: #000;
                            line-height: rem(25px);
        
                            margin-top: rem(10px);

                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            height: rem(50px);
                            /* overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;  */
                        }
                        
                        .date {
                            font-size: rem(24px);
                            color: #b7b7b7;
                            line-height: rem(50px);
        
                            margin-top: rem(10px);
                        }
                    }
                }
            }
        }
    
        .right {
            width: 50%;

            .promo-list {
                width: rem(740px);
                margin-left: rem(130px);
                padding-left: rem(70px);
                box-sizing: border-box;
    
                padding-top: rem(40px);
                padding-bottom: rem(40px);

                position: relative;
    
                .line {
                    position: absolute;
                    top: rem(122px);
                    left: rem(7px);
    
                    width: 1px;
                    height: auto;
    
                    background-color: #b6b7b7;
    
                    transition: height 1s;
                }
                
                .promo-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
    
                    margin-top: rem(30px);
    
                    position: relative;
    
                    cursor: pointer;
    
                    .pic {
                        width: rem(260px);
                        height: rem(220px);
    
                        filter: none;
                        transition: 0.25s;
    
                        img {
                            width: rem(260px);
                            height: rem(220px);
    
                            object-fit: cover;
                        }
                    }
    
                    .texts {
                        margin-left: rem(50px);
    
                        .category {
                            font-size: rem(15px);
                            color: #b7b7b7;
                        }
    
                        .title {
                            font-size: rem(30px);
                            color: #00aeef;
                            font-weight: 700;
    
                            margin-top: rem(20px);
                        }
    
                        .desc {
                            font-size: rem(16px);
                            color: #000;
    
                            margin-top: rem(15px);
                        }
    
                        .date {
                            font-size: rem(18px);
                            color: #b7b7b7;
    
                            margin-top: rem(20px);
                        }
                    }
    
                    .dot {
                        position: absolute;
                        top: 0;
                        left: rem(-70px);
                        bottom: 0;
                        margin: auto;
    
                        width: rem(16px);
                        height: rem(16px);
    
                        background-color: #b6b7b7;
                        border-radius: 50%;
                    }
    
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: rem(-70px);
                        bottom: 0;
                        margin: auto;
    
                        width: rem(70px);
                        height: rem(1px);
    
                        background-color: #b6b7b7;
                    }
    
                    &:hover .pic {
                        filter: contrast(0.3) brightness(1.5);
                    }
                }
            }
        }
    }

    .search-result {
        display: none;

        &.active {
            display: block;
        }

        .header {
            width: 100%;

            padding-top: rem(70px);
            padding-left: rem(150px);

            box-sizing: border-box;

            position: relative;

            .pagename {
                font-size: rem(48px);
                color: #00aeef;
                font-weight: 700;
            }

            .search {
                input {
                    appearance: none;

                    margin-top: rem(35px);

                    width: rem(500px);

                    border: 1px solid #b6b7b7;

                    font-size: rem(24px);
                    color: #b6b7b7;

                    padding: rem(15px 25px);
                    box-sizing: border-box;
                    
                    border-radius: rem(15px);
                }
            }

            .logo {
                width: rem(198px);
                height: rem(164px);

                position: absolute;
                bottom: 0;
                right: rem(100px);
            }
        }

        .main {
            width: 100%;
            
            display: flex;
            flex-direction: row;

            .left {
                width: 50%;
    
                .promo-list {
                    width: rem(740px);
                    margin-left: rem(150px);
                    padding-left: rem(70px);
                    box-sizing: border-box;
        
                    padding-top: rem(40px);
                    padding-bottom: rem(40px);

                    position: relative;
        
                    .line {
                        position: absolute;
                        top: rem(122px);
                        left: rem(7px);
        
                        width: 1px;
                        height: auto;
        
                        background-color: #b6b7b7;
        
                        transition: height 1s;
                    }
                    
                    .promo-item {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
        
                        margin-top: rem(30px);
        
                        position: relative;
        
                        cursor: pointer;
        
                        .pic {
                            width: rem(260px);
                            height: rem(220px);
        
                            filter: none;
                            transition: 0.25s;
        
                            img {
                                width: rem(260px);
                                height: rem(220px);
        
                                object-fit: cover;
                            }
                        }
        
                        .texts {
                            margin-left: rem(50px);
        
                            .category {
                                font-size: rem(15px);
                                color: #b7b7b7;
                            }
        
                            .title {
                                font-size: rem(30px);
                                color: #00aeef;
                                font-weight: 700;
        
                                margin-top: rem(20px);
                            }
        
                            .desc {
                                font-size: rem(16px);
                                color: #000;
        
                                margin-top: rem(15px);
                            }
        
                            .date {
                                font-size: rem(18px);
                                color: #b7b7b7;
        
                                margin-top: rem(20px);
                            }
                        }
        
                        .dot {
                            position: absolute;
                            top: 0;
                            left: rem(-70px);
                            bottom: 0;
                            margin: auto;
        
                            width: rem(16px);
                            height: rem(16px);
        
                            background-color: #b6b7b7;
                            border-radius: 50%;
                        }
        
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: rem(-70px);
                            bottom: 0;
                            margin: auto;
        
                            width: rem(70px);
                            height: rem(1px);
        
                            background-color: #b6b7b7;
                        }
        
                        &:hover .pic {
                            filter: contrast(0.3) brightness(1.5);
                        }
                    }
                }
            }
        
            .right {
                width: 50%;
    
                .promo-list {
                    width: rem(740px);
                    margin-left: rem(150px);
                    padding-left: rem(0);
                    box-sizing: border-box;
        
                    padding-top: rem(40px);
                    padding-bottom: rem(40px);

                    position: relative;
        
                    .line {
                        position: absolute;
                        top: rem(122px);
                        left: rem(-63px);
        
                        width: 1px;
                        height: auto;
        
                        background-color: #b6b7b7;
        
                        transition: height 1s;
                    }
                    
                    .promo-item {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
        
                        margin-top: rem(30px);
        
                        position: relative;
        
                        cursor: pointer;
        
                        .pic {
                            width: rem(260px);
                            height: rem(220px);
        
                            filter: none;
                            transition: 0.25s;
        
                            img {
                                width: rem(260px);
                                height: rem(220px);
        
                                object-fit: cover;
                            }
                        }
        
                        .texts {
                            margin-left: rem(50px);
        
                            .category {
                                font-size: rem(15px);
                                color: #b7b7b7;
                            }
        
                            .title {
                                font-size: rem(30px);
                                color: #00aeef;
                                font-weight: 700;
        
                                margin-top: rem(20px);
                            }
        
                            .desc {
                                font-size: rem(16px);
                                color: #000;
        
                                margin-top: rem(15px);
                            }
        
                            .date {
                                font-size: rem(18px);
                                color: #b7b7b7;
        
                                margin-top: rem(20px);
                            }
                        }
        
                        .dot {
                            position: absolute;
                            top: 0;
                            left: rem(-70px);
                            bottom: 0;
                            margin: auto;
        
                            width: rem(16px);
                            height: rem(16px);
        
                            background-color: #b6b7b7;
                            border-radius: 50%;
                        }
        
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: rem(-70px);
                            bottom: 0;
                            margin: auto;
        
                            width: rem(70px);
                            height: rem(1px);
        
                            background-color: #b6b7b7;
                        }
        
                        &:hover .pic {
                            filter: contrast(0.3) brightness(1.5);
                        }
                    }
                }
            }
        }
    }

    .promo-single {
        display: none;

        &.active {
            display: flex;
        }

        .left {
            width: 63%;

            padding-left: rem(200px);
            padding-right: rem(115px);
            padding-top: rem(50px);
            padding-bottom: rem(50px);
            
            box-sizing: border-box;

            .logo {
                width: 100%;
                height: rem(60px);

                position: relative;

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: left center;
                }
            }

            .pic {
                margin-top: rem(25px);

                width: 100%;
                height: rem(400px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: left center;
                }
            }

            .meta {
                width: 100%;
                height: auto;

                margin-top: rem(40px);
                padding-bottom: rem(40px);

                display: flex;
                flex-direction: row;
                align-items: center;

                border-bottom: 1px solid #b6b7b7;

                .date_wrap {
                    width: rem(130px);
                    height: rem(155px);

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;

                    text-align: center;

                    .icon {
                        width: rem(79px);
                        height: rem(88px);
                    }

                    .date {
                        color: #b7b7b7;
                        font-size: rem(21px);
                        line-height: rem(25px);
                    }
                }

                .text_wrap {
                    margin-left: rem(15px);

                    .category {
                        font-size: rem(16px);
                        color: #b7b7b7;
                        line-height: rem(48px);
                    }

                    .title {
                        font-size: rem(49px);
                        color: #00aeef;
                        line-height: rem(58px);
                        font-weight: 700;
                    }
                }
            }

            .promo-single-text {
                margin-top: rem(30px);

                color: #000;

                font-size: rem(20px);
                line-height: rem(24px);

                padding-bottom: rem(30px);

                border-bottom: 1px solid #b6b7b7;
            }

            .footer {
                margin-top: rem(30px);
                margin-left: rem(10px);

                .social {
                    color: #9bb3be;
                    font-size: rem(16px);

                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {
                        margin-right: rem(10px);
                    }

                    .icon {
                        width: rem(32px);
                        height: rem(32px);

                        border-radius: 50%;

                        background-color: #00aeef;

                        margin-right: rem(5px);

                        padding: rem(1px);
                        box-sizing: border-box;

                        transition: 0.3s;

                        &:hover {
                            background-color: #009edf;
                        }
                    }
                }
            }
        }
    
        .right {
            width: 37%;
            height: 100vh;
    
            position: sticky;
            top: 0;
            right: 0;
    
            z-index: 10;

            background-color: #00aeef;

            .logo {
                width: rem(173px);
                height: rem(143px);

                position: absolute;
                top: rem(50px);
                right: rem(80px);
            }

            .promo-list-small {
                padding-top: rem(200px);
                padding-left: rem(70px);

                box-sizing: border-box;

                .line {
                    position: absolute;
                    top: rem(244px);
                    left: rem(47px);
    
                    width: 1px;
                    height: auto;
    
                    background-color: #fff;
    
                    transition: height 0.3s;
                }

                .promo-item {
                    display: flex;
                    flex-direction: column;
    
                    margin-top: rem(30px);
    
                    position: relative;
    
                    cursor: pointer;
    
                    .texts {
                        margin-left: rem(10px);
    
                        .title {
                            font-size: rem(32px);
                            color: #fff;
                            font-weight: 700;
                            line-height: rem(38px);
                        }

                        .category {
                            font-size: rem(16px);
                            color: #fff;
                            line-height: rem(30px);
                        }
    
                        .date {
                            font-size: rem(19px);
                            color: #fff;
                            line-height: rem(30px);
                        }
                    }
    
                    .dot {
                        position: absolute;
                        top: rem(13px);
                        left: rem(-30px);
    
                        width: rem(16px);
                        height: rem(16px);
    
                        background-color: #fff;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.news-main {
    .first-screen {
        flex-direction: row;

        display: none;

        &.active {
            display: flex;
        }

        .left {
            width: 50%;
            height: 100vh;
    
            position: sticky;
            top: 0;
            left: 0;
    
            box-shadow: rem(7px 7px 5px rgba(0, 0, 0, 0.1));
    
            z-index: 10;

            box-sizing: border-box;

            .wrap {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;

                height: 88%;

                padding-left: rem(150px);
                padding-right: rem(80px);

                .pagename {
                    font-size: rem(48px);
                    color: #00aeef;
                    font-weight: 700;
                }
    
                .search {
                    input,
                    select {
                        appearance: none;
    
                        margin-top: rem(35px);
    
                        width: rem(270px);
    
                        border: 1px solid #b6b7b7;
    
                        font-size: rem(24px);
                        color: #b6b7b7;
    
                        padding: rem(15px 25px);
                        box-sizing: border-box;
                        
                        border-radius: rem(15px);

                        option {
                            color: #b6b7b7;
                        }

                        &.year {
                            width: rem(0);
                            
                            option {
                                width: rem(135px);
                            }
                        }

                        &.month {
                            width: rem(0);
                            margin-left: rem(10px);
                        }

                        &.year,
                        &.month {
                            visibility: hidden;
                            opacity: 0;

                            transition: opacity 0.3s, width 0.2s 0.3s, visibility 0.3s 0.3s;

                            &.active {
                                visibility: visible;
                                opacity: 1;
                            }
                        }

                        &.year {
                            &.active {
                                width: rem(135px);
                                visibility: visible;
                                opacity: 1;
                            }
                        }

                        &.month {
                            &.active {
                                width: rem(170px);
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }

                    a {
                        font-size: rem(24px);
                        color: #b6b7b7;
                        text-decoration: none;

                        margin-left: rem(10px);

                        display: inline-block;

                        transform: scale(1);
                        transition: 0.3s;

                        &.hide {
                            visibility: hidden;
                            opacity: 0;
                            transform: scale(0);
                            width: 0;
                        }
                    }
                }
    
                .news-select {
                    .pic {
                        margin-top: rem(35px);
    
                        img {
                            max-height: rem(300px);
                            width: 100%;

                            object-fit: cover;
                            object-position: 50% 50%;
                        }
                    }
    
                    .new-news {
                        font-size: rem(48px);
                        color: #00aeef;
                        font-weight: 700;
    
                        margin-top: rem(40px);
                    }
    
                    .category {
                        font-size: rem(20px);
                        color: #b7b7b7;
                        line-height: rem(60px);
                    }
    
                    .title {
                        font-size: rem(34px);
                        color: #00aeef;
                        font-weight: 700;
                        line-height: rem(42px);
                    }
    
                    .desc {
                        font-size: rem(21px);
                        color: #000;
                        line-height: rem(25px);
    
                        margin-top: rem(10px);
                    }
                    
                    .date {
                        font-size: rem(24px);
                        color: #b7b7b7;
                        line-height: rem(50px);
    
                        margin-top: rem(10px);
                    }
                }
            }
        }
    
        .right {
            width: 50%;

            .news-list {
                width: rem(740px);
                margin-left: rem(130px);
                padding-left: rem(70px);
                box-sizing: border-box;
    
                padding-top: rem(40px);
                padding-bottom: rem(40px);

                position: relative;
    
                .line {
                    position: absolute;
                    top: rem(122px);
                    left: rem(7px);
    
                    width: 1px;
                    height: auto;
    
                    background-color: #b6b7b7;
    
                    transition: height 1s;
                }
                
                .news-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
    
                    margin-top: rem(30px);
    
                    position: relative;
    
                    cursor: pointer;
    
                    .pic {
                        width: rem(260px);
                        height: rem(220px);
    
                        filter: none;
                        transition: 0.25s;
    
                        img {
                            width: rem(260px);
                            height: rem(220px);
    
                            object-fit: cover;
                        }
                    }
    
                    .texts {
                        margin-left: rem(50px);
    
                        .category {
                            font-size: rem(15px);
                            color: #b7b7b7;
                        }
    
                        .title {
                            font-size: rem(30px);
                            color: #00aeef;
                            font-weight: 700;
    
                            margin-top: rem(20px);
                        }
    
                        .desc {
                            font-size: rem(16px);
                            color: #000;
    
                            margin-top: rem(15px);
                        }
    
                        .date {
                            font-size: rem(18px);
                            color: #b7b7b7;
    
                            margin-top: rem(20px);
                        }
                    }
    
                    .dot {
                        position: absolute;
                        top: 0;
                        left: rem(-70px);
                        bottom: 0;
                        margin: auto;
    
                        width: rem(16px);
                        height: rem(16px);
    
                        background-color: #b6b7b7;
                        border-radius: 50%;
                    }
    
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: rem(-70px);
                        bottom: 0;
                        margin: auto;
    
                        width: rem(70px);
                        height: rem(1px);
    
                        background-color: #b6b7b7;
                    }
    
                    &:hover .pic {
                        filter: contrast(0.3) brightness(1.5);
                    }
                }
            }
        }
    }

    .search-result {
        display: none;

        &.active {
            display: block;
        }

        .header {
            width: 100%;

            padding-top: rem(70px);
            padding-left: rem(150px);

            box-sizing: border-box;

            position: relative;

            .pagename {
                font-size: rem(48px);
                color: #00aeef;
                font-weight: 700;
            }

            .search {
                input,
                select {
                    appearance: none;

                    margin-top: rem(35px);

                    width: rem(270px);

                    border: 1px solid #b6b7b7;

                    font-size: rem(24px);
                    color: #b6b7b7;

                    padding: rem(15px 25px);
                    box-sizing: border-box;
                    
                    border-radius: rem(15px);

                    option {
                        color: #b6b7b7;
                    }

                    &.year {
                        width: rem(0);
                        
                        option {
                            width: rem(135px);
                        }
                    }

                    &.month {
                        width: rem(0);
                        margin-left: rem(10px);
                    }

                    &.year,
                    &.month {
                        visibility: hidden;
                        opacity: 0;

                        transition: opacity 0.3s, width 0.2s 0.3s, visibility 0.3s 0.3s;

                        &.active {
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    &.year {
                        &.active {
                            width: rem(135px);
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    &.month {
                        &.active {
                            width: rem(170px);
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }

                a {
                    font-size: rem(24px);
                    color: #b6b7b7;
                    text-decoration: none;

                    margin-left: rem(10px);

                    display: inline-block;

                    transform: scale(1);
                    transition: 0.3s;

                    &.hide {
                        visibility: hidden;
                        opacity: 0;
                        transform: scale(0);
                        width: 0;
                    }
                }
            }

            .logo {
                width: rem(198px);
                height: rem(164px);

                position: absolute;
                bottom: 0;
                right: rem(100px);
            }
        }

        .main {
            width: 100%;
            
            display: flex;
            flex-direction: row;

            .left {
                width: 50%;
    
                .news-list {
                    width: rem(740px);
                    margin-left: rem(150px);
                    padding-left: rem(70px);
                    box-sizing: border-box;
        
                    padding-top: rem(40px);
                    padding-bottom: rem(40px);

                    position: relative;
        
                    .line {
                        position: absolute;
                        top: rem(122px);
                        left: rem(7px);
        
                        width: 1px;
                        height: auto;
        
                        background-color: #b6b7b7;
        
                        transition: height 1s;
                    }
                    
                    .news-item {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
        
                        margin-top: rem(30px);
        
                        position: relative;
        
                        cursor: pointer;
        
                        .pic {
                            width: rem(260px);
                            height: rem(220px);
        
                            filter: none;
                            transition: 0.25s;
        
                            img {
                                width: rem(260px);
                                height: rem(220px);
        
                                object-fit: cover;
                            }
                        }
        
                        .texts {
                            margin-left: rem(50px);
        
                            .category {
                                font-size: rem(15px);
                                color: #b7b7b7;
                            }
        
                            .title {
                                font-size: rem(30px);
                                color: #00aeef;
                                font-weight: 700;
        
                                margin-top: rem(20px);
                            }
        
                            .desc {
                                font-size: rem(16px);
                                color: #000;
        
                                margin-top: rem(15px);
                            }
        
                            .date {
                                font-size: rem(18px);
                                color: #b7b7b7;
        
                                margin-top: rem(20px);
                            }
                        }
        
                        .dot {
                            position: absolute;
                            top: 0;
                            left: rem(-70px);
                            bottom: 0;
                            margin: auto;
        
                            width: rem(16px);
                            height: rem(16px);
        
                            background-color: #b6b7b7;
                            border-radius: 50%;
                        }
        
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: rem(-70px);
                            bottom: 0;
                            margin: auto;
        
                            width: rem(70px);
                            height: rem(1px);
        
                            background-color: #b6b7b7;
                        }
        
                        &:hover .pic {
                            filter: contrast(0.3) brightness(1.5);
                        }
                    }
                }
            }
        
            .right {
                width: 50%;
    
                .news-list {
                    width: rem(740px);
                    margin-left: rem(150px);
                    padding-left: rem(0);
                    box-sizing: border-box;
        
                    padding-top: rem(40px);
                    padding-bottom: rem(40px);

                    position: relative;
        
                    .line {
                        position: absolute;
                        top: rem(122px);
                        left: rem(-63px);
        
                        width: 1px;
                        height: auto;
        
                        background-color: #b6b7b7;
        
                        transition: height 1s;
                    }
                    
                    .news-item {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
        
                        margin-top: rem(30px);
        
                        position: relative;
        
                        cursor: pointer;
        
                        .pic {
                            width: rem(260px);
                            height: rem(220px);
        
                            filter: none;
                            transition: 0.25s;
        
                            img {
                                width: rem(260px);
                                height: rem(220px);
        
                                object-fit: cover;
                            }
                        }
        
                        .texts {
                            margin-left: rem(50px);
        
                            .category {
                                font-size: rem(15px);
                                color: #b7b7b7;
                            }
        
                            .title {
                                font-size: rem(30px);
                                color: #00aeef;
                                font-weight: 700;
        
                                margin-top: rem(20px);
                            }
        
                            .desc {
                                font-size: rem(16px);
                                color: #000;
        
                                margin-top: rem(15px);
                            }
        
                            .date {
                                font-size: rem(18px);
                                color: #b7b7b7;
        
                                margin-top: rem(20px);
                            }
                        }
        
                        .dot {
                            position: absolute;
                            top: 0;
                            left: rem(-70px);
                            bottom: 0;
                            margin: auto;
        
                            width: rem(16px);
                            height: rem(16px);
        
                            background-color: #b6b7b7;
                            border-radius: 50%;
                        }
        
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: rem(-70px);
                            bottom: 0;
                            margin: auto;
        
                            width: rem(70px);
                            height: rem(1px);
        
                            background-color: #b6b7b7;
                        }
        
                        &:hover .pic {
                            filter: contrast(0.3) brightness(1.5);
                        }
                    }
                }
            }
        }
    }

    .news-single {
        display: none;

        &.active {
            display: flex;
        }

        .left {
            width: 63%;

            padding-left: rem(200px);
            padding-right: rem(115px);
            padding-top: rem(50px);
            padding-bottom: rem(50px);
            
            box-sizing: border-box;

            .logo {
                width: 100%;
                height: rem(60px);

                position: relative;

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: left center;
                }
            }

            .pic {
                margin-top: rem(25px);

                width: 100%;
                height: rem(400px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: left center;
                }
            }

            .meta {
                width: 100%;
                height: auto;

                margin-top: rem(40px);
                padding-bottom: rem(40px);

                display: flex;
                flex-direction: row;
                align-items: center;

                border-bottom: 1px solid #b6b7b7;

                .date_wrap {
                    width: rem(130px);
                    height: rem(155px);

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;

                    text-align: center;

                    .icon {
                        width: rem(79px);
                        height: rem(88px);
                    }

                    .date {
                        color: #b7b7b7;
                        font-size: rem(21px);
                        line-height: rem(25px);
                    }
                }

                .text_wrap {
                    margin-left: rem(15px);

                    .category {
                        font-size: rem(16px);
                        color: #b7b7b7;
                        line-height: rem(48px);
                    }

                    .title {
                        font-size: rem(49px);
                        color: #00aeef;
                        line-height: rem(58px);
                        font-weight: 700;
                    }
                }
            }

            .news-single-text {
                margin-top: rem(30px);

                color: #000;

                font-size: rem(20px);
                line-height: rem(24px);

                padding-bottom: rem(30px);

                border-bottom: 1px solid #b6b7b7;
            }

            .footer {
                margin-top: rem(30px);
                margin-left: rem(10px);

                .social {
                    color: #9bb3be;
                    font-size: rem(16px);

                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {
                        margin-right: rem(10px);
                    }

                    .icon {
                        width: rem(32px);
                        height: rem(32px);

                        border-radius: 50%;

                        background-color: #00aeef;

                        margin-right: rem(5px);

                        padding: rem(1px);
                        box-sizing: border-box;

                        transition: 0.3s;

                        &:hover {
                            background-color: #009edf;
                        }
                    }
                }
            }
        }
    
        .right {
            width: 37%;
            height: 100vh;
    
            position: sticky;
            top: 0;
            right: 0;
    
            z-index: 10;

            background-color: #00aeef;

            .logo {
                width: rem(173px);
                height: rem(143px);

                position: absolute;
                top: rem(50px);
                right: rem(80px);
            }

            .news-list-small {
                padding-top: rem(200px);
                padding-left: rem(70px);

                box-sizing: border-box;

                .line {
                    position: absolute;
                    top: rem(244px);
                    left: rem(47px);
    
                    width: 1px;
                    height: auto;
    
                    background-color: #fff;
    
                    transition: height 0.3s;
                }

                .news-item {
                    display: flex;
                    flex-direction: column;
    
                    margin-top: rem(30px);
    
                    position: relative;
    
                    cursor: pointer;
    
                    .texts {
                        margin-left: rem(10px);
    
                        .title {
                            font-size: rem(32px);
                            color: #fff;
                            font-weight: 700;
                            line-height: rem(38px);
                        }

                        .category {
                            font-size: rem(16px);
                            color: #fff;
                            line-height: rem(30px);
                        }
    
                        .date {
                            font-size: rem(19px);
                            color: #fff;
                            line-height: rem(30px);
                        }
                    }
    
                    .dot {
                        position: absolute;
                        top: rem(13px);
                        left: rem(-30px);
    
                        width: rem(16px);
                        height: rem(16px);
    
                        background-color: #fff;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.about-main {
    position: relative;

    .f1 {
        /* background: url(../img/f1.svg) 100% 0% no-repeat;
        background-size: contain; */

        width: rem(1300px);
        height: rem(1919px);

        position: absolute;
        top: rem(-434px);
        right: rem(0);
    }

    .f2 {
        /* background: url(../img/f2.svg) 0% 0% no-repeat;
        background-size: contain; */

        width: rem(1852px);
        height: rem(1118px);

        position: absolute;
        top: rem(1250px);
        left: rem(-330px);
    }

    .f3 {
        /* background: url(../img/f3.svg) 0% 0% no-repeat;
        background-size: contain; */

        width: rem(2555px);
        height: rem(1338px);

        position: absolute;
        top: rem(2750px);
        left: rem(-540px);
    }

    .f4 {
        /* background: url(../img/f4.svg) 0% 0% no-repeat;
        background-size: contain; */

        width: rem(2025px);
        height: rem(1418px);

        position: absolute;
        top: rem(4480px);
        right: rem(-10px);
    }

    .f5 {
        /* background: url(../img/f5.svg) 0% 0% no-repeat;
        background-size: contain; */

        width: rem(2078px);
        height: rem(1503px);

        position: absolute;
        top: rem(4790px);
        right: rem(-10px);

        z-index: 1;
    }

    .f6 {
        /* background: url(../img/f6.svg) 0% 0% no-repeat;
        background-size: contain; */

        width: rem(1926px);
        height: rem(1794px);

        position: absolute;
        top: rem(6323px);
        right: rem(-390px);

        z-index: 1;
    }

    canvas{ 
        display: block; 
        vertical-align: bottom; 
    }

    #particles-js1{ 
        position:absolute; 
        width: 100%; 
        height: 100%;

        background-color: transparent;
    }

    .block1 {
        width: 100%;
        box-sizing: border-box;
        /* padding-top: rem(100px);
        padding-bottom: rem(100px); */
        padding-left: rem(240px);
        position: relative;

        .container {
            width: 100%;

            display: flex;
            flex-direction: row;
            z-index: 1;

            .texts {
                width: 52%;
                /* margin-right: rem(150px); */
    
                .logo {
                    width: rem(498px);
                }
    
                .text {
                    margin-top: rem(90px);
    
                    p {
                        font-family: 'PT Sans Caption', sans-serif;
                        color: #000;
                        font-size: rem(25px);
                        line-height: rem(30px);
                    }
                }

                .stat {
                    color: #00aeef;
                    font-weight: 700;
                    font-size: rem(39px);

                    width: 85%;

                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    margin-top: rem(75px);

                    span {
                        font-size: rem(78px);
                        display: block;
                    }
                }
            }
    
            .video {
                width: 97vh;
                height: 97vh;
    
                border-radius: 50%;
    
                overflow: hidden;
    
                position: absolute;
                top: 0;
                bottom: 0;
                right: rem(-200px);
                margin: auto;
    
                video {
                    width: 100%;
                    height: 100%;
    
                    object-fit: cover;
                }
            }
        }
    }

    .block2 {
        width: 100%;
        box-sizing: border-box;
        padding-left: rem(240px);
        position: relative;

        .container {
            z-index: 1;

            img {
                width: rem(1370px);
                height: auto;
            }
        }
    }

    .block3 {
        width: 100%;
        box-sizing: border-box;
        padding-left: rem(240px);
        position: relative;

        .container {
            z-index: 1;
            p {
                color: #00aeef;
                font-weight: 700;
                font-size: rem(46.5px);

                span {
                    display: inline-block;
                    width: 70%;
                }
            }
        }
    }

    .block4 {
        width: 100%;
        box-sizing: border-box;
        /* padding-top: rem(150px); */
        padding-left: rem(240px);
        position: relative;

        .container {
            z-index: 1;
            .video_wrap {
                position: relative;

                .video {
                    width: 87vh;
                    height: 87vh;
        
                    video {
                        width: 100%;
                        height: 100%;
        
                        object-fit: cover;
        
                        border-radius: 50%;
                        overflow: hidden;
                    }

                    .before {
                        position: absolute;
                        top: rem(-10px);
                        left: rem(-110px);

                        font-size: rem(50px);
                        color: #00aeef;
                        font-weight: 700;
                    }
                }

                .items {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;

                    width: 66%;
                    height: 98%;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;

                    .item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .icon {
                            width: rem(170px);
                            height: rem(170px);
                        }

                        .text {
                            font-size: rem(40px);
                            color: #00aeef;
                            font-weight: 700;
                            margin-left: rem(20px);
                        }

                        &.item2,
                        &.item3 {
                            margin-left: rem(145px);
                        }
                    }
                }
            }
        }
    }

    .block5 {
        width: 100%;
        box-sizing: border-box;
        /* padding-top: rem(200px); */
        padding-left: rem(240px);
        position: relative;

        .container {
            z-index: 1;
            p {
                color: #00aeef;
                font-weight: 700;
                font-size: rem(46.5px);
            }
        }
    }

    .block6 {
        width: 100%;
        box-sizing: border-box;
        /* padding-top: rem(550px); */
        padding-left: rem(240px);
        position: relative;

        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1;

            .video {
                width: rem(775px);
                height: rem(775px);
    
                border-radius: 50%;
    
                overflow: hidden;
    
                video {
                    width: 100%;
                    height: 100%;
    
                    object-fit: cover;
                }
            }

            .texts {
                width: rem(685px);
                margin-left: rem(110px);

                .title {
                    color: #00aeef;
                    font-size: rem(53px);
                    font-weight: 700;
                }

                p {
                    font-family: 'PT Sans Caption', sans-serif;
                    color: #000;
                    font-size: rem(25px);
                }

                .ul {
                    font-family: 'PT Sans Caption', sans-serif;
                    color: #000;
                    font-size: rem(25px);

                    padding-left: rem(45px);

                    position: relative;

                    &:before {
                        content: '';
                        background-color: #00aeef;

                        width: 1px;
                        height: 87%;

                        position: absolute;
                        top: 0;
                        left: rem(7px);
                        bottom: 0;
                        margin: auto;
                    }

                    .li {
                        margin: rem(20px auto);
                        position: relative;

                        &:before {
                            content: '';
                            background-color: #00aeef;

                            width: rem(15px);
                            height: rem(15px);

                            border-radius: 50%;

                            position: absolute;
                            top: 0;
                            left: rem(-45px);
                            bottom: 0;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }

    .block7 {
        width: 100%;
        box-sizing: border-box;
        /* padding-top: rem(200px);
        padding-bottom: rem(180px); */
        padding-left: rem(240px);
        position: relative;

        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1;

            .video {
                width: rem(775px);
                height: rem(775px);
    
                border-radius: 50%;
    
                overflow: hidden;
    
                video {
                    width: 100%;
                    height: 100%;
    
                    object-fit: cover;
                }
            }

            .texts {
                width: rem(600px);
                margin-right: rem(110px);

                &>.title {
                    color: #00aeef;
                    font-size: rem(53px);
                    font-weight: 700;
                }

                &>p {
                    font-family: 'PT Sans Caption', sans-serif;
                    color: #000;
                    font-size: rem(25px);
                }

                .dropdowns {
                    position: relative;
                    padding-left: rem(15px);

                    &:before {
                        content: '';

                        width: 1px;
                        height: calc(100% - 2rem);

                        position: absolute;
                        top: rem(16px);
                        left: rem(7px);

                        background-color: #00aeef;
                    }

                    .item {
                        padding-left: rem(15px);
                        position: relative;

                        .dot {
                            width: rem(15px);
                            height: rem(15px);

                            position: absolute;
                            top: rem(16px);
                            left: rem(-15px);

                            background-color: #00aeef;

                            border-radius: 50%;
                        }

                        .title {
                            font-size: rem(37px);
                            color: #00aeef;
                            font-weight: 700;
                            margin: rem(20px auto);

                            cursor: pointer;

                            img {
                                width: rem(19px);
                                height: rem(16px);

                                transform-origin: 50% 50%;

                                transition: 0.4s;
                            }

                            &.active {
                                img {
                                    transform: rotate(-90deg);
                                }
                            }
                        }

                        .text {
                            font-family: 'PT Sans Caption', sans-serif;
                            color: #000;
                            font-size: rem(21px);
                            display: none;
                        }
                    }
                }

            }
        }
    }

    .block8 {
        width: 100%;
        box-sizing: border-box;
        /* padding-top: rem(140px);
        padding-bottom: rem(150px); */
        padding-left: rem(240px);
        position: relative;
        background-color: #00aeef;

        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1;

            .video {
                width: rem(775px);
                height: rem(775px);
    
                border-radius: 50%;
    
                overflow: hidden;

                border: 3px solid #fff;
                box-sizing: border-box;
    
                video {
                    width: 100%;
                    height: 100%;
    
                    object-fit: cover;
                }
            }

            .texts {
                width: rem(600px);
                margin-left: rem(110px);

                &>.title {
                    color: #fff;
                    font-size: rem(53px);
                    font-weight: 700;
                }

                &>p {
                    font-family: 'PT Sans Caption', sans-serif;
                    color: #fff;
                    font-size: rem(25px);
                }

                .dropdowns {
                    position: relative;
                    padding-left: rem(15px);

                    &:before {
                        content: '';

                        width: 1px;
                        height: calc(100% - 2rem);

                        position: absolute;
                        top: rem(16px);
                        left: rem(7px);

                        background-color: #fff;
                    }

                    .item {
                        padding-left: rem(15px);
                        position: relative;

                        .dot {
                            width: rem(15px);
                            height: rem(15px);

                            position: absolute;
                            top: rem(16px);
                            left: rem(-15px);

                            background-color: #fff;

                            border-radius: 50%;
                        }

                        .title {
                            font-size: rem(37px);
                            color: #fff;
                            font-weight: 700;
                            margin: rem(20px auto);

                            cursor: pointer;

                            img {
                                width: rem(19px);
                                height: rem(16px);

                                transform-origin: 50% 50%;

                                transition: 0.4s;
                            }

                            &.active {
                                img {
                                    transform: rotate(-90deg);
                                }
                            }
                        }

                        .text {
                            font-family: 'PT Sans Caption', sans-serif;
                            color: #fff;
                            font-size: rem(21px);
                            display: none;
                        }
                    }
                }

            }
        }
    }

    .block9 {
        width: 100%;
        box-sizing: border-box;
        /* padding-top: rem(150px); */
        padding-left: rem(240px);
        position: relative;
        background-color: #00aeef;

        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1;

            .video {
                width: rem(775px);
                height: rem(775px);
    
                border-radius: 50%;
    
                overflow: hidden;

                border: 3px solid #fff;
                box-sizing: border-box;
    
                video {
                    width: 100%;
                    height: 100%;
    
                    object-fit: cover;
                }
            }

            .texts {
                width: rem(600px);
                margin-right: rem(110px);

                &>.title {
                    color: #fff;
                    font-size: rem(53px);
                    font-weight: 700;
                }

                &>p {
                    font-family: 'PT Sans Caption', sans-serif;
                    color: #fff;
                    font-size: rem(25px);
                }

                .dropdowns {
                    position: relative;
                    padding-left: rem(15px);

                    &:before {
                        content: '';

                        width: 1px;
                        height: calc(100% - 2rem);

                        position: absolute;
                        top: rem(16px);
                        left: rem(7px);

                        background-color: #fff;
                    }

                    .item {
                        padding-left: rem(15px);
                        position: relative;

                        .dot {
                            width: rem(15px);
                            height: rem(15px);

                            position: absolute;
                            top: rem(16px);
                            left: rem(-15px);

                            background-color: #fff;

                            border-radius: 50%;
                        }

                        .title {
                            font-size: rem(37px);
                            color: #fff;
                            font-weight: 700;
                            margin: rem(20px auto);

                            cursor: pointer;

                            img {
                                width: rem(19px);
                                height: rem(16px);

                                transform-origin: 50% 50%;

                                transition: 0.4s;
                            }

                            &.active {
                                img {
                                    transform: rotate(-90deg);
                                }
                            }
                        }

                        .text {
                            font-family: 'PT Sans Caption', sans-serif;
                            color: #fff;
                            font-size: rem(21px);
                            display: none;
                        }
                    }
                }

            }
        }
    }

    .slideFromTop {
        opacity: 0;
        transform: translateY(-100%);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .slideFromBottom {
        opacity: 0;
        transform: translateY(100%);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .slideFromLeft {
        opacity: 0;
        transform: translateX(-100%);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .slideFromRight {
        opacity: 0;
        transform: translateX(100%);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .scaleTo {
        opacity: 0;
        transform: scale(0.5);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.contacts-main {
    display: flex;

    .logo {
        width: rem(198px);
        height: rem(164px);

        position: absolute;
        top: rem(30px);
        right: rem(100px);
    }

    .left {
        background-color: #00aeef;
        width: rem(600px);
        height: 100vh;

        position: sticky;
        top: 0;
        left: 0;

        box-shadow: rem(7px 7px 5px rgba(0, 0, 0, 0.1));

        z-index: 10;

        box-sizing: border-box;

        .wrap {
            width: rem(350px);
            height: 34%;

            position: absolute;
            top: 0;
            left: rem(64px);
            bottom: 0;
            right: 0;
            margin: auto;

            .title {
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;
                font-size: rem(48px);
                margin-bottom: rem(30px);
            }
            .items {
                position: relative;

                .line {
                    width: 1px;
                    height: calc(100% - 1rem);

                    background-color: #fff;

                    position: absolute;
                    top: 0;
                    left: rem(6px);
                    bottom: 0;
                    margin: auto;
                }
                .item {
                    font-family: 'PT Sans', sans-serif;
                    font-size: rem(24.5px);

                    margin-bottom: rem(25px);
                    padding-left: rem(30px);

                    position: relative;

                    cursor: pointer;

                    .dot {
                        width: rem(16px);
                        height: rem(16px);

                        background-color: #fff;
                        border-radius: 50%;

                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
    }

    .right {
        width: calc(100% - 37.5rem);

        padding-top: rem(90px);
        padding-left: rem(80px);
        padding-right: rem(80px);

        box-sizing: border-box;

        position: relative;

        .screen {
            display: none;

            .title {
                color: #00aeef;
                font-size: rem(48px);
                font-family: 'PT Sans Caption', sans-serif;
                font-weight: 700;
                line-height: rem(57.5px);
                margin-bottom: rem(20px);
                margin-top: rem(75px);
            }
    
            .address {
                color: #000;
                font-size: rem(29px);
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;

                margin-bottom: rem(15px);
    
                padding-left: rem(40px);
                position: relative;
    
                &:before {
                    content: '';
    
                    background: url(../img/icon_pin.svg) 50% 50% no-repeat;
                    background-size: contain;
    
                    width: rem(25px);
                    height: rem(36px);
    
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
    
            .tel {
                color: #000;
                font-size: rem(29px);
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;
                line-height: rem(50px);
    
                padding-left: rem(40px);
                position: relative;
    
                &:before {
                    content: '';
    
                    background: url(../img/icon_tel.svg) 50% 50% no-repeat;
                    background-size: contain;
    
                    width: rem(27px);
                    height: rem(27px);
    
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                }
            }

            .tel-two {
                color: #000;
                font-size: rem(29px);
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;
                line-height: rem(50px);
    
                padding-left: rem(40px);
            }

            .worktime {
                color: #000;
                font-size: rem(25px);
                font-family: 'PT Sans', sans-serif;
                line-height: rem(50px);
    
                padding-left: rem(40px);
                position: relative;
    
                &:before {
                    content: '';
    
                    background: url(../img/icon-worktime.svg) 50% 50% no-repeat;
                    background-size: contain;
    
                    width: rem(30px);
                    height: rem(30px);
    
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
                }
            }

            .map {
                width: rem(705px);
                height: rem(420px);

                margin-top: rem(30px);
            }

            .photo-office {
                p {
                    color: #00aeef;
                    font-size: rem(24px);
                    font-family: 'PT Sans', sans-serif;
                    position: relative;
                    padding-left: rem(25px);
                    margin: rem(15px 0);

                    cursor: pointer;

                    &:before {
                        content: '';

                        background: url(../img/icon-down-contacts.svg) 50% 50% no-repeat;
                        background-size: contain;

                        width: rem(14px);
                        height: rem(12px);

                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;

                        transition: 0.3s;
                    }

                    &.active {
                        &:before {
                            transform: rotate(-90deg);
                        }
                    }
                }

                .photo {
                    display: none;
                    max-width: rem(705px);

                    img {
                        width: 100%;
                    }
                }
            }

            &>p {
                color: #000;
                font-size: rem(24px);
                font-family: 'PT Sans', sans-serif;
                margin: rem(10px 0);

                span {
                    font-weight: 700;
                }
            }

            ul {
                padding-left: rem(0);

                li {
                    color: #000;
                    font-size: rem(24px);
                    font-family: 'PT Sans', sans-serif;
                    margin: rem(10px 0);
                    list-style-type: none;
                    position: relative;
                    padding-left: rem(30px);

                    span {
                        font-weight: 700;
                    }
                    
                    &:before {
                        content: '';

                        background-color: #00aeef;

                        width: rem(12px);
                        height: rem(12px);

                        border-radius: 50%;

                        position: absolute;
                        
                        top: rem(7px);
                        left: 0;
                    }
                }
            }

            .departments {
                .block {
                    .title {
                        color: #000;
                        font-size: rem(24px);
                        font-family: 'PT Sans', sans-serif;
                        font-weight: 700;
                        text-transform: uppercase;
                        padding-left: rem(40px);
                        margin-top: rem(25px);

                        span.email {
                            text-transform: capitalize;
                            font-size: rem(24px);
                            font-weight: 400;
                            font-family: 'PT Sans', sans-serif;

                            position: relative;

                            padding-left: rem(30px);
                            margin-left: rem(20px);

                            &:before {
                                content: '';

                                background: url(../img/icon_email.svg) 50% 50% no-repeat;
                                background-size: contain;

                                width: rem(21px);
                                height: rem(16px);

                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                margin: auto;
                            }
                        }
                    }

                    .list {
                        width: 100%;

                        color: #000;
                        font-size: rem(24px);
                        font-family: 'PT Sans', sans-serif;

                        .item {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            padding: rem(30px 40px);
                            border-radius: rem(37px);
                            background-color: #ededed;

                            margin-bottom: rem(15px);

                            &.item-end {
                                background-color: transparent;
                            }

                            .email {
                                position: relative;
                                padding-left: rem(30px);

                                &:before {
                                    content: '';
    
                                    background: url(../img/icon_email.svg) 50% 50% no-repeat;
                                    background-size: contain;
    
                                    width: rem(21px);
                                    height: rem(16px);
    
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    margin: auto;
                                }
                            }
                        }
                    }
                }
            }

            .agents {
                width: 100%;

                color: #000;
                font-size: rem(24px);
                font-family: 'PT Sans', sans-serif;

                .title {
                    margin-bottom: rem(40px);
                }

                .item {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: rem(30px 40px);
                    border-radius: rem(37px);
                    background-color: #ededed;

                    margin-bottom: rem(15px);

                    &.item-end {
                        background-color: transparent;
                    }

                    &.item-invert {
                        background-color: #00aeef;
                        color: #fff;

                        .email {
                            &:before {
                                filter: brightness(0) invert(1);
                            }
                        }
                    }

                    .email {
                        position: relative;
                        padding-left: rem(30px);

                        &:before {
                            content: '';

                            background: url(../img/icon_email.svg) 50% 50% no-repeat;
                            background-size: contain;

                            width: rem(21px);
                            height: rem(16px);

                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                }
            }

            .sng {
                width: 100%;

                color: #000;
                font-size: rem(24px);
                font-family: 'PT Sans', sans-serif;

                .name {
                    font-weight: 700;
                    margin-bottom: rem(15px);
                }

                .item {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: rem(30px 40px);
                    border-radius: rem(37px);
                    background-color: #ededed;

                    margin-bottom: rem(15px);

                    &.item-end {
                        background-color: transparent;
                    }

                    .text {
                        width: 44%;

                        a {
                            color: #000;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .phone {
                        position: relative;
                        padding-left: rem(30px);

                        &:before {
                            content: '';

                            background: url(../img/icon_tel.svg) 50% 50% no-repeat;
                            background-size: contain;

                            width: rem(21px);
                            height: rem(21px);

                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                    .email {
                        position: relative;
                        padding-left: rem(30px);

                        &:before {
                            content: '';

                            background: url(../img/icon_email.svg) 50% 50% no-repeat;
                            background-size: contain;

                            width: rem(21px);
                            height: rem(16px);

                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
}

.manufacturers-main {
    .header {
        width: 100%;

        padding-top: rem(70px);
        padding-left: rem(150px);

        box-sizing: border-box;

        position: relative;

        .pagename {
            font-size: rem(48px);
            color: #00aeef;
            font-weight: 700;
        }

        .search {
            select {
                appearance: none;

                margin-top: rem(35px);
                margin-right: rem(15px);

                width: rem(350px);

                border: 1px solid #b6b7b7;

                font-size: rem(24px);
                color: #b6b7b7;

                padding: rem(15px 50px 15px 25px);
                box-sizing: border-box;
                
                border-radius: rem(15px);

                background-image: url(../img/icon_select_arrow.svg);
                background-position: 95% 50%;
                background-size: rem(15px);
                background-repeat: no-repeat;

                option {
                    color: #b6b7b7;
                }

                &.alphabet {
                    display: none;
                }
            }
        }

        .logo {
            width: rem(198px);
            height: rem(164px);

            position: absolute;
            bottom: 0;
            right: rem(100px);
        }
    }

    .links {
        padding-left: rem(170px);
        margin: rem(20px 0);

        a {
            color: #b6b7b7;
            font-size: rem(24px);
            text-decoration: none;
            margin: rem(0 4px);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .main {
        padding-left: rem(150px);
        padding-bottom: rem(50px);

        .logos {
            width: 95%;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;

            .separate {
                color: #b5b6b6;
                font-size: rem(30px);
                font-family: 'PT Sans', sans-serif;
                line-height: rem(32px);
                margin-bottom: rem(20px);
                margin-top: rem(20px);

                text-align: center;

                width: 100%;

                position: relative;

                cursor: pointer;

                display: none;

                &.hide {
                    display: none;
                }
            }

            .logos-hide {
                width: 100%;

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;

                opacity: 1;
                display: flex;

                transition: 1s;

                &.active {
                    opacity: 1;
                    display: flex !important;
                }
            }

            .item {
                width: 12.5%;
                height: rem(210px);

                border: 1px solid #e1e1e1;
                box-sizing: border-box;

                position: relative;

                &:hover {
                    border: 1px solid #00aeef;
                    box-shadow: 0 0 1.25rem rgba(0,105,241,0.2);
                }

                img {
                    width: 80%;
                    height: 80%;

                    object-fit: contain;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }
    }
}

.support-main {
    position: relative;

    .f1 {
        width: rem(1300px);
        height: rem(1919px);

        position: absolute;
        top: rem(-434px);
        right: rem(0);
    }

    .f2 {
        width: rem(1852px);
        height: rem(1118px);

        position: absolute;
        top: rem(1250px);
        left: rem(-330px);
    }

    .f3 {
        width: rem(2555px);
        height: rem(1338px);

        position: absolute;
        top: rem(2750px);
        left: rem(-540px);
    }

    .f4 {
        width: rem(2025px);
        height: rem(1418px);

        position: absolute;
        top: rem(4480px);
        right: rem(-10px);
    }

    .f5 {
        width: rem(2078px);
        height: rem(1503px);

        position: absolute;
        top: rem(4790px);
        right: rem(-10px);

        z-index: 1;
    }

    .f6 {
        width: rem(1926px);
        height: rem(1794px);

        position: absolute;
        top: rem(6323px);
        right: rem(-390px);

        z-index: 1;
    }

    canvas{ 
        display: block; 
        vertical-align: bottom; 
    }

    #particles-js1{ 
        position:absolute; 
        width: 100%; 
        height: 100%;

        background-color: transparent;
    }

    .block1 {
        width: 100%;
        box-sizing: border-box;
        padding-left: rem(150px);
        position: relative;

        .container {
            width: 100%;

            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1;

            .texts {
                width: 45%;
    
                .logo {
                    width: rem(441px);
                    height: rem(163px);
                    margin-left: auto;

                    img {
                        width: 100%;
                        height: 100%;

                        object-fit: contain;
                        object-position: 0% 50%;
                    }
                }
    
                .text {
                    margin-top: rem(90px);

                    .title {
                        color: #00aeef;
                        font-family: 'PT Sans', sans-serif;
                        font-weight: 700;
                        font-size: rem(53px);
                        line-height: rem(52px);
                        margin-bottom: rem(30px);
                    }
    
                    p {
                        font-family: 'PT Sans Caption', sans-serif;
                        color: #000;
                        font-size: rem(24px);
                        line-height: rem(28px);
                    }
                }
            }
            
            .pic {
                width: rem(744px);
                height: rem(744px);

                margin-left: rem(100px);
                margin-top: rem(60px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: 50% 50%;
                }
            }
        }
    }

    .block2,
    .block4,
    .block6,
    .block8 {
        width: 100%;
        box-sizing: border-box;
        padding-left: rem(150px);
        position: relative;

        .container {
            width: 100%;

            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1;

            .texts {
                width: 45%;
                margin-left: rem(150px);
    
                .text {
                    margin-top: rem(90px);

                    .title {
                        color: #00aeef;
                        font-family: 'PT Sans', sans-serif;
                        font-weight: 700;
                        font-size: rem(53px);
                        line-height: rem(52px);
                        margin-bottom: rem(30px);
                    }
    
                    p {
                        font-family: 'PT Sans Caption', sans-serif;
                        color: #000;
                        font-size: rem(24px);
                        line-height: rem(28px);
                    }
                }
            }
            
            .pic {
                width: rem(700px);
                height: rem(700px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: 50% 50%;
                }
            }
        }
    }

    .block3,
    .block5,
    .block7 {
        width: 100%;
        box-sizing: border-box;
        padding-left: rem(150px);
        position: relative;

        .container {
            width: 100%;

            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1;

            .texts {
                width: 45%;

                .text {
                    margin-top: rem(90px);

                    .title {
                        color: #00aeef;
                        font-family: 'PT Sans', sans-serif;
                        font-weight: 700;
                        font-size: rem(53px);
                        line-height: rem(52px);
                        margin-bottom: rem(30px);
                    }
    
                    p {
                        font-family: 'PT Sans Caption', sans-serif;
                        color: #000;
                        font-size: rem(24px);
                        line-height: rem(28px);
                    }
                }
            }
            
            .pic {
                width: rem(700px);
                height: rem(700px);

                margin-left: rem(100px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: 50% 50%;
                }
            }
        }
    }


    .slideFromTop {
        opacity: 0;
        transform: translateY(-100%);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .slideFromBottom {
        opacity: 0;
        transform: translateY(100%);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .slideFromLeft {
        opacity: 0;
        transform: translateX(-100%);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .slideFromRight {
        opacity: 0;
        transform: translateX(100%);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .scaleTo {
        opacity: 0;
        transform: scale(0.5);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.partners-main {
    position: relative;

    .f1 {
        width: rem(1300px);
        height: rem(1919px);

        position: absolute;
        top: rem(-434px);
        right: rem(0);
    }

    .f2 {
        width: rem(1852px);
        height: rem(1118px);

        position: absolute;
        top: rem(1250px);
        left: rem(-330px);
    }

    canvas{ 
        display: block; 
        vertical-align: bottom; 
    }

    #particles-js1{ 
        position:absolute; 
        width: 100%; 
        height: 100%;

        background-color: transparent;
    }

    .block1 {
        width: 100%;
        box-sizing: border-box;
        padding-left: rem(150px);
        position: relative;

        .container {
            width: 100%;

            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1;

            .texts {
                width: 53%;
                margin-left: rem(100px);
                margin-top: rem(0);
    
                .logo {
                    width: rem(441px);
                    height: rem(163px);
                    margin-left: auto;

                    img {
                        width: 100%;
                        height: 100%;

                        object-fit: contain;
                        object-position: 0% 50%;
                    }
                }
    
                .text {
                    margin-top: rem(50px);

                    .title {
                        color: #00aeef;
                        font-family: 'PT Sans', sans-serif;
                        font-weight: 700;
                        font-size: rem(53px);
                        line-height: rem(52px);
                        margin-bottom: rem(30px);
                    }
    
                    p {
                        font-family: 'PT Sans Caption', sans-serif;
                        color: #000;
                        font-size: rem(22px);
                        line-height: rem(26px);
                    }
                }
            }
            
            .pic {
                width: rem(900px);
                height: rem(900px);

                margin-left: rem(-250px);
                margin-top: rem(50px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: 50% 50%;
                }
            }
        }
    }

    .block2 {
        width: 100%;
        box-sizing: border-box;
        padding-left: rem(240px);
        position: relative;

        .container {
            width: 100%;

            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1;

            .texts {
                width: 41%;
    
                .text {
                    .title {
                        color: #00aeef;
                        font-family: 'PT Sans', sans-serif;
                        font-weight: 700;
                        font-size: rem(53px);
                        line-height: rem(52px);
                        margin-bottom: rem(30px);
                    }
    
                    p {
                        font-family: 'PT Sans Caption', sans-serif;
                        color: #000;
                        font-size: rem(24px);
                        line-height: rem(28px);
                    }

                    a.btn {
                        background-color: #00aeef;
                        color: #fff;
                        font-family: 'PT Sans Caption', sans-serif;
                        font-weight: 700;
                        font-size: rem(25px);

                        width: rem(358px);
                        height: rem(68px);

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        text-decoration: none;

                        border-radius: rem(10px);

                        margin-top: rem(40px);

                        cursor: pointer;
                    }
                }
            }
            
            .pic {
                width: rem(800px);
                height: rem(800px);

                margin-left: rem(100px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: 50% 50%;
                }
            }
        }
    }

    .block3 {
        width: 100%;
        box-sizing: border-box;
        padding-left: rem(150px);
        position: relative;
        background-color: #00aeef;

        .container {
            width: 100%;

            display: flex;
            flex-direction: row;
            align-items: center;
            z-index: 1;

            .texts {
                width: 41%;
                margin-left: rem(100px);

                .text {
                    .title {
                        color: #ffffff;
                        font-family: 'PT Sans', sans-serif;
                        font-weight: 700;
                        font-size: rem(53px);
                        line-height: rem(52px);
                        margin-bottom: rem(30px);
                    }
    
                    p {
                        font-family: 'PT Sans Caption', sans-serif;
                        color: #ffffff;
                        font-size: rem(24px);
                        line-height: rem(28px);
                    }

                    a.btn {
                        background-color: #fff;
                        color: #00aeef;
                        font-family: 'PT Sans Caption', sans-serif;
                        font-weight: 700;
                        font-size: rem(25px);

                        width: rem(358px);
                        height: rem(68px);

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        text-decoration: none;

                        border-radius: rem(10px);

                        margin-top: rem(40px);

                        cursor: pointer;
                    }
                }
            }
            
            .pic {
                width: rem(800px);
                height: rem(800px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: 50% 50%;
                }
            }
        }
    }

    .forms {
        background-color: #fff;

        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: row;

        z-index: 2;

        .left {
            width: 45%;

            padding-left: rem(150px);
            padding-right: rem(60px);

            box-sizing: border-box;

            box-shadow: rem(7px 7px 5px rgba(0, 0, 0, 0.1));

            .form {
                padding-bottom: rem(50px);

                .title {
                    color: #00aeef;
                    font-family: 'PT Sans Caption', sans-serif;
                    font-weight: 700;
                    font-size: rem(48px);

                    margin-top: rem(50px);
                }

                .title2 {
                    color: #b6b7b7;
                    font-family: 'PT Sans', sans-serif;
                    font-weight: 700;
                    font-size: rem(35px);
                }

                form {
                    .inputs {
                        display: flex;
                        flex-direction: column;

                        margin-top: rem(40px);
                        
                        input {
                            width: 100%;
                            height: rem(58px);

                            border: 1px solid #b6b7b7;
                            border-radius: rem(10px);

                            box-shadow: none;

                            color: #1d1d1b;
                            font-family: 'PT Sans', sans-serif;
                            font-weight: 400;
                            font-size: rem(24px);

                            padding: rem(0 20px);
                            box-sizing: border-box;

                            margin-bottom: rem(20px);
                        }
                    }

                    label {
                        color: #1d1d1b;
                        font-family: 'PT Sans', sans-serif;
                        font-weight: 400;
                        font-size: rem(19px);

                        display: flex;
                        flex-direction: row;

                        input {
                            width: rem(44px);
                            height: rem(27px);
                            
                            border: 1px solid #b6b7b7;
                            border-radius: rem(10px);

                            box-shadow: none;
                        }

                        p {
                            margin: 0;
                            margin-left: rem(10px);

                            a {
                                color: #1d1d1b;
                            }
                        }
                    }

                    .bottom {
                        background-color: transparent;
                        margin-top: rem(40px);

                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        input[type="submit"] {
                            background-color: #00aeef;
                            color: #fff;
                            font-family: 'PT Sans Caption', sans-serif;
                            font-weight: 700;
                            font-size: rem(25px);
    
                            width: rem(273px);
                            height: rem(58px);
    
                            display: flex;
                            align-items: center;
                            justify-content: center;
    
                            border-radius: rem(10px);
    
    
                            box-shadow: none;
                            border: none;
    
                            cursor: pointer;
                        }

                        .captcha {
                            width: rem(338px);
                            
                            img {
                                width: 100%;
                            }
                        }
                    }

                    
                }
            }
        }

        .right {
            width: 55%;
            padding-bottom: rem(50px);

            .logo {
                width: rem(198px);
                height: rem(164px);

                position: absolute;
                top: rem(40px);
                right: rem(100px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: 50% 50%;
                }
            }

            .texts {
                margin-top: rem(270px);
                padding-left: rem(70px);
                padding-right: rem(70px);

                .title {
                    color: #00aeef;
                    font-family: 'PT Sans Caption', sans-serif;
                    font-weight: 700;
                    font-size: rem(44px);
                }

                .title2 {
                    color: #1d1d1b;
                    font-family: 'PT Sans', sans-serif;
                    font-weight: 700;
                    font-size: rem(27px);

                    margin-top: rem(40px);
                    margin-bottom: rem(40px);

                    text-transform: uppercase;
                }

                p {
                    color: #1d1d1b;
                    font-family: 'PT Sans', sans-serif;
                    font-weight: 400;
                    font-size: rem(22px);
                    position: relative;

                    &:before {
                        content: '';

                        background-color: #00aeef;

                        width: rem(6px);
                        height: rem(6px);

                        border-radius: 50%;

                        position: absolute;
                        top: 0;
                        left: rem(-15px);
                        bottom: 0;
                        margin: auto;
                    }

                    &.text_end {
                        margin-top: rem(50px);

                        &:before {
                            display: none;
                        }
                    }
                }

                &.texts-vendor {
                    margin-top: rem(300px);

                    p {
    
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }


    .slideFromTop {
        opacity: 0;
        transform: translateY(-100%);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .slideFromBottom {
        opacity: 0;
        transform: translateY(100%);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .slideFromLeft {
        opacity: 0;
        transform: translateX(-100%);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .slideFromRight {
        opacity: 0;
        transform: translateX(100%);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: translateY(0%);
        }
    }

    .scaleTo {
        opacity: 0;
        transform: scale(0.5);

        transition: 0.8s;

        &.active {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.presscenter-main {
    .first-screen {
        flex-direction: row;

        display: none;

        &.active {
            display: flex;
        }

        .left {
            width: 50%;
            height: 100vh;
    
            position: sticky;
            top: 0;
            left: 0;
    
            box-shadow: rem(7px 7px 5px rgba(0, 0, 0, 0.1));
    
            z-index: 10;

            box-sizing: border-box;

            .wrap {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;

                height: 88%;

                padding-left: rem(150px);
                padding-right: rem(80px);

                .pagename {
                    font-size: rem(48px);
                    color: #00aeef;
                    font-weight: 700;
                }
    
                .search {
                    input,
                    select {
                        appearance: none;
    
                        margin-top: rem(35px);
    
                        width: rem(270px);
    
                        border: 1px solid #b6b7b7;
    
                        font-size: rem(24px);
                        color: #b6b7b7;
    
                        padding: rem(15px 25px);
                        box-sizing: border-box;
                        
                        border-radius: rem(15px);

                        option {
                            color: #b6b7b7;
                        }

                        &.year {
                            width: rem(135px);
                            
                            option {
                                width: rem(135px);
                            }
                        }

                        &.month {
                            width: rem(170px);
                            margin-left: rem(10px);
                        }
                    }

                    a {
                        font-size: rem(24px);
                        color: #b6b7b7;
                        text-decoration: none;

                        margin-left: rem(10px);

                        display: inline-block;

                        transform: scale(1);
                        transition: 0.3s;

                        &.hide {
                            visibility: hidden;
                            opacity: 0;
                            transform: scale(0);
                            width: 0;
                        }
                    }
                }
    
                .presscenter-select {
                    .pic {
                        margin-top: rem(35px);
    
                        img {
                            max-height: rem(300px);
                            width: 100%;

                            object-fit: cover;
                            object-position: 50% 50%;
                        }
                    }
    
                    .new-presscenter {
                        font-size: rem(48px);
                        color: #00aeef;
                        font-weight: 700;
    
                        margin-top: rem(40px);
                    }
    
                    .category {
                        font-size: rem(20px);
                        color: #b7b7b7;
                        line-height: rem(60px);
                    }
    
                    .title {
                        font-size: rem(34px);
                        color: #00aeef;
                        font-weight: 700;
                        line-height: rem(42px);
                    }
    
                    .desc {
                        font-size: rem(21px);
                        color: #000;
                        line-height: rem(25px);
    
                        margin-top: rem(10px);
                    }
                    
                    .date {
                        font-size: rem(24px);
                        color: #b7b7b7;
                        line-height: rem(50px);
    
                        margin-top: rem(10px);
                    }

                    .line {
                        width: 100%;
                        height: 1px;

                        margin-top: rem(20px);
                        margin-bottom: rem(20px);

                        background-color: #b6b7b7;
                    }

                    .profile {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .photo {
                            width: rem(110px);
                            height: rem(110px);

                            border-radius: 50%;

                            margin-right: rem(20px);

                            img {
                                width: 100%;
                                height: 100%;

                                object-fit: contain;
                                object-position: 50% 50%;
                            }
                        }

                        .texts {
                            color: #1d1d1b;

                            .name {
                                font-size: rem(24px);
                                line-height: rem(29px);

                                font-weight: 700;
                            }
                            .position {
                                font-size: rem(16px);
                                line-height: rem(19px);

                                margin-top: rem(10px);
                            }
                            .contact {
                                font-size: rem(16px);
                                line-height: rem(19px);

                                margin-top: rem(10px);

                                a {
                                    margin-left: rem(10px);
                                    
                                    img {
                                        width: rem(21px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    
        .right {
            width: 50%;

            .presscenter-list {
                width: rem(740px);
                margin-left: rem(130px);
                padding-left: rem(70px);
                box-sizing: border-box;
    
                padding-top: rem(40px);
                padding-bottom: rem(40px);

                position: relative;
    
                .line {
                    position: absolute;
                    top: rem(122px);
                    left: rem(7px);
    
                    width: 1px;
                    height: auto;
    
                    background-color: #b6b7b7;
    
                    transition: height 1s;
                }
                
                .presscenter-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
    
                    margin-top: rem(30px);
    
                    position: relative;
    
                    cursor: pointer;
    
                    .pic {
                        width: rem(260px);
                        height: rem(220px);
    
                        filter: none;
                        transition: 0.25s;
    
                        img {
                            width: rem(260px);
                            height: rem(220px);
    
                            object-fit: cover;
                        }
                    }
    
                    .texts {
                        margin-left: rem(50px);
    
                        .category {
                            font-size: rem(15px);
                            color: #b7b7b7;
                        }
    
                        .title {
                            font-size: rem(30px);
                            color: #00aeef;
                            font-weight: 700;
    
                            margin-top: rem(20px);
                        }
    
                        .desc {
                            font-size: rem(16px);
                            color: #000;
    
                            margin-top: rem(15px);
                        }
    
                        .date {
                            font-size: rem(18px);
                            color: #b7b7b7;
    
                            margin-top: rem(20px);
                        }
                    }
    
                    .dot {
                        position: absolute;
                        top: 0;
                        left: rem(-70px);
                        bottom: 0;
                        margin: auto;
    
                        width: rem(16px);
                        height: rem(16px);
    
                        background-color: #b6b7b7;
                        border-radius: 50%;
                    }
    
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: rem(-70px);
                        bottom: 0;
                        margin: auto;
    
                        width: rem(70px);
                        height: rem(1px);
    
                        background-color: #b6b7b7;
                    }
    
                    &:hover .pic {
                        filter: contrast(0.3) brightness(1.5);
                    }
                }
            }
        }
    }

    .search-result {
        display: none;

        &.active {
            display: block;
        }

        .header {
            width: 100%;

            padding-top: rem(70px);
            padding-left: rem(150px);

            box-sizing: border-box;

            position: relative;

            .pagename {
                font-size: rem(48px);
                color: #00aeef;
                font-weight: 700;
            }

            .search {
                input,
                select {
                    appearance: none;

                    margin-top: rem(35px);

                    width: rem(270px);

                    border: 1px solid #b6b7b7;

                    font-size: rem(24px);
                    color: #b6b7b7;

                    padding: rem(15px 25px);
                    box-sizing: border-box;
                    
                    border-radius: rem(15px);

                    option {
                        color: #b6b7b7;
                    }

                    &.year {
                        width: rem(135px);
                        
                        option {
                            width: rem(135px);
                        }
                    }

                    &.month {
                        width: rem(170px);
                        margin-left: rem(10px);
                    }
                }

                a {
                    font-size: rem(24px);
                    color: #b6b7b7;
                    text-decoration: none;

                    margin-left: rem(10px);

                    display: inline-block;

                    transform: scale(1);
                    transition: 0.3s;

                    &.hide {
                        visibility: hidden;
                        opacity: 0;
                        transform: scale(0);
                        width: 0;
                    }
                }
            }

            .logo {
                width: rem(198px);
                height: rem(164px);

                position: absolute;
                bottom: 0;
                right: rem(100px);
            }
        }

        .main {
            width: 100%;
            
            display: flex;
            flex-direction: row;

            .left {
                width: 50%;
    
                .presscenter-list {
                    width: rem(740px);
                    margin-left: rem(150px);
                    padding-left: rem(70px);
                    box-sizing: border-box;
        
                    padding-top: rem(40px);
                    padding-bottom: rem(40px);

                    position: relative;
        
                    .line {
                        position: absolute;
                        top: rem(122px);
                        left: rem(7px);
        
                        width: 1px;
                        height: auto;
        
                        background-color: #b6b7b7;
        
                        transition: height 1s;
                    }
                    
                    .presscenter-item {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
        
                        margin-top: rem(30px);
        
                        position: relative;
        
                        cursor: pointer;
        
                        .pic {
                            width: rem(260px);
                            height: rem(220px);
        
                            filter: none;
                            transition: 0.25s;
        
                            img {
                                width: rem(260px);
                                height: rem(220px);
        
                                object-fit: cover;
                            }
                        }
        
                        .texts {
                            margin-left: rem(50px);
        
                            .category {
                                font-size: rem(15px);
                                color: #b7b7b7;
                            }
        
                            .title {
                                font-size: rem(30px);
                                color: #00aeef;
                                font-weight: 700;
        
                                margin-top: rem(20px);
                            }
        
                            .desc {
                                font-size: rem(16px);
                                color: #000;
        
                                margin-top: rem(15px);
                            }
        
                            .date {
                                font-size: rem(18px);
                                color: #b7b7b7;
        
                                margin-top: rem(20px);
                            }
                        }
        
                        .dot {
                            position: absolute;
                            top: 0;
                            left: rem(-70px);
                            bottom: 0;
                            margin: auto;
        
                            width: rem(16px);
                            height: rem(16px);
        
                            background-color: #b6b7b7;
                            border-radius: 50%;
                        }
        
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: rem(-70px);
                            bottom: 0;
                            margin: auto;
        
                            width: rem(70px);
                            height: rem(1px);
        
                            background-color: #b6b7b7;
                        }
        
                        &:hover .pic {
                            filter: contrast(0.3) brightness(1.5);
                        }
                    }
                }
            }
        
            .right {
                width: 50%;
    
                .presscenter-list {
                    width: rem(740px);
                    margin-left: rem(150px);
                    padding-left: rem(0);
                    box-sizing: border-box;
        
                    padding-top: rem(40px);
                    padding-bottom: rem(40px);

                    position: relative;
        
                    .line {
                        position: absolute;
                        top: rem(122px);
                        left: rem(-63px);
        
                        width: 1px;
                        height: auto;
        
                        background-color: #b6b7b7;
        
                        transition: height 1s;
                    }
                    
                    .presscenter-item {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
        
                        margin-top: rem(30px);
        
                        position: relative;
        
                        cursor: pointer;
        
                        .pic {
                            width: rem(260px);
                            height: rem(220px);
        
                            filter: none;
                            transition: 0.25s;
        
                            img {
                                width: rem(260px);
                                height: rem(220px);
        
                                object-fit: cover;
                            }
                        }
        
                        .texts {
                            margin-left: rem(50px);
        
                            .category {
                                font-size: rem(15px);
                                color: #b7b7b7;
                            }
        
                            .title {
                                font-size: rem(30px);
                                color: #00aeef;
                                font-weight: 700;
        
                                margin-top: rem(20px);
                            }
        
                            .desc {
                                font-size: rem(16px);
                                color: #000;
        
                                margin-top: rem(15px);
                            }
        
                            .date {
                                font-size: rem(18px);
                                color: #b7b7b7;
        
                                margin-top: rem(20px);
                            }
                        }
        
                        .dot {
                            position: absolute;
                            top: 0;
                            left: rem(-70px);
                            bottom: 0;
                            margin: auto;
        
                            width: rem(16px);
                            height: rem(16px);
        
                            background-color: #b6b7b7;
                            border-radius: 50%;
                        }
        
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: rem(-70px);
                            bottom: 0;
                            margin: auto;
        
                            width: rem(70px);
                            height: rem(1px);
        
                            background-color: #b6b7b7;
                        }
        
                        &:hover .pic {
                            filter: contrast(0.3) brightness(1.5);
                        }
                    }
                }
            }
        }
    }

    .presscenter-single {
        display: none;

        &.active {
            display: flex;
        }

        .left {
            width: 63%;

            padding-left: rem(200px);
            padding-right: rem(115px);
            padding-top: rem(50px);
            padding-bottom: rem(50px);
            
            box-sizing: border-box;

            .pagename {
                font-size: rem(48px);
                color: #00aeef;
                font-weight: 700;
            }

            .pic {
                margin-top: rem(25px);

                width: 100%;
                height: rem(400px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: left center;
                }
            }

            .meta {
                width: 100%;
                height: auto;

                margin-top: rem(40px);
                padding-bottom: rem(40px);

                display: flex;
                flex-direction: row;
                align-items: center;

                border-bottom: 1px solid #b6b7b7;

                .text_wrap {
                    margin-bottom: rem(20px);

                    .category {
                        font-size: rem(16px);
                        color: #b7b7b7;
                        line-height: rem(48px);
                    }

                    .title {
                        font-size: rem(49px);
                        color: #00aeef;
                        line-height: rem(58px);
                        font-weight: 700;
                    }
                }
            }

            .presscenter-single-text {
                margin-top: rem(30px);

                color: #000;

                font-size: rem(20px);
                line-height: rem(24px);

                padding-bottom: rem(30px);

                border-bottom: 1px solid #b6b7b7;
            }

            .footer {
                margin-top: rem(30px);
                margin-left: rem(10px);

                .social {
                    color: #9bb3be;
                    font-size: rem(16px);

                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {
                        margin-right: rem(10px);
                    }

                    .icon {
                        width: rem(32px);
                        height: rem(32px);

                        border-radius: 50%;

                        background-color: #00aeef;

                        margin-right: rem(5px);

                        padding: rem(1px);
                        box-sizing: border-box;

                        transition: 0.3s;

                        &:hover {
                            background-color: #009edf;
                        }
                    }
                }
            }
        }
    
        .right {
            width: 37%;
            height: 100vh;
    
            position: sticky;
            top: 0;
            right: 0;
    
            z-index: 10;

            background-color: #00aeef;

            .logo {
                width: rem(173px);
                height: rem(143px);

                position: absolute;
                top: rem(50px);
                right: rem(80px);
            }

            .presscenter-list-small {
                padding-top: rem(200px);
                padding-left: rem(70px);

                box-sizing: border-box;

                .line {
                    position: absolute;
                    top: rem(244px);
                    left: rem(47px);
    
                    width: 1px;
                    height: auto;
    
                    background-color: #fff;
    
                    transition: height 0.3s;
                }

                .presscenter-item {
                    display: flex;
                    flex-direction: column;
    
                    margin-top: rem(30px);
    
                    position: relative;
    
                    cursor: pointer;
    
                    .texts {
                        margin-left: rem(10px);
    
                        .title {
                            font-size: rem(32px);
                            color: #fff;
                            font-weight: 700;
                            line-height: rem(38px);
                        }

                        .category {
                            font-size: rem(16px);
                            color: #fff;
                            line-height: rem(30px);
                        }
    
                        .date {
                            font-size: rem(19px);
                            color: #fff;
                            line-height: rem(30px);
                        }
                    }
    
                    .dot {
                        position: absolute;
                        top: rem(13px);
                        left: rem(-30px);
    
                        width: rem(16px);
                        height: rem(16px);
    
                        background-color: #fff;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.direction-details-main {
    display: flex;

    .left {
        background-color: #00aeef;
        width: 45%;
        height: 100vh;

        position: sticky;
        top: 0;
        left: 0;

        box-shadow: rem(7px 7px 5px rgba(0, 0, 0, 0.1));

        z-index: 10;

        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            width: rem(173px);
            height: rem(143px);

            position: absolute;
            top: rem(30px);
            right: rem(50px);
        }

        .wrap {
            width: 75%;

            margin-left: rem(64px);

            .title {
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;
                font-size: rem(48px);
                margin-bottom: rem(30px);
            }
            form {
                select {
                    appearance: none;

                    margin-bottom: rem(25px);

                    width: rem(500px);

                    border: 1px solid #b6b7b7;

                    font-size: rem(24px);
                    color: #b6b7b7;

                    padding: rem(15px 25px);
                    box-sizing: border-box;
                    
                    border-radius: rem(15px);

                    background-image: url(../img/icon_select_arrow.svg);
                    background-position: 95% 50%;
                    background-size: rem(15px);
                    background-repeat: no-repeat;

                    option {
                        color: #b6b7b7;
                    }
                }
            }
            .items {
                position: relative;

                .line {
                    width: 1px;
                    height: calc(100% - 2.8rem);

                    background-color: #fff;

                    position: absolute;
                    top: 0;
                    left: rem(6px);
                    bottom: 0;
                    margin: auto;
                }
                .item {
                    font-family: 'PT Sans', sans-serif;
                    font-size: rem(24.5px);

                    margin-bottom: rem(25px);
                    padding-left: rem(30px);

                    position: relative;

                    cursor: pointer;

                    .dot {
                        width: rem(16px);
                        height: rem(16px);

                        background-color: #fff;
                        border-radius: 50%;

                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
    }

    .right {
        width: 55%;

        padding-top: rem(70px);

        box-sizing: border-box;

        position: relative;

        .screen {
            display: none;

            &>.title {
                color: #00aeef;
                font-size: rem(48px);
                font-family: 'PT Sans Caption', sans-serif;
                font-weight: 700;
                line-height: rem(57.5px);
                margin-bottom: rem(20px);
                margin-top: rem(0px);
                
                padding-left: rem(80px);
                padding-right: rem(80px);
            }

            &>p {
                color: #1d1d1b;
                font-size: rem(23px);
                font-family: 'PT Sans', sans-serif;
                margin: rem(10px 0 30px 0);
                
                padding-left: rem(80px);
                padding-right: rem(80px);
            }

            .title2 {
                color: #1d1d1b;
                font-size: rem(27px);
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;
                text-transform: uppercase;
                line-height: rem(32px);
                margin-bottom: rem(20px);
                margin-top: rem(20px);

                position: relative;

                span {
                    display: inline-block;

                    background-color: #fff;

                    position: relative;
                    z-index: 2;

                    padding-right: rem(10px);
                }

                &:after {
                    content: '';

                    width: 90%;
                    height: 1px;

                    background-color: #ccc;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;

                    z-index: 1;
                }
            }

            .contacts {
                padding-left: rem(80px);
                padding-right: rem(80px);

                .items {
                    width: 100%;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .separate {
                        color: #b5b6b6;
                        font-size: rem(24px);
                        font-family: 'PT Sans', sans-serif;
                        font-weight: 400;
                        line-height: rem(32px);
                        margin-bottom: rem(20px);
                        margin-top: rem(20px);
                        text-align: center;

                        width: 100%;

                        position: relative;

                        cursor: pointer;

                        display: none;

                        &.hide {
                            display: none;
                        }
                    }

                    .wrap {
                        width: 100%;

                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }

                    .item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        width: 47%;

                        margin-bottom: rem(30px);

                        .photo {
                            width: rem(120px);
                            height: rem(120px);

                            margin-right: rem(15px);

                            border-radius: 50%;
                            overflow: hidden;

                            background-color: #57575c;

                            img {
                                width: 100%;
                                height: 100%;

                                object-fit: cover;
                                object-position: 50% 50%;
                            }
                        }

                        .texts {
                            color: #1d1d1b;
                            width: calc(100% - 7.5rem - .9375rem);

                            .name {
                                font-size: rem(24px);
                                line-height: rem(29px);

                                font-weight: 700;
                            }
                            .position {
                                font-family: 'PT Sans', sans-serif;
                                font-size: rem(16px);
                                line-height: rem(19px);

                                margin-top: rem(10px);
                            }
                            .contact {
                                font-family: 'PT Sans', sans-serif;
                                font-size: rem(16px);
                                line-height: rem(19px);

                                margin-top: rem(10px);

                                a {
                                    margin-left: rem(10px);
                                    
                                    img {
                                        width: rem(21px);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .brands {
                padding-left: rem(80px);
                padding-right: rem(80px);

                .logos {
                    width: 95%;
        
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    .separate {
                        color: #00aeef;
                        font-size: rem(24px);
                        font-family: 'PT Sans', sans-serif;
                        font-weight: 700;
                        line-height: rem(32px);
                        margin-bottom: rem(20px);
                        margin-top: rem(20px);

                        width: 100%;

                        position: relative;

                        cursor: pointer;

                        &.hide {
                            display: none;
                        }

                        span {
                            display: inline-block;

                            background-color: #fff;

                            position: relative;
                            z-index: 2;

                            padding-right: rem(10px);
                        }

                        &:after {
                            content: '';

                            width: 100%;
                            height: 1px;

                            background-color: #1d1d1b;

                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            margin: auto;

                            z-index: 1;
                        }
                    }

                    .logos-hide {
                        width: 100%;
        
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: flex-start;

                        opacity: 0;
                        display: none;

                        transition: 1s;

                        &.active {
                            opacity: 1;
                            display: flex !important;
                        }
                    }
        
                    .item {
                        width: 20%;
                        height: rem(170px);
        
                        border: 1px solid #e1e1e1;
                        box-sizing: border-box;
        
                        position: relative;
        
                        &:hover {
                            border: 1px solid #00aeef;
                            box-shadow: 0 0 1.25rem rgba(0,105,241,0.2);
                        }
        
                        img {
                            width: 80%;
                            height: 80%;
        
                            object-fit: contain;
        
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            margin: auto;
                        }
                    }
                }
            }

            .news,
            .promos,
            .events {
                padding-left: rem(80px);
                padding-right: rem(80px);

                .slider {
                    width: 100%;
                    height: auto;
                    
                    overflow: visible;

                    .slick-next, 
                    .slick-prev {
                        width: rem(20px);
                        height: rem(20px);
                        
                        top: 30%;

                        z-index: 10;

                        &:before {
                            display: none;
                        }
                    }

                    .slick-disabled {
                        opacity: 0;
                    }

                    .slick-prev {
                        background: url(../img/arrow-left.svg) 50% 50% no-repeat;
                        background-size: contain;

                        left: rem(-45px);
                    }
 
                    .slick-next {
                        background: url(../img/arrow-right.svg) 50% 50% no-repeat;
                        background-size: contain;
                        
                        right: rem(0);
                    }

                    .slide {
                        height: auto;
                        margin-bottom: rem(10px);

                        a {
                            display: flex;
                            flex-direction: column;
    
                            text-decoration: none;
                            width: 87%;
    
                            img {
                                width: rem(250px);
                                height: rem(250px);
    
                                object-fit: cover;
                                object-position: 50% 50%;
                            }
    
                            .title {
                                font-size: rem(30px);
                                color: #00aeef;
                                line-height: rem(33px);
                                font-weight: 700;
                                margin-top: rem(15px);
                            }
    
                            .desk {
                                font-size: rem(16px);
                                color: #000000;
                                margin: 0;
    
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis; 

                                margin-top: rem(10px);
                            }
    
                            .date {
                                font-size: rem(18px);
                                color: #b7b7b7;
                                margin-top: rem(10px);
                            }
                        }
                    }
                }
            }

            .events {
                background-color: #f2f2f2;
                
                padding-left: rem(80px);
                padding-right: rem(80px);
                padding-bottom: rem(50px);
                padding-top: rem(20px);

                margin-top: rem(30px);

                .slider {
                    width: 100%;
                    height: auto;
                }

                .title2 {
                    span {
                        background-color: transparent;
                    }
    
                    &:after {
                        display: none;
                    }
                }
            }

            .shadow {
                background-color: rgba(124, 124, 124, 0.36);
                
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;

                z-index: 3;

                display: none;
            }

            .popup {
                background-color: #fff;

                width: rem(755px);
                height: rem(745px);

                position: fixed;
                top: 0;
                right: 7%;
                bottom: 0;
                margin: auto;

                border-radius: rem(20px);

                padding: rem(30px);
                box-sizing: border-box;

                z-index: 4;

                display: none;


                .title {
                    color: #00aeef;
                    font-family: 'PT Sans Caption', sans-serif;
                    font-weight: 700;
                    font-size: rem(48px);
                }

                .title2 {
                    color: #b6b7b7;
                    font-family: 'PT Sans', sans-serif;
                    font-weight: 700;
                    font-size: rem(35px);
                    text-transform: none;

                    line-height: initial;
                    margin-bottom: initial;
                    margin-top: initial;
                    position: relative;

                    &:after {
                        display: none;
                    }
                }

                .form {
                    padding-bottom: rem(50px);
    
                    form {
                        .inputs {
                            display: flex;
                            flex-direction: column;
    
                            margin-top: rem(30px);
                            
                            input {
                                width: 100%;
                                height: rem(58px);
    
                                border: 1px solid #b6b7b7;
                                border-radius: rem(10px);
    
                                box-shadow: none;
    
                                color: #1d1d1b;
                                font-family: 'PT Sans', sans-serif;
                                font-weight: 400;
                                font-size: rem(24px);
    
                                padding: rem(0 20px);
                                box-sizing: border-box;
    
                                margin-bottom: rem(20px);
                            }

                            textarea {
                                width: 100%;
    
                                border: 1px solid #b6b7b7;
                                border-radius: rem(10px);
    
                                box-shadow: none;
    
                                color: #1d1d1b;
                                font-family: 'PT Sans', sans-serif;
                                font-weight: 400;
                                font-size: rem(24px);
    
                                padding: rem(10px 20px 0 20px);
                                box-sizing: border-box;
    
                                margin-bottom: rem(20px);
                            }
                        }
    
                        label {
                            color: #1d1d1b;
                            font-family: 'PT Sans', sans-serif;
                            font-weight: 400;
                            font-size: rem(19px);
    
                            display: flex;
                            flex-direction: row;
    
                            input {
                                width: rem(44px);
                                height: rem(27px);
                                
                                border: 1px solid #b6b7b7;
                                border-radius: rem(10px);
    
                                box-shadow: none;
                            }
    
                            p {
                                margin: 0;
                                margin-left: rem(10px);
    
                                a {
                                    color: #1d1d1b;
                                }
                            }
                        }
    
                        .bottom {
                            background-color: transparent;
                            margin-top: rem(40px);
    
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
    
                            input[type="submit"] {
                                background-color: #00aeef;
                                color: #fff;
                                font-family: 'PT Sans Caption', sans-serif;
                                font-weight: 700;
                                font-size: rem(25px);
        
                                width: rem(273px);
                                height: rem(58px);
        
                                display: flex;
                                align-items: center;
                                justify-content: center;
        
                                border-radius: rem(10px);
        
        
                                box-shadow: none;
                                border: none;
        
                                cursor: pointer;
                            }
    
                            .captcha {
                                width: rem(338px);
                                
                                img {
                                    width: 100%;
                                }
                            }
                        }
    
                        
                    }
                }
            }
        }
    }
}

.brand-main {
    display: flex;

    .left {
        background-color: #00aeef;
        width: 45%;
        height: 100vh;

        position: sticky;
        top: 0;
        left: 0;

        box-shadow: rem(7px 7px 5px rgba(0, 0, 0, 0.1));

        z-index: 10;

        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            width: rem(173px);
            height: rem(143px);

            position: absolute;
            top: rem(30px);
            right: rem(50px);
        }

        .wrap {
            width: 76%;

            padding-bottom: rem(60px);
            margin-top: rem(50px);
            margin-left: rem(64px);

            max-height: calc(60% - 3.125rem);

            &:after {
                content: '';

                background: rgba(0, 175, 239, 0);
                background: linear-gradient(
                    0deg
                    ,#00aeef 0,#00aeef 73%,rgba(246,246,246,0) 100%);
                width: 100%;
                height: 14%;

                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;

                pointer-events: none;

                z-index: 10;
            }

            .title {
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;
                font-size: rem(40px);
                margin-bottom: rem(30px);
            }

            form {
                select {
                    appearance: none;

                    margin-bottom: rem(25px);

                    width: rem(500px);

                    border: 1px solid #b6b7b7;

                    font-size: rem(24px);
                    color: #b6b7b7;

                    padding: rem(15px 25px);
                    box-sizing: border-box;
                    
                    border-radius: rem(15px);

                    background-image: url(../img/icon_select_arrow.svg);
                    background-position: 95% 50%;
                    background-size: rem(15px);
                    background-repeat: no-repeat;

                    option {
                        color: #b6b7b7;
                    }
                }
            }

            .items {
                position: relative;

                .line {
                    width: 1px;
                    height: calc(100% - 2rem);

                    background-color: #fff;

                    position: absolute;
                    top: 0;
                    left: rem(7px);
                    bottom: 0;
                    margin: auto;
                }

                .item {
                    font-family: 'PT Sans', sans-serif;
                    font-size: rem(24px);

                    margin-bottom: rem(15px);
                    padding-left: rem(30px);

                    position: relative;

                    cursor: pointer;

                    p {
                        margin: 0;
                        display: inline-block;
                    }

                    span {
                        display: inline-block;
                        font-size: rem(20px);
        
                        transition: 0.2s;

                        &.active {
                            transform: rotate(-90deg);
                        }
                    }

                    .dot {
                        width: rem(16px);
                        height: rem(16px);

                        background-color: #fff;
                        border-radius: 50%;

                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                    }
        
                    &.active {
                        .dot {
                            &:before {
                                content: '';

                                width: rem(6px);
                                height: rem(6px);

                                background-color: #00aeef;

                                border-radius: 50%;

                                position: absolute;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                margin: auto;
                            }
                        }
                    }
                }

                .toggle-list-2 {
                    margin-left: rem(35px);
                    position: relative;

                    display: none;

                    .line {
                        width: 1px;
                        height: calc(100% - 1.375rem);
    
                        background-color: #fff;
    
                        position: absolute;
                        top: 0;
                        left: rem(5px);
                        bottom: 0;
                        margin: auto;
                    }

                    .item {
                        margin-bottom: rem(10px);

                        .dot {
                            width: rem(11px);
                            height: rem(11px);
                        }

                        &.active {
                            .dot {
                                &:before {
                                    width: rem(5px);
                                    height: rem(5px);
                                }
                            }
                        }
                    }
                }

                .toggle-list-3 {
                    margin-left: rem(35px);

                    display: none;

                    .item {
                        padding-left: rem(20px);
                    }
                }
            }
        }
    }

    .right {
        width: 55%;

        padding-top: rem(70px);

        box-sizing: border-box;

        position: relative;

        .screen {
            display: none;

            .logo-brand {
                width: rem(250px);
                height: rem(160px);
                
                padding-left: rem(80px);
                padding-right: rem(80px);

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: 0% 0%;
                }
            }

            &>.title {
                color: #00aeef;
                font-size: rem(44px);
                font-family: 'PT Sans Caption', sans-serif;
                font-weight: 700;
                line-height: rem(52.5px);
                margin-bottom: rem(20px);
                margin-top: rem(0px);
                
                padding-left: rem(80px);
                padding-right: rem(80px);
            }

            .title2 {
                color: #1d1d1b;
                font-size: rem(27px);
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;
                text-transform: uppercase;
                line-height: rem(32px);
                margin-bottom: rem(20px);
                margin-top: rem(20px);

                position: relative;

                span {
                    display: inline-block;

                    background-color: #fff;

                    position: relative;
                    z-index: 2;

                    padding-right: rem(10px);
                }

                &:after {
                    content: '';

                    width: 90%;
                    height: 1px;

                    background-color: #ccc;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;

                    z-index: 1;
                }
            }

            .video {
                padding-left: rem(80px);
                padding-right: rem(80px);

                iframe {
                    width: rem(644px);
                    height: rem(352px);
                }
            }

            .title_sm {
                color: #1d1d1b;
                font-size: rem(27px);
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;
                text-transform: uppercase;
                line-height: rem(32px);
                margin-bottom: rem(20px);
                margin-top: rem(20px);
                
                padding-left: rem(80px);
                padding-right: rem(80px);
            }

            &>p {
                color: #1d1d1b;
                font-size: rem(22px);
                line-height: rem(26px);
                font-family: 'PT Sans', sans-serif;
                margin: rem(10px 0 30px 0);
                
                padding-left: rem(80px);
                padding-right: rem(80px);

                a {
                    color: #000;
                    font-weight: 700;
                    text-decoration: none;
                }
            }

            ul {
                color: #1d1d1b;
                font-size: rem(22px);
                line-height: rem(26px);
                font-family: 'PT Sans', sans-serif;
                margin: rem(10px 0 30px 0);
                
                padding-left: rem(80px);
                padding-right: rem(80px);

                li {
                    list-style:none;
                    position: relative;
                    margin: rem(15px 0);

                    a {
                        color: #1d1d1b;
                    }

                    &:before { 
                        content: '';
                        background-color: #00aeef;

                        display: block;

                        height: rem(6px);
                        width: rem(6px);

                        border-radius: 50%;

                        position: absolute;
                        top: 0;
                        left: rem(-20px);
                        bottom: 0;
                        margin: auto;
                    }
                }
            }

            ol {
                color: #1d1d1b;
                font-size: rem(22px);
                line-height: rem(26px);
                font-family: 'PT Sans', sans-serif;
                margin: rem(10px 0 30px 0);
                
                padding-left: rem(100px);
                padding-right: rem(80px);

                li {
                    position: relative;
                    margin: rem(15px 0);

                    a {
                        color: #1d1d1b;
                    }
                }
            }

            .cols {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                padding-left: rem(80px);
                padding-right: rem(80px);

                ul {
                    padding-left: 0;
                    padding-right: 0;
                }

                .col-2 {
                    width: 47%;
                }
            }

            .pic {
                max-width: 100%;
                
                padding-left: rem(80px);
                padding-right: rem(80px);

                img {
                    max-width: 100%;
                }
            }

            .contacts {
                padding-left: rem(80px);
                padding-right: rem(80px);

                .items {
                    width: 100%;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        width: 47%;

                        margin-bottom: rem(30px);

                        .photo {
                            width: rem(120px);
                            height: rem(120px);

                            margin-right: rem(15px);

                            border-radius: 50%;
                            overflow: hidden;

                            background-color: #57575c;

                            img {
                                width: 100%;
                                height: 100%;

                                object-fit: cover;
                                object-position: 50% 50%;
                            }
                        }

                        .texts {
                            color: #1d1d1b;
                            width: calc(100% - 7.5rem - .9375rem);

                            .name {
                                font-size: rem(24px);
                                line-height: rem(29px);

                                font-weight: 700;
                            }
                            .position {
                                font-family: 'PT Sans', sans-serif;
                                font-size: rem(16px);
                                line-height: rem(19px);

                                margin-top: rem(10px);
                            }
                            .contact {
                                font-family: 'PT Sans', sans-serif;
                                font-size: rem(16px);
                                line-height: rem(19px);

                                margin-top: rem(10px);

                                a {
                                    margin-left: rem(10px);
                                    
                                    img {
                                        width: rem(21px);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .brands {
                padding-left: rem(80px);
                padding-right: rem(80px);

                .logos {
                    width: 95%;
        
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    .separate {
                        color: #00aeef;
                        font-size: rem(24px);
                        font-family: 'PT Sans', sans-serif;
                        font-weight: 700;
                        line-height: rem(32px);
                        margin-bottom: rem(20px);
                        margin-top: rem(20px);

                        width: 100%;

                        position: relative;

                        cursor: pointer;

                        &.hide {
                            display: none;
                        }

                        span {
                            display: inline-block;

                            background-color: #fff;

                            position: relative;
                            z-index: 2;

                            padding-right: rem(10px);
                        }

                        &:after {
                            content: '';

                            width: 100%;
                            height: 1px;

                            background-color: #1d1d1b;

                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            margin: auto;

                            z-index: 1;
                        }
                    }

                    .logos-hide {
                        width: 100%;
        
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: flex-start;

                        opacity: 0;
                        display: none;

                        transition: 1s;

                        &.active {
                            opacity: 1;
                            display: flex !important;
                        }
                    }
        
                    .item {
                        width: 20%;
                        height: rem(170px);
        
                        border: 1px solid #e1e1e1;
                        box-sizing: border-box;
        
                        position: relative;
        
                        &:hover {
                            border: 1px solid #00aeef;
                            box-shadow: 0 0 1.25rem rgba(0,105,241,0.2);
                        }
        
                        img {
                            width: 80%;
                            height: 80%;
        
                            object-fit: contain;
        
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            margin: auto;
                        }
                    }
                }
            }

            .news,
            .promos,
            .events {
                padding-left: rem(80px);
                padding-right: rem(80px);

                .slider {
                    width: 100%;
                    height: auto;
                    
                    overflow: visible;

                    .slick-next, 
                    .slick-prev {
                        width: rem(20px);
                        height: rem(20px);
                        
                        top: 30%;

                        z-index: 10;

                        &:before {
                            display: none;
                        }
                    }

                    .slick-disabled {
                        opacity: 0;
                    }

                    .slick-prev {
                        background: url(../img/arrow-left.svg) 50% 50% no-repeat;
                        background-size: contain;

                        left: rem(-45px);
                    }
 
                    .slick-next {
                        background: url(../img/arrow-right.svg) 50% 50% no-repeat;
                        background-size: contain;
                        
                        right: rem(0);
                    }

                    .slide {
                        height: auto;
                        margin-bottom: rem(10px);

                        a {
                            display: flex;
                            flex-direction: column;
    
                            text-decoration: none;
                            width: 87%;
    
                            img {
                                width: rem(250px);
                                height: rem(250px);
    
                                object-fit: cover;
                                object-position: 50% 50%;
                            }
    
                            .title {
                                font-size: rem(30px);
                                color: #00aeef;
                                line-height: rem(33px);
                                font-weight: 700;
                                margin-top: rem(15px);
                            }
    
                            .desk {
                                font-size: rem(16px);
                                color: #000000;
                                margin: 0;
    
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis; 

                                margin-top: rem(10px);
                            }
    
                            .date {
                                font-size: rem(18px);
                                color: #b7b7b7;
                                margin-top: rem(10px);
                            }
                        }
                    }
                }
            }

            .events {
                background-color: #f2f2f2;
                
                padding-left: rem(80px);
                padding-right: rem(80px);
                padding-bottom: rem(50px);
                padding-top: rem(20px);

                margin-top: rem(30px);

                .slider {
                    width: 100%;
                    height: auto;
                }

                .title2 {
                    span {
                        background-color: transparent;
                    }
    
                    &:after {
                        display: none;
                    }
                }
            }

            .brand-shop {
                padding-left: rem(80px);
                padding-right: rem(80px);

                margin-top: rem(50px);

                .item-list {
                    width: 100%;

                    display: flex;
                    flex-direction: column;

                    .item {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;

                        margin-bottom: rem(30px);

                        .photo {
                            width: rem(350px);
                            height: rem(200px);

                            img {
                                width: 100%;
                                height: 100%;

                                object-fit: contain;
                            }
                        }
    
                        .info {
                            width: 58%;
                            height: rem(200px);
                            
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            margin-left: rem(20px);
    
                            .title {
                                color: #000000;
                                font-size: rem(24px);
                            }

                            .btn {
                                color: #00aeef;
                                font-size: rem(20px);

                                width: rem(248px);
                                height: rem(52px);

                                display: flex;
                                justify-content: center;
                                align-items: center;

                                position: relative;

                                border: 1px solid #00aeef;
                                box-sizing: border-box;

                                padding-left: rem(52px);

                                cursor: pointer;

                                will-change: filter;

                                transition: 0.2s;

                                &:hover {
                                    filter: grayscale(1);
                                }

                                &:before {
                                    content: '';
                                    background: url(../img/shop/cart.svg) 50% 50% no-repeat;
                                    background-size: rem(30px);
                                    background-color: #00aeef;

                                    width: rem(52px);
                                    height: rem(52px);

                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    bottom: 0;
                                    margin: auto;
                                }
                            }
                        }
                    }
                }
            }

            .shadow {
                background-color: rgba(124, 124, 124, 0.36);
                
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;

                z-index: 3;

                display: none;
            }

            .popup {
                background-color: #fff;

                width: rem(755px);
                height: rem(745px);

                position: fixed;
                top: 0;
                right: 7%;
                bottom: 0;
                margin: auto;

                border-radius: rem(20px);

                padding: rem(30px);
                box-sizing: border-box;

                z-index: 4;

                display: none;


                .title {
                    color: #00aeef;
                    font-family: 'PT Sans Caption', sans-serif;
                    font-weight: 700;
                    font-size: rem(48px);
                }

                .title2 {
                    color: #b6b7b7;
                    font-family: 'PT Sans', sans-serif;
                    font-weight: 700;
                    font-size: rem(35px);
                    text-transform: none;

                    line-height: initial;
                    margin-bottom: initial;
                    margin-top: initial;
                    position: relative;

                    &:after {
                        display: none;
                    }
                }

                .form {
                    margin-top: rem(20px);
                    padding-bottom: rem(50px);
    
                    form {
                        .cart-list {
                            margin-top: rem(20px);
        
                            .name {
                                color: #000;
                                font-size: rem(24px);
                            }

                            .count {
                                margin-top: rem(20px);
                                height: rem(58px);

                                display: flex;
    
                                .value {
                                    width: auto;
                                    height: rem(58px);
    
                                    background-color: #00aeef;
        
                                    border-radius: rem(10px);
        
                                    color: #fff;
                                    font-family: 'PT Sans', sans-serif;
                                    font-weight: 400;
                                    font-size: rem(36px);
        
                                    padding: rem(0 20px);
                                    box-sizing: border-box;

                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                }

                                .arrows {
                                    display: inline-flex;
                                    flex-direction: column;
                                    justify-content: space-around;
                                    align-items: center;

                                    height: rem(58px);

                                    margin-left: rem(15px);

                                    .arrow_top {
                                        /* background: url(../img/shop/arrow_top.svg) 50% 50% no-repeat;
                                        background-size: contain; */

                                        width: rem(21px);
                                        height: rem(13px);

                                        cursor: pointer;

                                        &:hover {
                                            .cls-1 {
                                                stroke: #00aeef;
                                            }
                                        }
                                    }

                                    .arrow_bottom {
                                        /* background: url(../img/shop/arrow_bottom.svg) 50% 50% no-repeat;
                                        background-size: contain; */

                                        width: rem(21px);
                                        height: rem(13px);

                                        cursor: pointer;

                                        &:hover {
                                            .cls-1 {
                                                stroke: #00aeef;
                                            }
                                        }
                                    }
                                }
                            }

                        }

                        .inputs {
                            display: flex;
                            flex-direction: column;
    
                            margin-top: rem(20px);
                            
                            input {
                                width: 100%;
                                height: rem(58px);
    
                                border: 1px solid #b6b7b7;
                                border-radius: rem(10px);
    
                                box-shadow: none;
    
                                color: #1d1d1b;
                                font-family: 'PT Sans', sans-serif;
                                font-weight: 400;
                                font-size: rem(24px);
    
                                padding: rem(0 20px);
                                box-sizing: border-box;
    
                                margin-bottom: rem(20px);
                            }

                            textarea {
                                width: 100%;
    
                                border: 1px solid #b6b7b7;
                                border-radius: rem(10px);
    
                                box-shadow: none;
    
                                color: #1d1d1b;
                                font-family: 'PT Sans', sans-serif;
                                font-weight: 400;
                                font-size: rem(24px);
    
                                padding: rem(10px 20px 0 20px);
                                box-sizing: border-box;
    
                                margin-bottom: rem(20px);
                            }
                        }
    
                        label {
                            color: #1d1d1b;
                            font-family: 'PT Sans', sans-serif;
                            font-weight: 400;
                            font-size: rem(19px);
    
                            display: flex;
                            flex-direction: row;
    
                            input {
                                width: rem(44px);
                                height: rem(27px);
                                
                                border: 1px solid #b6b7b7;
                                border-radius: rem(10px);
    
                                box-shadow: none;
                            }
    
                            p {
                                margin: 0;
                                margin-left: rem(10px);
    
                                a {
                                    color: #1d1d1b;
                                }
                            }
                        }
    
                        .bottom {
                            background-color: transparent;
                            margin-top: rem(40px);
    
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
    
                            input[type="submit"] {
                                background-color: #00aeef;
                                color: #fff;
                                font-family: 'PT Sans Caption', sans-serif;
                                font-weight: 700;
                                font-size: rem(25px);
        
                                width: rem(273px);
                                height: rem(58px);
        
                                display: flex;
                                align-items: center;
                                justify-content: center;
        
                                border-radius: rem(10px);
        
        
                                box-shadow: none;
                                border: none;
        
                                cursor: pointer;
                            }
    
                            .captcha {
                                width: rem(338px);
                                
                                img {
                                    width: 100%;
                                }
                            }
                        }
    
                        
                    }
                }
            }

            .popup-offer {
                height: rem(780px);
            }
        }
    }
}

.error {
    background: url(../img/bg-error.jpg) 50% 50% no-repeat;
    background-size: cover;

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    overflow: hidden;

    .wrap {
        background-color: #fff;

        width: 70vw;
        height: 70vw;

        border-radius: 50%;

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        img {
            width: 71%;
            height: 36%;

            position: absolute;
            top: -15%;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
        }

        p {
            font-size: rem(35px);
            font-family: 'PT Sans Caption', sans-serif;
            font-weight: 700;
            color: #000;
            text-align: center;

            width: 100%;
            height: 6%;

            position: absolute;
            top: 42%;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;

            a {
                color: #000;
            }
        }
    }
}

.condition-main {
    display: flex;

    .logo {
        width: rem(198px);
        height: rem(164px);

        position: absolute;
        top: rem(30px);
        right: rem(100px);
    }

    .left {
        background-color: #00aeef;
        width: rem(600px);
        height: 100vh;

        position: sticky;
        top: 0;
        left: 0;

        box-shadow: rem(7px 7px 5px rgba(0, 0, 0, 0.1));

        z-index: 10;

        box-sizing: border-box;

        .wrap {
            width: rem(370px);
            height: 34%;

            position: absolute;
            top: 0;
            left: rem(64px);
            bottom: 0;
            right: 0;
            margin: auto;

            .title {
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;
                font-size: rem(48px);
                margin-bottom: rem(30px);
            }
            .items {
                position: relative;

                .line {
                    width: 1px;
                    height: calc(100% - 4.5rem);

                    background-color: #fff;

                    position: absolute;
                    top: 0;
                    left: rem(7px);
                    bottom: 0;
                    margin: auto;
                }
                .item {
                    font-family: 'PT Sans', sans-serif;
                    font-size: rem(24.5px);

                    margin-bottom: rem(25px);
                    padding-left: rem(30px);

                    position: relative;

                    cursor: pointer;

                    .dot {
                        width: rem(16px);
                        height: rem(16px);

                        background-color: #fff;
                        border-radius: 50%;

                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
    }

    .right {
        width: calc(100% - 37.5rem);

        padding-top: rem(90px);
        padding-left: rem(80px);
        padding-right: rem(80px);

        box-sizing: border-box;

        position: relative;

        .screen {
            display: none;

            .title {
                color: #00aeef;
                font-size: rem(48px);
                font-family: 'PT Sans Caption', sans-serif;
                font-weight: 700;
                line-height: rem(57.5px);
                margin-bottom: rem(60px);
            }

            .title2 {
                color: #000;
                font-size: rem(29px);
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;
                line-height: rem(34px);
                margin-top: rem(25px);
                margin-bottom: rem(25px);
            }

            &>p {
                color: #000;
                font-size: rem(25px);
                line-height: rem(30px);
                font-family: 'PT Sans', sans-serif;
                margin: rem(20px 0);

                span {
                    font-weight: 700;
                }
            }
            
            ul {
                padding-left: 0;
                list-style: none;

                li {
                    color: #000;
                    font-size: rem(25px);
                    line-height: rem(30px);
                    font-family: 'PT Sans', sans-serif;
                }
            }
        }
    }
}

.innovationlab-main {
    display: flex;

    .left {
        background-color: #00aeef;
        width: 45%;
        height: 100vh;

        position: sticky;
        top: 0;
        left: 0;

        box-shadow: rem(7px 7px 5px rgba(0, 0, 0, 0.1));

        z-index: 10;

        box-sizing: border-box;

        display: flex;
        align-items: flex-start;
        justify-content: center;

        .logo {
            width: rem(150px);
            height: rem(115px);

            position: absolute;
            top: rem(55px);
            right: rem(80px);
        }

        .wrap {
            width: 75%;

            margin-left: rem(64px);
            margin-top: rem(200px);

            .title {
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;
                font-size: rem(40px);
                margin-bottom: rem(30px);
            }
            form {
                select {
                    appearance: none;

                    margin-bottom: rem(25px);

                    width: rem(500px);

                    border: 1px solid #b6b7b7;

                    font-size: rem(24px);
                    color: #b6b7b7;

                    padding: rem(15px 25px);
                    box-sizing: border-box;
                    
                    border-radius: rem(15px);

                    background-image: url(../img/icon_select_arrow.svg);
                    background-position: 95% 50%;
                    background-size: rem(15px);
                    background-repeat: no-repeat;

                    option {
                        color: #b6b7b7;
                    }
                }
            }

            .calendar-wrap {
                width: 80%;
                height: rem(520px);
    
                position: relative;
    
                .shadow {
                    width: 96%;
                    height: 100%;
    
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
    
                    background-color: rgba(124, 124, 124, 0.37);
    
                    display: none;
                }
    
                .calendar {
                    width: rem(500px);
                    height: rem(505px);
    
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    margin: auto;
    
                    .calendar-nav {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        margin: auto;
    
                        width: 98%;
                        height: rem(35px);
    
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
    
                        color: #00aeef;
    
                        .calendar-nav-btn {
                            cursor: pointer;

                            filter: brightness(0) invert(1);
    
                            transition: 0.2s;
    
                            &:hover {
                                color: #b7b7b7;

                                filter: none;
                            }
    
                            &.disabled {
                                color: #b7b7b7;
                                pointer-events: none;
                                cursor: default;
                            }
                        }
                    }
    
                    .daynames {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-around;
    
                        font-size: rem(20px);
                        color: #ffffff;
                        
                        width: 99%;
                    }
    
                    .days {
                        margin-top: rem(15px);
                        position: absolute;

                        background-color: #fff;
    
                        transition: opacity 0.3s, visibility 0.3s 0s;
    
                        .day {
                            width: rem(70px);
                            height: rem(70px);
        
                            box-sizing: border-box;
    
                            position: relative;
    
                            &.active {
                                border-color: #231f20;
                            }
    
                            span {
                                color: #000;
                                font-size: rem(24px);
    
                                position: absolute;
                                top: rem(10px);
                                right: rem(10px);
                            }
    
                            .list {
                                position: absolute;
                                right: rem(-390px);
                                top: 0;
    
                                width: rem(380px);
    
                                color: #00aeef;
    
                                border-radius: rem(15px);
                                padding: rem(15px 0);
                                box-sizing: border-box;
    
                                background-color: #fff;
                                box-shadow: rem(7px 7px 4px rgba(0, 0, 0, 0.15));
    
                                z-index: 1;
    
                                opacity: 0;
                                visibility: hidden;
    
                                transition: opacity 0.3s, visibility 0s;
    
                                .item {
                                    font-size: rem(24px);
                                    font-weight: 700;
                                    padding: rem(15px 40px);
    
                                    span {
                                        font-size: rem(20px);
                                        color: #b7b7b7;
                                        display: block;
                                        position: relative;
                                        top: 0;
                                        right: 0;
                                    }
    
                                    &:hover {
                                        background-color: #00aeef;
                                        color: #fff;
                                        
                                        span {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
    
                            &:nth-child(7) {
                                .list {
                                    right: rem(130px);
                                }
                            }
    
                            &:nth-child(6) {
                                .list {
                                    right: rem(130px);
                                }
                            }
    
                            &:nth-child(5) {
                                .list {
                                    right: rem(130px);

                                    width: rem(275px);
                                }
                            }
    
                            &.hasEvent {
                                background-color: #00aeef;
                                cursor: pointer;
    
                                span {
                                    color: #fff;
                                }
    
                                .list {
                                    &.active {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                    }
    
                    .mounth-name {
                        margin-top: rem(15px);
                        text-transform: uppercase;
                        font-size: rem(25px);
                        color: #fff;
                        text-align: center;
    
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }

    }

    .right {
        width: 55%;

        padding-top: rem(90px);
        padding-bottom: rem(90px);
        padding-left: rem(80px);
        padding-right: rem(80px);

        box-sizing: border-box;

        position: relative;

        .logo {
            width: rem(150px);
            height: rem(130px);
        }

        p {
            color: #000;
            font-size: rem(20px);
        }

        .news {
            .title2 {
                color: #1d1d1b;
                font-size: rem(27px);
                font-family: 'PT Sans', sans-serif;
                font-weight: 700;
                text-transform: uppercase;
                line-height: rem(32px);
                margin-bottom: rem(20px);
                margin-top: rem(20px);

                position: relative;

                span {
                    display: inline-block;

                    background-color: #fff;

                    position: relative;
                    z-index: 2;

                    padding-right: rem(10px);
                }

                &:after {
                    content: '';

                    width: 90%;
                    height: 1px;

                    background-color: #ccc;

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;

                    z-index: 1;
                }
            }

            .slider {
                width: 100%;
                height: auto;
                
                overflow: visible;

                .slick-next, 
                .slick-prev {
                    width: rem(20px);
                    height: rem(20px);
                    
                    top: 30%;

                    z-index: 10;

                    &:before {
                        display: none;
                    }
                }

                .slick-disabled {
                    opacity: 0;
                }

                .slick-prev {
                    background: url(../img/arrow-left.svg) 50% 50% no-repeat;
                    background-size: contain;

                    left: rem(-45px);
                }

                .slick-next {
                    background: url(../img/arrow-right.svg) 50% 50% no-repeat;
                    background-size: contain;
                    
                    right: rem(0);
                }

                .slide {
                    height: auto;
                    margin-bottom: rem(10px);

                    a {
                        display: flex;
                        flex-direction: column;

                        text-decoration: none;
                        width: 87%;

                        img {
                            width: rem(250px);
                            height: rem(250px);

                            object-fit: cover;
                            object-position: 50% 50%;
                        }

                        .title {
                            font-size: rem(30px);
                            color: #00aeef;
                            line-height: rem(33px);
                            font-weight: 700;
                            margin-top: rem(15px);
                        }

                        .desk {
                            font-size: rem(16px);
                            color: #000000;
                            margin: 0;

                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; 

                            margin-top: rem(10px);
                        }

                        .date {
                            font-size: rem(18px);
                            color: #b7b7b7;
                            margin-top: rem(10px);
                        }
                    }
                }
            }
        }
    }
}









/* - cisco-landing - */

.cisco-landing {
    overflow: hidden;

    .hero {
        background: url(../img/cisco-landing/bg.png) 50% 50% no-repeat;
        background-size: cover;

        position: relative;

        .f1 {
            width: rem(1300px);
            height: rem(1919px);
    
            position: absolute;
            top: rem(0);
            left: rem(0);
        }

        .container {
            .man {
                background: url(../img/cisco-landing/man.png) 50% 50% no-repeat;
                background-size: contain;

                width: rem(680px);
                height: rem(705px);

                position: absolute;
                top: 2%;
                bottom: 0;
                right: 7%;
                margin: auto;
            }

            .header {
                width: 100%;
                height: rem(162px);

                position: absolute;
                top: rem(60px);
                left: 0;

                padding-left: rem(185px);
                padding-right: rem(120px);
                box-sizing: border-box;

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .logos {
                    width: rem(424px);
                    height: 100%;
                }

                .menu {
                    font-family: 'PT Sans Caption', sans-serif;
                    font-size: rem(25px);
                    width: rem(650px);

                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    a {
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }

            .texts {
                width: 72%;
                height: 59vh;

                top: 20%;

                .title {
                    color: #000;
                    font-weight: 700;
                }
                .desc {
                    font-family: 'PT Sans Caption', sans-serif;
                    font-size: rem(30px);
                    line-height: rem(36px);
                }
                .btn {
                    font-family: 'PT Sans Caption', sans-serif;
                    font-size: rem(25px);
                    color: #00aeef;
                    background-color: #fff;
                    border-radius: rem(35px);

                    width: rem(450px);
                    height: rem(86px);

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    cursor: pointer;
                }
            }
        }
    }

    .advantages {
        width: calc(100% - 4rem);
        margin-left: auto;
        
        .wrap {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            margin-top: rem(30px);

            .item {
                width: 25%;
                height: rem(405px);

                font-family: 'PT Sans Caption', sans-serif;

                padding-top: rem(50px);
                padding-left: rem(80px);

                box-sizing: border-box;

                position: relative;

                background-color: #fff;

                &.blue {
                    background-color: #00aeef;
                }

                .line {
                    width: rem(2px);
                    height: 50%;

                    position: absolute;
                    left: 10%;

                    &:before {
                        content: '';

                        width: rem(16px);
                        height: rem(16px);

                        border-radius: 50%;

                        position: absolute;
                        left: rem(-7px);
                    }

                    &.blue {
                        background-color: #00aeef;

                        &:before {
                            background-color: #00aeef;
                        }
                    }

                    &.white {
                        background-color: #fff;
                        
                        &:before {
                            background-color: #fff;
                        }
                    }

                    &.top {
                        top: 0;

                        &:before {
                            bottom: 0;
                        }
                    }

                    &.bottom {
                        bottom: 0;
                        
                        &:before {
                            top: 0;
                        }
                    }
                }

                .title {
                    font-size: rem(30px);
                    font-weight: 700;
                    color: #000;
                }

                .desc {
                    font-size: rem(21px);
                    color: #565656;

                    margin-top: rem(50px);

                    a {
                        color: #00aeef;

                        margin-top: rem(15px);

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        width: rem(200px);
                        height: rem(60px);
    
                        border: 2px solid #00aeef;
                        box-sizing: border-box;
                        border-radius: rem(35px);

                        text-decoration: none;
                    }
                }

                .pic {
                    max-width: rem(220px);
                    max-height: rem(160px);

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;

                    img {
                        width: 100%;
                        height: 100%;

                        object-fit: contain;
                    }
                }
                
            }
        }
    }

    .smartnet {
        margin-top: rem(30px);
        margin-bottom: rem(30px);

        .container {
            position: relative;
            height: 100vh;

            .scheme {
                width: rem(944px);
                height: 90vh;

                position: absolute;
                top: 0;
                left: rem(180px);
                bottom: 0;
                margin: auto;

                img {
                    width: 100%;
                    height: 100%;

                    object-fit: contain;
                    object-position: 0 50%;
                }
            }

            .slider-smart-net {
                background: url(../img/cisco-landing/bg-slider-smart.svg) 0% 50% no-repeat;
                background-size: cover;
    
                width: rem(780px);
                height: 100vh;
    
                position: absolute;
                top: 0;
                right: 0;
    
                &:before {
                    background: url(../img/arrow.svg) 0% 50% no-repeat;
                    background-size: contain;
    
                    width: 20%;
                    height: 83%;
    
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 10%;
                    bottom: 0;
                    margin: auto;
                }
    
                .slider-nav {
                    visibility: hidden;
                }
    
                .carousel_wrap {
                    width: 70%;
                    height: 85vh;
    
                    position: absolute;
                    top: 0;
                    left: 10%;
                    bottom: 0;
                    right: 0;
                    margin: auto;
    
                    .slick-list {
                        padding: rem(50px 0) !important;
                    }
    
                    .slick-track {
                        top: 35vh;
                    }
    
                    .item {
                        width: 100%;
                        height: 35vh;
    
                        margin: rem(-50px 0 100px);
    
                        cursor: pointer;
    
                        transform: scale(0.7) translateY(0);
                        transition: 0.4s;
    
                        outline: none;
    
                        .title {
                            font-size: rem(48px);
                            font-weight: 700;
                        }
    
                        .desc {
                            font-family: 'PT Sans Caption', sans-serif;
                            font-size: rem(24px);
                            margin-top: rem(40px);
                            color: #000;
    
                            opacity: 0;
    
                            transition: 0.5s;
                        }
    
                        .btn {
                            font-family: 'Open Sans', sans-serif;
                            color: #fff;
                            background-color: #00aeef;
                            text-decoration: none;
                            font-size: rem(25px);
    
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
    
                            width: rem(277px);
                            height: rem(86px);
    
                            border: 2px solid #fff;
                            box-sizing: border-box;
                            border-radius: rem(35px);
    
                            outline: none;
    
                            margin-top: rem(45px);
    
                            opacity: 0;
                            visibility: hidden;
    
                            transition: opacity 0.5s, background-color 0.3s, color 0.3s;
    
                            &:hover {
                                background-color: #fff;
                                color: #00aeef;
                            }
                        }
    
                        &.slick-center {
                            transform: scale(1) translateY(-43%);
    
                            .desc {
                                opacity: 1;
                            }
    
                            .btn {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }
    }

    .promo {
        background: url(../img/cisco-landing/bg2.png) 50% 50% no-repeat;
        background-size: cover;

        .container {
            padding-top: rem(90px);
            padding-left: rem(185px);
            padding-bottom: rem(60px);

            box-sizing: border-box;

            .title {
                font-family: 'PT Sans Caption', sans-serif;
                font-size: rem(60px);
                line-height: rem(72px);
                font-weight: 700;
            }

            .desc {
                font-family: 'PT Sans', sans-serif;
                font-size: rem(30px);
                margin-top: rem(60px);
            }

            .btns {
                margin-top: rem(60px);

                width: 90%;

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .btn {
                    font-family: 'PT Sans Caption', sans-serif;
                    font-size: rem(25px);

                    border-radius: rem(35px);

                    height: rem(86px);

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    box-sizing: border-box;

                    text-decoration: none;

                    cursor: pointer;

                    &.btn1 {
                        border: 2px solid #fff;
                        width: rem(565px);
                        color: #fff;
                    }

                    &.btn2 {
                        width: rem(277px);
                        background-color: #fff;
                        color: #00aeef;
                    }
                }
            }
        }
    }

    .capabilities {
        .container {
            padding-top: rem(90px);

            box-sizing: border-box;

            >.title {
                font-family: 'PT Sans Caption', sans-serif;
                font-size: rem(60px);
                line-height: rem(72px);
                color: #00aeef;
                text-align: center;
            }

            >.desс {
                font-family: 'PT Sans', sans-serif;
                font-size: rem(36px);
                line-height: rem(43px);
                color: #000;
                text-align: center;
                margin-top: rem(20px);
            }

            >.wrap {
                width: 70%;

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                margin: 0 auto;
                margin-top: rem(80px);

                .item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .pic {
                        width: rem(240px);
                        height: rem(240px);
                    }

                    .text {
                        font-family: 'PT Sans', sans-serif;
                        font-size: rem(30px);
                        line-height: rem(36px);
                        color: #000;
                        margin-top: rem(40px);
                        text-align: center;
                    }
                }
            }

            .list_wrap {
                padding-left: rem(185px);
                box-sizing: border-box;

                margin-top: rem(130px);

                .title {
                    color: #00aeef;
                    font-family: 'PT Sans', sans-serif;
                    font-size: rem(46px);
                    line-height: rem(55px);
                }

                .list {
                    margin-top: rem(60px);
                    position: relative;

                    &:before {
                        content: '';

                        position: absolute;
                        top: -2%;
                        left: rem(6px);
                        bottom: 0;
                        margin: auto;

                        width: rem(1px);
                        height: 89%;

                        background-color: #00aeef;
                    }

                    .item {
                        font-family: 'PT Sans', sans-serif;
                        font-size: rem(36px);
                        line-height: rem(43px);
                        color: #000;

                        margin-top: rem(35px);

                        padding-left: rem(60px);
                        box-sizing: border-box;

                        position: relative;

                        &:before {
                            content: '';

                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            margin: auto;

                            width: rem(14px);
                            height: rem(14px);

                            border-radius: 50%;

                            background-color: #00aeef;
                        }
                    }
                }
            }
        }
    }

    .help {
        position: relative;

        
        .f2 {
            width: 100%;
            height: 110vh;
    
            position: absolute;
            top: rem(-100px);
            left: rem(0);
        }

        .container {
            margin-top: rem(60px);

            .wrap {
                width: 95vh;
                height: 95vh;

                background-color: #00aeef;

                border-radius: 50%;

                margin: 0 auto;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                text-align: right;

                .title {
                    font-family: 'PT Sans', sans-serif;
                    font-weight: 700;
                    font-size: rem(46px);
                    color: #fff;
                }

                .list {
                    position: relative;

                    .item {
                        font-family: 'PT Sans', sans-serif;
                        font-weight: 700;
                        font-size: rem(36px);
                        color: #000;
                        font-weight: 400;
                        margin-top: rem(60px);

                        position: relative;

                        &:before {
                            content: '';

                            width: rem(15px);
                            height: rem(15px);

                            position: absolute;
                            top: rem(15px);
                            bottom: 0;
                            right: rem(-50px);

                            background-color: #fff;

                            border-radius: 50%;
                        }
                    }

                    &:before {
                        content: '';

                        width: rem(1px);
                        height: 53%;

                        position: absolute;
                        top: rem(80px);
                        right: rem(-43px);

                        background-color: #fff;
                    }
                }
            }
        }
    }

    .popup {
        background-color: #fff;

        width: 70%;
        height: 70vh;

        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;

        z-index: 12;

        box-shadow: rem(0 0 15px rgba(0, 0, 0, 0.1));

        display: none;

        .list {
            width: 95%;
            height: 90%;

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;

            .title {
                font-family: 'PT Sans Caption', sans-serif;
                font-size: rem(46px);
                color: #00aeef;
                text-align: center;
                margin-bottom: rem(50px);
            }

            ul {
                list-style: lower-alpha;
                color: #000;
                font-size: rem(24px);

                li {
                    margin-bottom: rem(15px);

                    a {
                        color: #00aeef;

                        transition: 0.15s;

                        &:visited {
                            color: #006e96;
                        }

                        &:hover {
                            color: #000;
                        }
                    }
                }
            }
        }

        .wrap {
            width: 65%;

            display: flex;
            flex-direction: column;

            margin: 0 auto;

            a {
                width: 100%;

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                color: #000;
                font-size: rem(24px);
                margin-bottom: rem(25px);

                text-decoration: none;

                .name {
                    width: 90%;
                }

                .date {
                    width: 10%;
                }
            }
        }

        .close {
            background: url(../img/cancel.svg) 50% 50% no-repeat;
            background-size: contain;

            width: rem(20px);
            height: rem(20px);

            position: absolute;
            top: rem(15px);
            right: rem(15px);

            cursor: pointer;
        }

    }

}

.to-top {
    width: rem(50px);
    height: rem(50px);

    position: fixed;
    left: 0;
    bottom: 5%;
    right: 0;
    margin: auto;

    border-radius: 50%;

    border: 1px solid #fff;
    
    z-index: 9999;

    opacity: 0;
    visibility: hidden;

    transition: opacity 0.3s, visibility 0.3s 0s;

    cursor: pointer;

    filter: drop-shadow(0px 0px 0.2vw #00adee);

    &.active {
        opacity: 0.7;
        visibility: visible;

        &:hover {
            opacity: 1;
        }
    }

    img {
        width: rem(50px);
        height: rem(50px);
    }
}




p a {
    border-bottom: 2px solid #5be;
    color: #555;
    background-image: linear-gradient(180deg, transparent 65%, #5bf 65%);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 0%;
}

p a:hover {
    background-size: 100% 100%;
}








@import "partials/animate.css";
@import "partials/app";